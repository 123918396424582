//Reselect
import { createSelector } from 'reselect';

const selectColorPalette = state => state.colorPalette;

export const selectCurrentColor = createSelector(
    [selectColorPalette],
    colorPalette => colorPalette.color
);

export const selectAllColors = createSelector(
    [selectColorPalette],
    colorPalette => colorPalette.color_data
);