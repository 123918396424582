import React from 'react';

//To style gia to FormInput element
import './forminput.style.scss';

//svgs
import { ReactComponent as UserLogo } from '../../images/SVG/user.svg'; //user - singin

import { ReactComponent as EmailLogo } from '../../images/SVG/alternate_email.svg';//email - singup

import { ReactComponent as LockLogo } from '../../images/SVG/locked.svg';//Locked - singin

import { ReactComponent as PencilLogo } from '../../images/SVG/pencil.svg';//Write - singup

import { ReactComponent as UnlockLogo } from '../../images/SVG/unlocked.svg';//unlock - singup



//dialegh poio icon prepei na steilei piso
const selectIcon = (icon) => {
    const classSvg = 'form-input-svg' //einai gia to style to icon
    let svgSelect = null; //gia na perasoyme to svg
    if (icon === 'user') {
        return <UserLogo className={classSvg} />;
        
    }else if(icon ==='email'){
        return <EmailLogo className={classSvg} />;

    }else if(icon === 'lock'){
        return <LockLogo className={classSvg} />;

    }else if(icon === 'write'){
        return <PencilLogo className={classSvg} />;

    }else if(icon === 'unlock'){
        return <UnlockLogo className={classSvg} />;
        
    }

    return svgSelect;
}

// const tsekWarning=(warning, value)=>{
//     if (warning && value !== "") return true;

//     return false;
// }


const FormInput = ({ handlechange, name, title, value, id,svgselect,...data }) => { //spreed warning
    //prepei na thn kanoyme kapoy render
    // {tsekWarning(warning,value)?<label className='label-dangerous' id={`#${id}`}>{`Προσοχή : ${title}`}</label> :null}

    return (
        <div className='input-element'>
            <div className='form-input'>
                {svgselect ? selectIcon(svgselect) : null}
                <input className='form-input-text'value={value} name={name} onChange={handlechange}{...data}></input>
            </div>
            </div>
            )
        }
        
        export default FormInput;