//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selector
import { selectStep } from '../../../redux/cartPage/cart.page.selectors';


//SVG
// import { ReactComponent as CartBarSvg } from '../../../assets/svg/cart-bar-steps.svg';

//Component
import SingleStep from './singleStep/single.step.component.jsx';


//Style
import {
    StepsPanelContainer,
    SpaceBetweenStepsContainer,
} from './steps.style.jsx';


class StepsCart extends Component {

    render() {
        const { stepState } = this.props;
        const activeArray = (step) => {
            switch (step) {
                case 1:
                    return [true, false, false, false];
                case 2:
                    return [false, true, false, false];
                case 3:
                    return [false, false, true, false];
                case 4:
                    return [false, false, false, true]

                default:
                    return [false, false, false, false];
            }
        };
        const selectArray = (step) => {
            switch (step) {
                case 1:
                    return [false, true, false, false];
                case 2:
                    return [true, false, false, false];
                case 3:
                    return [true, true, false, false];
                case 4:
                    return [false, false, false, false];
                default:
                    return [true, true, false, false];
            };
        };
        const active = activeArray(stepState);
        const select = selectArray(stepState);

        return (
            <StepsPanelContainer>
                <SingleStep title='ΚΑΛΑΘΙ' active={active[0]} select={select[0]} step={1} sendUrl='/cart' />
                <SingleStep title='ΣΤΟΙΧΕΙΑ' active={active[1]} select={select[1]} step={2} sendUrl='/checkout' />
                {
                    //<SingleStep title='ΤΑΜΕΙΟ' active={active[2]} select={select[2]} step={3}/>
                }
                <SingleStep title='ΟΛΟΚΛΗΡΩΣΗ' active={active[3]} select={select[3]} step={4} />
                <SpaceBetweenStepsContainer id='js-space-bar-cart' />
            </StepsPanelContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    stepState: selectStep
});


export default connect(mapStateToProps)(StepsCart);