//React
import React from 'react';

//Style
import './details.style.scss';
// import {
//     DetailsPanelStyles, DetailsTitle, DetailsTitleText, HrStyle,
//     PriceStyle, PriceText, PriceNum, FpaStyle, FpaText,
//     ModelStyle, ModelText, ModelTextRight
// } from './details.style';

const DetailsCart = ({ title, price, SelectFpa, nameModel, codeModel }) => {


    return (
        <div className='details'>

            <div className='details-title'>
                <h2 className='details-title-text'>{title}</h2>
            </div>

            <hr className='details-hr' />

            <div className='details-price'>
                <span className='details-price-text'>Από : </span>
                <span className='details-price-num'>{price} &euro;</span>
            </div>

            <div className='details-fpa'>
                <span className='details-fpa-text'>({SelectFpa} ΦΠΑ)</span>
            </div>

            <div className='details-model'>
                <div>
                    <span className='details-model-text'>Model:</span>
                    <span className='details-model-text-right'>{nameModel}</span>
                </div>
                <div>
                    <span className='details-model-text'>Code:</span>
                    <span className='details-model-text-right'>{codeModel}</span>
                </div>
            </div>

        </div>
    );
}

export default DetailsCart;