//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product61 = new product();
export const product62 = new product();
export const product63 = new product();
export const product64 = new product();
export const product65 = new product();
export const product66 = new product();
export const product67 = new product();
export const product68 = new product();
export const product69 = new product();
export const product70 = new product();

//product61----------------------------------------------------------------------------------------------------------------------------
product61.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product61.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product61.codeModelItem = '100.300.121';
product61.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 120X80 ΛΟΥΣΤΡΟ';
product61.titleItem = 'Τραπέζι Καφενείου';
product61.category = 'ΤΡΑΠΕΖΙΑ';
product61.xondriki.minSalesProducts = 3;
product61.xondriki.priceItem = 90;
product61.id = '61';

//product62----------------------------------------------------------------------------------------------------------------------------
product62.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product62.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product62.codeModelItem = '100.300.122';
product62.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 140X80 ΛΟΥΣΤΡΟ';
product62.titleItem = 'Τραπέζι Καφενείου';
product62.category = 'ΤΡΑΠΕΖΙΑ';
product62.xondriki.minSalesProducts = 3;
product62.xondriki.priceItem = 120;
product62.id = '62';

//product63----------------------------------------------------------------------------------------------------------------------------
product63.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product63.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product63.codeModelItem = '100.300.123';
product63.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 160X80 ΛΟΥΣΤΡΟ';
product63.titleItem = 'Τραπέζι Καφενείου';
product63.category = 'ΤΡΑΠΕΖΙΑ';
product63.xondriki.minSalesProducts = 3;
product63.xondriki.priceItem = 140;
product63.id = '63';

//product64----------------------------------------------------------------------------------------------------------------------------
product64.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product64.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product64.codeModelItem = '100.300.124';
product64.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 180X80 ΛΟΥΣΤΡΟ';
product64.titleItem = 'Τραπέζι Καφενείου';
product64.category = 'ΤΡΑΠΕΖΙΑ';
product64.xondriki.minSalesProducts = 3;
product64.xondriki.priceItem = 160;
product64.id = '64';

//product65----------------------------------------------------------------------------------------------------------------------------
product65.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp';
product65.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product65.codeModelItem = '100.300.125';
product65.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 50X50';
product65.titleItem = 'Τραπέζι Καφενείου';
product65.category = 'ΤΡΑΠΕΖΙΑ';
product65.xondriki.minSalesProducts = 3;
product65.xondriki.priceItem = 60;
product65.id = '65';

//product66----------------------------------------------------------------------------------------------------------------------------
product66.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp';
product66.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product66.codeModelItem = '100.300.126';
product66.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 60X60';
product66.titleItem = 'Τραπέζι Καφενείου';
product66.category = 'ΤΡΑΠΕΖΙΑ';
product66.xondriki.minSalesProducts = 3;
product66.xondriki.priceItem = 60;
product66.id = '66'; 

//product67----------------------------------------------------------------------------------------------------------------------------
product67.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp';
product67.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product67.codeModelItem = '100.300.127';
product67.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 70X70';
product67.titleItem = 'Τραπέζι Καφενείου';
product67.category = 'ΤΡΑΠΕΖΙΑ';
product67.xondriki.minSalesProducts = 3;
product67.xondriki.priceItem = 70;
product67.id = '67';

//product68----------------------------------------------------------------------------------------------------------------------------
product68.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp';
product68.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product68.codeModelItem = '100.300.128';
product68.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 80X80';
product68.titleItem = 'Τραπέζι Καφενείου';
product68.category = 'ΤΡΑΠΕΖΙΑ';
product68.xondriki.minSalesProducts = 3;
product68.xondriki.priceItem = 85;
product68.id = '68';

//product69----------------------------------------------------------------------------------------------------------------------------
product69.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp';
product69.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product69.codeModelItem = '100.300.129';
product69.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 80X90';
product69.titleItem = 'Τραπέζι Καφενείου';
product69.category = 'ΤΡΑΠΕΖΙΑ';
product69.xondriki.minSalesProducts = 3;
product69.xondriki.priceItem = 95;
product69.id = '69';

//product70----------------------------------------------------------------------------------------------------------------------------
product70.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp';
product70.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product70.codeModelItem = '100.300.130';
product70.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 90X90';
product70.titleItem = 'Τραπέζι Καφενείου';
product70.category = 'ΤΡΑΠΕΖΙΑ';
product70.xondriki.minSalesProducts = 3;
product70.xondriki.priceItem = 95;
product70.id = '70';