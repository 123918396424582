//React
import React from 'react';

//Style
import './time.style.scss';

export const Time = () => {

    // <div className='openTime-caption'>
    //     <h6>Επικοινωνία</h6>
    // </div>
    return (
        <div className='openTime'>
            <div className='places-grid'>
                <div className='openTime-place'>

                    <span>Εργοστάσιο</span>

                    <div className='openTime-place-adress'>
                        <h3>Διεύθυνση : Τατοϊου 91 (πρώην 349), Αχαρναί Τ.Κ 13672</h3>
                        <a className='phone-call' href='tel:2102312374'>Τηλέφωνο : 2102312374</a>
                    </div>

                    <div className='openTime-place-days'>

                        <p>Ωράριο Λειτουργίας</p>

                        <ul>
                            <li>Δευτέρα - Παρασκευή</li>
                            <li>09:00 - 14:00</li>
                        </ul>
                    </div>
                </div>

                <div className='openTime-place'>

                    <span>Έκθεση</span>

                    <div className='openTime-place-adress'>
                        <h3>Σάμου 92, Αχαρνές Τ.Κ 13672</h3>
                        <a className='phone-call' href='tel:2102633962'>Τηλέφωνο : 2102633962</a>
                    </div>

                    <div className='openTime-place-days'>

                        <p>Ωράριο Λειτουργίας</p>

                        <ul>
                            <li>Δευτέρα , Τετάρτη , Σάββατο</li>
                            <li>09:00 - 14:00</li>
                            <li className='days-space'>Τρίτη , Πέμπτη , Παρασκευή</li>
                            <li>09:00 - 14:00 και 17:00 - 20:00</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}