import React from 'react'

//components
// import Logo from './logo/logo.component.jsx'; //To logo sthn navigation bar
// import Search from './search/search.component.jsx'; //To search box
import Nav from './nav/nav.component.jsx'; //To nav

//style for component
import './navigation.style.scss';
// import { NavPanelStyle } from './navigation.style';

const Navigation = ({ currentUser }) => {
    return (
        <div className='navigation'>
            <Nav />
        </div>
    )
}
// <Search title='Κάντε αναζήτηση για τα προϊόντα που σας ενδιαφέρουν.'/>

export default Navigation;