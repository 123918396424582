//Actions
import { panelContainerTypes } from './panel.container.types';

export const setItems = (item) => ({
    type: panelContainerTypes.ADD_ITEMS,
    payload: item
});

export const setActive = (item) => ({
    type:panelContainerTypes.ADD_ACTIVE,
    payload:item
});

export const setDisplayArrow = (item) => ({
    type: panelContainerTypes.SET_ARROW,
    payload:item
});

export const setPanelDisplayItems = (item) => ({
   type:panelContainerTypes.SET_PANEL_DISPLAY_ITEMS,
   payload:item 
});

export const setDirection = (item) => ({
    type: panelContainerTypes.SET_DIRECTION,
    payload: item
});

export const setShowItems = (items) => ({
    type: panelContainerTypes.SET_SHOW_ITEMS,
    payload: items
});

export const setNewStyle = (item) => ({
    type: panelContainerTypes.SET_NEW_STYLE,
    payload:item
})
