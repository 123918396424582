//Types
import { orderTypes } from './order.types';


export const setNewOrder = (order) => ({
    type: orderTypes.CREATE_NEW_ORDER,
    payload: order
});

export const orderRemoveAll = () => ({
    type: orderTypes.REMOVE_ALL
});
