//Style
import styled, { css } from 'styled-components';

export const ColorPaletteContainer = styled.div`
    display:grid;
    grid-template-columns:25% 1fr;
    grid-column-gap:1.5rem;
    justify-items:center;
    padding:1.5rem;
    
    @media(max-width:700px){
        grid-template-rows:50vh max-content;
        grid-template-columns:100%
    }
`;

export const PanelImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media(max-width:700px){
        width:100%;
        height:100%;
    }
`;

export const WrapImageContainerBackground = styled.div`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    ${props => props.selectbackground ?
        {
            ...props.selectbackground
        }
        :
        {
            backgroundColor: "#666"
        }
    }

    @media(max-width:700px){
        height:90%;
        width:auto;
        border-top-right-radius: 10%;
        border-bottom-right-radius: 0%;
        border-bottom-left-radius: 10%;
    }

    @media(max-width:450px){
        height:80%;
    }
`;


export const ImagePanelContainer = styled.div`
    position: relative;
    width: 100%;


    ${props => props.backgroundimage ?
        {
            backgroundImage: props.backgroundimage
        }
        :
        {
            backgroundImage: 'url(https://res.cloudinary.com/karekles-gr/image/upload/v1602531985/Color_palette/karekla-kafeneioy-zografos-2-mask_yryo6v.webp)'
        }
    }
    background-size: contain;
    ${props => {
        return props.selectColor ?
            { ...props.selectColor }
            :
            {
                backgroundColor: '#fff',
                backgroundBlendMode: 'overlay'
            }
    }
    }

    mask-image: url(https://res.cloudinary.com/karekles-gr/image/upload/v1602531983/Color_palette/karekla-kafeneioy-zografos-2_zft4zk.webp);
    mask-size: contain;


    img:nth-child(1){
        width:100%;
        position:relative;

        &::before{
            content:'';
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
        }

        @media(max-width:700px){
            width:auto;
            height:100%;
        }
    }

    @media(max-width:700px){
        height:100%
    }
`;

export const ImageFrontDetails = styled.img`
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    ${props => props.imageopacity ?
        {
            opacity: props.imageopacity
        }
        :
        {
            opacity: '0.2'
        }
    }
    ${props => props.imageproperty &&
    {
        ...props.imageproperty
    }
    }

    ${props => props.backgroundColor &&
        { backgroundColor: props.backgroundColor }
    }
`;



export const ImageColorDefinitionContainer = styled.div`
    font-size: 1.4rem;
    letter-spacing: 1px;
    word-spacing: 1px;
    text-align: justify;
    text-align-last: center;
    color: #777;
    padding: 1rem;
    line-height:1.8;

    @media(max-width:600px){
        font-size:1.1rem;
        line-height:1.5;
    }
`;

export const PanelColorsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const HeadingContainer = styled.h1`
    display: inline-block;
    font-size: 7rem;
    font-weight: 600;
    padding: 0 15rem 1.5rem;
    background-image: linear-gradient(45deg,#ff4800 25%, #e7ff00 50%, #00ff2c 75%,#037fff 100%);
    background-size:cover;
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;

    clip-path: polygon(0% 0%,90% 0%,100% 100%,10% 100%);
    color: transparent;
    letter-spacing: 5px;
    text-transform: uppercase;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgba(0,0,0,.5);

    @media(max-width:1300px){
        font-size:5rem;
        clip-path:none;
        padding:0 0 1.5rem;
    }
    @media(max-width:600px){
        font-size:3.5rem;
        padding:0;
    }
    @media(max-width:400px){
        font-size:2.7rem;
    }
`;

export const ColorPanelContainer = styled.div`
    width: 100%;
    /* background: aqua; */
    height: 55vh;
    padding:1.5rem;
    /* overflow:overlay */
    overflow:scroll;
    

    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(20rem,20rem));
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;

    @media(max-width:1300px){
        grid-template-columns: repeat(auto-fill,minmax(15rem,15rem));
    }

    @media(max-width:1000px){
        grid-template-columns: repeat(auto-fill,minmax(10rem,10rem));
    }

    @media(max-width:600px){
        grid-template-columns: repeat(auto-fill,minmax(8rem,8rem));
        height: 35vh;
    }

    @media(max-width:450px){
        grid-template-columns: repeat(auto-fill,minmax(5rem,5rem));
        height: 30vh;
    }

    @media(max-width:400px){
        grid-template-columns: repeat(auto-fill,minmax(4rem,4rem));
    }
`;
export const PanelColorDetailsContainer = styled.div`
    display : flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    user-select:none;
    margin-top:1.5rem;

    ${props => props.select ?
        {
            fontSize: '1.4rem',
            color: '#888',
            paddingLeft: '4rem'
        }
        :
        {
            backgroundColor: '#9e9e9e0f',
            padding: '1rem 6rem',
            color: 'red',
            letterSpacing: '1px',
            fontSize: '1.5rem'
        }
    }

    p{
        font-size: 1.8rem;
        padding-bottom: .5rem;
        width: 100%;
        border-bottom: 1px solid #888;
        text-align: center;
        margin-bottom: 1rem;
    }
`;

export const ColorDetailsContainer = styled.div`
width: 100 %;
display: flex;
justify-content: flex - start;
align-items: center;
span{


    &:not(:last-child){
        margin-right: 2rem;
    }
}
`;

export const PanelButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items:center;

    width: 100%;
    margin: 2rem 0;

    @media(max-width:900px){
        flex-direction:column;
    }
`;

const buttonGeneralStyle = css`
    display:flex;
    justify-content:center;
    align-items:center;

    font-size: 2.5rem;
    letter-spacing: 1px;
    padding: 0.8rem 6rem;
    border-radius: 2px;
    color: white;
    text-align: center;
    cursor: pointer;
    user-select: none;

    @media(max-width:1300px){
        font-size:2rem;
        padding:.5rem 3rem;
    }

    @media(max-width:450px){
        font-size:1.5rem;
        padding:.5rem 1rem;
    }
`;

export const ButtonContainerSuccess = styled.div`
    ${buttonGeneralStyle}
    background-color: #009688;
    box-shadow: 0.5rem 0.5rem 1rem #00524b;

    &:hover{
        background-color:#00d4c0;
    }

    @media(max-width:900px){
        margin-bottom:3rem;
        width:100%;
    }
`;

export const ButtonContainerCancel = styled.div`
    ${buttonGeneralStyle}
    background-color: #e91e1e;
    box-shadow: 0.5rem 0.5rem 1rem #841313;

    &:hover{
        background-color:#ff4747;
    }

    @media(max-width:900px){
        width:100%;
    }
`;