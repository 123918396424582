//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect 
import { createStructuredSelector } from 'reselect';

//actions
import { sethomeSliderStartStartAnimation, setHomeSliderStartEndAnimation, setHomeSliderStartFirstTimeAnimation } from '../../../redux/homeSlider/homeSliderAnimation.action';

//selectors
import { selectorStartPanelAnimation } from '../../../redux/homeSlider/homeSliderAnimation.selectors';

//Style
import './homeSlider.start.style.scss';

class HomeSliderStart extends Component {

    componentDidUpdate(previewProps) {

        if (previewProps !== this.props && this.props.play === true
            && this.props.startPanelAnimation.firstTimeAnimation === true
            && this.props.startPanelAnimation.animationStart === false
            && this.props.startPanelAnimation.animationEnd === false) {
            //erxete gia na kanei Animation gia proti fora'

            setTimeout(() => { this.startAnimation(); }, this.props.startPanelAnimation.startAnimationTime);
        } else if (previewProps !== this.props && this.props.play === true
            && this.props.startPanelAnimation.firstTimeAnimation === false
            && this.props.startPanelAnimation.animationStart === false
            && this.props.startPanelAnimation.animationEnd === true) {
            //erxete an exei kanei Animation Esto kai mia fora

            //bazei to details sto position poy prepei na einai
            const detailsAnim = document.getElementsByClassName('description')[0];
            detailsAnim.style.transform = 'translateY(0%)';

            this.props.nextPanel();
        }

    };


    startAnimation = () => {

        // animationStart

        //prepei na enimerosi to state oti kanei ksekinaei to Animation
        //prepei na kanei to Animation

        const { startPanelstartAnimation } = this.props;

        const detailsAnim = document.getElementsByClassName('description')[0];

        //bazei to position gia to Animation
        detailsAnim.style.transform = 'translateY(100%)';


        detailsAnim.style.animation = 'anim-description 7s ease-in';

        startPanelstartAnimation();

        //Send to close the Animation
        this.finishedAnimation();
    };

    finishedAnimation = () => {
        //Take the variables
        const { startPanelAnimation: { awaitAfterAnimation } } = this.props;

        //bazei to details sto position poy prepei na einai
        const detailsAnim = document.getElementsByClassName('description')[0];
        detailsAnim.style.transform = 'translateY(0%)';


        detailsAnim.addEventListener('animationend', () => {

            //prepei na teleiosi to Animation meta apo to xrono poy toy exoyme dosei
            setTimeout(() => {
                //Prepei na enimerosi oti teliose to Animation
                const { startPanelEndAnimation, startPanelFirstTimeAnimation } = this.props;

                //teleiose kai enhmeronh to state kai epistrefi
                startPanelEndAnimation();

                //Enhmeronh oti egine h proth fora to animation
                startPanelFirstTimeAnimation();



            }, awaitAfterAnimation);
        });
    }


    render() {

        return (
            <div className='home-slider-start'>

                <div className='home-slider-start-texts'>

                    <div className='left-place'></div>

                    <div className='right-place'>

                        <h3 className='title'>Εργοστάσιο παραδοσιακών καθισμάτων και τραπεζιών</h3>

                        <span className='from-start'>Από το <span className='from-start-year'>1945</span></span>

                        <div className='description-anim-place'>

                            <p className='description'>Το Εργοστάσιο παραδοσιακών καθισμάτων και τραπεζιών ΖΩΓΡΑΦΟΣ, μέσα από την ιστορική του διαδρομή,
                            που μετράει περισσότερα από εβδομήντα χρόνια ζωής,
                            έχει αναδειχθεί σε έναν από τους πλέον αξιόπιστους και ποιοτικούς κατασκευαστές παραδοσιακών καθισμάτων και τραπεζιών με έμφαση στην ποιότητα και τις καλύτερες τιμές της αγοράς.
                            Αξιοποιώντας τις τεχνολογικές καινοτομίες και ανταποκρινόμενη στις προκλήσεις της εποχής,
                            η εταιρία έχει διευρύνει την γκάμα των προϊόντων και των υπηρεσιών που προσφέρει με μια σειρά από μοντέρνες και κλασσικές κατασκευές εξαιρετικής σχεδίασης,
                        μια πλούσια συλλογή ποιοτικών προϊόντων αποκλειστικής κατασκευής σε ιδιαίτερα ανταγωνιστικές τιμές.</p>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
};



//prepei na perasoyme to animation sto state tis redux
const mapStateToProps = createStructuredSelector({
    startPanelAnimation: selectorStartPanelAnimation
})

//Setup Animation for Start Panel
const mapDispatchToProps = (dispatch) => ({
    startPanelstartAnimation: () => dispatch(sethomeSliderStartStartAnimation()),
    startPanelEndAnimation: () => dispatch(setHomeSliderStartEndAnimation()),
    startPanelFirstTimeAnimation: (item) => dispatch(setHomeSliderStartFirstTimeAnimation(item)),
});


export default connect(mapStateToProps, mapDispatchToProps)(HomeSliderStart);