//React
import React from 'react';

//Component
import { FollowUs } from '../followUsIcons/followUsIcons.component.jsx';

//Style
import './followUsPanel.style.scss';

export const FollowPanel = () => {

    return (
        <div className='follow-us-container'>

            <div className='follow-us'>

                <div className='follow-us-box'>
                    <p className='follow-us-text'>Ακολουθήστε μας για μοναδικές Προσφορές</p>
                    <p className='follow-us-text'>σε</p>
                    <h2>Καρέκλες και Τραπέζια</h2>
                </div>

                <div className='follow-us-panel'>

                    <div onClick={() => window.open('https://www.facebook.com/%CE%9A%CE%B1%CF%81%CE%AD%CE%BA%CE%BB%CE%B5%CF%82-%CE%A4%CF%81%CE%B1%CF%80%CE%AD%CE%B6%CE%B9%CE%B1-%CE%96%CF%89%CE%B3%CF%81%CE%AC%CF%86%CE%BF%CF%82-1072643919521138/?ref=page_internal')}>
                        <FollowUs selectIcon='facebook' titleIcon='Ακολουθήστε μας στο Facebook' />
                    </div>

                    <div onClick={() => window.open('https://www.instagram.com/xariszografos/')}>
                        <FollowUs selectIcon='instagram' titleIcon='Ακολουθήστε μας στο Instagram' />
                    </div>

                </div>
            </div>

        </div>
    )
}

// <div>
// <FollowUs selectIcon='youtube' titleIcon='Γραφτείτε στο κανάλι μας στο YouTube' />
// </div>

// <div>
// <FollowUs selectIcon='email' titleIcon='Γραφτείτε στο email μας' />
// </div>