//Types
import { systemUpdateTypes } from './system.types';

//Utilities
import { setSystemPelatesMonitorCenterImage,setSystemPelatesMonitorSmallImage } from './system.utilities';

//Data for system
import { system_data } from './system.data';

//Set default data from data system
const INITIAL_STATE = system_data();


const systemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //erxete otan prepei na allaksei tis diastastis toy Center Image gia toys pelates apo to home.page
        case systemUpdateTypes.SYSTEM_PELATES_MONITOR_CENTER_IMAGE_UPDATE:
            return setSystemPelatesMonitorCenterImage(state, action.payload);
        
        //erxete otan prepei na allaksei tis diastastis toy Small Image gia toys pelates apo to home.page
        case systemUpdateTypes.SYSTEM_PELATES_MONITOR_SMALL_IMAGE_UPDATE:
            return setSystemPelatesMonitorSmallImage(state,action.payload);
        
        default:
            return state;
    }
};

export default systemReducer;