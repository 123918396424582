import { orderTypes } from './order.types';

const INIT_STATE = {
    newOrder: '',
};

const orderReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case orderTypes.CREATE_NEW_ORDER:
            return { ...state, newOrder: action.payload };

        case orderTypes.REMOVE_ALL:
            return { newOrder: '' };
        
        default:
            return { ...state }
    }

};

export default orderReducer;
