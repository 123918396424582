//React
import React from 'react';

//Style
import {
    WrapPaymentDetails,
    TitlePanel,
    DetailsContainer,
    HighlightText
} from './order.details.style.jsx';


const OrderDetails = ({ selectOrder }) => {
    if (selectOrder !== null) {

        const dateOrder = selectOrder.date.day + '  ' + selectOrder.date.time;
        const idOrder = selectOrder.settings.order_number.value;
        const priceOrder = selectOrder.prices.synolo;

        return (
            <WrapPaymentDetails>

                <div>
                    <TitlePanel>Στοιχεία Παραγγελίας</TitlePanel>
                </div>

                <DetailsContainer>
                    <span>{window.screen.availWidth > 400 ? 'Ημερομηνία ' : "Ημ/νια "}: <HighlightText>{dateOrder}</HighlightText></span>
                    <span>ID Παραγγελίας : <HighlightText>{idOrder}</HighlightText></span>
                    <span>{window.screen.availWidth> 400 ? "Συνολικό Ποσό Πληρωμής " : "Συν. Ποσό Πληρωμής "}: <HighlightText>{priceOrder} &euro;</HighlightText></span>
                </DetailsContainer>

            </WrapPaymentDetails>
        );
    } else {
        return null;
    }
};

export default OrderDetails;

