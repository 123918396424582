//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product141 = new product();
export const product142 = new product();
export const product143 = new product();
export const product144 = new product();
export const product145 = new product();
export const product146 = new product();
// export const product147 = new product();
export const product148 = new product();
export const product149 = new product();
// export const product150 = new product();

//product141----------------------------------------------------------------------------------------------------------------------------
product141.images.center = stadarURL.pagkakia + 'xoris-plati/xoris-plati_1_normal.webp';
product141.images.others = [stadarURL.pagkakia + 'xoris-plati/xoris-plati_1_normal.webp',
stadarURL.pagkakia + 'xoris-plati/xoris-plati_2_normal.webp',
stadarURL.pagkakia + 'xoris-plati/xoris-plati_3_normal.webp']
product141.codeModelItem = '100.400.107';
product141.nameModelItem = 'ΠΑΓΚΑΚΙΑ ΟΞΥΑ ΧΩΡΙΣ ΠΛΑΤΗ 100Χ45X45';
product141.titleItem = 'ΠΑΓΚΑΚΙΑ';
product141.category = 'ΠΑΓΚΑΚΙΑ';
product141.xondriki.minSalesProducts = 1;
product141.xondriki.priceItem = 70;
product141.id = '141';

//product142----------------------------------------------------------------------------------------------------------------------------
product142.images.center = stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_2_normal.webp';
product142.images.others = [stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_1_normal.webp',
stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_2_normal.webp',
stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_3_normal.webp',
stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_4_normal.webp'];
product142.codeModelItem = '100.400.108';
product142.nameModelItem = 'ΠΑΓΚΑΚΙ ΟΞΥΑ ΜΕ ΠΛΑΤΗ 100Χ45Χ45';
product142.titleItem = 'ΠΑΓΚΑΚΙΑ';
product142.category = 'ΠΑΓΚΑΚΙΑ';
product142.xondriki.minSalesProducts = 1;
product142.xondriki.priceItem = 130;
product142.id = '142';

//product143----------------------------------------------------------------------------------------------------------------------------
product143.images.center = stadarURL.pagkakia + 'pagkaki-koyreloy/pagkaki-koyreloy.webp';
product143.images.others = [stadarURL.pagkakia + 'pagkaki-koyreloy/pagkaki-koyreloy.webp']
product143.codeModelItem = '100.400.109';
product143.nameModelItem = 'ΠΑΓΚΑΚΙ ΜΕ ΚΟΥΡΕΛΟΥ 100Χ45Χ45';
product143.titleItem = 'ΠΑΓΚΑΚΙΑ';
product143.category = 'ΠΑΓΚΑΚΙΑ';
product143.xondriki.minSalesProducts = 1;
product143.xondriki.priceItem = 150;
product143.id = '143';

//product144----------------------------------------------------------------------------------------------------------------------------
product144.images.center = stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_2_normal.webp';
product144.images.others = [stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_1_normal.webp',
stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_2_normal.webp',
stadarURL.pagkakia + 'pagkaki-me-plati/pagkaki-me-plati_3_normal.webp']
product144.codeModelItem = '100.400.110';
product144.nameModelItem = 'ΠΑΓΚΑΚΙ ΟΞΥΑ ΜΕ ΠΛΑΤΗ 140Χ45Χ45';
product144.titleItem = 'ΠΑΓΚΑΚΙΑ';
product144.category = 'ΠΑΓΚΑΚΙΑ';
product144.xondriki.minSalesProducts = 1;
product144.xondriki.priceItem = 180;
product144.id = '144';

//product145----------------------------------------------------------------------------------------------------------------------------
product145.images.center = stadarURL.pagkakia + 'pagkaki-koyreloy/pagkaki-koyreloy.webp';
product145.images.others = [stadarURL.pagkakia + 'pagkaki-koyreloy/pagkaki-koyreloy.webp']
product145.codeModelItem = '100.400.111';
product145.nameModelItem = 'ΠΑΓΚΑΚΙ ΜΕ ΚΟΥΡΕΛΟΥ 130Χ45Χ45';
product145.titleItem = 'ΠΑΓΚΑΚΙΑ';
product145.category = 'ΠΑΓΚΑΚΙΑ';
product145.xondriki.minSalesProducts = 1;
product145.xondriki.priceItem = 180;
product145.id = '145';

//product146----------------------------------------------------------------------------------------------------------------------------
product146.images.center = stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_1_normal.webp';
product146.images.others = [stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_1_normal.webp',
stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_2_normal.webp',
stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_3_normal.webp',
stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_4_normal.webp']
product146.codeModelItem = '100.400.112';
product146.nameModelItem = 'ΠΑΓΚΑΚΙ ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ30Χ32';
product146.titleItem = 'ΠΑΓΚΑΚΙΑ';
product146.category = 'ΠΑΓΚΑΚΙΑ';
product146.xondriki.minSalesProducts = 1;
product146.xondriki.priceItem = 80;
product146.id = '146';

//product147----------------------------------------------------------------------------------------------------------------------------
// product147.images.center = stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_1_normal.webp';
// product147.images.others = [stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_1_normal.webp',
// stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_2_normal.webp',
// stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_3_normal.webp',
// stadarURL.pagkakia + 'nypeiagogioy/nypeiagogioy_4_normal.webp']
// product147.codeModelItem = '100.400.112';
// product147.nameModelItem = 'ΠΑΓΚΑΚΙ ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ30Χ32';
// product147.titleItem = 'ΠΑΓΚΑΚΙΑ';
// product147.category = 'ΠΑΓΚΑΚΙΑ';
// product147.xondriki.minSalesProducts = 1;
// product147.xondriki.priceItem = 80;
// product147.id = '147';

//product148----------------------------------------------------------------------------------------------------------------------------
product148.images.center = stadarURL.skampo + 'skampo-80ari/80ari_1_normal.webp';
product148.images.others = [stadarURL.skampo + 'skampo-80ari/80ari_1_normal.webp',
stadarURL.skampo + 'skampo-80ari/80ari_1_normal.webp']
product148.codeModelItem = '100.200.101';
product148.nameModelItem = 'ΣΚΑΜΠΟ 80ΑΡΙ';
product148.titleItem = 'ΣΚΑΜΠΟ';
product148.category = 'ΣΚΑΜΠΟ';
product148.xondriki.minSalesProducts = 4;
product148.xondriki.priceItem = 22;
product148.id = '148';

//product149----------------------------------------------------------------------------------------------------------------------------
product149.images.center = stadarURL.skampo + 'skampo-50ari/50ari_1_normal.webp';
product149.images.others = [stadarURL.skampo + 'skampo-50ari/50ari_1_normal.webp',
stadarURL.skampo + 'skampo-50ari/50ari_2_normal.webp',
stadarURL.skampo + "skampo-50ari/50ari_3_normal.webp"]
product149.codeModelItem = '100.200.102';
product149.nameModelItem = 'ΣΚΑΜΠΟ 50ΑΡΙ';
product149.titleItem = 'ΣΚΑΜΠΟ';
product149.category = 'ΣΚΑΜΠΟ';
product149.xondriki.minSalesProducts = 4;
product149.xondriki.priceItem = 17;
product149.id = '149';

//product150----------------------------------------------------------------------------------------------------------------------------
// product150.images.center = stadarURL.skampo + 'skampo-50ari/50ari_1_normal.webp';
// product150.images.others = [stadarURL.skampo + 'skampo-50ari/50ari_1_normal.webp',
// stadarURL.skampo + 'skampo-50ari/50ari_2_normal.webp',
// stadarURL.skampo + "skampo-50ari/50ari_3_normal.webp"]
// product150.codeModelItem = '100.200.102';
// product150.nameModelItem = 'ΣΚΑΜΠΟ 50ΑΡΙ';
// product150.titleItem = 'ΣΚΑΜΠΟ';
// product150.category = 'ΣΚΑΜΠΟ';
// product150.xondriki.minSalesProducts = 4;
// product150.xondriki.priceItem = 17;
// product150.id = '150';