//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectUser } from '../../../../redux/cartPage/cart.page.selectors';
import { selectCartItems, selectCartFPA } from '../../../../redux/cart/cart.selectors';
import { selectPriceValues } from '../../../../redux/cartPage/cart.page.selectors';

//Create Notification Data
import { setNewNotification } from '../../../../redux/notification/notification.data';

//Actions
import { SetNotificationAdd } from '../../../../redux/notification/notification.acions';
import { setNewStepNumber } from '../../../../redux/cartPage/cart.page.actions';

//Etra code
import { checkUserInports } from '../payment.js';

//Components
import { DetailsForPayment } from './details/details.component.jsx';

//Style
import {
    PanelPaymentContainer,
    ButtonSendOrderContainer,
} from './pay.details.style.jsx';



class PayDetails extends Component {
    render() {

        const { newNotify, cartItems, selectUser, history, setNewStepNumber, selectFPA, selectPrices, } = this.props;


        return (

            <PanelPaymentContainer>

                <DetailsForPayment />

                <ButtonSendOrderContainer onClick={async () => {
                    try {
                        //Πρέπει να ελέγχει ότι έστειλε ο χρήστης και να φτιάχνει το τελικό obj της παραγγελίας
                        const reselt = await checkUserInports(selectUser, cartItems, selectPrices, selectFPA);

                        //Πρέπει να πηγαίνει στο επόμενο νούμερο
                        await setNewStepNumber(4);
                        if (reselt) {

                            //Πρεπει να στέλνει τον χρήστη στην τελευταία καρτέλα για την παραγγελία του
                            history.push('/paycenter');
                        }

                    } catch (error) {
                        const notify = setNewNotification(error.message, 'error', true, 200, 3000);

                        newNotify(notify);
                    }
                }
                }>
                    ολοκληρωση παραγγελιας
                </ButtonSendOrderContainer>

            </PanelPaymentContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({

    selectUser: selectUser,
    cartItems: selectCartItems,
    selectFPA: selectCartFPA,
    selectPrices: selectPriceValues,
});

const mapDispatchToProps = dispatch => ({
    newNotify: notify => dispatch(SetNotificationAdd(notify)),
    setNewStepNumber: step => dispatch(setNewStepNumber(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayDetails);