//Redux
import { combineReducers } from 'redux'

//Redux-persist
import { persistReducer } from 'redux-persist';

// import storage from 'redux-persist/lib/storage';

import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// Reducers
import userReducer from './user/user.reducer'; // Fοr User
import cartReducer from './cart/cart.reducer'; // For Cart
import cartItemReducer from './cartItem/cartItem.reducer'; // For cartItem
import categoryReducer from './category/category.reducer'; //For Categories
import pelatisReducer from './pelates/pelatis.reducer'; //For pelates
import productsReducer from './product/products.reducer';//for products
import selectExtraChoiseReducer from './product/selectExtraChoise/selectExtraChoiseReducer';
import homeSliderAnimationReducer from './homeSlider/homeSliderAnimation.reducer'; //homeslider animation
import prosforesReducer from './prosfores/prosfores.reducer';
import panelContainerReducer from './panelContainer/panelContainer.reducer'; // Για την homepage -> panelContainer
import reviewsReducer from './reviews/reviews.reducer'; // Για τα Reviews
import notificationReducer from './notification/notification.reducer';
import systemReducer from './system/system.reducers' //state for system
import addressBarReducer from './AddressBar/addressBar.reducer';
import productsPanelReducer from './products-panel/products-panel.reducer';
import colorPaletteReducer from './colorPalette/colorPalette.reducer';
import cartPageReducer from './cartPage/cart.page.reducer';
import orderReducer from './order/order.reducer';
import smallNavBarReducer from './small_nav_bar/small.nav.bar.reducer';

//Create web storage 
const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();



//@key = to key gia na kanoyme retrive ta dedomena
//@storage = se poio meros 8eloyme na apo8ikeysoyme ta dedomena
//@whiteList = bazoyme toys reducer poy 8eloyme na pername sto localstorage

const persistConfig = {
    key: 'karekles-root',
    storage: storage,
    whiteList: ['cart'],
    blackList: ['homeSliderAnimation']
}

const persistConfigCartPage = {
    key: 'karekles-cartPage',
    storage: storage,
    whiteList: ['cartPage'],
};


const persistConfigOrder = {
    key: 'karekles-order',
    storage: storage,
    whiteList: ['order']
};

const rootReducer = combineReducers({
    user: userReducer,
    // cart: cartReducer,
    cart: persistReducer(persistConfig, cartReducer),
    // cartPage: cartPageReducer,
    cartPage: persistReducer(persistConfigCartPage, cartPageReducer),
    cartSelectItem: selectExtraChoiseReducer,
    cartItems: cartItemReducer,
    categories: categoryReducer,
    // order: orderReducer,
    order: persistReducer(persistConfigOrder, orderReducer),
    pelates: pelatisReducer,
    system: systemReducer,
    products: productsReducer,
    panelContainer: panelContainerReducer,
    reviews: reviewsReducer,
    homeSliderAnimation: homeSliderAnimationReducer,
    prosfores: prosforesReducer,
    notifications: notificationReducer,
    productsPanel: productsPanelReducer,
    addressBar: addressBarReducer,
    colorPalette: colorPaletteReducer,
    smallNavBar: smallNavBarReducer
});

export default rootReducer;