//React
import React from 'react';

//component svg
import FlagSVG from './svg/flagSVG.component.jsx';

//Style
import './flag.style.scss';
// import { FlagStyle, FlagLogoStyle, FlagTextStyle } from './flag.style';

const Flag = ({ flagName, isRotate }) => {
    return (
        <div className='flag' title={flagName}>

            <div isRotate={isRotate} className='flag-logo'>
                <FlagSVG elementName={flagName} />
            </div>

            <span className='flag-text'>{flagName}</span>

        </div>
    )
}

export default Flag;