//React
import React from 'react';

//SVG
import { ReactComponent as ArrowLogo } from '../../assets/icons/arrow-up-circle.svg';

//Style
// import './button-up.style.scss';
import { ButtonUpStyle } from './button-up.style';

//Απο αυτο το σημειο και κατω θα εμφανιζετε το ButtonUp
const scrollPoint = 100;


export const ButtonUp = () => {

    return (
        <ButtonUpStyle id='js-btn-up' className='buttonUp' onClick={goUp} title='Επιστροφή στην κορυφή' >
            <ArrowLogo className='buttonUp-svg' />
        </ButtonUpStyle>
    )
};


const goUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

if (typeof window !== "undefined") {
    window.addEventListener('scroll', () => {
        if (window.scrollY > scrollPoint) {
            document.getElementById('js-btn-up').style = ({ transform: 'scale(1)' }) // classList.add('buttonUp-show');
        } else {
            document.getElementById('js-btn-up').style = ({ transform: 'scale(0)' }) //classList.remove('buttonUp-show');
        }
    });
}