import styled from 'styled-components';

export const SmallContainer = styled.div`
    
    width:2rem;
    height:2rem;

    svg{
        width:100%;
        height:100%;
        fill:#444;
    }
`;