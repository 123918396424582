//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Action
import { setDefaultValues, setProductValues, setExtraChoiseOBJ, setNewSaleItems } from '../../redux/product/selectExtraChoise/selectExtraChoiseActions';
import { removeSelectColor } from '../../redux/colorPalette/colorPalette.actions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectProductsAll } from '../../redux/product/product.selectors';
import { selectCurrentColor } from '../../redux/colorPalette/colorPalette.selector';

//Gia to Extra choise toy product 
import { selectExtraChoise, selectSaleItems } from '../../redux/product/selectExtraChoise/selectExtraChoiseSelectors'


//Components
import AddressBar from '../../components/AddressBar/address.component.jsx';
import ImagePanel from '../../components/imagePanel/image.panel.component.jsx';
import ProductDetails from '../../components/product-details/product.details.component.jsx';

//Extra Code
import { productExtraPrices } from '../../redux/product/selectExtraChoise/selectExtraChoiseData.js';

//Style
import { ProductItemContainer, NotFindProductContainer, ProductContainer } from './product.item.style.jsx';

class ProductItemPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            codeItem: props.match.params.itemID,
            product: {},
            saleItems: null,
            status: false,
            update: false
        };
    }
    //Edo prepei na ftiaksei to object gia to Extra Price to Product
    newExtraPriceObject = {};

    static getDerivedStateFromProps(props, state) {

        //Prepei na doyme an einai to idio proion apo8hkeymeno
        if (state.codeItem !== props.extraChoise.product.id) {

            //Prepei na dei an o kvdikos yparxei sth DATA
            const findProductFromData = props.products.filter((pr) => pr.id === state.codeItem);
            let productFind = '';

            //Πρέπει να ελεγχεί αν υπάρχει το ID. Εάν υπάρχει επιστρέφει το (object) αλλιός (null)
            if (findProductFromData.length) {
                //convert to object the Find product
                productFind = { ...findProductFromData[0] }
            } else {
                //Not match search for product
                productFind = null;
            };


            if (productFind === null) {
                state = { ...state, product: {}, status: false };
            } else {
                state = { ...state, product: productFind, status: true, update: true };
            }

            // props.setProduct()
            return state;
        } else {
            state = { ...state, product: { ...props.extraChoise.product }, status: true, saleItems: props.saleItems }
            return state;
        }

    }

    update = async () => {
        //Prepei na balei tis default times
        await this.props.setDefaultValues();

        //Prepei na balei to epilegmeno product sth redux
        await this.props.setProduct(this.state.product);

        //Prepei na pairnaei tis elaxistes posotites gia agora gia to proion
        await this.props.setNewSaleItems(this.state.product.xondriki.minSalesProducts);

        //Prepei na midenisei to color
        await this.props.removeSelectColor();

        const { category } = this.state.product;

        //Prepei na blepei tis times apo to standar extra choise na tis sygkrinei kai na pairnaei tis sostes gia ka8e epilogi
        this.newExtraPriceObject = {

            color: {
                ...this.props.extraChoise.color,
                show: category === 'ΚΑΡΕΚΛΕΣ' ? true :
                    category === 'ΣΚΑΜΠΟ' ? true :
                        category === 'ΣΚΗΝΟΘΕΤΗ' ? true :
                            category === 'ΚΑΡΕΚΛΑΚΙΑ' ? true :
                                true,
                showNoCost: true,
                price: category === 'ΚΑΡΕΚΛΕΣ' ? productExtraPrices.chair.color :
                    category === 'ΣΚΑΜΠΟ' ? productExtraPrices.chair.color :
                        category === 'ΣΚΗΝΟΘΕΤΗ' ? productExtraPrices.skhnotheti.color :
                            category === 'ΚΑΡΕΚΛΑΚΙΑ' ? productExtraPrices.kareklakia.color :
                                productExtraPrices.table.color,
                selectColor: this.props.selectCurrentColor,//8a prepei na erxete apo to xromatologio

                text: 'Από το Χρωματολόγιο',
            },

            loystro: {
                ...this.props.extraChoise.loystro,
                show: category === 'ΚΑΡΕΚΛΕΣ' ? true :
                    category === 'ΣΚΑΜΠΟ' ? true :
                        category === 'ΚΑΡΕΚΛΑΚΙΑ' ? true :
                            false,
                showNoCost: true,
                price: productExtraPrices.chair.loystro
            },

            syrma: {
                ...this.props.extraChoise.syrma,
                show: category === 'ΚΑΡΕΚΛΕΣ' ? true :
                    category === 'ΣΚΑΜΠΟ' ? true :
                        category === 'ΚΑΡΕΚΛΑΚΙΑ' ? true :
                            false,
                showNoCost: true,
                price: productExtraPrices.chair.syrma
            },

            seat: {
                ...this.props.extraChoise.seat,
                show: category === 'ΚΑΡΕΚΛΕΣ' ? true :
                    category === 'ΣΚΑΜΠΟ' ? true :
                        category === 'ΚΑΡΕΚΛΑΚΙΑ' ? true :
                            false,
                showNoCost: true,
                price: productExtraPrices.chair.seat
            },

            legs: {
                ...this.props.extraChoise.legs,
                show: category === 'ΤΡΑΠΕΖΙΑ' ? true : false,
                showNoCost: true,
                price: productExtraPrices.table.legs
            }
        }

        await this.props.setExtraChoiseOBJ(this.newExtraPriceObject);
    }


    componentDidMount() {
        if (this.state.update) {
            this.update().then(() => {

                this.setState({ ...this.state, update: false });
            });
        }
    };

    render() {
        const { images, nameModelItem, titleItem, category } = this.state.product;



        return (
            <React.Fragment>
                <AddressBar />
                <ProductItemContainer>
                    {this.state.status ?
                        <ProductContainer>
                            <ImagePanel images={images} details={nameModelItem + ' ' + titleItem + ' ' + category} />
                            <ProductDetails product={this.state.product} saleItems={this.state.saleItems} />
                        </ProductContainer>
                        :
                        //Ερχετε μόνο άν δεν βρεθή κάποιο προϊόν
                        <NotFindProductContainer>
                            <p>Το Προϊόν δεν βρέθηκε</p>
                        </NotFindProductContainer>
                    }
                </ProductItemContainer>
            </React.Fragment>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    products: selectProductsAll,
    extraChoise: selectExtraChoise,
    saleItems: selectSaleItems,
    selectCurrentColor: selectCurrentColor
})

const mapDispatchToProps = dispatch => ({
    setDefaultValues: async () => await dispatch(setDefaultValues()),
    setProduct: async (product) => await dispatch(setProductValues(product)),
    setNewSaleItems: async (items) => await dispatch(setNewSaleItems(items)),
    setExtraChoiseOBJ: async (obj) => await dispatch(setExtraChoiseOBJ(obj)),
    removeSelectColor: async () => await dispatch(removeSelectColor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductItemPage);