//Extra choise
//Οι επιλογές που μπορούμε να κάνουμε στο μενού Ταξινόμηση
const sortOptions = {
    DEFAULT: 'default',
    NAMEA_Z: 'nameA-Z',
    NAMEZ_A: 'nameZ-A',
    PRICELOW: 'priceLow',
    PRICEBIG: 'priceBig',
    CODEA_Z: 'codeA-Z',
    CODEZ_A: 'codeZ-A'
};

//Έρχετε για να κάνει Ταξινόμηση στα Items
export const sortItemsFn = (allItems, classification) => {

    // const { classification, allItems } = this.props.pageProperties;

    //prepei na elegxei ton tropo poy kanei sort
    switch (classification) {
        case sortOptions.DEFAULT:
            return allItems.sort((a, b) => (a.id > b.id) ? 1 : -1);

        case sortOptions.NAMEA_Z:
            return allItems.sort((a, b) => (a.nameModelItem > b.nameModelItem) ? 1 : -1);

        case sortOptions.NAMEZ_A:
            return allItems.sort((a, b) => (a.nameModelItem < b.nameModelItem) ? 1 : -1);

        case sortOptions.PRICELOW:
            return allItems.sort((a, b) => (a.xondriki.priceItem > b.xondriki.priceItem) ? 1 : -1);

        case sortOptions.PRICEBIG:
            return allItems.sort((a, b) => (a.xondriki.priceItem < b.xondriki.priceItem) ? 1 : -1);

        case sortOptions.CODEA_Z:
            return allItems.sort((a, b) => (a.codeModelItem > b.codeModelItem) ? 1 : -1);

        case sortOptions.CODEZ_A:
            return allItems.sort((a, b) => (a.codeModelItem < b.codeModelItem) ? 1 : -1);

        default:
            return allItems.sort((a, b) => (a.id > b.id) ? 1 : -1);
    }
};