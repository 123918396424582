import styled from "styled-components";

export const SvgLogoStyle = styled.div`
    svg{
        width:100%;
        height:100%;
    }
`;

export const SvgPathStyle = styled.path`
    ${props => props.pathFill !== false ?
        { fill: '#cecece' }
        :
        { fill: 'none' }
    }

    ${props => props.pathStroke === true &&
    {
        stroke: '#cecece',
        strokeWidth: 2.36,
    }}
    
    ${props => props.pathStrokeBib === true &&
    {
        strokeWidth: 8
    }}
    
    ${props => props.pathStrokeMedium === true &&
    {
        strokeWidth: 5
    }
    }
`;

export const CameraImagePlaceStyle = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const CameraImageNumStyle = styled.h4`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #cecece;
    font-size: 1.5rem;
    font-weight: bold;

    &::selection {
        background-color: transparent;
    }
`;

let circleLogoSelect = false;

export const CircleMenuStyle = styled.div`
    display: inline-flex;
    padding: .6rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s;
    ${circleLogoSelect = false}

    &:hover {
        background-color: #fff;
        ${circleLogoSelect = true}
    }

`;

export const CircleMenuLogoStyle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    fill: #fff;
    transition: fill .2s;

    ${circleLogoSelect && {
        fill: '#DB4437',
        stroke: 'white',
        strokeWidth: 2
    }}
`;

