export const cartPageTypes = {
    CHANGE_STEP_NUMBER: 'CHANGE_STEP_NUMBER',

    CHANGE_USER_VALUES: 'CHANGE_USER_VALUES',

    TIMOLOGIO_VALUE: 'TIMOLOGIO_VALUE',

    CHANGE_CART_VALUES: 'CHANGE_CART_VALUES',

    SET_ORDER_SETTINGS: 'SET_ORDER_SETTINGS',

    SET_DATE: 'SET_DATE',
    
    REMOVE_ALL:'REMOVE_ALL'
};