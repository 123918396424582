//Style
import styled, { css } from "styled-components";




const cssBlock = css`
    height:100%;
    width:100%;
    fill:dimgray;
    cursor:pointer;

    transition:fill .5s ease,height .5s ease,width .5s ease;

    &:hover{
        fill:#8d6b52;
        height:120%;
        width:120%;
    }
`;

const ArrowDisable = css`
    cursor:default;
    fill:dimgray;
`;



export const ArrowPointer = styled.div`
    width:6rem;
    height:6rem;
    margin:2rem;
    display:flex;
    justify-content:center;
    align-items:center;

    @media(max-width:900px){
        width:4rem;
        height:4rem;
    }
`;
//(prop) => <ArrowLeft {...prop} />
export const SVGContainerLeft = styled.div` 
    ${cssBlock};
    ${prop => prop.enable === 'false' && ArrowDisable};
`;
//(prop) => <ArrowRight {...prop} />
export const SVGContainerRight = styled.div` 
    ${cssBlock};
    ${prop => prop.enable === 'false' && ArrowDisable};
`;