import styled from "styled-components";

export const ButtonUpStyle = styled.div`

    width: 7rem;
    height: 7rem;
    display: block;
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    cursor: pointer;
    z-index: 1000;
    transform: scale(0);

    transition: transform .5s ease;

    svg{
        fill: dimgrey;

        transition: fill .2s ease;

        &:hover{
            fill: #979797;
        }
    }

    @media only screen and (max-width:1000px){
        width: 6rem;
        height: 6rem;
        right: 2rem;
        bottom: 2rem;
    }

    @media only screen and (max-width:600px){
        width: 5.5rem;
        height: 5.5rem;
        right: 2rem;
        bottom: 2rem;
    }

    @media only screen and (max-width:400px){
        width: 5rem;
        height: 5rem;
        right: 1.5rem;
        bottom: 1.5rem;
    }

    @media only screen and (max-width:300px){
        width: 4.5rem;
        height: 4.5rem;
        right: 1.5rem;
        bottom: 1.5rem;
    }
`;