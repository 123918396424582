//React
import React, { Component } from 'react';

//Style
import { WrapPriceContainer } from './itemPrice.style.jsx';

class ItemPrice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            priceItem: props.priceItem,
            extraPrice: props.extraPrice
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props !== state) {
            state = { ...props }
            return state;
        };

        return null;
    };

    render() {

        return (
            <WrapPriceContainer>

                <span>{this.state.priceItem + this.state.extraPrice} &euro;</span>
                <p>Τιμή Τεμαχίου</p>

            </WrapPriceContainer>
        );
    }
}

export default ItemPrice;