import styled from "styled-components";

export const CartHoverMainStyle = styled.div`
    display: grid;
    
    grid-template-columns: 40px max-content;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    grid-row-gap: 1.5rem;
    align-items: center;
    justify-content: center;
`;

export const MainLogo = styled.div`
    height: 3rem;
    width: 3rem;
`;

export const MainText = styled.h4`
    font-size: 2rem;
    color: #cecece;
    letter-spacing: 1.5px;
    &::selection{
        background-color: transparent;
    }
    @media only screen and (max-width:270px){
        font-size: 1.8rem;
    }
`;