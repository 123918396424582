//React
import React from 'react';

//Style
import './image.style.scss';
// import { ImageLogoStyle} from './image.style';


const ImageCart = ({scr,alt,title}) => {
    return (
        <img className='image-logo' src={scr} alt={alt} title={title} />
    )
}

export default ImageCart;