//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Action
import { setNewStepNumber } from '../../../redux/cartPage/cart.page.actions';


//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectCountOnCart, selectCartItems } from '../../../redux/cart/cart.selectors';

//Components
import PreCart from '../preCart/pre.cart.component.jsx';

//SVG
import { ReactComponent as CartLogo } from '../../../images/basket.svg';//../../../images/basket.svg'

//Style
import {
    NavCartContainer,
    ItemTextContainer,
    CartLogoSVGContainer,
} from './navCart.style';

class NavCart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false
        }
    };

    handlerMouseEnterState = () => {
        this.setState({ isHovering: true });
    };

    handlerMouseLeaveState = () => {
        this.setState({ isHovering: false });
    };

    render() {
        const { title, itemCount, items, location, history } = this.props;

        const temaxia = `${itemCount} Προϊόν`;
        return (
            <React.Fragment>
                <NavCartContainer
                    title={title}
                    item={itemCount}
                    location={location}
                    onMouseLeave={() => this.handlerMouseLeaveState()}
                    onMouseEnter={() => {
                        location.pathname !== '/cart' &&
                            location.pathname !== '/checkout' &&
                            location.pathname !== '/paycenter' &&
                            itemCount > 0 ?
                            this.handlerMouseEnterState()
                            :
                            this.handlerMouseLeaveState()
                    }}
                    onClick={() => {
                        this.props.setNewStep(1);
                        items.length && location.pathname !== '/cart' && location.pathname !== '/checkout' && location.pathname !== '/paycenter' && history.push('/cart');
                    }}

                >
                    <CartLogoSVGContainer>
                        <CartLogo style={{ width: '100%', height: '100%' }} />
                    </CartLogoSVGContainer>
                    <ItemTextContainer>{temaxia}</ItemTextContainer>
                </NavCartContainer>
                {   location.pathname !== '/cart' && location.pathname !== '/checkout' && location.pathname !== '/paycenter' &&
                    this.state.isHovering && <PreCart onOut={this.handlerMouseLeaveState} items={items} />}

            </React.Fragment>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    itemCount: selectCountOnCart,
    items: selectCartItems,
});

const mapDispatchToProps = dispatch => ({
    setNewStep: num => dispatch(setNewStepNumber(num)),
})


export default connect(mapStateToProps, mapDispatchToProps)(NavCart);