//Style
import styled, { css } from 'styled-components';

export const ListItemContainer = styled.div`
    width: 60%;
    /* border: inset; */
    margin: 0 auto;
    padding: 1rem;
    user-select: none;

    @media(max-width:1300px){
        width:90%;
    }
    @media(max-width:700px){
        width:98%;
    }
`;

export const NoItemsCartContainer = styled.div`
    text-align:center;
    font-size: 3rem;
    color: #777;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width:500px){
        font-size:2rem;
    }

    @media(max-width:400px){
        font-size:1.7rem;
    }
`;

export const TitleGridContainer = styled.div`
    display: grid;
    grid-template-columns:5% 45% 10% 20% 12% 8%;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #999a;
    color:#999;

    span{
        width:100%;
        border-right:1px dashed #999a;
        text-align:center;
    }

    @media(max-width:600px){
        grid-template-columns: 45% 10% 20% 12% 13%;
    }
    
    @media(max-width:550px){
        grid-template-columns: 50% 10% 20% 12% 8%;
    }
    @media(max-width:450px){
        grid-template-columns: 55% 10% 15% 12% 8%;
    }

    @media(max-width:450px){
        font-size:1.1rem;

    }
`;

const generalPriceStyle = css`
    display: grid;
    grid-template-columns: 75% 25%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1rem 0;
    color: #999;

    @media(max-width:700px){
        font-size:1.1rem;
    }
    `;


export const WrapPriceContainer = styled.div`
    ${generalPriceStyle}
    border-top: 1px solid #999a;
`;

export const GeneralPriceContainer = styled.div`
    ${generalPriceStyle}
    padding:0 0 1rem;
`;

export const LastPriceContainer = styled.div`
    ${generalPriceStyle}
    padding: 0 0 1rem;
    border-bottom:1px solid #999a;
`;

export const TextPriceContainer = styled.span`
    width: 100%;
    border-right: 1px dashed #222a;
    text-align: end;
    padding-right: 2rem;
`;

export const WrapButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 2rem auto 5rem;

    @media(max-width:850px){
        flex-direction:column;
        align-items:center;
    }
`;

export const ButtonContiniouBuy = styled.div`
    font-size: 2rem;
    letter-spacing:1px;
    font-weight:bold;
    text-transform: uppercase;
    padding: 1rem 4rem;
    background-color: #E91E63;
    width: 40rem;
    text-align: center;
    color: white;
    cursor:pointer;

    transition:background-color .2s ease;

    &:hover{
        background-color:#FF9800;
    }

    @media(max-width:1100px){
        font-size: 1.8rem;
        padding: 1rem 3rem;
        width: 30rem;
    }

    @media(max-width:850px){
        order:2;
    }

    @media(max-width:550px){
        width:90%;
    }
`;

export const ButtonBuy = styled.div`
    font-size: 2rem;
    letter-spacing:1px;
    font-weight:bold;
    text-transform: uppercase;
    padding: 1rem 4rem;
    background-color: #009688;
    width: 60rem;
    text-align: center;
    color: white;
    cursor:pointer;

    transition:background-color .2s ease;

    &:hover{
        background-color:#FF9800;
    }

    @media(max-width:1100px){
        font-size: 1.8rem;
        padding: 1rem 3rem;
        width: 50rem;
    }
    
    @media(max-width:850px){
        order:1;
        margin-bottom:2rem;
    }
    
    @media(max-width:550px){
        width:90%;
    }
`;