export const system_data = () => {
    if (typeof window !== "undefined") {
        return {
            pelates_view: {
                //einai gia ta msg alert
                consoloLog: true,

                change_pelati_timer: 4,

                pelates_random_choise: true,
                load_max_pelates: 10,

                monitor: {
                    random_center_image: true,

                    random_small_image: true,

                    small_image_choise: 5,

                    synolo_width_small_image_wrap: 0,

                    center_image_dimension: {

                        width: window.screen.availWidth > 1100 ? 600 : Number.parseFloat(window.screen.availWidth * .7),
                        height: window.screen.availWidth > 1100 ? 600 : Number.parseFloat(window.screen.availWidth * .7),
                        center_radius: 0,
                        border: window.screen.availWidth > 700 ? 10 : 5,
                        color: 'white',
                        posision: []
                    },

                    small_image_dimension: {
                        percent: 0.25,
                        wrap_percent: 0.35,
                        width: 0,
                        height: 0,
                        border: window.screen.availWidth > 700 ? 10 :
                            window.screen.availWidth > 380 ? 5 : 2,
                        color: 'white',
                        posision: []
                    }
                }
            }
        }
    } else {

        return {
            pelates_view: {
                //einai gia ta msg alert
                consoloLog: true,

                change_pelati_timer: 4,

                pelates_random_choise: true,
                load_max_pelates: 10,

                monitor: {
                    random_center_image: true,

                    random_small_image: true,

                    small_image_choise: 5,

                    synolo_width_small_image_wrap: 0,

                    center_image_dimension: {

                        width: 600,
                        height: 600,
                        center_radius: 0,
                        border: 10,
                        color: 'white',
                        posision: []
                    },

                    small_image_dimension: {
                        percent: 0.25,
                        wrap_percent: 0.35,
                        width: 0,
                        height: 0,
                        border: 10,
                        color: 'white',
                        posision: []
                    }
                }
            }
        }

    };
}


    // pelates_view: {
    //     //einai gia ta msg alert
    //     consoloLog: true,

    //         change_pelati_timer: 4,

    //             pelates_random_choise: true,
    //                 load_max_pelates: 10,

    //                     monitor: {
    //         random_center_image: true,

    //             random_small_image: true,

    //                 small_image_choise: 5,

    //                     synolo_width_small_image_wrap: 0,

    //                         center_image_dimension: {

    //             width: typeof (window.screen) !== "undefined" ? window.screen.availWidth > 1100 ? 600 : Number.parseFloat(window.screen.availWidth * .7)
    //                 : 600,
    //                 height: typeof (window.screen) !== "undefined" ? window.screen.availWidth > 1100 ? 600 : Number.parseFloat(window.screen.availWidth * .7)
    //                     : 600,
    //                     center_radius: 0,
    //                         border: typeof (window.screen) !== "undefined" ? window.screen.availWidth > 700 ? 10 : 5
    //                             : 10,
    //                             color: 'white',
    //                                 posision: []
    //         },

    //         small_image_dimension: {
    //             percent: 0.25,
    //                 wrap_percent: 0.35,
    //                     width: 0,
    //                         height: 0,
    //                             border: typeof (window.screen) !== "undefined" ? window.screen.availWidth > 700 ? 10 :
    //                                 window.screen.availWidth > 380 ? 5 : 2

    //                                 : 10,
    //                                 color: 'white',
    //                                     posision: []
    //         }
    //     }
    // }
