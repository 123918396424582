//Create class for items

//
class colorItemData {
    id = '';
    name = '';
    color = ''
    code = '';
    source = '';
    background = '';
    backgroundImage = 'url(https://res.cloudinary.com/karekles-gr/image/upload/v1602531985/Color_palette/karekla-kafeneioy-zografos-2-mask_yryo6v.webp)';
    maskImageOpacity = 0.2;
    imageProperty = '';
    backgroundColor = null;
};

const color1 = new colorItemData();
color1.id = 0;
color1.name = 'AM';
color1.code = '541/13';
color1.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295556/Color_palette/colors/AM_541_13-NEW_dgrbem.webp';
color1.color = {
    backgroundColor: '#fff',
    backgroundBlendMode: 'color',
};
color1.background = {
    backgroundColor: '#666',
}
color1.maskImageOpacity = 0.7;


//
const color2 = new colorItemData();
color2.id = 1;
color2.name = 'AM';
color2.code = '550/10';
color2.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_10-NEW_hixrsz.webp';
color2.color = {
    backgroundColor: '#89e8bc',
    backgroundBlendMode: 'overlay',
};
color2.background = {
    backgroundColor: '#666',
}
color2.maskImageOpacity = 0.2;

//
const color3 = new colorItemData();
color3.id = 2;
color3.name = 'AM';
color3.code = '550/15';
color3.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_15-NEW_ggcrwi.webp';
color3.color = {
    backgroundColor: '#40464991',
    backgroundBlendMode: 'saturation',
};
color3.background = {
    backgroundColor: '#ededed',
}
//
const color4 = new colorItemData();
color4.id = 3;
color4.name = 'AM';
color4.code = '550/20';
color4.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_20-NEW_fpphpn.webp';
color4.color = {
    backgroundColor: '#044586',
    backgroundBlendMode: 'overlay',
};
color4.maskImageOpacity = 0.1;
color4.backgroundImage = 'none';
color4.background = {
    backgroundColor: '#ededed',
}
//
const color5 = new colorItemData();
color5.id = 4;
color5.name = 'AM';
color5.code = '550/21';
color5.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_21-NEW_dsk11u.webp';
color5.color = {
    backgroundColor: '#de0278',
    backgroundBlendMode: 'overlay',
};
color5.backgroundImage = 'none';
//
const color6 = new colorItemData();
color6.id = 5;
color6.name = 'AM';
color6.code = '550/22';
color6.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_22-NEW_wso6y6.webp';
color6.color = {
    backgroundColor: '#000',
    backgroundBlendMode: 'overlay',
    // filter: 'grayscale(1)',
};
color6.maskImageOpacity = 0.1;
//
const color7 = new colorItemData();
color7.id = 6;
color7.name = 'AM';
color7.code = '550/23';
color7.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_23-NEW_xf1pzf.webp';
color7.color = {
    backgroundColor: '#ffe226',
    backgroundBlendMode: 'overlay',
};
color7.maskImageOpacity = 0.1;
color7.imageProperty = {
    filter: 'invert(1)'
}
//
const color8 = new colorItemData();
color8.id = 7;
color8.name = 'AM';
color8.code = '550/24';
color8.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_24-NEW_uika5c.webp';
color8.color = {
    backgroundColor: '#fb6855',
    backgroundBlendMode: 'overlay',
};

//
const color9 = new colorItemData();
color9.id = 8;
color9.name = 'AM';
color9.code = '550/25';
color9.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_25-NEW_u9ahc5.webp';
color9.color = {
    backgroundColor: '#f2335f',
    backgroundBlendMode: 'overlay',
};
//
const color10 = new colorItemData();
color10.id = 9;
color10.name = 'AM';
color10.code = '550/31';
color10.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_31-NEW_dtlopq.webp';
color10.color = {
    backgroundColor: '#3d7299',
    backgroundBlendMode: 'overlay',
};
color10.backgroundImage = 'none';

//
const color11 = new colorItemData();
color11.id = 10;
color11.name = 'AM';
color11.code = '550/33';
color11.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_33-NEW_yepn2o.webp';
color11.color = {
    backgroundColor: '#ede1e5',
    backgroundBlendMode: 'overlay',
};
color11.maskImageOpacity = 0.1;
color11.backgroundImage = 'none';
color11.imageProperty = {
    filter: 'invert(1)'
}
color11.background = { backgroundColor: '#333' };
//
const color12 = new colorItemData();
color12.id = 11;
color12.name = 'AM';
color12.code = '550/36';
color12.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_36-NEW_pgbts5.webp';
color12.color = {
    backgroundColor: '#5c2c48',
    backgroundBlendMode: 'overlay',
};
color12.backgroundImage = 'none';
color12.maskImageOpacity = 0.4;
color12.imageProperty = {
    filter: 'invert(1)'
};
//
const color13 = new colorItemData();
color13.id = 12;
color13.name = 'AM';
color13.code = '550/46';
color13.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_46-NEW_imjt1k.webp';
color13.color = {
    backgroundColor: '#fbd4ad',
    backgroundBlendMode: 'overlay',
};
color13.imageProperty = {
    filter: 'invert(0.5)'
};
//
const color14 = new colorItemData();
color14.id = 13;
color14.name = 'MOGANO';
color14.code = '90';
color14.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/MOGANO_90-NEW_all97x.webp';
color14.color = {
    backgroundColor: '#953518',
    backgroundBlendMode: 'overlay',
};
color14.maskImageOpacity = 0.3;
color14.imageProperty = {
    filter: 'invert(1)'
};
color14.backgroundImage = 'none';
//
const color15 = new colorItemData();
color15.id = 14;
color15.name = 'NOCE';
color15.code = '92';
color15.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295558/Color_palette/colors/NOCE_92-NEW_xhqu5a.webp';
color15.color = {
    backgroundColor: '#bb704c',
    backgroundBlendMode: 'multiply'
};
//
const color16 = new colorItemData();
color16.id = 15;
color16.name = 'NOCE BRENNERO';
color16.code = '84';
color16.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295558/Color_palette/colors/NOCE_BRENNERO_84-NEW_xqksjq.webp';
color16.color = {
    backgroundColor: '#261d19',
    backgroundBlendMode: 'overlay',
};
color16.maskImageOpacity = 0.1;
//
const color17 = new colorItemData();
color17.id = 16;
color17.name = 'NOCE MEDIO';
color17.code = '94';
color17.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295558/Color_palette/colors/NOCE_MEDIO_94-NEW_f7c8iv.webp';
color17.color = {
    backgroundColor: '#2f1e14',
    backgroundBlendMode: 'difference',
};
//
const color18 = new colorItemData();
color18.id = 17;
color18.name = 'VERDE';
color18.code = '66';
color18.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295558/Color_palette/colors/VERDE_66-NEW_qc6i73.webp';
color18.color = {
    backgroundColor: '#285e35',
    backgroundBlendMode: 'overlay',
};

//
const color19 = new colorItemData();
color19.id = 18;
color19.name = 'AM';
color19.code = '550/28';
color19.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295557/Color_palette/colors/AM_550_28-NEW_xerqlq.webp';
color19.color = {
    backgroundColor: '#f4c386',
    backgroundBlendMode: 'overlay',
};
color19.backgroundImage = 'none';
color19.imageProperty = {
    filter: 'invert(.5)'
};

const color20 = new colorItemData();
color20.id = 19;
color20.name = 'Αναπαλαίωση';
color20.code = 'Γκρί';
color20.source = 'https://res.cloudinary.com/karekles-gr/image/upload/v1603295556/Color_palette/colors/AM_541_13-NEW_dgrbem.webp';
color20.color = {
    backgroundColor: '#fff',
    backgroundBlendMode: 'overlay',
};
color20.background = {
    backgroundColor: '#666',
}
color20.maskImageOpacity = 0.3;
color20.backgroundColor = '#000';
//

export const colorPaletteData = [color1, color2, color3, color4, color5, color6, color7, color8, color9, color10, color11, color12, color13, color14, color15, color16, color17, color18, color19, color20];