//React
import styled from 'styled-components';

export const MainContainer = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding:1.5rem 1.5rem 0;
`;

export const ImageContainer = styled.img`
    width:6rem;
    margin-right:1.5rem;
`;

export const HeadingContainer = styled.h4`
    font-size: 2rem;
    letter-spacing: 2px;
    color: #333;
`;

export const ExtraChoiseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2rem;
    color: brown;
    letter-spacing: 1px;
    margin: 0.5rem;
    border-top: 1px solid #a52a2a3d;
    border-bottom: 1px solid #a52a2a3d;
    padding: 0.5rem;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-size: 1.1rem;
    letter-spacing: 1.5px;
    color: gray;
    margin-top:.5rem;
    margin-bottom:1.5rem;
`;

export const ExitContainer = styled.div`
    position: absolute;
    top: 1rem;
    right: 2rem;
    padding: .5rem;
    border-radius: 50%;
    color:red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;

    transition:color .3s ease-in;

    &:hover{
        color:#333;
    }

    span{
        color:currentColor;
    }
`;

export const HrContainer = styled.hr`
    &:not(:last-child){
        width: 50%;
        margin: 0 auto;
        border: 1px dotted #dcdcdc;
    }
    :last-child{opacity:0}
`;