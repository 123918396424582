//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { systemPelatesMonitorCenterImageUpdate, systemPelatesMonitorSmallImageUpdate } from '../../../redux/system/system.actions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import selectSystemPelatesView from '../../../redux/system/system.selectors';
import selectPelatesData from '../../../redux/pelates/pelates.selector';

//Components
import CenterImage from './center-image/centerImage.component.jsx';
import SmallImage from './small-image/small-image.component.jsx';

//Style
import './pelates-image.style.scss';
class PelatesImage extends Component {

    //prepei paroyme tis times apo thn database kai na ypologisoyme
    //to radius apo to center image
    center_image_dimension = async () => {
        let { system, systemPelatesMonitorCenterImageUpdate } = this.props;

        //ftiaxnei to radius gia to center image
        system.monitor.center_image_dimension.center_radius = Math.floor(system.monitor.center_image_dimension.width / 2)

        //pernaei tis allages sto state 
        await systemPelatesMonitorCenterImageUpdate(system)
    };

    //edo prepei na ftiaxnei ta small image diastasis
    small_Image_dimension = () => {
        const { system, pelatis, systemPelatesMonitorSmallImageUpdate } = this.props;
        let small_image = system.monitor.small_image_dimension;

        let imageNum = 0;

        let { synolo_width_small_image_wrap, small_image_choise } = system.monitor;

        const centerWidth = system.monitor.center_image_dimension.width;

        let centerRadius = system.monitor.center_image_dimension.center_radius;

        const { wrap_percent } = small_image;


        //prepei na dei posa images exei gia na ftiaksei to posision

        const images = pelatis[0].workshop.images.smallImages;


        //einai gia ta steps toy X
        let stepX = 0;
        //einai gia ta steps toy Y
        let convertX = 0
        //h arxh gia ta stepX
        let startPosition = 0;

        //prepei na balei tis 8eseis gia ta small image
        const { posision } = small_image;

        //elegxei ean yparxoyn image kai proxoraei mono ean yparxei esto ena
        if (images.length > 0) {

            //kanei periorismo mexri (small_image_choise) images
            images.length > small_image_choise
                ? imageNum = small_image_choise
                : imageNum = images.length;

            // ypologizei ki ftiaxnei ta posision ton small image

            //ypologizei to width kai to height gia to small image
            const persent_center_image = Math.floor(centerWidth * small_image.percent)
            small_image.width = persent_center_image;
            small_image.height = persent_center_image;


            //ypologizei to synoliko mege8os poy prepei na piasoyn ta image mazi me to wrap 
            synolo_width_small_image_wrap = (small_image.width * imageNum) - (wrap_percent * small_image.width * (imageNum - 1));

            //prepei na ypologizei to bhma gia ta stepXs kai na afairei to proto stepX apo to startPosition
            startPosition = (centerWidth - synolo_width_small_image_wrap) / 2;

            //ftiaxnei ta steps
            stepX = (centerWidth - synolo_width_small_image_wrap) / (imageNum - 1);

            //afairh to proto step apo to startPosition
            startPosition = startPosition - stepX;


            let positionX = 0;
            let positionY = 0;
            let i = 0;

            //Ftiaxnei ta posision gia ta small image
            images.filter((item, idx) => idx < small_image_choise).map(item => {
                let personal_Style = {};

                //bazei sta small-image to width kai to height
                personal_Style.width = small_image.width + 'px';
                personal_Style.height = small_image.width + 'px';

                //prepei na bazei ta image sthn sosth 8esh toys
                //ypologismos gia to X
                positionX = startPosition + (i * (small_image.width - small_image.width * wrap_percent) + stepX);
                //alazei to shmeio metrisei kai to bazei sto kentro toy kykloy
                convertX = (positionX - ((centerWidth / 2) - (small_image.width / 2)));

                centerRadius = (centerWidth / 2);

                //ypologismos gia to Y
                positionY = Math.floor(Math.sqrt(Math.abs((centerRadius * centerRadius) - (convertX * convertX))));

                //ftiaxnei thn diafora tis metrisis gia na phgainei sto kentro toy kykloy
                positionY = positionY + ((centerWidth / 2) - (small_image.width / 2));

                //bazei tis times X-Y
                personal_Style.left = positionX + 'px';
                personal_Style.top = positionY + 'px';

                //ftiaxnei ta key gia ta img 
                personal_Style.key = `smallimage_${i}:${item}`;

                //bazi to img poy exei parei apo ton pelati
                personal_Style.data_src = item;

                //bazei to color
                personal_Style.color = small_image.color;

                //bazei to border
                personal_Style.border = small_image.border + 'px';

                //ayksanei kata 1 gia to epomeno
                i++;

                posision.push(personal_Style);

                return posision;
            });
            //telos elegxoy
        }

        //pernaei tis allages sto state
        systemPelatesMonitorSmallImageUpdate(system);
    }

    //ftiaxnei to center Image kai ta small images
    create_pelatis_images() {
        const { system, pelatis } = this.props;

        let otherProps = system.monitor.center_image_dimension;

        //elegxei an yparxei img
        let data_src = pelatis[0].workshop.images.centerImage
            ? pelatis[0].workshop.images.centerImage
            : null;

        //pernai ta props gia to center image
        otherProps = { ...otherProps };

        //ftiaxnei to component gia ta image toy pelati ( center + small )
        const component = (
            <div className='images'>
                <div>
                    <CenterImage idx='js-center-image' data_src={data_src} {...otherProps} >
                    </CenterImage>
                </div>
                <div>
                    {
                        //ftiaxnei ta small image
                        system.monitor.small_image_dimension.posision.map((item, idx) => {
                            return (
                                <SmallImage key={item.key + idx} item={item} handlerOnClick={this.changeImageOnClick} />
                            )
                        })
                    }
                </div>
            </div>

        );

        return component;

    }


    UNSAFE_componentWillMount() {
        //prepei na elegxoyme thn o8onh

        //ypologizei to center image radius
        this.center_image_dimension();

        //kai ta small image
        this.small_Image_dimension();

        //energopoihsh toy timer
        //enhmerosh toy state
        //probolh ston xrhsth
    }


    changeImageOnClick(item) {

        const testimage = document.getElementById('js-center-image');

        testimage.style.filter = 'blur(5px)';

        setTimeout(() => {
            testimage.style.backgroundImage = `url("${item.data_src}")`;
            testimage.style.filter = `blur(0)`;
        }, 400);
    };


    render() {
        return (
            <div className='pelatis-image'>
                {this.create_pelatis_images()}
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    system: selectSystemPelatesView,
    pelatis: selectPelatesData
});

const mapDispatchToProps = (dispatch) => ({
    systemPelatesMonitorCenterImageUpdate: item => dispatch(systemPelatesMonitorCenterImageUpdate(item)),
    systemPelatesMonitorSmallImageUpdate: item => dispatch(systemPelatesMonitorSmallImageUpdate(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(PelatesImage);