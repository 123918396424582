//Data for reviews
import { reviews_data } from './reviews_data';

//Actions types 
import { reviewsContainerPanel } from './reviews.types'

//set data 
const INITIAL_DATA = {
    reviews_data: reviews_data,
    selectReview: {}
};

const reviewsReducer = (state = INITIAL_DATA, action) => {
    switch (action.type) {
        // case reviewsContainerPanel.ADD_REVIEW:
        //     return { ...state, newReview: action.payload };

        case reviewsContainerPanel.SHOW_REVIEW:
            return {...state,
                selectReview: action.payload
            };

        default:
            return state;
    }
}

export default reviewsReducer;

// state.reviews_data.find((ele) => ele.id === action.payload.id)