// Φέρνουμε την data για το panel
import { panelContainerData } from './panelContainer.data';
import { defaultStyleItem } from './panelContainer.data';

//Actions 
import { panelContainerTypes } from './panel.container.types';

// Περνάμε της πρώτες τιμές πρήν γίνει καμία αλλαγή
const INITIAL_STATE = { ...panelContainerData, defaultStyleItem: { ...defaultStyleItem } };


const panelContainerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Περνάει τα Items που του στέλνουμε στο state
        case panelContainerTypes.ADD_ITEMS:
            return { ...state, allItems: action.payload };

        //Περνάει το Active στο state
        case panelContainerTypes.ADD_ACTIVE:
            return { ...state, active: action.payload };
        
        //Περνάει εάν εμφανίζονται τα Arrow στο state
        case panelContainerTypes.SET_ARROW:
            return { ...state, displayArrows: action.payload };
        
        //Πρέπει να περνάει πόσα Itmes Θα εμφανίζονται στο panel
        case panelContainerTypes.SET_PANEL_DISPLAY_ITEMS:
            return { ...state, panelDisplayItems: action.payload };
        
        //Πρέπει να αλλάζει το Direction 
        case panelContainerTypes.SET_DIRECTION:
            return { ...state, direction: action.payload };
        
        //Πρέπει να περνάει τα Show Items στο State
        case panelContainerTypes.SET_SHOW_ITEMS:
            return {...state,showItems:action.payload}
        
        //Πρέπει να περνάει το νέο Style ανάλογα την θέση του Item
        case panelContainerTypes.SET_NEW_STYLE:
            return { ...state, defaultStyleItem: { ...state.defaultStyleItem, ...action.payload }}
        
        default:
            return state;

    }
}


export default panelContainerReducer;