//Style
import styled from 'styled-components';

export const StepsPanelContainer = styled.div`
    position: relative;
    display: flex;
    width: 35%;
    justify-content: space-between;
    align-items: center;
    margin:3rem auto;

    @media(max-width:1400px){
        width:50%;
    }
    
    @media(max-width:850px){
        width:70%;
    }
`;

export const SpaceBetweenStepsContainer = styled.span`
    width: 99%;
    height: 1rem;
    background-color: #888;
    position: absolute;
    top: 61%;
    left: 50%;
    ${{
        bottom: () => {

            const circle = document.getElementsByClassName('js-circle-step');
            const space = document.getElementById('js-space-bar-cart');

            const circleHeigth = circle.length > 0 && circle[0].offsetHeight;

            const circleCenter = circleHeigth && circleHeigth / 2;
            const spacecenter = space && space.offsetHeight / 2;
            return -(circleCenter - spacecenter) + 'px';

        }
    }}

    transform: translateX(-50%);

    @media(max-width:1000px){
        height:.5rem;
        top:65%;
    }
    
    @media(max-width:600px){
        top:61%;
    }
`;
