//React
import React, { Component } from 'react';

//Router
import { Switch, Route } from 'react-router-dom';

//Pages
import CartItemsPage from './items/cart.items.page.jsx';
import CheckOutPage from './checkOut/check.out.page.jsx';
import PayCenterPage from './payCenter/pay.center.component.jsx';


class ShopingCartPage extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/cart' component={CartItemsPage} />
                    <Route path='/checkout' component={CheckOutPage} />
                    <Route path='/paycenter ' component={PayCenterPage} />
                </Switch>

            </div>
        );
    }
}

export default ShopingCartPage;