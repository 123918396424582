//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setAddNewItem } from '../../../redux/cart/cart.actions';

//Component
import { setNewNotification } from '../../../redux/notification/notification.data';
import { SetNotificationAdd } from '../../../redux/notification/notification.acions';



//Extra code
import { paraggelia } from './paraggelia.code.js';

//Style
import {
    WrapPriceContainer,
    WrapNumSalesContainer,
    ButtonContainer,
    FormContainer,
    InputContainer,
    LabelContainer,
    CalcPriceContainer,

    WrapButtonContainer
} from './button.sales.style.jsx';

class ButtonSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priceItem: props.priceItem,
            minNumForSale: props.minNumForSale,
            salesProducts: props.saleItems !== null ? props.saleItems : props.minNumForSale,
            fpa: 1,

        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.priceItem !== state.priceItem || props.minNumForSale !== state.minNumForSale) {
            state = { ...props };
            return state;
        }
        return null;
    }

    componentDidMount() {
        // const setText = document.getElementById('numProducts').innerHTML;
    }

    changeSalesProducts = (mode) => {
        const { minNumForSale, salesProducts } = this.state;

        //Elegxos gia afairesh
        if (mode === 'minus') {
            //elegxei an den einai mikrotero apo ton elaxisto noymero
            if (salesProducts > minNumForSale) {
                this.setState({ salesProducts: this.state.salesProducts - 1 })
                this.props.changeSalesProducts(this.state.salesProducts - 1);
            }
        }
        //Elegxos gia pros8esh
        else if (mode === 'plus') {
            this.setState({ salesProducts: this.state.salesProducts + 1 });
            this.props.changeSalesProducts(this.state.salesProducts + 1);
        }
    }


    render() {
        const { sendNewItemToCart, changeSalesProducts } = this.props;

        return (<WrapPriceContainer style={{ flexDirection: 'column' }}>
            <WrapPriceContainer>
                <WrapNumSalesContainer>

                    <ButtonContainer onClick={() => this.changeSalesProducts('minus')}>
                        <span>-</span>
                    </ButtonContainer>

                    <FormContainer>
                        <InputContainer
                            type="text"
                            id='numProducts'
                            autoComplete="off"
                            value={this.state.salesProducts}
                            onChange={(evt) => {
                                evt.preventDefault();

                                if (evt.currentTarget.value !== this.state.salesProducts && !isNaN(evt.currentTarget.value)) {
                                    this.setState({ salesProducts: +evt.currentTarget.value });
                                    changeSalesProducts(+evt.currentTarget.value);
                                };
                            }}

                            onKeyPress={(evt) => {
                                if (evt.key === 'Enter') {
                                    evt.preventDefault();
                                    if (evt.currentTarget.value < this.state.minNumForSale || isNaN(evt.currentTarget.value)) {
                                        this.setState({ salesProducts: +this.state.minNumForSale });
                                        changeSalesProducts(+this.state.minNumForSale);
                                    } else {
                                        this.setState({ salesProducts: +evt.currentTarget.value });
                                        changeSalesProducts(+evt.currentTarget.value);
                                    }
                                }
                            }}
                            onBlur={(evt) => {
                                if (isNaN(evt.currentTarget.value) || evt.currentTarget.value < this.state.minNumForSale) {
                                    this.setState({ salesProducts: +this.state.minNumForSale });
                                    changeSalesProducts(+this.state.minNumForSale);
                                } else {
                                    this.setState({ salesProducts: +evt.currentTarget.value });
                                    changeSalesProducts(+evt.currentTarget.value);
                                }
                            }}
                        />
                        <LabelContainer htmlFor="numProducts">ΤΕΜ</LabelContainer>
                    </FormContainer>

                    <ButtonContainer onClick={() => this.changeSalesProducts('plus')}>
                        <span>+</span>
                    </ButtonContainer>

                </WrapNumSalesContainer>


                <div>
                    <CalcPriceContainer>
                        (Σύνολο : {Number.parseFloat((this.state.priceItem * this.state.salesProducts) * this.state.fpa).toFixed(2)} &euro;) + Φ.Π.Α.
                    </CalcPriceContainer>
                </div>

            </WrapPriceContainer>

            <WrapButtonContainer onClick={() => {
                try {
                    const msg = `Το Προϊόν ( ${this.props.selectState.product.nameModelItem} ) έχει προστεθή στο καλάθι σας.`;
                    const newNotify = setNewNotification(msg, 'success', true, 200, 3000);
                    //creat new object for cart
                    const newItemToSales = paraggelia(this.props.selectState);

                    //Mast create new item on cart reducer
                    sendNewItemToCart(newItemToSales);

                    //End notify user
                    this.props.newNotification(newNotify);

                } catch (err) {
                    const newErrorMsg = setNewNotification(err.message, 'error', true, 100);
                    this.props.newNotification(newErrorMsg);
                    return;
                }
            }}>
                <span>παραγγελια</span>
            </WrapButtonContainer>

        </WrapPriceContainer>
        );
    }
};

const mapDispatchToProps = dispatch => ({
    newNotification: notification => dispatch(SetNotificationAdd(notification)),
    sendNewItemToCart: item => dispatch(setAddNewItem(item)),

});

export default connect(null, mapDispatchToProps)(ButtonSales);