//Reselect
import { createSelector } from 'reselect';

const selectPanelContainer = state => state.panelContainer;

//Επιστρέφει από το State όλα τα Items που περάσαμε στο component
export const selectPanelAllItems = createSelector(
    [selectPanelContainer],
    (panelContainer) => panelContainer.allItems
);

//Επιστέφει το Active Item 
export const selectActive = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.active
);

//Επιστρέφει εάν είναι ορατά τα Arrows
export const selectDisplayArrow = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.displayArrows
);

//Επιστρέφει τα Items που μπορούν να εφανιστούν στο Panel
export const selectDisplayPanelItems = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.panelDisplayItems
);

//Επιστρέφει το Direction 
export const selectDirection = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.direction
);

//Επιστρέφει τα Show Items
export const selectShowItems = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.showItems
);

export const selectStyles = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.defaultStyleItem
);

//Είναι τοο Default Style για τα Items
export const selectDefaultStyle = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.defaultStyleItem.default
);

//Είναι για να επιστρέφει το Timer 
export const selectTimer = createSelector(
    [selectPanelContainer],
    panelContainer => panelContainer.timer
);