//Reselect
import { createSelector } from 'reselect';

const selectPelates = state => state.pelates;

export const selectPelatesData = createSelector(
    [selectPelates],
    pelates => pelates
);

export default selectPelatesData;