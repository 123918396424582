//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Actions
import { setHomeSliderCreationStartAnimation, setHomeSliderCreationEndAnimation, setHomeSliderCreationFirstTimeAnimation } from '../../../redux/homeSlider/homeSliderAnimation.action';

//Selectors
import { selectorCreationPanelAnimation } from '../../../redux/homeSlider/homeSliderAnimation.selectors';

//Style
import './homeSlider.creation.style.scss';


class HomeSliderCreation extends Component {

    componentDidUpdate(preVProps) {
        if (preVProps !== this.props &&
            this.props.play === true &&
            this.props.creationAnimation.firstTimeAnimation === true &&
            this.props.creationAnimation.animationEnd === false &&
            this.props.creationAnimation.animationStart === false) {

            const { creationAnimation: { startAnimationTime } } = this.props;

            setTimeout(() => {
                this.startAnimation();
            }, startAnimationTime);

        } else if (preVProps !== this.props &&
            this.props.play === true &&
            this.props.creationAnimation.firstTimeAnimation === false &&
            this.props.creationAnimation.animationEnd === true &&
            this.props.creationAnimation.animationStart === false) {

            //Prepei na balei ta element sto sosto meros

            const animText = document.getElementsByClassName('baner-slider-text');
            animText[0].style.transform = "translateX(0%)";

            const animImage = document.getElementsByClassName('baner-creation');
            animImage[0].style.transform = 'translate(0%, 0%)';


            //stelnei entolh na paei sto epomeno panel
            this.props.nextPanel();
        }
    };

    UNSAFE_componentWillMount() {
        const { play, startAnimationTime } = this.props;
        if (play) {
            setTimeout(() => {
                this.startAnimation();
            }, startAnimationTime);
        }
    };

    //Prepei na kanei to Animation 
    startAnimation() {
        const { creationStartAnimation, creationAnimation: { animationTime } } = this.props;
        //prepei na enhmerosei oti arxise to Animation
        creationStartAnimation();

        const animText = document.getElementsByClassName('baner-slider-text');
        const animImage = document.getElementsByClassName('baner-creation');

        animText[0].style.animation = `animation-text ${animationTime}ms ease-in 0s`;
        animImage[0].style.animation = `animation-image ${animationTime}ms ease-in`;

        //elegxei an teleiose to Animation 
        animText[0].addEventListener('animationend', () => {
            const animText = document.getElementsByClassName('baner-slider-text');
            animText[0].style.transform = "translateX(0%)";

        });
        //
        animImage[0].addEventListener('animationend', () => {
            const { creationAnimation: { awaitAfterAnimation } } = this.props;

            const animImage = document.getElementsByClassName('baner-creation');
            animImage[0].style.transform = 'translate(0%, 0%)';

            setTimeout(() => {
                //paei na teleiosei to animation
                this.endAnimation()

            }, awaitAfterAnimation);
        });


    }

    //Prepei na telionei to Animation
    endAnimation() {
        const { creationEndAnimation, creationChangeFirstTimeAnimation } = this.props;

        //eneimeronei oti teleiose to Animation
        creationEndAnimation();

        //Enhmeronei oti teleiose to First Time Animation
        creationChangeFirstTimeAnimation();
    }

    render() {
        return (
            <div className='home-slider-creation' >

                <div className='baner-creation'>
                    <img className='baner-creation-image' src='https://res.cloudinary.com/karekles-gr/image/upload/v1590660200/proionta/karekles/zografos-1/karekla-kafeneioy-zografos-2_z3tslc.webp' alt='creation passion' />
                </div>

                <div className='baner-slider-text'>
                    <div className='baner-slider-text-header'>
                        <h2>Σχεδιασμός Καρέκλας</h2>
                    </div>

                    <div className='baner-slider-text-main'>
                        <p className='sub-title'>Ένα σχέδιο ή μια απλή ιδέα σε ένα χαρτί, μας είναι αρκετό.</p>
                        <p>Έχουμε την δυνατότητα και την εμπειρία να σας προτείνουμε ή να σχεδιάσουμε</p>
                        <h2> Καρέκλες & Τραπέζια </h2>
                        <p>με βάση τις δικές σας ιδιαίτερες απαιτήσεις,
                        μπορούμε να καλύψουμε την αισθητική και την μοναδικότητα
                    που έχετε εμπνευστεί για τον χώρο σας.</p>
                    </div>

                    <div className='baner-slider-text-footer'>
                        <p>Είμαστε σίγουροι ότι ακόμα και ο ποιό απαιτητικός πελάτης θα είναι απόλυτα ευχαριστημένος από το τελικό αποτέλεσμα.</p>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    creationAnimation: selectorCreationPanelAnimation
});

const mapDispatchToProps = (dispatch) => ({
    creationStartAnimation: () => dispatch(setHomeSliderCreationStartAnimation()),
    creationEndAnimation: () => dispatch(setHomeSliderCreationEndAnimation()),
    creationChangeFirstTimeAnimation: () => dispatch(setHomeSliderCreationFirstTimeAnimation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeSliderCreation);