//React
import React, { Component } from 'react';

//Components
import ColorPalette from '../../components/colorPalette/color.palette.component.jsx';

//Styles


class ColorPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    };

    render() {

        return (
            <ColorPalette history={this.props.history} />
        )
    }
};


export default ColorPage;