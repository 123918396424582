export const homeSliderAnimationTypes = {
    CHANGE_PANEL_NUMBER:'CHANGE_PANEL_NUMBER',
    CHANGE_PANEL_ANIMATION:'CHANGE_PANEL_ANIMATION',
    
    
    START_START_ANIMATION: 'START_START_ANIMATION',
    START_END_ANIMATION: 'START_END_ANIMATION',
    CHANGE_START_FIRST_TIME_ANIMATION:'CHANGE_START_FIRST_TIME_ANIMATION',
    
    
    PROIONTA_START_ANIMATION: 'PROIONTA_START_ANIMATION',
    PROIONTA_END_ANIMATION: 'PROIONTA_END_ANIMATION',
    CHANGE_PROIONTA_FIRST_TIME_ANIMATION:'CHANGE_PROIONTA_FIRST_TIME_ANIMATION',
    
    CREATION_START_ANIMATION: 'CREATION_START_ANIMATION',
    CREATION_END_ANIMATION: 'CREATION_END_ANIMATION',
    CHANGE_CREATION_FIRST_TIME_ANIMATION:'CHANGE_CREATION_FIRST_TIME_ANIMATION',
};