//Style
import styled from 'styled-components'

//Wrap all 
export const WrapPriceContainer = styled.div`
    display:flex;
    align-items: center;
    justify-content:center;

    @media(max-width:1500px){
        flex-direction:column;
    }
`;

//Wrap
export const WrapNumSalesContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    /* width:25vw; */
    margin-right:3rem;

    @media(max-width:1500px){
        margin-right:0%;
        margin-bottom:2rem;
    }
`;

//For Button
export const ButtonContainer = styled.div`
    width: 4rem;
    height: 4rem;
    background-color: #38a631;
    font-size: 5rem;
    color: white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0.4rem 0.4rem 1.3rem #1d5b18, inset -1rem -1rem 1.3rem 0px #5bbf54;
    border-left: 1px solid #2f9128;
    border-top: 1px solid #2f9128;
    border-right: 1px solid #1d5b18;
    border-bottom: 1px solid #1d5b18;
    cursor:pointer;

    transition: box-shadow .3s ease;

    &:hover{
        box-shadow: 0.4rem 0.4rem 1.3rem #1d5b18, inset -1rem -1rem 1.3rem 0px #11ff00;
    }

    &:active{
        box-shadow: 0.2rem 0.2rem .7rem #1d5b18, inset -1rem -1rem 1.3rem 0px #5bbf54;
        transform:translate(2px,2px);
    }

    span{
        &::selection{
            background:transparent;
        }
    }

`;
//For Form
export const FormContainer = styled.form`
    margin: 0 1.2rem;
    position:relative;

    border: 3px solid green;
    padding: 0.5rem;
    width: 15rem;
    border-radius: 5px;
    display: flex;
`;


//For Input
export const InputContainer = styled.input`
    /* height: 4rem;
    width:10rem; */
    /* border-radius: 5px; */
    width:70%;
    font-size: 2rem;
    font-weight:600;
    color:green;
    /* width: 15rem; */
    text-align: center;
    letter-spacing: 1px;
    border:none;
    /* border-color: green; */

    &::selection{
        background:green;
        color:white;
    }
    
    &:focus{
        outline:none;
    }
`;

//For Label
export const LabelContainer = styled.label`
    position: absolute;
    width:30%;
    top: 50%;
    right: 0.3rem;
    transform: translateY(-50%);
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 500;
    color: green;
    border-left: 3px dotted;
    padding: 0.3rem 0 0.3rem 0.3rem;

    &::selection{
        background:transparent;
    }


`;

export const CalcPriceContainer = styled.span`
    text-align:center;
    font-size: 2rem;
    letter-spacing: 3px;
    color: green;

    &::selection{
        background:transparent;
    }

    @media(max-width:400px){
        letter-spacing: 1px;
    }
`;

export const WrapButtonContainer = styled.div`
    border: 2px solid #3969a4;
    border-radius: 1rem;
    padding: 1rem 10rem;
    width: 60%;
    font-size: 3rem;
    font-weight: 500;
    color: white;
    letter-spacing: 5px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4486d8;
    box-shadow: inset 0.5rem 0.6rem 1rem #8fc0fd, 0.5rem 1rem 1.5rem #4486d8;
    margin-top: 2rem;
    cursor:pointer;
    
    transition:background-color .3s ease , box-shadow .3s ease-in;

    &:hover{
        background-color:#82b2ed;
    };

    &:active{
        transform:translate(2px,2px);
        background-color:#3969a4;
        box-shadow: inset 0.5rem 0.6rem 1rem #8fc0fd, 0.5rem .3rem 1.5rem #4486d8;
    };

    span::selection{
        background-color:transparent;
    }

    @media(max-width:500px){
        width:90%;
    }
    
`;