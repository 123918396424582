// is for package.json
// "proxy": "https://xn--mxaiarcg3bu.gr/",


//React
import React, { Component } from 'react';

//Router
import { Switch, Route, Redirect } from 'react-router-dom'

//Firebase
//(addCollectionAndDocument) για να φέρουμε την Function για το πέρασμα των κατηγοριών από αρχείο στην Firebase
import { auth, createUserProfile } from './firebase/firebase.utils';

//Components
import Navigation from './components/navigation/navigation.component.jsx';
import { ButtonUp } from './components/button-up/button-up.component.jsx';
// import TestCart from './pages/testpage/test.component';
import CustomFooter from './components/footer/footer.component.jsx';
import NotificationList from './components/notification/notification.list.component.jsx';

//Pages
import HomePage from './pages/home/home.Page.jsx';
import SingInSingOut from './pages/sing-in/sing-in-sing-out.page.jsx';
import ProductPage from './pages/products/product.page.jsx';
// import CategoryPage from './pages/category/category.page';
import ProsforesPage from './pages/prosfores/prosfores.page.jsx';
import JobsFromUsPage from './pages/jobsForUs/jobsForUs.page.jsx';
import CompanyPage from './pages/company/company.page.jsx';
import ForUsPage from './pages/newsForUs/newsForUs.page.jsx';
import ContactPage from './pages/contact/contact.page.jsx';
import NewsPage from './pages/news/news.page.jsx';
import ProductItemPage from './pages/product_item/product.item.page.jsx';
import TestComponent from './pages/testpage/test.component.jsx';
import ColorPage from './pages/colorPalette/color.palette.page.jsx';
import ShopingCart from './pages/shopingCart/shopingCart.page.jsx';
//Cart
import CartItemsPage from './pages/shopingCart/items/cart.items.page.jsx';
import CheckOutPage from './pages/shopingCart/checkOut/check.out.page.jsx';
import PayCenterPage from './pages/shopingCart/payCenter/pay.center.component.jsx';



//site map router
import { ROUTER_ADRESS } from './pages/site.map.router';

// Redux connect with React
import { connect } from 'react-redux';
// Redux - Actions 
import setCurrentUser from './redux/user/user.actions';
import { setSmallNavBarNewValue } from './redux/small_nav_bar/small.nav.bar.actions';

//Redux - Reselect
import { createStructuredSelector } from 'reselect';
//Reselect - Selectors
import { selectCurrentUser } from './redux/user/user.selectors';
import { selectAllNotifications } from './redux/notification/notification.selectors';

// import { selectCategory } from './redux/category/category.selector'; //Φέρνουμε τον Selector 

// extra code
import { findScreenSize } from './code/screen.size.functions';

//style for App component
import './App.css'

class App extends Component {
  constructor(props) {
    super(props);
    //variables gia ta emails
    this.state = {
      data: null,
      notifications: {
        show: true,
      },
      apivalues: '',
    };

  }
  //8a prepei na kleinei to lifecircle to auth.onAuthStateChanged()
  unSubscribeFromAuth = null;

  //8a prepei na blepei an yparxei kapoios xrhsths syndemenos me to project
  //kai 8a synde8ei me ton Email Server gia na mporei na stelnei emails
  componentDidMount() {

    const { setCurrentUser, navBarSetNewValue } = this.props

    this.unSubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      //elegxoyme an yparxei kapoios user syndemenos
      if (userAuth) {
        //ean yparxei
        const userRef = await createUserProfile(userAuth);//8a eleksei an yparxei o user sthn data mas kai 8a epistrepsei ena userRef object

        //ean ginei allagh sthn data 
        userRef.onSnapshot(snapshot => {

          //pairname sto project ton xrhsth apo thn firebase
          setCurrentUser({
            id: snapshot.id,
            ...snapshot.data()
          });
        });

      } else {
        //ean den yparei pairname san xrhsth to null (tipota)
        setCurrentUser(userAuth);
      }

      //Ειναι μια Function για να περάσουμε τις κατηγορίες από το αρχείο στην Firebase
      // addCollectionAndDocument('categories', this.props.cotegories);

      //only to first run.... 
      //Elegxei to screensize gia to navigation bar > 900px then open then navigation bar else close 
      const screensize = findScreenSize()
      if (screensize.x > 900) {
        navBarSetNewValue(true);
      } else {
        navBarSetNewValue(false);
      };
    })

  }

  //8a prepei na stelnei oti egine kleisimo sto lifecircle toy auth.onAuthStateChanged
  componentWillUnmount() {
    this.unSubscribeFromAuth();
  }

  render() {
    const { navBarSetNewValue } = this.props

    if (typeof window !== "undefined") {
      window.addEventListener('resize', () => {
        const screensize = findScreenSize()
        if (screensize.x > 900) {
          navBarSetNewValue(true);
        } else {
          navBarSetNewValue(false);
        };
      })
    }

    const { notifications } = this.props;
 
    return (
      <div >
        <Navigation />
        <div className='top-space'></div>
        {notifications.length > 0 && <NotificationList itemList={notifications} />}

        <ButtonUp />

        <Switch>
          <Route exact path='/login' render={() => this.props.currentUser ? (<Redirect to='/' />) : (<SingInSingOut />)} />

          <Route exact path={ROUTER_ADRESS.products.url} component={ProductPage} />
          <Route path='/products/:itemID' component={ProductItemPage} />

          <Route path='/cart' component={CartItemsPage} />
          <Route path='/checkout' component={CheckOutPage} />
          <Route path='/paycenter' component={PayCenterPage} />

          <Route path={ROUTER_ADRESS.colors.url} component={ColorPage} />
          <Route path={ROUTER_ADRESS.prosfores.url} component={ProsforesPage} />
          <Route path={ROUTER_ADRESS.jobFromUs.url} component={JobsFromUsPage} />
          <Route path={ROUTER_ADRESS.company.url} component={CompanyPage} />
          <Route path={ROUTER_ADRESS.aboutUs.url} component={ForUsPage} />
          <Route path={ROUTER_ADRESS.contactUs.url} component={ContactPage} />
          <Route path={ROUTER_ADRESS.news.url} component={NewsPage} />
          <Route path={ROUTER_ADRESS.shopingCart.url} component={ShopingCart} />

          <Route exact path={ROUTER_ADRESS.home.url} component={HomePage} />

          <Route exact path='/test' component={TestComponent} />

          <Route exact path='/' component={HomePage} />

          <Redirect to={ROUTER_ADRESS.home.url} />
        </Switch>

        <CustomFooter />

      </div>
    );
  }


  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  // callBackendAPI = async () => {
  //   const fetch_init = {
  //     // method: 'POST',
  //     mode: 'no-cors'
  //     // headers: {
  //     //   'Content-Type': 'application/json',
  //     //   'Content-Type': 'application/x-www-form-urlencoded'
  //   };

  //   const response = await fetch('/express_backend', fetch_init);

  //   // , {
  //   // method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //   // mode: 'no-cors', // no-cors, *cors, same-origin
  //   // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //   // credentials: 'omit', // include, *same-origin, omit
  //   // headers: {
  //   // 'Content-Type': 'application/json',
  //   // 'Content-Type': 'application/x-www-form-urlencoded',
  //   // },
  //   // redirect: 'follow', // manual, *follow, error
  //   // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //   // body: JSON.stringify() // body data type must match "Content-Type" header
  //   // });


  //   if (response.status !== 200) {
  //     throw Error(body.message)
  //   }
  //   return body;
  // };


}
//Redux - prepei na diabazei ton trexon xrhsth
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  notifications: selectAllNotifications,

  // cotegories: selectCategory, // Το χριαζόμαστε μόνο για να πάρουμε από το αρχείο τις Κατηγορίες

});

//Redux - prepei na pernaei ton xrhsth sto Store.
const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  navBarSetNewValue: value => dispatch(setSmallNavBarNewValue(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
