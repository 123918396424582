//React
import React from 'react';

//Style 
import './button.style.scss';

const Button = ({ children, onClick, url, item }, ...otherProps) => {

    return (
        <div className='button' onClick={() => onClick(url + '/' + item)}>
            <h3 className='button-text'>{children}</h3>
        </div>
    )
};

export default Button;