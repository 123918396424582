//Style
import styled from 'styled-components';

export const DivDetailsPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

//Wrap Select No Price
export const WrapSelectContainer = styled.div`
    padding: 1rem;
    border: 3px solid rgba(105, 105, 105, 0.5);
    border-radius: 5px;
    height: 7vh;
    width: 25vw;
    background: rgba(105, 105, 105,.1);
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const CenterDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    align-items: center;
    grid-column-gap:4rem;

    @media(max-width:1500px){
        grid-template-columns:max-content;
        grid-column-gap:0;
        grid-row-gap:4rem;
        margin-bottom:4rem;
    }

    @media(max-width:500px){
        grid-template-columns:100%;
    }
`;

export const CenterDetailsPanel = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`;


export const FPAContainer = styled.p`
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-weight: 400;
    color: dimgray;
    margin-top: 2rem;
    text-align:center;
`;