

//Πρεπει να υπολογίζει τις σελίδες που είναι να φτιάξει και μετα να επιστρέφει τον αριθμό των σελίδων
export const findPagesToShow = (allItemsLength, numItemPerPage) => {
    const reseltPages = Math.ceil(allItemsLength / numItemPerPage);
    return reseltPages
};

//Πρέπει να βάζει τα Items τις σελίδας σε έναν Array.
export const setPerPageItems = (numItemPerPage, allItems) => {
    //Πρέπει να περιέχει όλες τις σελίδες με τα Items που εμφανίζει η κάθε μια
    const itemsPerPageArray = [];
    //Πρέπει να έχει τα Items της σελίδας
    let pageItemsArray = [];
    //Μετριτής για τα τρέχων items
    let pageItem = 0;

    for (let i = 0; i < allItems.length; i++) {
        //Πρέπει να χωρίζει τα Items σε σελίδες ανάλογα με τα numItemPerPage και μετα να τα καταχωρεί σε εναν Array
        if (pageItem < numItemPerPage) {
            pageItemsArray.push(allItems[i]);
            pageItem++;
        } else {
            itemsPerPageArray.push(pageItemsArray);
            pageItemsArray = [];
            pageItemsArray.push(allItems[i]);
            pageItem = 1;
        }
    };
    //Ελέγχει για το αν υπάρχουν εγγραφές στον Array και αν ναι της καταχωρεί στο κεντρικό Array
    if (pageItemsArray.length) {
        itemsPerPageArray.push(pageItemsArray);
    }
    return itemsPerPageArray;
}