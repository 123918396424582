//React
import React from 'react';
//Rendary all Project
import { render, hydrate } from 'react-dom';
//All Project
import App from './App.jsx';
//redux-persist
import { PersistGate } from 'redux-persist/integration/react'
// import * as serviceWorker from './serviceWorker';
//Bazoume to router sto project
import { BrowserRouter } from 'react-router-dom';
// global STATE from redux
import { store, persistor } from './redux/store/store';
//Style
import './index.css';
// Bazoume thn Redux sto project 
import { Provider } from 'react-redux'

const root = document.getElementById('root');

let renderMode;
if (root && root.innerHTML !== '') {
    renderMode = hydrate;

} else {
    renderMode = render;
}

renderMode(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register();