//React
import React from 'react';

//Router
import { withRouter } from 'react-router-dom';

//Components
//For Top Menu
// import SocialLinksSVG from './social/social-cart-link.component';
// import FavoriteSVG from './favorite/favorite.component';
// import CompressSVG from './compress/compress.component';

//For main Menu
import CartHoverMain from './cartHoverMain.component.jsx';
//For Button
import Button from '../../utils/buttons/button.component.jsx';

//Style
import './cartHover.style.scss';
// import { CartHoverStyle, HoverTopStyle, HoverMain } from './cart.hover.style';

const CartHover = ({ item, match, history }) => {
    //extra coise

    // <div className='cartHover-top'>
    //     <CompressSVG className='cartHover-top--compress' />
    //     <FavoriteSVG className='cartHover-top--favorite' />
    //     <SocialLinksSVG className='cartHover-top--social' />
    // </div>


    return (
        <div className='cartHover' onClick={() => {
            history.push(match.path + '/' + item.id);
        }}>
            <div className='cartHover-top'>

            </div>

            <div className='cartHover-main'>

                <CartHoverMain imagesNum={item.images.others.length} />
            </div>

            <div className='cartHover-button'>
                <Button onClick={history.push} url={match.path} item={item.id}>Λεπτομέρειες</Button>
            </div>

        </div>
    )
};

export default withRouter(CartHover);
