// React
import React from 'react';

// Router
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux';

// Redux-Reselect
import { createStructuredSelector } from 'reselect';

//Reselect-Selectors
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { selectSmallNavBar } from '../../../redux/small_nav_bar/small.nav.bar.selectors';


//tools- Firebase
// import { auth } from '../../../firebase/firebase.utils';

//components
// import ConnectButton from './connect-button/connectButton.component.jsx'; // nav - connect
import NavCart from '../../cart/nav-cart/navCart.component.jsx'; // nav - cart
import NavItem from './navItem/navItem.component.jsx'; // nav - links
// import PreCart from '../../cart/preCart/pre.cart.component';


//site map router
import { ROUTER_ADRESS } from '../../../pages/site.map.router';

//Components
//Small Navigation Bar
import SmallNavBar from './smallNav/small.nav.component.jsx';

import Logo from '../logo/logo.component.jsx'; //To logo sthn navigation bar


//style for Nav component
import './nav.style.scss';

//Extra Style for Navigation Menu
import { NavContainer } from './nav.style'


// <NavItem isFavorite linkTitle='Αγαπημένα' title='Η λίστα με τα Αγαπημένα σας' />

class Nav extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {  history } = this.props;

        return (
            <div className='nav'>
                <Logo />

                <div className="small-nav-btn">
                    <SmallNavBar />
                </div>


                <NavContainer className='nav-items js-nav-items' select={this.props.navBarSelect}>
                    <ul className='nav-items-ul'>
                        <NavItem link={ROUTER_ADRESS.home.url} history={history} isHome linkTitle='Αρχική' title='Αρχική Σελίδα' />
                        <NavItem link={ROUTER_ADRESS.products.url} linkTitle='Προϊόντα' title='Τα Προϊόντα μας' />
                        <NavItem link={ROUTER_ADRESS.prosfores.url} linkTitle='Προσφορές' title='Οι Προσφορές μας' />
                        <NavItem link={ROUTER_ADRESS.jobFromUs.url} linkTitle='Έργα μας' title='Τα Έργα μας' />
                        <NavItem link={ROUTER_ADRESS.company.url} linkTitle='Εταιρεία' title='Η Εταιρεία μας' />
                        <NavItem link={ROUTER_ADRESS.news.url} linkTitle='Νέα' title='Τα νέα μας' />
                        <NavItem link={ROUTER_ADRESS.contactUs.url} linkTitle='Επικοινωνία' title='Επικοινωνία με εμάς' />
                    </ul>
                </NavContainer>

                <div className='nav-cart'>
                    <NavCart
                        history={this.props.history}
                        location={this.props.location}
                        title='Το καλάθι σας' />
                </div>
                {
                    // currentUser ?
                    //     <ConnectButton user={currentUser} connectName='Αποσύνδεση' clicked={() => auth.signOut()} />
                    //     :
                    //     // <Link className='account-sing-in' to='/login'>Sing In</Link>
                    //     <ConnectButton connectName='Σύνδεση' clicked={location.pathname === '/login' ? null : () => history.push('/login')} />
                }
            </div>
        );
    }
};


//Prepei na pernaei sta Props ton currentUser apo to State ths Redux
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    navBarSelect: selectSmallNavBar
});

//Mas epistrefei ena kainoyrgio element, me ta props apo thn Redux kai meta to kanei eksagogh
export default withRouter(connect(mapStateToProps)(Nav));