//React
import React, { Component } from 'react';

//Component
import { ContactItem } from '../../components/contact/contact.component.jsx';

import ContactForm from '../../components/contact/form/contact.form.component.jsx';
//Style
// import './contact.page.style.scss';
// import { ContactPageStyle, TitleStyle, TitleText } from './contact.style';

class ContactPage extends Component {

    render() {
        return (
            <div className='contact-page'>
                <div className='contact-page-title'>
                    <h1 className='contact-page-title-text'>Επικοινωνήστε μαζί μας</h1>
                </div>

                <ContactItem contactItem='shop' />
                <ContactItem contactItem='factory' />

                <ContactForm />
            </div>
        )
    }
};

export default ContactPage;