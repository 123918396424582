import styled from 'styled-components';

export const WrapPriceContainer = styled.div`
    position:relative;

    border-radius: 1rem;
    /* transform: skewX(-30deg); */
    width: 70%;
    margin: 0 auto;


    span{
        /* transform:skewX(30deg); */
        font-family: sans-serif;
        font-style: italic;
        font-size: 11rem;
        letter-spacing: 5px;
        font-weight: bold;
        -webkit-text-stroke-color: #00000073;
        -webkit-text-stroke-width: thin;
        color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: animPrice 6s ease infinite;

        &::selection{
            background:transparent;
        };

        @media(max-width:500px){
            font-size:8rem
        }
    };
    
    p{
        font-size: 2rem;
        text-align: center;
        -webkit-text-stroke: medium;
        color: transparent;
        -webkit-text-stroke-color: dimgray;
        letter-spacing: 10px;
    };

    @keyframes animPrice{
        0%{
            transform:scale(1);
        }
        50%{
            transform:scale(1.3);
        }
        100%{
            transform:scale(1);
        }
    }

`;

