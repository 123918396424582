//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectProductsHomeSlider } from '../../redux/product/product.selectors';

// items.length
//component
import Carousel from '../panel-container/panel.container.component.jsx';

const ProductsSlider = ({ productsItems }) => {

    return (
        <div className='home-newProduct'>
            <Carousel items={productsItems} active={0} arrow={'true'} panelDisplayItems={9} />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    productsItems: selectProductsHomeSlider
});


export default connect(mapStateToProps)(ProductsSlider);

//1 zografos
//2 corina -koyrmpa
//3 karfoti-koyrmpa
//4 zakynthos
//5 tabla elato
//6 mdf kaplama
//7 skampo karfoto
//8 80 ari
//9 me plati
