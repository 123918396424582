//React
import React from 'react';

//Style
import './review.style.scss'

//Component
// import { PlusSymbol } from './plus/plus_symbol.component';
import { ReviewDetails } from './review-details/reviewDetails.component.jsx';
import Points from './review-points/review-points.components.jsx';

//Redux
import { connect } from 'react-redux';

//Reselect 
import { createStructuredSelector } from 'reselect';


//Selector
import { selectReviews, selectReviewSelect } from '../../redux/reviews/reviews.selector';

import { v5 as keyCreator } from 'uuid'




const ReviewContainer = ({ reviews, selectReview }) => {

    let selectNum = selectReview.id ? selectReview.id - 1 : 0;

    const title_style = {
        fontSize: window.screen.availWidth > 1100 ? '4rem' :
            window.screen.availWidth > 900 ? '3rem' : '2rem',
        letterSpacing: '2px',
        color: 'dimgrey',
        marginBottom: '5rem',
        textAlign: 'center'
    }

    const { name, imageUrl, reviewTxt } = reviews[selectNum];

    //Είναι για να μπορεί ο user να βάλει κριτική
    // <PlusSymbol title='Προσθέστε την κριτική σας' />


    return (
        <div className='review-panel'>

            <h3 style={title_style} >Έχουν πει για εμάς</h3>


            <ReviewDetails name={name} imageUrl={imageUrl} reviewTxt={reviewTxt} />


            <div className='reviews-num'>
                {
                    reviews.map((ele) => {
                        return (<Points
                            selectNum={selectNum}
                            key={`${keyCreator.DNS}${ele.name}`}
                            reviewItem={ele} />)
                    })
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    reviews: selectReviews,
    selectReview: selectReviewSelect
});

export default connect(mapStateToProps)(ReviewContainer);