import 'isomorphic-fetch';

//eisagoume thn firebase library
import firebase from 'firebase/app' //
//kai oti 8eloyme na doylepsoyme apo thn firebase
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

// einai oles oi ry8miseis gia na mporesoume na synde8oyme me thn firebase
export const firebaseConfig = {
  apiKey: "AIzaSyAWrn1YDcvwdD8n2GCxR9XeDcFslY-2GwQ",
  authDomain: "karekles-8d486.firebaseapp.com",
  databaseURL: "https://karekles-8d486.firebaseio.com",
  projectId: "karekles-8d486",
  storageBucket: "karekles-8d486.appspot.com",
  messagingSenderId: "696059039190",
  appId: "1:696059039190:web:c8803a15d347f1d5116877",
  measurementId: "G-4TRZEVC099"
};

//Πρεπει να κάνει καταχώρηση στην Firebase τον χρήστη αλλιός να επιστρέφει το ήδη υπάρχον χρήστη
export const createUserProfile = async (userAuth, additionalData) => {
  //elegxoyme an yparxei userAuth an einai null stamataei allios synexizei

  if (!userAuth) return;
  //psaxnoyme thn eggrafh sthn firebase
  const userRef = firestore.doc(`users/${userAuth.uid}`);

  //pairnoyme oti data exei h eggrafh kai to poio symantiko blepoyme to exist
  const snapshot = await userRef.get();

  //elegxei an h eggrafh yparxei kai an den yparxei thn dhmioyrgei
  if (!snapshot.exists) {
    //ftiaxnei tis times poy 8a steilei gia apo8ykeysh
    const { displayName, email, phoneNumber, photoURL } = userAuth;
    const currentDay = new Date();

    //stelnei gia apo8ykeysh to object
    try {
      await userRef.set(
        {
          displayName,
          email,
          phoneNumber,
          photoURL,
          currentDay,
          ...additionalData
        }
      );
    } catch (error) {
      // console.log(`Faild create User (${error})`);
    }
  }

  return userRef;
}

//Πρέπει να καταχωρεί στην Firebase τις Κατηγορίες (Είναι μια ενέργια που πρέπει να γίνει μια φορά)
export const addCollectionAndDocument = async (collection, documents) => {
  //Πρέπει να συνδεθεί με την Firebase για να δει αν υπάρχει το Collaction ή όχι
  const collectionRef = firestore.collection(collection);


  // const collectionSnapshop = await collectionRef.get();


  //Είναι για να βλέπει αν στάλθηκαν όλα τα δεδομένα.
  const batch = firestore.batch();

  //Πρέπει να φτιάξει εναν καινούργιο Array με Keys από το Documents.
  const ArrayFromDocuments = Object.keys(documents);

  //Για κάθε εγγαφή του Array πρέπει να στέλνει τα δεδομένα 
  ArrayFromDocuments.forEach(obj => {
    //Πρέπει να κάνει έτημα για καινούργιο Document και να πιάσει ενα τυχαίο (ID)
    const documentRef = collectionRef.doc();

    const { id, name, perigrafi, photo } = documents[obj];

    batch.set(documentRef, { order: id, name, perigrafi, photo, objName: obj });

  });

  //Πρέπει να επιστρέψει το αποτέλεσμα από το (batch) 
  return batch.commit();
};

//Πρέπει να παίρνει το εισερχόμενο snapshot και το μετατρέπη σε μορφή data για να την περάσουμε στην redux
export const collectDataFromSnapshotCategories = (snapshot) => {
  let sortArrayCategory = '';

  //Πρέπει να ελέγχει αν έχει μέσα εγγραφές και αν υπάρχουν τότε πρέπει να τις μετατρέψει σε obj
  if (!snapshot.empty) {

    //πρέπει να φτιάχνει 1 καινούργιο obj με επιπλέων το Key να είναι το nameObj , όπως και το link για την router από το name.
    //πρέπει να βάζει τα items σε σειρά βάση του (order).
    //πρέπει να φτίαχνει το τελικό obj με Keys τα ονόματα και μετατροπή του id σε αριθμό.
    sortArrayCategory = snapshot.docs.map(item => {
      const documentID = item.id;
      const newObj = { id: documentID, link: '/' + item.data().name.toLowerCase(), ...item.data() }

      return newObj;
    }).sort((item1, item2) => {
      return item1.order - item2.order;
    }).reduce((accumulator, value) => {

      accumulator[value.objName] = value
      return accumulator;
    }, {});

  } else {
    sortArrayCategory = null;
  }

  return sortArrayCategory;
};

//Πρέπει να βλέπουμε το snapshot και να το μετατρέψουμε σε (Data)
export const collectDataFromOrdersSettings = (snapshot) => {
  //Ελέγχει αν το Snapshot δεν είναι άδειο
  if (!snapshot.empty) {
    const orderSettings = snapshot.docs.map(setting => {

      const newObj = { settingName: setting.id, ...setting.data() }

      return newObj
    }).reduce((accumulator, value) => {
      accumulator[value.settingName] = { value: value.value };
      return accumulator;
    }, {});

    return orderSettings;
  }
  return null;
}

//Πρεπει να καταχωρει την παραγγελια 
export const setNewOrderToFirebase = async (order) => {
  try {
    const orderRef = firestore.collection('orders').doc();

    //create safe send data.
    const batch = firestore.batch();

    batch.set(orderRef, order);

    return batch.commit();

  } catch (error) {
    return null;
  }
}

//Πρέπει να αλλάζει τον αριθμό της παραγγελίας κατα 1 μονάδα
export const setNewOrderNumber = async (num) => {
  try {

    let newNumber;

    const batch = firestore.batch();

    const orderSettingsRef = firestore.doc('order_settings/order_number')

    const order_snapshot = await orderSettingsRef.get();

    if (order_snapshot.exists) {

      const orderSettingsData = order_snapshot.data();

      const NUMBER_FROM_FIREBASE = orderSettingsData.value

      if (num > NUMBER_FROM_FIREBASE || num === NUMBER_FROM_FIREBASE) {
        newNumber = num + 1;
      };


      batch.set(orderSettingsRef, { value: newNumber });

      return batch.commit()
    }
  } catch (error) {

    return null;
  }
}

//setaroume to project auto me to project ths firebase 
firebase.initializeApp(firebaseConfig);

if (typeof window !== 'undefined') {
  firebase.analytics();
};

//pername 2 variables gia na mporoume na tis doyme kai apo alla meroi
export const auth = firebase.auth();
export const firestore = firebase.firestore();

//ftiaxnoyme ton Provider gia na mporoyme na kanoyme sing in with Google
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ 'prompt': 'select_account' });

//ena Popup gia na mas ftiaxnei ton provider poy toy dinoyme 
export const singInWithGoogle = async () => auth.signInWithPopup(provider);

//eksagoyme kai oloi thn library app
export default firebase;