//Types
import { cartPageTypes } from './cart.page.types';

export const setNewStepNumber = (num) => ({
    type: cartPageTypes.CHANGE_STEP_NUMBER,
    payload: num
});

export const setTimologioValue = (val) => ({
    type: cartPageTypes.TIMOLOGIO_VALUE,
    payload: val
});

export const setUserValues = (name, value) => ({
    type: cartPageTypes.CHANGE_USER_VALUES,
    payload: { name: name, value: value }
});

export const setCartValues = (values) => ({
    type: cartPageTypes.CHANGE_CART_VALUES,
    payload: values
});

export const setOrderSettings = (set) => ({
    type: cartPageTypes.SET_ORDER_SETTINGS,
    payload: set
});

export const setNewDate = (date) => ({
    type: cartPageTypes.SET_DATE,
    payload: date
});

export const cartPageRemoveAll = () => ({
    type: cartPageTypes.REMOVE_ALL
})