//React
import React from 'react';

//Router
import { withRouter } from 'react-router-dom';

//Style
import './panel.item.style.scss';

const PanelItem = ({ imgPath, title, price, item, history, match }) => {

    return (
        <div className='panelItem' onClick={() => {
            history.push(`/products/${item.id}`)
        }}>
            <main>
                <img src={imgPath} alt={title + ' ' + price} />
            </main>
            <footer>
                <div className='footer-name'>
                    <h2>{title}</h2>
                </div>
                <div className='footer-details'>
                    <span className='footer-details-from'>( Από <span>{price} &euro;</span> )</span>
                </div>
            </footer>
        </div>
    )
};

export default withRouter(PanelItem);