//Styled
import styled from 'styled-components';


export const ElementPhotoContainer = styled.div`
    border-radius: 50%;
    overflow: hidden;
    width: 15rem;
    height: 15rem;
    display:flex;
    justify-content:center;
    align-items:center;

    background: radial-gradient(#fcfcfc, transparent);
    filter: drop-shadow(2px 4px 6px white);
    border: 2px solid gray;
        
    cursor: pointer;
    z-index:20;
    ${props => +(props.num + 1) % 2 === 0 ?
        {
            alignSelf: "flex-end"
        } : {
            alignSelf: 'flex-start'
        }};
    transition:background .3s ease,filter .3s ease,border .3s ease;   
    
    &:hover{
        background: radial-gradient(#ffffff 60%,#0c4c17);
        filter: drop-shadow(2px 4px 6px #0c4c17);
        border: 2px solid #0c4c17;
    }

    @media(max-width:650px){
        width: 12rem;
        height: 12rem;
    }

    @media(max-width:500px){
        width: 10rem;
        height: 10rem;
    }
    
    @media(max-width:450px){
        width: 9rem;
        height: 9rem;
    }
    @media(max-width:400px){
        width: 8rem;
        height: 8rem;
    }
`;

export const SmallImageContainer = styled.img`
    width:75%;
`;