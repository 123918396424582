//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";


export const product1 = new product();
export const product2 = new product();
// export const product3 = new product();
export const product4 = new product();
export const product5 = new product();
// export const product6 = new product();
export const product7 = new product();
export const product8 = new product();
// export const product9 = new product();
export const product10 = new product();

//product1----------------------------------------------------------------------------------------------------------------------------
product1.images.center = stadarURL.karekles + 'zografos_1/karekla-kafeneioy-zografos-2_95.webp';
product1.images.others = [stadarURL.karekles + 'zografos_1/karekla-kafeneioy-zografos-1_95.webp',
stadarURL.karekles + 'zografos_1/karekla-kafeneioy-zografos-2_95.webp',
stadarURL.karekles + 'zografos_1/karekla-kafeneioy-zografos-3_95.webp',
stadarURL.karekles + 'zografos_1/karekla-kafeneioy-zografos-4_95.webp']
product1.codeModelItem = '100.100.101';
product1.nameModelItem = 'ΖΩΓΡΑΦΟΣ 1';
product1.titleItem = 'Καρέκλα Καφενείου';
product1.category = 'ΚΑΡΕΚΛΕΣ';
product1.xondriki.minSalesProducts = 16;
product1.xondriki.priceItem = 20;
product1.id = '1';

//product2----------------------------------------------------------------------------------------------------------------------------
product2.images.center = stadarURL.karekles + 'zografos-7/zografos-7_1_normal.webp';
product2.images.others = [stadarURL.karekles + 'zografos-7/zografos-7_1_normal.webp']
product2.codeModelItem = '100.100.102';
product2.nameModelItem = 'ΖΩΓΡΑΦΟΣ 7';
product2.titleItem = 'Καρέκλα Καφενείου';
product2.category = 'ΚΑΡΕΚΛΕΣ';
product2.xondriki.minSalesProducts = 16;
product2.xondriki.priceItem = 25;
product2.id = '2';

//product3----------------------------------------------------------------------------------------------------------------------------
// product3.images.center = stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-3_normal.webp';
// product3.images.others = [stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-1_normal.webp',
// stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-2_normal.webp',
// stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-3_normal.webp']
// product3.codeModelItem = '100.100.103';
// product3.nameModelItem = 'ΕΠΙΛΟΧΙΑ 2';
// product3.titleItem = 'Καρέκλα Καφενείου';
// product3.category = 'ΚΑΡΕΚΛΕΣ';
// product3.xondriki.minSalesProducts = 16;
// product3.xondriki.priceItem = 20;
// product3.id = '3';

//product4----------------------------------------------------------------------------------------------------------------------------
product4.images.center = stadarURL.karekles + 'skyrianh-masif/skyrianh-masif_2_normal.webp';
product4.images.others = [stadarURL.karekles + 'skyrianh-masif/skyrianh-masif_2_normal.webp']
product4.codeModelItem = '100.100.104';
product4.nameModelItem = 'ΣΚΥΡΙΑΝΗ';
product4.titleItem = 'Καρέκλα Καφενείου';
product4.category = 'ΚΑΡΕΚΛΕΣ';
product4.xondriki.minSalesProducts = 16;
product4.xondriki.priceItem = 20;
product4.id = '4';

//product5----------------------------------------------------------------------------------------------------------------------------
product5.images.center = stadarURL.karekles + 'epiloxia/karekla-kafeneioy-epiloxia-2_normal.webp';
product5.images.others = [stadarURL.karekles + 'epiloxia/karekla-kafeneioy-epiloxia-3_normal.webp',
stadarURL.karekles + 'epiloxia/karekla-kafeneioy-epiloxia-2_normal.webp',
stadarURL.karekles + 'epiloxia/karekla-kafeneioy-epiloxia-4_normal.webp']
product5.codeModelItem = '100.100.105';
product5.nameModelItem = 'ΕΠΙΛΟΧΙΑ 1';
product5.titleItem = 'Καρέκλα Καφενείου';
product5.category = 'ΚΑΡΕΚΛΕΣ';
product5.xondriki.minSalesProducts = 16;
product5.xondriki.priceItem = 22;
product5.id = '5';

//product6----------------------------------------------------------------------------------------------------------------------------
// product6.images.center = stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-3_normal.webp';
// product6.images.others = [stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-1_normal.webp',
// stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-2_normal.webp',
// stadarURL.karekles + 'epiloxia-2/karekla-kafeneioy-epiloxia-2-3_normal.webp']
// product6.codeModelItem = '100.100.106';
// product6.nameModelItem = 'ΕΠΙΛΟΧΙΑ 2';
// product6.titleItem = 'Καρέκλα Καφενείου';
// product6.category = 'ΚΑΡΕΚΛΕΣ';
// product6.xondriki.minSalesProducts = 16;
// product6.xondriki.priceItem = 20;
// product6.id = '6';

//product7----------------------------------------------------------------------------------------------------------------------------
product7.images.center = stadarURL.karekles + 'italias/karekla-kafeneioy-italias-1_normal.webp';
product7.images.others = [stadarURL.karekles + 'italias/karekla-kafeneioy-italias-1_normal.webp',
stadarURL.karekles + 'italias/karekla-kafeneioy-italias-2_normal.webp',
stadarURL.karekles + 'italias/karekla-kafeneioy-italias-3_normal.webp',
stadarURL.karekles + 'italias/karekla-kafeneioy-italias-4_normal.webp']
product7.codeModelItem = '100.100.107';
product7.nameModelItem = 'ΙΤΑΛΙΑΣ';
product7.titleItem = 'Καρέκλα Καφενείου';
product7.category = 'ΚΑΡΕΚΛΕΣ';
product7.xondriki.minSalesProducts = 16;
product7.xondriki.priceItem = 23;
product7.id = '7';

//product8----------------------------------------------------------------------------------------------------------------------------
product8.images.center = stadarURL.karekles + 'corina-masif/karekla-kafeneioy-corina-masif-2_normal.webp';
product8.images.others = [stadarURL.karekles + 'corina-masif/karekla-kafeneioy-corina-masif-1_normal.webp',
stadarURL.karekles + 'corina-masif/karekla-kafeneioy-corina-masif-2_normal.webp',
stadarURL.karekles + 'corina-masif/karekla-kafeneioy-corina-masif-4_normal.webp']
product8.codeModelItem = '100.100.108';
product8.nameModelItem = 'ΚΟΡΙΝΑ';
product8.titleItem = 'Καρέκλα Καφενείου';
product8.category = 'ΚΑΡΕΚΛΕΣ';
product8.xondriki.minSalesProducts = 16;
product8.xondriki.priceItem = 24;
product8.id = '8';

//product9----------------------------------------------------------------------------------------------------------------------------
// product9.images.center = stadarURL.karekles + 'karfoti/karekla-kafeneioy-karfoti-masif-2_normal.webp';
// product9.images.others = [stadarURL.karekles + 'karfoti/karekla-kafeneioy-karfoti-masif-1_normal.webp',
// stadarURL.karekles + 'karfoti/karekla-kafeneioy-karfoti-masif-2_normal.webp',
// stadarURL.karekles + 'karfoti/karekla-kafeneioy-karfoti-masif-3_normal.webp',
// stadarURL.karekles + 'karfoti/karekla-kafeneioy-karfoti-masif-4_normal.webp']
// product9.codeModelItem = '100.100.109';
// product9.nameModelItem = 'ΚΑΡΦΩΤΗ';
// product9.titleItem = 'Καρέκλα Καφενείου';
// product9.category = 'ΚΑΡΕΚΛΕΣ';
// product9.xondriki.minSalesProducts = 12;
// product9.xondriki.priceItem = 17;
// product9.id = '8';

//product10----------------------------------------------------------------------------------------------------------------------------
product10.images.center = stadarURL.karekles + 'karfoti-koyrmpa/karekla-kafeneioy-karfoti-koyrmpa-3_normal.webp';
product10.images.others = [stadarURL.karekles + 'karfoti-koyrmpa/karekla-kafeneioy-karfoti-koyrmpa-1_normal.webp',
stadarURL.karekles + 'karfoti-koyrmpa/karekla-kafeneioy-karfoti-koyrmpa-2_normal.webp',
stadarURL.karekles + 'karfoti-koyrmpa/karekla-kafeneioy-karfoti-koyrmpa-3_normal.webp',
stadarURL.karekles + 'karfoti-koyrmpa/karekla-kafeneioy-karfoti-koyrmpa-4_normal.webp']
product10.codeModelItem = '100.100.110';
product10.nameModelItem = 'ΚΑΡΦΩΤΗ ΚΟΥΡΜΠΑ';
product10.titleItem = 'Καρέκλα Καφενείου';
product10.category = 'ΚΑΡΕΚΛΕΣ';
product10.xondriki.minSalesProducts = 16;
product10.xondriki.priceItem = 24;
product10.id = '10';