//React 
import React from "react";

//components
import Flag from './CartMain/flag/flag.component.jsx';
import Prosfora from './CartMain/prosfora/prosfora.component.jsx';
import ImageCart from './CartMain/image/image.component.jsx';
import DetailsCart from './details/details.component.jsx';

//Hover
import CartHover from './hover/cartHover.component.jsx';

//Style
import './cartItem.style.scss';
// import {
//     CartItemStyle, CartItemHover, ProsforaStyle, ProsforaTextStyle,
//     HotStyle, NewStyle, MainStyle, DetailsStyle
// } from './cart.item.style';

const CartItem = ({ cartItemNow }) => {
    const { isProsfora, prosforaPrice, isHot, isNew, titleItem, xondriki, nameModelItem, codeModelItem, images, category } = cartItemNow;


    return (
        <div className='cartItem' >

            <div className='cartItem-hover'>
                <CartHover item={cartItemNow} />
            </div>

            {isProsfora === true
                ? (<div>

                    <div className='cartItem-prosfora'>
                        <Prosfora />
                    </div>

                    <div className='cartItem-prosforaText'>
                        <Flag key='prosfora-text' flagName={`-${prosforaPrice}%`} />
                    </div>

                </div>)
                : null}

            {isHot
                ? (<div className='cartItem-hot'>
                    <Flag key='hot' flagName='HOT' isRotate />
                </div>)
                : null}

            {isNew
                ? (<div className='cartItem-new'>
                    <Flag key='new' flagName='NEW' />
                </div>)
                : null}

            <div className='cartItem-main'>
                <ImageCart scr={images.center} alt={`${category} / ${nameModelItem} / ${titleItem}`} title={nameModelItem} />
            </div>

            <div className='cartItem-details'>
                <DetailsCart
                    title={titleItem}
                    price={xondriki.priceItem}
                    SelectFpa={xondriki.selectFpaItem}
                    nameModel={nameModelItem}
                    codeModel={codeModelItem}
                />
            </div>

        </div>
    )
}


export default CartItem;

