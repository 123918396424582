//Types
import { cartPageTypes } from './cart.page.types';

const INIT_STATE = {
    user: {
        timologio: { value: true, required: false },

        afm: { value: '', required: true },
        doy: { value: '', required: true },
        drasthriothta: { value: '', required: true },

        name: { value: '', required: true },
        lastname: { value: '', required: true },
        companyName: { value: '', required: false },
        country: { value: '', required: true },
        nomos: { value: '', required: true },
        town: { value: '', required: true },
        address: { value: '', required: true },
        addressNumber: { value: '', required: true },
        tk: { value: '', required: true },
        phone: { value: '', required: true },
        email: { value: '', required: true },

        details: { value: '', required: false },
    },
    step: 1,
    orderSettings: {},
    date: {},
    cart: {
        merikoSynolo: 0,
        fpa: 0,
        synolo: 0
    }
};

const cartPageReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case cartPageTypes.CHANGE_STEP_NUMBER:
            return { ...state, step: action.payload };

        case cartPageTypes.TIMOLOGIO_VALUE:
            return { ...state, user: { ...state.user, timologio: { ...state.user.timologio, value: action.payload } } };

        case cartPageTypes.CHANGE_USER_VALUES:
            return { ...state, user: { ...state.user, [action.payload.name]: { ...state.user[action.payload.name], value: action.payload.value } } };

        case cartPageTypes.CHANGE_CART_VALUES:
            return { ...state, cart: { merikoSynolo: action.payload.merikoSynolo, fpa: action.payload.fpa, synolo: action.payload.synolo } }

        case cartPageTypes.SET_ORDER_SETTINGS:
            return { ...state, orderSettings: { ...action.payload } }

        case cartPageTypes.SET_DATE:
            return { ...state, date: action.payload }

        case cartPageTypes.REMOVE_ALL:
            return {
                ...state,
                orderSettings: {},
                date: {},
                cart: {
                    merikoSynolo: 0,
                    fpa: 0,
                    synolo: 0
                }
            };

        default:
            return { ...state };
    };
};

export default cartPageReducer;