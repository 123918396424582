//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Router
import { withRouter } from 'react-router-dom';

//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectCartFPA, SelectAllItemPrices, selectPriceFpaForItems } from '../../../../redux/cart/cart.selectors';

// Extra code
import { setCartValuesObj } from '../../../../redux/cartPage/cart.page.utilities';

//Actions
import { setNewStepNumber, setCartValues } from '../../../../redux/cartPage/cart.page.actions';

//Notification Data
import { setNewNotification } from '../../../../redux/notification/notification.data';
//Notification
import { SetNotificationAdd } from '../../../../redux/notification/notification.acions';


//Extra Code
import { fixItems } from '../../items/cart.items.code.js';

//Style
import {
    StepContainer,
    TitleContainer,
    CircleStepContainer,
} from './single.step.style.jsx';

const SingleStep = ({ title = 'circle1', active = false, select = false, step, setNewStepNumber, sendUrl, history, selectFPA, setNotify,selectAllPrices,selectAllFpa }) => {

    return (
        <StepContainer select={select} onClick={() => {
            try {
                let reseltCheckFPA = false;

                if (select) {
                    if (+step === 2) {
                        reseltCheckFPA = fixItems(selectFPA);
                        if (reseltCheckFPA) {
                            setNewStepNumber(step);

                            const newValuesObj = setCartValuesObj(+selectAllPrices, +selectAllFpa, +Number.parseFloat(selectAllPrices + selectAllFpa).toFixed(2));
                            setCartValues(newValuesObj);

                            history.push(sendUrl);
                            return;
                        }
                    };

                    setNewStepNumber(step);
                    history.push(sendUrl);
                }
            } catch (error) {
                const newNotify = setNewNotification(error.message, 'error', true, 200, 3500);
                setNotify(newNotify);
            }

        }}>
            <TitleContainer active={active} id='js-title-step'>
                <h3>{title}</h3>
            </TitleContainer>
            <CircleStepContainer
                id='circle-step'
                className='js-circle-step'
                active={active}
                select={select} />

        </StepContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    selectFPA: selectCartFPA,
    selectAllPrices: SelectAllItemPrices,
    selectAllFpa: selectPriceFpaForItems
});

const mapDispatchToProps = dispatch => ({
    setNewStepNumber: num => dispatch(setNewStepNumber(num)),
    setNotify: notify => dispatch(SetNotificationAdd(notify)),
    setCartValues: values => dispatch(setCartValues(values)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleStep));