//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setNewStepNumber, setCartValues } from '../../../redux/cartPage/cart.page.actions';

//Extra data
import { setCartValuesObj } from '../../../redux/cartPage/cart.page.utilities.js';

//Reselect
import { createStructuredSelector } from 'reselect';

//Extra Code
import { fixItems } from './cart.items.code';

//Notification data
import { setNewNotification } from '../../../redux/notification/notification.data';
//Notification
import { SetNotificationAdd } from '../../../redux/notification/notification.acions';


//Selectors
import {
    selectCartItems,
    selectCartFPA,
    SelectAllItemPrices,
    selectPriceFpaForItems,
} from '../../../redux/cart/cart.selectors';

//Components
import SelectFPA from '../selectFPA/select.fpa.component.jsx';
import ListItem from './listItems/list.items.component.jsx';
import StepsCart from '../steps/steps.component.jsx';

//Extra code
// import isEqual from 'lodash.isequal';

//Style
import {
    ListItemContainer,
    NoItemsCartContainer,
    TitleGridContainer,
    WrapPriceContainer,
    GeneralPriceContainer,
    LastPriceContainer,
    TextPriceContainer,
    WrapButtonsContainer,
    ButtonContiniouBuy,
    ButtonBuy,
} from './cart.items.style.jsx';

class CartItemsPage extends Component {

    render() {
        const { priceForAll, priceFpaAll, setCartValues } = this.props;

        return (
            <div>
                {this.props.cartItems.length > 0 &&
                    <StepsCart />
                }

                <ListItemContainer>
                    {this.props.cartItems.length > 0 &&
                        <TitleGridContainer>
                            {window.screen.availWidth < 601 ? null : <span>α/α</span>}
                            <span>Προιον</span>
                            <span>Τιμη</span>
                            <span>Τεμ</span>
                            <span>{window.screen.availWidth < 601 ? 'ΣΥΝ.' : 'Συνολο'}</span>
                        </TitleGridContainer>
                    }

                    {this.props.cartItems.length > 0 ?
                        this.props.cartItems.map((item, idx) =>
                            <ListItem key={idx}
                                num={idx + 1}
                                imgSource={item.product.images.center}
                                item={item}
                            />
                        )
                        :
                        <NoItemsCartContainer>Δεν έχετε προϊόντα στο καλάθι σας</NoItemsCartContainer>
                    }

                    {
                        this.props.cartItems.length > 0 &&
                        <SelectFPA />
                    }

                    {this.props.cartItems.length > 0 &&
                        <React.Fragment>
                            <WrapPriceContainer>
                                <TextPriceContainer>Μερικο Συνολο</TextPriceContainer>
                                <span>{priceForAll}€</span>
                            </WrapPriceContainer>

                            <GeneralPriceContainer>
                                <TextPriceContainer>Φ.Π.Α.</TextPriceContainer>
                                <span>{priceFpaAll}€</span>
                            </GeneralPriceContainer>

                            <LastPriceContainer>
                                <TextPriceContainer>Συνολικη Τιμη</TextPriceContainer>
                                <span>{Number.parseFloat(+priceForAll + +priceFpaAll).toFixed(2)}€</span>
                            </LastPriceContainer>
                        </React.Fragment>
                    }

                </ListItemContainer>
                <div>


                    <section>
                        <WrapButtonsContainer >

                            <ButtonContiniouBuy onClick={() => this.props.history.goBack()}>
                                επιστρεψτε στις αγορες
                            </ButtonContiniouBuy>

                            {this.props.cartItems.length > 0 &&
                                <ButtonBuy onClick={() => {
                                    try {
                                        //Πρέπει να κάνει μερικούς ελέγχους πρήν πάει στο επόμενο βήμα.
                                        fixItems(this.props.fpaPrice);

                                        //Φτιάχνει το Obj για τις συνολικές τιμές των προιόντων
                                        const newValuesObj = setCartValuesObj(+priceForAll, +priceFpaAll, +Number.parseFloat(+priceForAll + +priceFpaAll).toFixed(2));
                                        //Πρεπει να τις περάσει στην Redux
                                        setCartValues(newValuesObj);

                                        this.props.setNextStep(2);
                                        this.props.history.push('/checkout')

                                    } catch (error) {
                                        const newNotify = setNewNotification(error.message, 'error', true, 200, 3500);
                                        this.props.setNotify(newNotify);
                                    }
                                }}>
                                    Αποστολη παραγγελιας
                                </ButtonBuy>
                            }

                        </WrapButtonsContainer>
                    </section>

                </div>

            </div>
        );
    };
};


const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems,
    fpaPrice: selectCartFPA,
    priceForAll: SelectAllItemPrices,
    priceFpaAll: selectPriceFpaForItems,

});

const mapDispatchToProps = dispatch => ({
    setNotify: notify => dispatch(SetNotificationAdd(notify)),
    setNextStep: step => dispatch(setNewStepNumber(step)),
    setCartValues: values => dispatch(setCartValues(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemsPage);