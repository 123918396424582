//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setNewSelectColor, removeSelectColor } from '../../../redux/colorPalette/colorPalette.actions';

//SVG
import { ReactComponent as SelectSVG } from '../../../assets/icons/success.svg'

//Styles
import {
    ColorItemContainer,
    PanelImageContainer,

    PanelSelectContainer,
} from './color.item.style.jsx';


class ColorItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelect: props.isSelect || false,
            item: props.item,
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.isSelect !== state.isSelect) {
            state.isSelect = props.isSelect;

            return state;
        }
        return false
    }

    setIsSelect = (item) => {
        const { setNewSelectColor, removeSelectColor, isSelect, changeSelectColor } = this.props;

        if (isSelect) {
            changeSelectColor(null);

            removeSelectColor();
        } else {
            changeSelectColor(item);

            setNewSelectColor(item);
        };
    };



    render() {
        const { item } = this.props;

        return (
            <ColorItemContainer isSelect={this.props.isSelect} onClick={() => {
                if (!this.state.isSelect) {
                    this.setIsSelect(item);
                }
            }
            }>
                <div>
                    <PanelImageContainer src={item.source} alt={item.colorName + ' ' + item.colorCode} />
                </div>

                {
                    this.state.isSelect &&
                    <PanelSelectContainer>
                        <SelectSVG style={{ width: '100%', height: '100%', fill: 'green' }} />
                    </PanelSelectContainer>
                }
            </ColorItemContainer >
        );
    }
};

const mapDispatchToProps = dispatch => ({
    setNewSelectColor: color => dispatch(setNewSelectColor(color)),
    removeSelectColor: () => dispatch(removeSelectColor()),
})

export default connect(null, mapDispatchToProps)(ColorItem);