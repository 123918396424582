//React
import React from 'react';

//SVG
import { ReactComponent as FacebookLogo } from '../../../../assets/icons/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../../assets/icons/instagram.svg';
import { ReactComponent as YoutubeLogo } from '../../../../assets/icons/youtube.svg';
import { ReactComponent as EmailLogo } from '../../../../assets/icons/email.svg';


//Style
import './followUsIcons.style.scss';

export const FollowUs = ({ selectIcon, titleIcon }) => {

    const selecticon = () => {
        switch (selectIcon) {
            case 'facebook':
                return <FacebookLogo className='followUsButton-facebook' title={titleIcon} />;

            case 'instagram':
                return <InstagramLogo className='followUsButton-instagram' title={titleIcon} />

            case 'youtube':
                return <YoutubeLogo className='followUsButton-youtube' title={titleIcon} />

            case 'email':
                return <EmailLogo className='followUsButton-email' title={titleIcon}/>

            default:
                return null;
        }
    }

    return (
        <div className='followUsButton' title={titleIcon}> 
            {
                selecticon()
            }
        </div>
    )
}