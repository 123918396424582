//React
import React from 'react';
//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//selector
import { selectProductsAll } from '../../redux/product/product.selectors';

//components
import AddressBar from '../../components/AddressBar/address.component.jsx';
import ProductsPanel from '../../components/products-panel/products-panel.component.jsx';



class ProductPage extends React.Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         allItems: this.props.showItems || []
    //     }
    // };

    // componentDidMount() {
    //     this.onlyforTest();
    // };

    // onlyforTest = () => {
    //     const testArray1 = this.props.showItems;
    //     // const testArray2 = [...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1, ...testArray1];
    //     // const testArray3 = testArray2.map((item, idx) => ({ ...item, id: idx }));
    //     const testAllArrays = [...testArray1];

    //     this.setRange(testAllArrays);
    // };

    // setRange = (newArray) => {
    //     this.setState({ allItems: newArray.filter((item, idx) => idx < 500) });
    // }

    render() {
        const { showItems } = this.props;

        return (
            <React.Fragment>
                <AddressBar />
                <ProductsPanel allItems={showItems} />
            </React.Fragment>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    showItems: selectProductsAll
});


export default connect(mapStateToProps)(ProductPage);