const INITIAL_STATE = {
    prosfores: []
};


const prosforesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        default:
            return state;
    }
};

export default prosforesReducer;