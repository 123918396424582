//React
import React from 'react';

//Style
import './proion.image.style.scss';

export const CircleImageProion = ({ pathMin, title, position, scale = 1 }) => {

    return (
        <div style={{ transform: `scale(${scale})` }} className={`circle-image-prooion prooion-position-${position}`}>

            <img className='circle-image-prooion-img' src={pathMin} title={title} alt={title} />
        </div>
    )
}