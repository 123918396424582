//Style
import styled from 'styled-components';


export const WrapPaymentDetails = styled.div`
    height: 100%;
    margin-right: 2rem;
    padding-right: 2rem;
    border-right: 1px dashed #999a;

    @media(max-width:750px){
        margin-right: 0rem;
        padding-right: 0rem;
        border-right: none;
    }
`;

export const TitlePanel = styled.h3`
    font-size: 2rem;
    letter-spacing: 2px;
    text-align: center;
    color: #666;

    @media(max-width:900px){
        font-size:1.7rem;
    }
`;

export const DetailsContainer = styled.div`
    font-size: 1.8rem;
    letter-spacing: 1px;
    line-height: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 2rem;
    color:#555;

    @media(max-width:900px){
        font-size:1.5rem;
        padding:1rem;
    }
`;

export const HighlightText = styled.span`
    color: #2196f3;
    padding: 0.1rem 0;
    border-bottom: 1px solid currentColor;
    border-top: 1px solid currentColor;
`;