//React
import React from 'react';

//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectorClassificationChoise, selectorNumItemChoise, selectorPagesProperties } from '../../../redux/products-panel/products-panel.selectors';

//Actions
import { setClassificationOrder, setNumbersOfItems, setShowItems } from '../../../redux/products-panel/products-panel.actions';

//Components
// import { PropertiesButton } from './button/button.component.jsx';

//Extra code 
import { sortItemsFn } from './properties.bar.code.js';

//Style
import './properties.bar.style.scss';

class PropertiesBar extends React.Component {

    componentDidUpdate(prevProps) {
        const { pageProperties, setShowItems } = this.props;
        if (prevProps.pageProperties !== pageProperties) {

            if (prevProps.pageProperties.classification !== pageProperties.classification) {
                //Classification change

                const sortArray = sortItemsFn(pageProperties.allItems, pageProperties.classification);
                setShowItems(sortArray);
            };

            if (prevProps.pageProperties.allItems !== pageProperties.allItems) {
                //All Items Change
            };

        };
        // || pageProperties.filter !== prevProps.pageProperties.filter
    };


    render() {
        const { classification_Choise, numItem_choise, setClassificationOrder, setNumbersOfItems } = this.props;

        return (
            <div className='propertiesBarPanel'>
                <div className='propertiesBarPanel-classification'>
                    <label htmlFor='classification-js'>Ταξινόμηση</label>
                    <select id='classification-js'
                        value={this.props.pageProperties.classification}
                        onChange={() => {
                            const findSelect = document.getElementById('classification-js');
                            const findOptionSelect = findSelect.options[findSelect.options.selectedIndex].value;

                            //Change new classification
                            setClassificationOrder(findOptionSelect);
                        }}>
                        {classification_Choise.map(e => <option key={e.id} value={e.value} >{e.text}</option>)}
                    </select>
                </div>

                <div className='propertiesBarPanel-panel'>

                    <div className='propertiesBarPanel-panel-numItems'>
                        <label htmlFor='selectNumItems-js'>Εμφάνιση</label>
                        <select id='selectNumItems-js'
                            value={this.props.pageProperties.numItemPerPage}
                            onChange={() => {
                                const findValue = document.getElementById('selectNumItems-js');
                                const findNumOfItems = Number(findValue.options[findValue.options.selectedIndex].value);

                                setNumbersOfItems(findNumOfItems);
                            }}>
                            {numItem_choise.map(e => <option key={e.id} value={e.value}>{e.text}</option>)}
                        </select>
                    </div>

                </div>

            </div>
        )
    }
}

// <div className='propertiesBarPanel-panel-mode'>

//     <div className='propertiesBarPanel-panel-mode-btn propertiesBarPanel-panel-mode-btn-selected' >
//         <PropertiesButton onClickEvent={onClickBtnGrid} id='btn-grid-js' selected title='Πλέγμα' styleBtn="grid" style={{ width: '70%', height: '70%' }} />
//     </div>

//     <div className='propertiesBarPanel-panel-mode-btn '>
//         <PropertiesButton onClickEvent={onClickBtnGrid} id='btn-list-js' title='Λίστα' styleBtn="list" style={{ width: '70%', height: '70%' }} />
//     </div>

// </div>

const mapStateToProps = createStructuredSelector({
    classification_Choise: selectorClassificationChoise,
    numItem_choise: selectorNumItemChoise,
    pageProperties: selectorPagesProperties
})

const mapDispatchToProps = dispatch => ({
    setClassificationOrder: item => dispatch(setClassificationOrder(item)),
    setNumbersOfItems: item => dispatch(setNumbersOfItems(item)),

    setShowItems: items => dispatch(setShowItems(items))
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesBar);

// const onClickBtnGrid = (e) => {

//     if (e === 'Grid') {
//         const selectGrid = document.getElementById('btn-grid-js');

//     } else {
//         const selectGrid = document.getElementById('btn-list-js');
//     }
// }