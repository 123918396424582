//Style
import styled from 'styled-components';

export const WrapListItem = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 5% 13% 32% 10% 20% 12% 8%;
    width: 100%;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    margin:0 auto 1.5rem;
    
    &:not(:last-child)::after{
        content: '';
        position: absolute;
        width: 58%;
        height: 0.2rem;
        background-color: rgba(153,153,153,0.3);
        bottom: -1rem;
        border-radius: 5px;
    }

    &:last-child{
        margin-bottom:0;
    }

    @media(max-width:600px){
        grid-template-columns: 15% 30% 10% 20% 12% 13%;
    }

    @media(max-width:550px){
        grid-template-columns: 15% 35% 10% 20% 12% 8%;
    }

    @media(max-width:450px){
        grid-template-columns: 14% 41% 10% 15% 12% 8%;
    }
`;

export const NumContainer = styled.div`
    background-color: #999a;
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`;

export const WrapImage = styled.div`
    /* width:10rem; */
    height:10rem;
    display:flex;
    justify-content:center;
    align-items:center;

    img{height:100%}

    @media(max-width:450px){
        height:7rem;
    }
`;

export const WrapExtraCost = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TitleItemContainer = styled.h2`
    padding: .5rem 3rem;
    border-bottom: 1px solid #888;
    margin-bottom: 1rem;
    letter-spacing: 1px;

    @media(max-width:700px){
        padding:.5rem;
        font-size:1.2rem;
    }
    @media(max-width:450px){
        font-size:1.1.rem;
        letter-spacing:normal;
    }
`;

export const ExtraCostPanelContainer = styled.div`
    font-size: 1.5rem;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;

    @media(max-width:700px){
        font-size:1.2rem;
    }

    @media(max-width:450px){
        font-size:1.1rem;
        letter-spacing:normal;
    }
`;

export const ItemPriceContainer = styled.div`
    font-size:2.5rem;

    @media(max-width:1000px){
        font-size:2rem;
    }

    @media(max-width:700px){
        font-size:1.2rem;
    }
`;

export const QuantityPanel = styled.div`
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width:1000px){
        font-size:2rem;
    }

    @media(max-width:700px){
        font-size:1.2rem;
    }
    @media(max-width:450px){
        flex-direction:column;
    }
`;

export const ButtonQuantity = styled.div`
    font-size:4rem;
    cursor:pointer;
    
    &:first-child{
        margin-right:1rem;
    }
    &:last-child{
        font-size:3rem;
        margin-left:1rem;
    }

    @media(max-width:850px){
        font-size:3rem;
        
        &:first-child{
            margin-right:.5rem;
        }
        
        &:last-child{
            font-size:2rem;
            margin-left:.5rem;
        }
    }

    @media(max-width:600px){
        &:first-child{
            margin-right:0rem;
        }
        &:last-child{
            margin-left:0rem;
        }
    }
`;

export const QuantityNumberContainer = styled.div`
    padding: 0 1rem;
    border-left: 1px dashed #999a;
    border-right: 1px dashed #999a;
    margin: 0 1rem;

    @media(max-width:850px){
        margin: 0 .5rem;
    }
    @media(max-width:600px){
        padding: 0 .5rem;
    }

`;

export const DeleteContainer = styled.div`
    color:red;
    cursor:pointer;
`;

export const PanelDel = styled.p`
    padding: .5rem;
    border: 1px solid;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width:500px){
        width: 1.5rem;
        height: 1.5rem;
    }
`;