//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setTimologioValue, setUserValues } from '../../../../redux/cartPage/cart.page.actions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectUser } from '../../../../redux/cartPage/cart.page.selectors';

//Style
import {
    PanelDetailsContainer,
    TitleContainer,
    WrapContainerDiv,

    TimologioContainer,

    WrapInputContainer,
    InputContainer,
    LabelContainer,

    SelectContainer,

    SpanContainer,
    WrapTimologioContainer,
    OptionTimologioContainer,

    DivForTextAreaContainer,

} from './user.details.style.jsx';

class UserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timologio: true,

            afm: '',
            doy: '',
            drasthriothta: '',

            name: '',
            lastname: '',
            companyName: '',
            country: '',
            nomos: '',
            town: '',
            address: '',
            addressNumber: '',
            tk: '',
            phone: '',
            email: '',
            details: '',
        }
    }

    render() {
        //Functions from Redux
        const { setTimologio, setUserValue, selectUser } = this.props;
        //Values From Redux
        const { timologio, afm, doy, drasthriothta, name, lastname, companyName, country, nomos, town, address, addressNumber, tk, phone, email, details } = this.props.selectUser;

        return (
            <PanelDetailsContainer>
                <form action="submit" autoComplete='on'>
                    <WrapInputContainer style={{ padding: '.5rem 1rem 1rem', border: '1px solid #4444', marginBottom: '2rem', borderRadius: '1rem' }}>

                        <SpanContainer htmlFor="user-timologio">Εκδοσή Τιμολογιού</SpanContainer>

                        <WrapTimologioContainer>

                            <OptionTimologioContainer>
                                <input type="radio"
                                    value={true}
                                    id='timologio-true'
                                    name='timologio'
                                    onChange={() => setTimologio(true)}
                                    tabIndex={1}
                                    defaultChecked={timologio.value ? true : false} />

                                <label htmlFor="timologio-true">Ναι</label>
                            </OptionTimologioContainer>

                            <OptionTimologioContainer>
                                <input type="radio"
                                    value={false}
                                    id='timologio-false'
                                    name='timologio'
                                    onChange={() => setTimologio(false)}
                                    tabIndex={2}
                                    defaultChecked={timologio.value ? false : true}
                                />
                                <label htmlFor="timologio-false">Απόδειξη</label>
                            </OptionTimologioContainer>

                        </WrapTimologioContainer>

                    </WrapInputContainer>

                    {timologio.value &&
                        <TimologioContainer>

                            <WrapContainerDiv>
                                <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '50%' } : { width: '100%' }}>
                                    <InputContainer
                                        autoComplete='off'
                                        type="text"
                                        name='user-afm'
                                        id='user-afm'
                                        placeholder='Α.Φ.Μ.'
                                        tabIndex={3}
                                        value={afm.value}
                                        onChange={(event) => setUserValue('afm', event.currentTarget.value)} />
                                    <LabelContainer htmlFor="user-afm" select={afm.value !== '' ? true : false}>Α.Φ.Μ.</LabelContainer>
                                </WrapInputContainer>
                                <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '50%', padding: '.5rem 1rem 1.5rem' } :
                                    { width: '100%', padding: '.5rem 1rem 1.5rem' }}>
                                    <InputContainer
                                        autoComplete='off'
                                        type="text"
                                        name='user-doy'
                                        id='user-doy'
                                        placeholder='Δ.Ο.Υ.'
                                        tabIndex={4}
                                        value={doy.value}
                                        onChange={(event) => setUserValue('doy', event.currentTarget.value)} />
                                    <LabelContainer htmlFor="user-doy" select={doy.value !== '' ? true : false}>Δ.Ο.Υ.</LabelContainer>
                                </WrapInputContainer>
                            </WrapContainerDiv>

                            <WrapInputContainer style={{ width: '100%', padding: '.5rem 1rem 1.5rem' }}>
                                <InputContainer
                                    autoComplete='off'
                                    type="text"
                                    name='user-drasthriothta'
                                    id='user-drasthriothta'
                                    placeholder='Δραστηριότητα'
                                    tabIndex={5}
                                    value={drasthriothta.value}
                                    onChange={(event) => setUserValue('drasthriothta', event.currentTarget.value)} />
                                <LabelContainer htmlFor="user-drasthriothta" select={drasthriothta.value !== '' ? true : false}>Δραστηριότητα</LabelContainer>
                            </WrapInputContainer>

                        </TimologioContainer>
                    }

                    <TitleContainer>Προσωπικά στοιχεία</TitleContainer>

                    <WrapContainerDiv>
                        <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '50%' } : { width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-name'
                                id='user-name'
                                placeholder='Όνομα'
                                tabIndex={6}
                                autoComplete='given-name'
                                value={name.value}
                                onChange={(event) => setUserValue('name', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-name" select={name.value !== '' ? true : false}>Όνομα</LabelContainer>
                        </WrapInputContainer>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { padding: '.5rem 1rem 1.5rem', width: '50%' } :
                            { padding: '.5rem 1rem 1.5rem', width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-lastname'
                                id='user-lastname'
                                placeholder='Επίθετο'
                                tabIndex={7}
                                autoComplete='family-name'
                                value={lastname.value}
                                onChange={(event) => setUserValue('lastname', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-lastname" select={lastname.value !== '' ? true : false}>Επίθετο</LabelContainer>
                        </WrapInputContainer>
                    </WrapContainerDiv>

                    <WrapInputContainer>
                        <InputContainer
                            type="text"
                            name='user-company'
                            id='user-company'
                            placeholder='Όνομα Εταιρείας (Προαιρετικό)'
                            tabIndex={8}
                            autoComplete='organization'
                            value={companyName.value}
                            onChange={(event) => setUserValue('companyName', event.currentTarget.value)} />
                        <LabelContainer htmlFor="user-company" select={companyName.value !== '' ? true : false}>Όνομα Εταιρείας</LabelContainer>
                    </WrapInputContainer>

                    <WrapContainerDiv>
                        <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '33%' } : { width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-country'
                                id='user-country'
                                placeholder='Χώρα'
                                tabIndex={9}
                                value={country.value}
                                onChange={(event) => setUserValue('country', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-country" select={country.value !== '' ? true : false}>Χώρα</LabelContainer>
                        </WrapInputContainer>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '33%' } : { width: '100%' }}>
                            <SelectContainer id='user-nomos' name='user-nomos' value={selectUser.nomos.value !== '' && selectUser.nomos.value} onChange={(evt) => setUserValue('nomos', evt.currentTarget.value)}>
                                <option value="select">Επιλέξτε Νομό</option>
                                <option value="Αττικής">Αττικής</option>
                                <option value="Ευβοίας">Ευβοίας</option>
                                <option value="Ευρυτανίας">Ευρυτανίας</option>
                                <option value="Φωκίδας">Φωκίδας</option>
                                <option value="Φθιώτιδας">Φθιώτιδας</option>
                                <option value="Βοιωτίας">Βοιωτίας</option>
                                <option value="Χαλκιδικής">Χαλκιδικής</option>
                                <option value="Ημαθίας">Ημαθίας</option>
                                <option value="Κιλκίς">Κιλκίς</option>
                                <option value="Πέλλας">Πέλλας</option>
                                <option value="Πιερίας">Πιερίας</option>
                                <option value="Σερρών">Σερρών</option>
                                <option value="Θεσσαλονίκης">Θεσσαλονίκης</option>
                                <option value="Χανίων">Χανίων</option>
                                <option value="Ηρακλείου">Ηρακλείου</option>
                                <option value="Λασιθίου">Λασιθίου</option>
                                <option value="Ρεθύμνης">Ρεθύμνης</option>
                                <option value="Δράμας">Δράμας</option>
                                <option value="Έβρου">Έβρου</option>
                                <option value="Καβάλας">Καβάλας</option>
                                <option value="Ροδόπης">Ροδόπης</option>
                                <option value="Ξάνθης">Ξάνθης</option>
                                <option value="Άρτας">Άρτας</option>
                                <option value="Ιωαννίνων">Ιωαννίνων</option>
                                <option value="Πρέβεζας">Πρέβεζας</option>
                                <option value="Θεσπρωτίας">Θεσπρωτίας</option>
                                <option value="Κέρκυρας">Κέρκυρας</option>
                                <option value="Κεφαλληνίας">Κεφαλληνίας</option>
                                <option value="Λευκάδας">Λευκάδας</option>
                                <option value="Ζακύνθου">Ζακύνθου</option>
                                <option value="Χίου">Χίου</option>
                                <option value="Λέσβου">Λέσβου</option>
                                <option value="Σάμου">Σάμου</option>
                                <option value="Αρκαδίας">Αρκαδίας</option>
                                <option value="Αργολίδας">Αργολίδας</option>
                                <option value="Κορινθίας">Κορινθίας</option>
                                <option value="Λακωνίας">Λακωνίας</option>
                                <option value="Μεσσηνίας">Μεσσηνίας</option>
                                <option value="Κυκλάδων">Κυκλάδων</option>
                                <option value="Δωδεκανήσου">Δωδεκανήσου</option>
                                <option value="Καρδίτσας">Καρδίτσας</option>
                                <option value="Λάρισας">Λάρισας</option>
                                <option value="Μαγνησίας">Μαγνησίας</option>
                                <option value="Τρικάλων">Τρικάλων</option>
                                <option value="Αχαΐας">Αχαΐας</option>
                                <option value="Αιτωλοακαρνανίας">Αιτωλοακαρνανίας</option>
                                <option value="Ηλείας">Ηλείας</option>
                                <option value="Φλώρινας">Φλώρινας</option>
                                <option value="Γρεβενών">Γρεβενών</option>
                                <option value="Καστοριάς">Καστοριάς</option>
                                <option value="Κοζάνης">Κοζάνης</option>
                                <option value="Άγιο Όρος">Άγιο Όρος</option>
                            </SelectContainer>
                            <LabelContainer htmlFor="user-nomos" select={nomos.value !== '' ? true : false}>Νομός</LabelContainer>
                        </WrapInputContainer>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { padding: '.5rem 1rem 1.5rem', width: '34%' } :
                            { padding: '.5rem 1rem 1.5rem', width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-town'
                                id='user-town'
                                placeholder='Πόλη'
                                tabIndex={10}
                                value={town.value}
                                onChange={(event) => setUserValue('town', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-town" select={town.value !== '' ? true : false}>Πόλη</LabelContainer>
                        </WrapInputContainer>

                    </WrapContainerDiv>




                    <WrapContainerDiv>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '55%' } : { width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-address'
                                id='user-address'
                                placeholder='Διεύθυνση'
                                tabIndex={11}
                                autoComplete='address-line1'
                                value={address.value}
                                onChange={(event) => setUserValue('address', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-address" select={address.value !== '' ? true : false}>Διεύθυνση</LabelContainer>
                        </WrapInputContainer>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { width: '20%' } : { width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-addressNumber'
                                id='user-addressNumber'
                                placeholder='Αριθμός'
                                tabIndex={12}
                                autoComplete='address-line2'
                                value={addressNumber.value}
                                onChange={(event) => setUserValue('addressNumber', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-addressNumber" select={addressNumber.value !== '' ? true : false}>Αριθμός</LabelContainer>
                        </WrapInputContainer>

                        <WrapInputContainer style={window.screen.availWidth > 600 ? { padding: '.5rem 1rem 1.5rem', width: '25%' } :
                            { padding: '.5rem 1rem 1.5rem', width: '100%' }}>
                            <InputContainer
                                type="text"
                                name='user-zip'
                                id='user-zip'
                                placeholder='Τ.Κ.'
                                tabIndex={13}
                                value={tk.value}
                                onChange={(event) => setUserValue('tk', event.currentTarget.value)} />
                            <LabelContainer htmlFor="user-zip" select={tk.value !== '' ? true : false}>Τ.Κ.</LabelContainer>
                        </WrapInputContainer>

                    </WrapContainerDiv>

                    <WrapInputContainer>
                        <InputContainer
                            type="tel"
                            name='user-phone'
                            id='user-phone'
                            placeholder='Τηλέφωνο'
                            tabIndex={14}
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            value={phone.value}
                            onChange={(event) => setUserValue('phone', event.currentTarget.value)} />
                        <LabelContainer htmlFor="user-phone" select={phone.value !== '' ? true : false}>Τηλέφωνο</LabelContainer>
                    </WrapInputContainer>

                    <WrapInputContainer >
                        <InputContainer
                            type="text"
                            name='user-email'
                            id='user-email'
                            placeholder='Email'
                            tabIndex={15}
                            value={email.value}
                            onChange={(event) => setUserValue('email', event.currentTarget.value)} />
                        <LabelContainer htmlFor="user-email" select={email.value !== '' ? true : false}>Email</LabelContainer>
                    </WrapInputContainer>



                    <TitleContainer>Προσθετές Πληροφορίες</TitleContainer>
                    <DivForTextAreaContainer>
                        <textarea
                            name='user-timologio'
                            id='user-timologio'
                            placeholder='Σημειώσεις σχετικά με την παραγγελία σας...'
                            tabIndex={16}
                            cols={50}
                            rows={7}
                            value={details.value}
                            onChange={(evn) => setUserValue('details', evn.currentTarget.value)} />
                    </DivForTextAreaContainer>

                </form>
            </PanelDetailsContainer>
        );
    }
};

//
const mapStateToProps = createStructuredSelector({
    selectUser: selectUser,
});

//
const mapDispatchToProps = dispatch => ({
    setTimologio: val => dispatch(setTimologioValue(val)),
    setUserValue: (name, value) => dispatch(setUserValues(name, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);