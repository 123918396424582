import React from "react";

//tools
import SingInComponent from '../../components/sing-in/sing-in.component.jsx';
import SingUpComponent from '../../components/sing-up/sing-up.component.jsx';

//Style for singin singup page
import { SingInSingUpStyle, CreateNewUserStyle } from './sing-in-sing-out.page.style.js';

const SingInSingOut = () => {

    return (
        <SingInSingUpStyle className='sing-in-sing-up'>
            <SingInComponent className='loginUser' />
            <CreateNewUserStyle className='createNewUser'>
                <SingUpComponent />
            </CreateNewUserStyle>
        </SingInSingUpStyle>
    )
}

export default SingInSingOut;