//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Action
import { setFilterCategory } from '../../../redux/products-panel/products-panel.actions';

//Style
import './category.style.scss';

//Router
import { ROUTER_ADRESS } from '../../../pages/site.map.router';

const Category = ({ item, history, setFilter }) => {

    const { name, photo, perigrafi } = item

    return (
        <div className='cart-category' onClick={() => {
            setFilter({ state: true, category: [item] });
            history.push(ROUTER_ADRESS.products.url);
            window.scrollTo(0, 0);
        }}>
            <div className='cart-panel'>

                <div className='cart-panel-main'>
                    <div className='cart-panel-main-logo'>
                        <img className='cart-panel-main-logo--img'
                            src={photo}
                            title={name}
                            alt={perigrafi}
                        />
                    </div>

                    <div className='cart-panel-main-details'>
                        <p className='cart-panel-main-details--text'>{perigrafi}</p>
                    </div>
                </div>

                <div className='cart-panel-footer'>
                    <h2 className='cart-panel-footer--text'>{name}</h2>
                </div>

            </div>
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    setFilter: filter => dispatch(setFilterCategory(filter))
});

export default connect(null, mapDispatchToProps)(Category);