//Styles
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonColorsContainer = styled(Link)`
    padding: 1rem 0.3rem;
    ${props => props.selectcolor ?
        {
            background: 'linear-gradient(45deg,#d2f900, #05600d, #2355e1,#ff0000)',
            cursor: 'pointer'
        }
        :
        {
            background: 'dimgray',
            cursor: 'default'
        }
    }
    /* background: linear-gradient(45deg,#d2f900, #05600d, #2355e1,#ff0000);
    cursor:pointer; */
    text-decoration:none;
    background-size:400% 400%;
    color: white;
    border-radius: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 2.5rem;
    position:relative;
    z-index:10;

    &::before{
        content:'';
        background: linear-gradient(45deg,#d2f900, #05600d, #2355e1,#ff0000);
        background-size:400% 400%;
        position:absolute;
        top:-5px;
        bottom:-5px;
        left:-5px;
        right:-5px;
        opacity:0;
        border-radius:1.5rem;
        filter:blur(9px);
        z-index:-1;

        transition:opacity .5s ease;
    }

    /* transition: .4s ease; */

    &:hover{
        ${props => props.selectcolor ?
        {
            animation: 'gradient 5s ease infinite'
        }
        :
        {
            animation: 'none'
        }
    }
    }
    &:hover::before{
        ${props => props.selectColor ?
        {
            opacity: 1,
            animation: 'gradient 5s ease infinite'
        }
        :
        {
            opacity: 0,
            animation: 'none'
        }
    }
    }

    @keyFrames gradient {
        0%{
            background-position: 0% 50%;
        }
        50%{
            background-position:100% 50%;
        }
        100%{
            background-position:0% 50%;
        }
    }
`;

export const TextButtonContainer = styled.span`
    padding: .5rem;
    border-radius: 1.5rem;
    border: 2px solid white;

    &::selection{
        background:transparent;
    }
`;