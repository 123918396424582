//React
import React from 'react';

//Style
const styleFlagSVG = {
    width: '100%',
    height: '100%'
}

const FlagSVG = ({elementName}) => (
    <svg style={styleFlagSVG} xmlns="http://www.w3.org/2000/svg" width="295" height="118" viewBox="0 0 295 118" >
        <linearGradient id={`flag-svg-${elementName}`} gradientUnits="userSpaceOnUse" x1="2.96" y1="60.01" x2="292.54" y2="60.01">
            <stop offset="0" stopColor="#e31e24" />
            <stop offset=".51" stopColor="#831e24" />
            <stop offset=".988" stopColor="#e31e24" />
            <stop offset="1" stopColor="#fefefe" />
        </linearGradient>
        <path fill={`url(#flag-svg-${elementName})`} d="M2.96 3.77h277.36c12.06-.06 12.06-.45 12.22 11.83v89.2c-.2 9.96-2.36 11.4-12.5 11.46H2.96l9.26-54.62L2.96 3.77z" />
    </svg>
);

export default FlagSVG;