//Reselect
import { createSelector } from 'reselect';

const selectStateExtraChoise = state => state.cartSelectItem;

export const selectExtraChoise = createSelector(
    [selectStateExtraChoise],
    cartSelectItem => cartSelectItem
);

export const selectExtraPrice = createSelector(
    [selectExtraChoise],
    cartSelectItem => cartSelectItem.extraPrice
);

//Stelnei ta items poy einai na ginouyn sale
export const selectSaleItems = createSelector(
    [selectStateExtraChoise],
    cartSelectItem => cartSelectItem.saleItems
);