

//Einai to object pou 8a kanoyn antigrafi ola ta antikoimena
const proionOBJ = {
    name: '',
    path: '',
    pathMin: '',
    title: '',
    perigrafi: ''
}

//Einai o Array poy ginete export
export const proiontaArray = [];


//Einai ta antikoimena
const proion1 = { ...proionOBJ };
const proion2 = { ...proionOBJ };
const proion3 = { ...proionOBJ };
const proion4 = { ...proionOBJ };
const proion5 = { ...proionOBJ };
const proion6 = { ...proionOBJ };
const proion7 = { ...proionOBJ };
const proion8 = { ...proionOBJ };

// https://res.cloudinary.com/karekles-gr/image/upload/c_scale,w_200/v1591013135/general_images/home-slider/pagkaki-xoris-plati-min_ofczhx.webp
//Bazoyme tis times apo ta antikoimena poy ftiaksame
//karekla kanoniki
proion1.name = 'Καρέκλες';
proion1.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1590864522/general_images/karekla-kafeneioy-zografos-3_rcthmo.webp';
proion1.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591010923/general_images/home-slider/karekla-kafeneioy-zografos-3-min_jczxh1.webp';
proion1.title = 'Καρέκλες παραδοσιακές , καρέκλες καφενείου';
//skampo
proion2.name = 'Σκαμπό';
proion2.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591009746/general_images/home-slider/skampo_niu9ra.webp';
proion2.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591009746/general_images/home-slider/skampo-min_xahuf6.webp';
proion2.title = 'Σκαμπό 50 , σκαμπό 80'
//kareklaki
proion3.name = 'Καρεκλάκια';
proion3.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012609/general_images/home-slider/kareklaki_t7uqsd.webp';
proion3.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012609/general_images/home-slider/kareklaki-min_l829xv.webp';
proion3.title = 'Καρεκλάκια'
//pagkaki-me-plati
proion4.name = 'Παγκάκια με πλάτη';
proion4.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012575/general_images/home-slider/pagkaki-me-plati_varyuj.webp';
proion4.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012575/general_images/home-slider/pagkaki-me-plati-min_xgy1e8.webp';
proion4.title = 'Παγκάκια με πλάτη';
//trapezi
proion5.name = 'Τραπέζια';
proion5.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012664/general_images/home-slider/trapezi_cr06mr.webp';
proion5.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012664/general_images/home-slider/trapezi-min_ucdauy.webp';
proion5.title = 'Τραπέζια';
//trapezaki
proion6.name = 'Τραπεζάκι βοηθητικό';
proion6.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012835/general_images/home-slider/trapezaki_unxf7x.webp';
proion6.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012835/general_images/home-slider/trapezaki-min_uqlkme.webp';
proion6.title = 'Τραπεζάκι βοηθητικό';
//trapezi-me-kolones
proion7.name = 'Τραπέζια με κολώνες';
proion7.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591013110/general_images/home-slider/trapezi-kolones_rmjhl8.webp';
proion7.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591013110/general_images/home-slider/trapezi-kolones-min_ggfxds.webp';
proion7.title = 'Τραπέζια με κολώνες';
//pagkaki-xoris-plati
proion8.name = 'Παγκάκια χωρίς πλάτες';
proion8.path = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591013136/general_images/home-slider/pagkaki-xoris-plati_yjf1da.webp';
proion8.pathMin = 'https://res.cloudinary.com/karekles-gr/image/upload/v1591013135/general_images/home-slider/pagkaki-xoris-plati-min_ofczhx.webp';
proion8.title = 'Παγκάκια χωρίς πλάτες';

//bazoume ta antikoimena ston Array
proiontaArray.push(proion1);
proiontaArray.push(proion2);
proiontaArray.push(proion3);
proiontaArray.push(proion4);
proiontaArray.push(proion5);
proiontaArray.push(proion6);
proiontaArray.push(proion7);
proiontaArray.push(proion8);
