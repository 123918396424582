// User types
import { userActionsTypes } from './user.types';

// set default variable 
const INITIAL_STATE = {
    currentUser: null
};

// Reducer
const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userActionsTypes.SET_NEW_USER:
            return {
                currentUser: action.payload
            };
        default:
            return state;
    }
}

export default userReducer;