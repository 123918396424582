//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setSelectReview } from '../../../redux/reviews/reviews.actions';

//Style
import './review-points.style.scss';


const Points = ({selectNum, reviewItem, setSelectReview }) => {

    const { id, name } = reviewItem

    return (
        <div
            className={`points-container ${selectNum === reviewItem.id - 1 ? 'viewPoint' : ''}`}

            title={name}
            id={id}
            onClick={() => {
                setSelectReview(reviewItem);
            }}
        />
    )
}

const mapDispatchToProps = (dispatch) => ({
    setSelectReview: item => dispatch(setSelectReview(item))
});


export default connect(null, mapDispatchToProps)(Points);
