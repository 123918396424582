//elegxei an ypaxoyn filtra checkarismena kai epistrefei ta sosta items
export const checkForFilters = (filter, items) => {
    //check if filters is true else do not anythink 

    if (!filter.state) {
        //Δεν θα έχει αλλάξει τίποτα 
        return items;
    };

    //select category
    const NewFilters = filter.category.map((filter) => items.filter((item) => item.category === filter.name));
    let filterArray = [];

    for (let i = 0; i < NewFilters.length; i++) {
        filterArray = [...filterArray, ...NewFilters[i]];
    };

    return filterArray;
};