//React
import React from 'react';

//Styles
import {
    WrapDivPanel,
    PanelTitleContainer,
    ChoiseContainer,
    NoCostContainer,
    ChoiseInputContainer,
    FormContainer,
    ExtraChoiseContainer
} from '../select.extra.choise.style.jsx';

export const SelectSeatValue = ({ seatValues, selectExtraChoise, calculateExtraPrice, setChangesToState }) => {
    return (
        <WrapDivPanel>
            <PanelTitleContainer>Αλλαγή <strong>Καθίσματος</strong></PanelTitleContainer>
            <FormContainer style={{ display: "flex" }}>

                <NoCostContainer cost={seatValues.price}
                    showNoCost={seatValues.showNoCost}>{seatValues.showNoCost ?
                        'Χωρίς \n Χρέωση'
                        :
                        `+${seatValues.price}€`}
                </NoCostContainer>

                <ExtraChoiseContainer>

                    <ChoiseContainer>

                        <ChoiseInputContainer
                            name='seat'
                            id='seat-psatha'
                            type="radio"
                            value='Ψάθα'
                            data-value={true}
                            onChange={() => {
                                setChangesToState({ name: 'seat', values: { ...seatValues, showNoCost: true, value: 'Ψάθα' } });

                                if (seatValues.showNoCost) return;
                                calculateExtraPrice('minus', seatValues.price);
                            }}
                            defaultChecked={seatValues.value === 'Ψάθα' ? true : false}
                        />
                        <label htmlFor="seat-psatha">Ψάθα</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            name='seat'
                            id='seat-dermatini'
                            type="radio"
                            value='Δερματίνη'
                            data-value={false}
                            onChange={() => {
                                setChangesToState({ name: 'seat', values: { ...seatValues, showNoCost: false, value: 'Δερματίνη' } });

                                if (!seatValues.showNoCost) return;
                                calculateExtraPrice('plus', seatValues.price);
                            }}
                            defaultChecked={seatValues.value === 'Δερματίνη' ? true : false}
                        />
                        <label htmlFor="seat-dermatini">Δερματίνη</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            name='seat'
                            id='seat-koyreloy'
                            type="radio"
                            value='Κουρελού'
                            data-value={false}
                            onChange={() => {
                                setChangesToState({ name: 'seat', values: { ...seatValues, showNoCost: false, value: 'Κουρελού' } });

                                if (!seatValues.showNoCost) return;
                                calculateExtraPrice('plus', seatValues.price);
                            }}
                            defaultChecked={seatValues.value === 'Κουρελού' ? true : false}
                        />
                        <label htmlFor="seat-koyreloy">Κουρελού</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            name='seat'
                            id='seat-yfasma'
                            type="radio"
                            value='Ύφασμα'
                            data-value={false}
                            onChange={() => {
                                setChangesToState({ name: 'seat', values: { ...seatValues, showNoCost: false, value: 'Ύφασμα' } });

                                if (!seatValues.showNoCost) return;
                                calculateExtraPrice('plus', seatValues.price);
                            }}
                            defaultChecked={seatValues.value === 'Ύφασμα' ? true : false}
                        />
                        <label htmlFor="seat-yfasma">Ύφασμα</label>
                    </ChoiseContainer>

                </ExtraChoiseContainer>

            </FormContainer>
        </WrapDivPanel>
    )
};