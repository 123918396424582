//Style
import styled from 'styled-components';

export const WrapNumSalesContainer = styled.div`
    width: 25vw;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* padding: 1rem; */
    margin-bottom:1.5rem;

    @media(max-width:400px){
        width:100%;
        flex-direction:column;
    }
`;


export const TitleContainer = styled.span`
    font-size: 1.3rem;
    line-height:1.7;
    letter-spacing: 6px;
    margin-right:1rem;
    text-align:center;
`;

export const PanelNumItemsContainer = styled.div`
    display:flex;
    align-items:center;
`;

export const WrapMinSalesPanel = styled.div`
    width: 7rem;
    height: 7rem;
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NumMinSalesContainer = styled.div`
    width: 6rem;
    height: 6rem;
    background-color: red;
    border: 4px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    font-weight: 600;
`;

export const ItemsTextContainer = styled.span`
    font-size: 4rem;
    text-transform: capitalize;
    color: red;
    font-weight: 600;
    margin-left: 2rem;
`;