const INITIAL_STATE = {
    cartItem:{
        isProsfora: true,
        prosforaPrice: 18,
        isNew: true,
        isHot: true,
        titleItem: 'Καρέκλα Καφενείου',
        priceItem: 19,
        selectFpaItem: 'Χωρίς',
        nameModelItem: 'chair',
        codeModelItem: '100.100.102'
    }
}

const cartItemReducer = (state = INITIAL_STATE, action)=>{
    switch(action.type){
        
        default:
            return state;
    }
}

export default cartItemReducer;