//React
import React, { Component } from 'react';

//Style
// import './jobsForUs.style.scss';

class JobsFromUsPage extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div>Jobs For Us Page Kosmas</div>
        )
    }
};

export default JobsFromUsPage;