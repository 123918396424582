// import { categoryData } from './categoryData';

import { typesCategory } from './category.types';

const INITIAL_STATE = {
    isFetching: false,
    errorMessage: undefined
};


const categoryReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case typesCategory.FETCH_CATEGORY_START:
            return {
                ...state,
                isFetching: true
            };

        case typesCategory.FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                categories: { ...action.payload }
            };

        case typesCategory.FETCH_CATEGORY_ERROR:
            return {
                ...state,
                isFatching: false,
                errorMessage: action.payload
            };

        default:
            return { ...state };
    }
}

export default categoryReducer;