//Reselect
import { createSelector } from 'reselect';


const selectNotification = (state) => state.notifications;


export const selectAllNotifications = createSelector(
    [selectNotification],
    notifications => notifications.notifications
);