//React
import React from 'react';


//ROUTERING
import { ROUTER_ADRESS } from '../../../../pages/site.map.router';

// Styles
import {
    WrapDivPanel,
    PanelTitleContainer,
    ChoiseContainer,
    NoCostContainer,
    ChoiseInputContainer,
    FormContainer,
    ExtraChoiseContainer
} from '../select.extra.choise.style.jsx';

//Local styles
import {
    ButtonColorsContainer,
    TextButtonContainer,
} from './select.color.styles.jsx';

export const SelectColorValue = ({ category, colorValues, calculateExtraPrice, setChangesToState }) => {

    return (
        <React.Fragment>

            <WrapDivPanel>

                <PanelTitleContainer>Αλλαγή <strong>Χρώματος</strong>{category === 'ΤΡΑΠΕΖΙΑ' && ' χωρίς χρέωση'}</PanelTitleContainer>

                <FormContainer>

                    <NoCostContainer cost={colorValues.price}
                        showNoCost={colorValues.showNoCost}>
                        {colorValues.showNoCost ?
                            'Χωρίς \n Χρέωση'
                            :
                            colorValues.price > 0 ?
                                `+${colorValues.price}€`
                                :
                                'Χωρίς \n Χρέωση'
                        }
                    </NoCostContainer>

                    <ExtraChoiseContainer>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                id='color-karidi'
                                type="radio"
                                value='Καρυδί'
                                name='colors'

                                onChange={() => {
                                    setChangesToState({ name: 'color', values: { ...colorValues, showNoCost: true, value: 'Καρυδί' } });

                                    if (colorValues.showNoCost) return;
                                    calculateExtraPrice('minus', colorValues.price);
                                }}

                                defaultChecked={colorValues.value === 'Καρυδί' ? true : false} />

                            <label htmlFor="color-karidi">Καρυδί</label>
                        </ChoiseContainer>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                id='color-none'
                                type="radio"
                                value='Άβαφο'
                                name='colors'
                                onChange={() => {
                                    setChangesToState({ name: 'color', values: { ...colorValues, showNoCost: true, value: 'Άβαφο' } });

                                    if (colorValues.showNoCost) return;
                                    calculateExtraPrice('minus', colorValues.price);
                                }}
                                defaultChecked={colorValues.value === 'Άβαφο' ? true : false}
                            />
                            <label htmlFor="color-none">Άβαφο</label>
                        </ChoiseContainer>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                id='color-xromatologio'
                                type="radio"
                                value='Χρωματολόγιο'
                                name='colors'
                                onChange={() => {
                                    setChangesToState({ name: 'color', values: { ...colorValues, showNoCost: false, value: 'Χρωματολόγιο' } });
                                    calculateExtraPrice('plus', colorValues.price);
                                }}
                                defaultChecked={colorValues.value === 'Χρωματολόγιο' ? true : false}
                            />
                            <label htmlFor="color-xromatologio">{colorValues.text}</label>
                        </ChoiseContainer>

                    </ExtraChoiseContainer>

                </FormContainer>

            </WrapDivPanel>

            {colorValues.showNoCost === false &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1.5rem',
                }}>

                    {
                        colorValues.selectColor.id !== '' &&
                        <div style={{
                            width: '7rem',
                            height: '7rem',
                            borderRadius: '1rem',
                            border: '4px solid green',
                            overflow: 'hidden',
                            marginRight: '1rem'
                        }}
                        >
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src={colorValues.selectColor.source}
                                alt="Xromatologio color select" />
                        </div>
                    }
                    <ButtonColorsContainer selectcolor={colorValues.showNoCost.toString()} to={ROUTER_ADRESS.colors.url}>
                        <TextButtonContainer>ΧΡΩΜΑΤΟΛΟΓΙΟ</TextButtonContainer>
                    </ButtonColorsContainer>
                </div>
            }

        </React.Fragment>

    )

};