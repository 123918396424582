//Style
import styled from 'styled-components';

export const TitlePanelContainer = styled.div`
    font-size: 1.8rem;
    color: #666;
    padding-bottom: 1.5rem;
`;

export const TitleContainer = styled.h2`
    text-align: center;
    padding-bottom: 2rem;
    font-size: 2rem;
    letter-spacing: 2px;
    color: #666;

    @media(max-width:900px){
        font-size:1.7rem;
    }
`;

export const BankPanelContainer = styled.div`
	display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #666;

    span:not(:last-child){
        margin-bottom:1rem;
    }

    @media(max-width:900px){
        font-size:1.5rem;
    }
`;

export const TitleBankContainer = styled.span`
    padding: .5rem;
    border-bottom: 1px solid #4444;
    width: 20%;
    text-align: center;
`;

export const PayDetailsContainer = styled.div`
    color: #555;
    padding: 1rem .5rem;
    font-size: 1.4rem;

    p:not(:last-child){
        margin-bottom:.5rem;
    }
`;

export const DeliveryWrap = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin:1rem;
`;

export const DeliveryTextContainer = styled.div`
    display: inline-block;
    padding: 0.5rem 2rem;
    border: 1px solid;
    transform: skewX(-20deg);
    font-size: 2rem;
    font-weight: bold;
    border: 3px solid #999;
    color: #999;
    text-transform: uppercase;
`;