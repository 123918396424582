//React
import React, { Component } from 'react';

//redux 
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { selectStep } from '../../../redux/cartPage/cart.page.selectors';

//Component
import StepsCart from '../steps/steps.component.jsx';
import UserDetails from './user-details/user.details.component.jsx';
import PayDetails from './payDetails/pay.details.component.jsx';

//Style
import {
    PaycenterWrap,
    DetailsPanelContainer,
    PayPanelContainer,

} from './check.out.style.jsx';


class CheckOutPage extends Component {

    componentDidMount() {
        const { stepNumber, history } = this.props;
        if (stepNumber !== 2) {
            history.push('/');
        }
    };

    render() {
        const { history } = this.props;

        return (
            <div>

                <StepsCart />

                <PaycenterWrap>
                    <DetailsPanelContainer>
                        <UserDetails />
                    </DetailsPanelContainer>

                    <PayPanelContainer>
                        <PayDetails history={history} />
                    </PayPanelContainer>
                </PaycenterWrap>

            </div>

        );
    }
}

const mapStateToProps = createStructuredSelector({
    stepNumber: selectStep
});

export default connect(mapStateToProps)(CheckOutPage);