//Code for filter
import { checkForFilters } from '../../components/products-panel/filtering/filter.code';
//Code for classification
import { sortItemsFn } from '../../components/products-panel/bar-properties/properties.bar.code';
//Code for pages
import { findPagesToShow, setPerPageItems } from '../../components/products-panel/pages/page.code';


export const changeShowItems = (state, newItems) => {
    //distractore variebles
    const { classification, numItemPerPage, filter } = state;

    //filter
    const filterItems = checkForFilters(filter, newItems);
    
    //classification
    const classificationItems = sortItemsFn(filterItems, classification);

    //pages
    const numPages = findPagesToShow(classificationItems.length, numItemPerPage);

    //ItemsPerPage
    const itemsPerPage = setPerPageItems(numItemPerPage, classificationItems);

    //Create New State Show Items
    const newItemsObj = {
        ...state,
        showItems: classificationItems,
        showPages: numPages,
        itemsPerPage: itemsPerPage
    };

    return newItemsObj;
};