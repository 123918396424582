//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Action
import { setNewStepNumber } from '../../../redux/cartPage/cart.page.actions';

import { withRouter } from 'react-router-dom';

//Components
import SmaillCartItem from './smallCartItem/small.cart.item.jsx';

//Style
import {
    CartContainer,
    MainContainer,
    BasketContainer,
    // ShopNowContainer,
    NoItemsContainer,

} from './pre.cart.style.js';

class PreCart extends Component {

    timers = [];

    componentDidMount() {
        this.timers.push(setTimeout(() => { this.props.onOut() }, 3000))
    }

    render() {
        const { onOut, items } = this.props;

        return (
            <CartContainer
                onMouseLeave={() => onOut()}
                onMouseEnter={() => clearTimeout(this.timers)}
            >
                <MainContainer>
                    {items.length ?
                        items.map((item, idx) => {
                            return <SmaillCartItem key={item.product.id + idx} item={item} idx={idx} />
                        })
                        :
                        <NoItemsContainer>Δεν έχετε προϊόντα στο καλάθι</NoItemsContainer>
                    }
                </MainContainer>
                {items.length ?
                    <div>
                        <BasketContainer onClick={() => {
                            onOut();
                            this.props.setStepCart(1);
                            this.props.history.push('/cart');
                        }}>
                            Προβολη Καλαθιου
                        </BasketContainer>
                    </div>
                    :
                    null
                }
            </CartContainer>
        );
    }
};

// <ShopNowContainer>
// Αμεση Αγορα
// </ShopNowContainer>

const mapDispatchToProps = dispatch => ({
    setStepCart : num => dispatch(setNewStepNumber(num)),
})

export default withRouter(connect(null, mapDispatchToProps)(PreCart));