//System actions
import { systemUpdateTypes } from './system.types';

//if system must update
const systemUpdateAction = (item) => ({
    type: systemUpdateTypes.SYSTEM_UPDATE,
    payload: item
});

export const systemPelatesMonitorCenterImageUpdate = (item) => ({
    type: systemUpdateTypes.SYSTEM_PELATES_MONITOR_CENTER_IMAGE_UPDATE,
    payload:item
});

export const systemPelatesMonitorSmallImageUpdate = (item) => ({
    type: systemUpdateTypes.SYSTEM_PELATES_MONITOR_SMALL_IMAGE_UPDATE,
    payload:item
});

export default systemUpdateAction;