//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectOrderDetails } from '../../../redux/order/order.selectors';
import { selectStep } from '../../../redux/cartPage/cart.page.selectors';

//Components
import { DetailsForPayment } from '../checkOut/payDetails/details/details.component.jsx';
import OrderDetails from './order-details/order.details.component.jsx';
import StepsCart from '../steps/steps.component.jsx';

//Code
import { sendEmail } from '../../../code/send.email';

//Firebase funtcions
import { setNewOrderToFirebase, setNewOrderNumber } from '../../../firebase/firebase.utils';

//Actions
import { cartRemoveAll } from '../../../redux/cart/cart.actions';
import { cartPageRemoveAll } from '../../../redux/cartPage/cart.page.actions';
import { orderRemoveAll } from '../../../redux/order/order.actions';
//\\
import { setNewStepNumber } from '../../../redux/cartPage/cart.page.actions';

//Style
import {
    PageContainer,
    MessageContainer,
    WrapPeymentContainer,
    ReturnButtonContaine,
} from './pay.center.style.jsx';

class PayCenterPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: null,
        };
    };

    componentWillUnmount() {
        this.props.setNewStepNumber(1);
    };

    componentDidMount() {

        const { selectStep, history, setNewStepNumber } = this.props;

        if (selectStep !== 4) {
            console.error('Error to Number Page...');
            //Αν δεν είναι το σωστό νούμερο
            history.push('/');
            return;
        } else if (this.state.order === null) {
            console.error('Error to Order Values...');
            //Αν δεν έχει καθόλου παραγγελία να δείξει
            setNewStepNumber(1);
            history.push('/');
            return;
        } else {

            //Πρέπει να στέλνει Email
            this.sendEmailOrder();
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectOrder !== null) {
            state.order = props.selectOrder;
            return state;
        } else {
            return null;
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.order === null && nextProps.selectOrder !== null) {
            return true;

        } else if (nextProps.selectOrder !== null) {
            return true;

        } else {
            return false;
        }
    };

    sendEmailOrder = async () => {
        //Πρέπει να στέλνει Email στο κατάστημα αλλά και στον πελάτι
        const orderData = { ...this.state.order, modeEmail: 'order' }
        // console.log("Send options from React....", orderData);

        const reseltEmail = await sendEmail(null, orderData);

        // console.log('came to clear order data...', reseltEmail);

        if (reseltEmail.status_email === 'success') {
            //Πρέπει να καταχωρήση την παραγγελία στην firebase.
            await setNewOrderToFirebase(orderData);

            //Πρέπει να ανεβάσει τον αριθμό των παραγγελιών κατα 1 μονάδα.
            await setNewOrderNumber(orderData.settings.order_number.value);

            //πρέπει να σβήνει τα πάντα και να μηδενίζει τους μετριτές για νέα παραγγελία.
            const { cartRemoveAll, cartPageRemoveAll, orderRemoveAll } = this.props;
            //cart Reducer
            cartRemoveAll();
            //cart page reducer
            cartPageRemoveAll();
            //order
            orderRemoveAll();
        }
    };

    render() {
        const { history } = this.props;

        return (
            <PageContainer>

                <StepsCart />

                <MessageContainer >Η ΠΑΡΑΓΓΕΛΙΑ ΣΑΣ ΕΧΕΙ ΣΤΑΛΘΕΙ</MessageContainer>

                <WrapPeymentContainer count={this.state.order !== null ? 2 : 1}>
                    {
                        this.state.order !== null &&
                        <OrderDetails selectOrder={this.state.order} />
                    }

                    <div>
                        <DetailsForPayment />
                    </div>
                </WrapPeymentContainer>

                <ReturnButtonContaine onClick={() => {
                    // this.props.setNewStepNumber(1);
                    history.push('/')
                }}>Επιστροφή στο κατάστημα</ReturnButtonContaine>

            </PageContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    selectOrder: selectOrderDetails,
    selectStep: selectStep,
});

const mapDispatchToState = dispatch => ({
    cartRemoveAll: () => dispatch(cartRemoveAll()),
    cartPageRemoveAll: () => dispatch(cartPageRemoveAll()),
    orderRemoveAll: () => dispatch(orderRemoveAll()),
    setNewStepNumber: (num) => dispatch(setNewStepNumber(num))
});


export default connect(mapStateToProps, mapDispatchToState)(PayCenterPage);