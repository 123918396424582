//React
import React, { Component } from 'react';

//Style

class ForUsPage extends Component {
    // constructor(props) {
    //     super(props);
    // }



    render() {
        return (
            <div>For Us Page Kosmas</div>
        )
    }
};

export default ForUsPage;