//React
import React from 'react';

//Style
import '../phones.style.scss';

export const PhonesShop = ({ phone = '2102633962', mobile = '6941411448' }) => {

    return (
        <div className='phones-panel'>
            <div className='phone'>
                <span className='phone-title'>Τηλέφωνο : </span>
                <a className='phone-number' href={`tel:${phone}`}>{phone}</a>
            </div>
            <div className='phone'>
                <span className='phone-title'>Κινητό : </span>
                <a className='phone-number' href={`tel:${mobile}`}>{mobile}</a>
            </div>
        </div>
    )
};