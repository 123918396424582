//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselet
import { createStructuredSelector } from 'reselect';

//Selector
import { selectCategory } from '../../../../redux/category/category.selector';


//Style
import styled from 'styled-components';

const FilterContainer = styled.div`
    display:flex;
    flex-direction:column;

    margin: 1rem;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    display:flex;
    justify-content:center;
    `;

const TitleContainer = styled.h5`
    text-align: center;
    font-family: serif;
    font-size: 2.4rem;
    text-transform: uppercase;
    border-bottom: 1px dashed;
    padding: 1rem;

    @media (max-width:1100px){
        font-size: 2rem;
    }
    @media (max-width:900px){
        font-size: 1.8rem;
    }
    @media (max-width:400px){
        padding:.6rem;
    }
`;

const CategoryContainer = styled.div`
    font-size: 1.8rem;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    padding: 1rem .5rem;
    
    @media(min-width:1101px){
        &:not(:last-child){
            border-bottom: 1px dashed;
        }
    }

    @media(max-width:1100px){
        font-size:1.6rem;
        border-bottom:1px dashed;
    }

    @media(max-width:900px){
        font-size:1.6rem;
    }

    @media(max-width:400px){
        padding:.5rem;
    }
`;

const FormContainer = styled.form`
    display:flex;
    flex-direction: column;

    @media (max-width:1100px){
        flex-direction:row;
        flex-wrap:wrap;
    }
`;

const CheckboxField = styled.input`
    margin-right: 1rem;
    width: 1.8rem;
    height: 1.8rem;
    cursor:pointer;
    @media(max-width:600px){
        margin-right:.5rem;
    }
`;

// const ButtonSubmit = styled.input`
//     margin: 1.5rem auto;
//     padding: .5rem 2rem;
//     font-size: 2.5rem;
//     color: white;
//     letter-spacing: 1px;
//     background-color: #328dea;
//     border-radius: 5px;
//     cursor:pointer;
//     transition: background-color .2s ease, color .2s ease-in;

//     &:hover{
//         background-color:#85bcf5;
//         color:black;
//     };

//     &:focus{
//         outline:none;
//     }
// `;

// const StylePanelBtn = styled.div`
//     display: flex;
//     visibility: hidden;
// `;

const LabelField = styled.label`
    cursor:pointer;
    
    @media(max-width:600px){
        font-size:1.3rem
    }
`;

class FilterCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        };
    }


    render() {
        const { categories, findSelectCategory, filter } = this.props;
        const nameCategoryArray = Object.values(categories);

        return (
            <FilterContainer>
                <TitleContainer>
                    κατηγοριες
            </TitleContainer>
                <FormContainer id='formFilterJS'>
                    {
                        nameCategoryArray && nameCategoryArray.map((category) => {

                            //Να φτιχτει ενας Array για να κραταει εαν είναι τσεκαρισμένη η κατηγορία
                            const findStateChecked = () => {

                                const reselt = filter.category.filter((f) => f.name === category.name);
                                return reselt.length > 0 ? true : false;
                            }

                            return (
                                <CategoryContainer key={category.name + category.id}>
                                    <CheckboxField id={category.id}
                                        type="checkbox"
                                        checked={findStateChecked()}
                                        onChange={() => this.setState({ checked: !this.state.checked })}
                                        // checked={this.state.checked}
                                        name={category.name}
                                        value={category.name}
                                        onClick={(event) => {
                                            findSelectCategory(nameCategoryArray);
                                        }} />
                                    <LabelField htmlFor={category.id}>{category.name}</LabelField>
                                </CategoryContainer>
                            )
                        })
                    }
                </FormContainer>
            </FilterContainer>
        );
    }


};

// <StylePanelBtn>
//     <ButtonSubmit type="submit" value='ΑΝΑΝΕΩΣΗ' onClick={(event) => { event.preventDefault(); findSelectCategory(nameCategoryArray) }} />
// </StylePanelBtn>



const mapStateToProps = createStructuredSelector({
    categories: selectCategory,
})

export default connect(mapStateToProps)(FilterCategory);