//React
import React, { Component } from 'react';

//Styled-component
import styled from 'styled-components';

//Component
import Notification from './notification/notification.component.jsx';


//Style
const NotificationsContainer = styled.div`
    position: fixed;
    top: 5rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow:hidden;
    z-index:1000;
`;

class NotificationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            showItems: 20,
            itemList: this.props.itemList
        }
    }

    UNSAFE_componentWillUpdate(newState, prevState) {
        // this.setState({itemList:this.props.itemList}) 
        if (newState.itemList !== prevState.itemList) {
            this.shouldComponentUpdate = true;
            this.setState({ itemList: newState.itemList });

        } else {
            this.shouldComponentUpdate = false;
        }
    }

    render() {

        return (
            <NotificationsContainer>
                {
                    this.state.itemList && this.state.itemList.map(item => <Notification key={item.id} {...item} />)
                }
            </NotificationsContainer>
        );
    }

}

export default NotificationList;