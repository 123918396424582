//React
import React from 'react';

//Style
// import '../circleMenu.style.scss';
import { CameraImagePlaceStyle, CameraImageNumStyle, SvgLogoStyle, SvgPathStyle } from '../circle.menu.style';

const CameraImageSVG = ({ imagesNum }) => {

    return (
        <CameraImagePlaceStyle className='cameraImage-place'>
            <CameraImageNumStyle className='cameraImage-num'>{imagesNum}</CameraImageNumStyle>
            <SvgLogoStyle>
                <svg className='svg-logo' xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox='0 0 100 100'>
                    <SvgPathStyle
                        pathFill={false}
                        pathStroke={true}
                        pathStrokeBig={true}
                        className="svg-fill-none svg-stroke svg-stroke-big" d="M94.73 30.41l0 50.36c0,4.63 -3.76,8.39 -8.39,8.39l-72.74 0c-4.63,0 -8.39,-3.76 -8.39,-8.39l0 -50.36c0,-4.63 3.76,-8.39 8.39,-8.39l15.39 0 2.15 -5.75c1.22,-3.27 4.35,-5.44 7.85,-5.44l21.94 0c3.5,0 6.63,2.17 7.85,5.44l2.17 5.75 15.39 0c4.63,0 8.39,3.76 8.39,8.39z" />
                </svg>
            </SvgLogoStyle>

        </CameraImagePlaceStyle>
    );
}

export default CameraImageSVG;