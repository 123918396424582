//React
import React from 'react';

//Style
import '../map.style.scss';

export const MapShop = ({ adress = 'Σάμου 92, Αχαρνές, Τ.Κ 13672' }) => {

    return (
        <div className='map-panel'>
            <div className='map-panel-map'>
             
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d555.3329385631449!2d23.729718661019383!3d38.06019129333281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1a2f8a174f90d%3A0xabbe4b88ab7dba13!2zzprOsc-Bzq3Ous67zrXPgiDOls-JzrPPgc6sz4bOv8-C!5e0!3m2!1sel!2sgr!4v1705172367052!5m2!1sel!2sgr"
                    width="100%" height="100%" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="map shop">
                </iframe>
            </div>

            <div className='map-panel-adress'>
                <h4 className='map-panel-adress-title'>Διεύθυνση :</h4>
                <a className='map-panel-adress-link'
                    target='_blank' rel="noopener noreferrer"
                    href='https://www.google.com/maps/place/%CE%9A%CE%B1%CF%81%CE%AD%CE%BA%CE%BB%CE%B5%CF%82+%CE%96%CF%89%CE%B3%CF%81%CE%AC%CF%86%CE%BF%CF%82/@38.0600479,23.7299599,18.63z/data=!4m15!1m8!3m7!1s0x14a1a23a5039f60d:0x785b9ab7bf42e808!2zzqPOrM68zr_PhSA5MiwgzpHPh86xz4HOvc6tz4IgMTM2IDc1!3b1!8m2!3d38.0602106!4d23.7298656!16s%2Fg%2F11hht_k8g1!3m5!1s0x14a1a2f8a174f90d:0xabbe4b88ab7dba13!8m2!3d38.0601613!4d23.7301057!16s%2Fg%2F11j0jkmbsb?entry=ttu'>

                    {adress}
                </a>
            </div>

        </div>
    )
};