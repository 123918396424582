import { pelates_data } from './pelates_data';

const INITIAL_STATE = pelates_data;

const pelatisReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        default:
            return state;
    }
};

export default pelatisReducer;