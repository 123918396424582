import isEqual from 'lodash.isequal';

//Prepei na briskei ta idia antikoimena kai na pros8eth ta quantity allios na
//kanei kainoyrgia eggrafh ean exoyn kapoies diafores
//
// sto loystro.
// sto color.
// sto syrma.
// sto seat.
// sta legs.
//
export const addItemToCart = (state, items) => {

    const newItem = state.cartItem.find(item => checkItemFn(item, items));

    if (newItem) {

        return {
            ...state, cartItem: [...state.cartItem.map((item) =>
                //Prepei na elegxei an einai to idio item
                checkItemFn(item, items) ?
                    { ...item, quantity: item.quantity += items.quantity }
                    :
                    { ...item })]
        };
    }
    return { ...state, cartItem: [...state.cartItem, items] };
};

const checkItemFn = (item1, item2) => {
    const newItem1 = { ...item1, quantity: 0 }
    const newItem2 = { ...item2, quantity: 0 }


    const sameObj = isEqual(newItem1, newItem2);

    return sameObj;
}

export const changeQuantity = (state, item) => {
    const newState = {
        ...state,
        cartItem: [...state.cartItem.map((e) => {
            return checkItemFn(e, item) ? { ...e, quantity: item.quantity } : { ...e }
        })]
    };

    return newState;
}