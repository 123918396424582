//React
import React from 'react';

//Style
import { CenterImageContainer } from './centerImage.style.jsx';

const CenterImage = ({ idx,data_src ,children, ...otherProps }) => {
    const { width, height, left, top, border, color } = otherProps;

    return <CenterImageContainer
        id={idx}
        width={width}
        height={height}
        left={left}
        top={top}
        data_src={data_src}
        border={border}
        color={color}
    >{children}</CenterImageContainer>
};

export default CenterImage;