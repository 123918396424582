export const selectExtraChoiseTypes = {
    SET_NEW_EXTRA_VALUE: 'SET_NEW_EXTRA_VALUE',
    SET_NEW_EXTRA_PRICE: 'SET_NEW_EXTRA_PRICE',

    SET_EXTRA_CHOISE_OBJ: 'SET_EXTRA_CHOISE_OBJ',

    SET_PRODUCT_VALES: 'SET_PRODUCT_VALES',
    SET_DEFAULT_VALUES: 'SET_DEFAULT_VALUES',

    CHANGE_NEW_SALE_ITEMS: 'CHANGE_NEW_SALE_ITEMS',
}