// User actions
import { userActionsTypes } from './user.types';

// Users function
const setCurrentUser = (user) => (
    {
        type: userActionsTypes.SET_NEW_USER,
        payload: user
    }
);

export default setCurrentUser;