//data - for products
//---------------------------------------------------------------
export class product {

    //Είναι το url για το product
    linkUrl = '';

    //To ID για το προιον 
    id = '';

    //Ο κωδικός του model       -> 100.100.102
    codeModelItem = '';
    //Το Όνομα του product      -> 'ΠΑΡΟΣ'
    nameModelItem = '';
    //Ονομασία SEO              -> 'Καρέκλα Καφενείου'
    titleItem = '';

    //Κατηγορία 
    category = '';
    //Υποκατηγορία
    subCategory = '';

    //Χαρακτηριστικά Χονδρικής 
    xondriki = {
        //Η τιμή του Προϊόντος  -> 19
        priceItem: 0,

        //Είναι με ΦΠΑ η τιμή   -> 'Χωρίς'
        selectFpaItem: 'Χωρίς',

        //Ελάχιστη ποσότητα Παραγγελίας -> 10
        minSalesProducts: 0,
    };

    //Χαρακτηριστικά Λιανική
    lianiki = {
    };


    //Είναι σε Προσφορά     -> true
    isProsfora = false;

    //Τιμή Προσφοράς        -> 18
    prosforaPrice = 0;

    //Είναι καινούργιο      -> true
    isNew = false;

    //Είναι Hot             -> true
    isHot = false;

    //Category              -> 'ΚΑΡΕΚΛΕΣ'

    //Sub Category          -> 'Καρέκλα Καφενείου','Καρέκλα Εστιατορίου'

    //Images                -> 'https://'.'https://'
    images = {
        //Είναι ο img που θα μπαίνει στην Κάρτα
        center: '',
        //τα υπόλοιπα img του product
        others: [],
        medium: [],
        big: []
    };
    //Colors                -> 'Χρωματολόγιο'

    //3d image

    //Έχει Σήρμα            -> true
    isSirma = false;

    //Κάθησμα               -> 'Ψάθα'    

    //Περιγραφή                 -> 'Λίγα λόγια για την Καρέκλα.'
    description = 'Μασίφ ξύλινη καρέκλα , στυβαρή κατασκευή με 8 χρόνια εγγύηση, κάθυσμα αναπαυτικό.';
    //Διαστάσεις                -> Πλάτος - Μίκος - Ύψος - Βάθος 
    dimensions = {
        platos: 0,
        mikos: 0,
        ypsos: 0,
        vathos: 0
    };

    //Αν μπορεί να εμφανιστεί στην Αρχική καρτέλα
    canSelectAtHome = true;

    //Είναι για τα Στατιστικά του product
    status = {};

}

//Είναι η σταθερές διευθήνσης
export const stadarURL = {
    karekles: 'https://images.zwgrafos.gr/proionta/karekles/',
    trapezia: 'https://images.zwgrafos.gr/proionta/trapezia/',
    skampo: 'https://images.zwgrafos.gr/proionta/skampo/',
    kareklakia: 'https://images.zwgrafos.gr/proionta/kareklakia/',
    pagkakia: 'https://images.zwgrafos.gr/proionta/pagkakia/',
    set: 'https://images.zwgrafos.gr/proionta/set/',
    skhnotheti: 'https://images.zwgrafos.gr/proionta/skhnotheti/',
}