//Types
import { productsPanelTypes } from './products-panel.types';

//Extra codes
import { changeShowItems } from './products-panel.code';

//start Data
const INIT_STATE = {
    titlePanel: '',
    showItems: [],
    itemsPerPage: [],
    classification: 'default',
    numItemPerPage: 12,
    showPages: 1,
    pageNow: 1,
    mode: 'grid',
    filter: { state: false, category: [] },
    properties: {
        classification_choise: [
            { id: '1', value: 'default', text: 'Προεπιλογή' },
            { id: '2', value: 'nameA-Z', text: 'Ονομασία (Α-Ω)' },
            { id: '3', value: 'nameZ-A', text: 'Ονομασία (Ω-Α)' },
            { id: '4', value: 'priceLow', text: 'Τιμή (Χαμηλή > Υψηλή)' },
            { id: '5', value: 'priceBig', text: 'Τιμή (Υψηλή > Χαμηλή)' },
            { id: '6', value: 'codeA-Z', text: 'Κωδικός (Α-Ω)' },
            { id: '7', value: 'codeZ-A', text: 'Κωδικος (Ω-Α)' },],
        numItem_choise: [
            { id: '1', value: 12, text: '12' },
            { id: '2', value: 24, text: '24' },
            { id: '3', value: 48, text: '48' },
            { id: '4', value: 96, text: '96' },
        ],
    },

}

const productsPanelReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        //Properties bar
        case productsPanelTypes.CHANGE_CLASSIFICATION_ORDER:
            return { ...state, classification: action.payload };

        case productsPanelTypes.CHANGE_NUMBER_OF_ITEMS_PER_PAGE:
            return { ...state, numItemPerPage: action.payload };

        //showItems
        case productsPanelTypes.CHANGE_SHOW_ITEMS:
            return changeShowItems(state, action.payload);
        // return { ...state, showItems: [...action.payload] };

        //PagesPanel
        case productsPanelTypes.CHANGE_NUMBER_OF_PAGES:
            return { ...state, showPages: action.payload };

        case productsPanelTypes.SET_ITEMS_PER_PAGE:
            return { ...state, itemsPerPage: action.payload };

        case productsPanelTypes.SET_PAGE_NOW:
            return { ...state, pageNow: action.payload };

        case productsPanelTypes.SET_FILTER_CATEGORY:
            return action.payload.state ?
                { ...state, filter: { ...action.payload } }
                :
                { ...state, filter: { state: false, category: [] } };

        default:
            return state;
    }
};

export default productsPanelReducer;