//Actions
import { reviewsContainerPanel } from './reviews.types';

export const setNewReview = (item) => ({
    type: reviewsContainerPanel.ADD_REVIEW,
    payload: item
});

export const setSelectReview = (item) => ({
    type: reviewsContainerPanel.SHOW_REVIEW,
    payload: item
})