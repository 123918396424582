//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selector
import { selectPelatesData } from '../../../redux/pelates/pelates.selector';

//Style
import { PelatesContainer, WorkshopName, WorkshopDetails } from './pelates_details.style.jsx';

const PelatesDetails = ({ workshop }) => {

    const { workshop_name, details } = workshop[0].workshop;
    return (
        <PelatesContainer>
            <WorkshopName>{workshop_name}</WorkshopName>
            <WorkshopDetails>{details}</WorkshopDetails>
        </PelatesContainer>
    )
};




const mapStateToProps = createStructuredSelector({
    workshop: selectPelatesData
});

export default connect(mapStateToProps)(PelatesDetails);