import React, { Component } from 'react';

//functions 
import { auth, singInWithGoogle } from '../../firebase/firebase.utils'; //gia to popup sing in with google

//components
import FormInput from '../Forminput/forminput.component.jsx'; //Einai ta custom inputs
import CustomButton from '../custom-button/custom-button.component.jsx'; //Einai to custom Button

//To style poy 8a exei to component
import './sing-in.style.scss';


class SingInComponent extends Component {
    constructor() {
        super()

        this.state = {
            userName: "",
            password: "",
        }
    }

    //erxete ka8e fora poy kanoyme kati sta input
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }


    //prepei na kanei login sthn firebase with email and password
    handlerSubmit = async (event) => {
        //akyronei tis energeies poy einai programatismenes kai kanei tis dikes mas
        event.preventDefault();

        const { userName, password } = this.state;
        //paei na kanei thn syndesh me thn firebase
        try {
            await auth.signInWithEmailAndPassword(userName, password);

            this.setState({
                userName: "",
                password: ""
            });
        } catch (error) {

        }
    }

    render() {
        //einai etoimo btn gia to facebook - prepei na to kanoyme kapoy render
        //
        // <CustomButton isFacebook>facebook</CustomButton>

        return (
            <div className='conect'>
                <h2 className='login-caption'>Σύνδεση με</h2>
                <div className='conect-buttons'>
                    <div className='btn-google'>
                        <CustomButton  isGoogle onClick={singInWithGoogle}>Google</CustomButton>
                    </div>
                </div>

                <div className='other'>
                    <span className='other-hr' /> <span className='other-text'>ή</span> <span className='other-hr' />
                </div>

                <form className='conect-form' onSubmit={this.handlerSubmit}>

                    <span className='login-details'>Κάντε σύνδεση με ( email και password )</span>

                    <FormInput
                        type="email"
                        placeholder="Βάλτε το Email σας"
                        id="inputEmail"
                        title="Βάλτε το Email σας"
                        autoComplete="off"
                        handlechange={this.handleChange}
                        value={this.state.userName}
                        name="userName"
                        svgselect="email"
                        required
                    />

                    <FormInput
                        type="password"
                        placeholder="Βάλτε τον Κωδικό σας"
                        id="inputPassword"
                        title="Βάλτε τον Κωδικό σας"
                        autoComplete="off"
                        handlechange={this.handleChange}
                        value={this.state.password}
                        name="password"
                        svgselect="lock"
                        required>
                    </FormInput>

                    <CustomButton type="submit" isBlack >Σύνδεση</CustomButton>

                </form>

            </div>
        )
    }
}

export default SingInComponent;