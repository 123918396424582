import { selectExtraChoiseTypes } from './selectExtraChoiseTypes';

export const setNewExtraValue = (value) => ({
    type: selectExtraChoiseTypes.SET_NEW_EXTRA_VALUE,
    payload: value
});

export const setNewExtraPrice = (price) => ({
    type: selectExtraChoiseTypes.SET_NEW_EXTRA_PRICE,
    payload: price
});

export const setDefaultValues = () => ({
    type: selectExtraChoiseTypes.SET_DEFAULT_VALUES
});

export const setProductValues = (product) => ({
    type: selectExtraChoiseTypes.SET_PRODUCT_VALES,
    payload: product
});

export const setExtraChoiseOBJ = (obj) => ({
    type: selectExtraChoiseTypes.SET_EXTRA_CHOISE_OBJ,
    payload: obj
});

//Einai gia na allazei ta temaxia poy einai na kanei poleish
export const setNewSaleItems = (items) => ({
    type: selectExtraChoiseTypes.CHANGE_NEW_SALE_ITEMS,
    payload: items
});