//React
import React, { Component } from 'react';

//Style
import { ElementPhotoContainer, SmallImageContainer } from './image.button.style.jsx';

export default class ElementPhoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            num: props.num,
            image_src: props.image_src,
            details: props.details,
            // select:props.select
        }
    };



    render() {
        return (
            <ElementPhotoContainer key={this.state.id} num={this.state.num} select={this.state.select} onClick={(e) => {
                this.props.OnClick(this.state.image_src);
                // this.setState({ select: !this.state.select });
            }}>
                <SmallImageContainer src={this.state.image_src} alt={this.state.details} />
            </ElementPhotoContainer>
        );
    }
};