const reviews_obj = {
    id: 0,
    name: '',
    imageUrl: '',
    reviewTxt: ''
};

const review1 = { ...reviews_obj };
const review2 = { ...reviews_obj }
const review3 = { ...reviews_obj }
const review4 = { ...reviews_obj }
// const review5 = { ...reviews_obj }
// const review6 = { ...reviews_obj }
// const review7 = { ...reviews_obj }


review1.id = 1;
review1.name = 'Nikos Ttaxidiotis';
review1.imageUrl = 'https://scontent.fath3-4.fna.fbcdn.net/v/t1.0-9/91092326_10217290109344890_5492076854372204544_o.jpg?_nc_cat=101&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeGHXeQNLLzm89mZ7l89aRmFga7gezy_aKmBruB7PL9oqb7BGgQPlmG4RXBaoYPHFC4&_nc_ohc=lr4knWVyHU4AX9KBaiM&_nc_ht=scontent.fath3-4.fna&oh=8906b05cac4f37c2e20f409aa0835940&oe=602E87E4';
review1.reviewTxt = 'Μετά από μια υπέροχη πρώτη συνεργασία που έγινε καλή φιλιά γιατί η παραδοσιακή οικογένεια στην τέχνη της κατασκευής καρέκλας και όχι μόνο. Μας ομορφαίνει και το πρίν αλλά και το μετά με τις καλαίσθητες δημιουργίες τους. Από το πιο δροσερό team 🍦AMORECONGELATO 🍦.Σας ευχαριστούμε και καλό καλοκαίρι.'

review2.id = 2;
review2.name = 'Αντωνης αποστολου'
review2.imageUrl = 'https://scontent.fath3-3.fna.fbcdn.net/v/t1.0-9/106355679_10207792161838504_7873940864128250996_o.jpg?_nc_cat=110&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeHDrm4BL0ZKAht2LWQb12TQZ4ICacvmybZnggJpy-bJtiL2LKTjNK_so-9xctyf5fU&_nc_ohc=2SAYhbKwOUwAX8AwZtI&_nc_oc=AQlXXkOVKhRkNbNhX-ZnpC6wVVhptASec32FcwjeJssDqx2cCqLhQjiNaKR_gIVr-XlB46zHKx75mmQygxziMRwb&_nc_ht=scontent.fath3-3.fna&oh=a0c98a7b427540a61b04302a5ea8bc9f&oe=602F8545';
review2.reviewTxt = 'μπορω να πω τα καλυτερα λογια,υπερβηκατε των προσδοκιων μας, μπραβο σας...ΤΑΒΕΡΝΑ ΣΤΑ ΚΑΛΑ ΚΑΘΟΥΜΕΝΑ ΩΡΕΟΙ ΕΥΒΟΙΑ...';

review3.id = 3;
review3.name = 'Panos Lymperis';
review3.imageUrl = 'https://scontent.fath3-3.fna.fbcdn.net/v/t1.0-9/135205880_10158826014854463_4067121838033086028_n.jpg?_nc_cat=110&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeHlFiohc_6kVVq0EpZU-SRRvJnSd9B08Ui8mdJ30HTxSD7GAEaOh38cjdIOlvsMPhg&_nc_ohc=lofSMJ41zC0AX8ISnEV&_nc_ht=scontent.fath3-3.fna&oh=4c68b72b6bebef7b8ec30cd39df18688&oe=602CCA71';
review3.reviewTxt = 'Φιλε Χάρη εμείς σε ευχαριστούμε για τα ωραία σου προϊόντα και την άψογη εξυπηρέτηση';


review4.id = 4;
review4.name = 'Giorgos Tsirimiagos';
review4.imageUrl = 'https://scontent.fath3-4.fna.fbcdn.net/v/t1.0-9/93867423_10218706754717242_2179894519187636224_n.jpg?_nc_cat=101&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeHEZ3-sFSEkQKhm-i_hlI6m7GAWJbHfIe7sYBYlsd8h7than5reVznY-edXJ8F0Icw&_nc_ohc=8FvxYMYuVD4AX96rbID&_nc_ht=scontent.fath3-4.fna&oh=b2b211e89c7cfd20794a0c6a8ec9810f&oe=602EC18B';
review4.reviewTxt = 'Ευχαριστω πολύ φιλε !!Είστε πολύ επαγγελματίες !!Μπραβος σας 🙏🙏';


export const reviews_data = [review1, review2, review3, review4];

//data - reviews data
//--------------------------------------------------------
// 1
// 2
// 3
// 4
// 5
// 6
// 7