//Types
import { productsPanelTypes } from './products-panel.types';


export const setClassificationOrder = (item) => ({
    type: productsPanelTypes.CHANGE_CLASSIFICATION_ORDER,
    payload: item
});

export const setNumbersOfItems = (item) => ({
    type: productsPanelTypes.CHANGE_NUMBER_OF_ITEMS_PER_PAGE,
    payload: item
});

export const setNumberOfPages = (item) => ({
    type: productsPanelTypes.CHANGE_NUMBER_OF_PAGES,
    payload: item
});

//Πρέπει να αλλάζει τα Items που θα δείξει 
export const setShowItems = (item) => ({
    type: productsPanelTypes.CHANGE_SHOW_ITEMS,
    payload: item
});

//Καταχωρεί τα Items ανα σελίδα
export const setItemsPerPage = (items) => ({
    type: productsPanelTypes.SET_ITEMS_PER_PAGE,
    payload: items
});

//Περνάει σε ποιά σελίδα πρέπει να πάει
export const setPageNow = (item) => ({
    type: productsPanelTypes.SET_PAGE_NOW,
    payload: item
})

//Πρέπει να καταχωρήσει τα Filters Category
export const setFilterCategory = (item) => ({
    type: productsPanelTypes.SET_FILTER_CATEGORY,
    payload: item
})