import React, { Component } from 'react';

//tools
import FormInput from '../Forminput/forminput.component.jsx';//Einai to custom input
import CustomButton from '../custom-button/custom-button.component.jsx'//Einai to custom button

//functions
import { auth, createUserProfile } from '../../firebase/firebase.utils.js';

//Style gia to sing up Component
import './sing-up.style.scss'

class SingUpComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayName: '',
            email: '',
            password: '',
            configPassword: '',
            // warning:false
        }
    }

    //prepei na pernaei ton xrhsth sthn firebase
    handleSubmit = async (event) => {
        event.preventDefault();
        const { displayName, email, password, configPassword } = this.state;
        //elegxei an o user edose allo kodiko apo thn epibaibeosh kai an nai stamataei thn diadikasia
        if (password !== configPassword) {
            alert("Προσοχή ο Κωδικός είναι άλλος από τον Κωδικό επαλήθευσης.");
            return;
        }

        //paei na kanei thn eggrafh sthn firebase
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);

            //kataxoroyme ton xrhsth sto project
            await createUserProfile(user, { displayName });

            //ka8arizoyme to stage
            this.setState({
                displayName: '',
                email: '',
                password: '',
                configPassword: '',
            });
        } catch (error) {
            console.error(error);
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target

        this.setState({ [name]: value });
    }

    render() {
        const { displayName, email, password, configPassword} = this.state; //spreed warning
        return (
            <div className='conect'{...this.props}>
                <h2 className='login-caption'>Δημιουργία Λογαριασμού</h2>

                <form className='conect-form' onSubmit={this.handleSubmit}>

                    <span className='login-details'>Συμπληρώστε τα ακόλουθα πεδία</span>

                    <FormInput
                        type='name'
                        placeholder="Βάλτε το Ονοματεπώνυμο σας"
                        title="Βάλτε το Ονοματεπώνυμο σας"
                        autoComplete="on"
                        handlechange={this.handleChange}
                        value={displayName}
                        name="displayName"
                        svgselect='user'
                        // warning={warning}
                        required
                    />

                    <FormInput
                        type="email"
                        placeholder="Βάλτε το Email σας"
                        title="Βάλτε το Email σας"
                        autoComplete="on"
                        handlechange={this.handleChange}
                        value={email}
                        name="email"
                        svgselect='email'
                        // warning={warning}
                        required
                    />

                    <FormInput
                        type="password"
                        placeholder="Βάλτε τον Κωδικό σας"
                        title="Βάλτε τον Κωδικό σας"
                        autoComplete="on"
                        handlechange={this.handleChange}
                        value={password}
                        name="password"
                        svgselect='unlock'
                        // warning={warning}
                        required
                    />

                    <FormInput
                        type="password"
                        placeholder="Επαλήθευση Κωδικού"
                        title="Επαλήθευση Κωδικού"
                        autoComplete="on"
                        handlechange={this.handleChange}
                        value={configPassword}
                        name="configPassword"
                        svgselect='unlock'
                        // warning={warning}
                        required
                    />

                    <CustomButton isWrite type="submit" isBlack>Εγγραφή</CustomButton>
                </form>
            </div>
        );
    }
}

export default SingUpComponent;