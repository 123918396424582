//Color Data
import { colorPaletteData } from './colorPalette.data';

//Types
import { colorPaletteTypes } from './colorPalette.types';

const INIT_STATE = {
    color: {
        id: '',
        name: '',
        color: '',
        code: '',
        source: ''
    },
    color_data: [...colorPaletteData]
};

const colorPaletteReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case colorPaletteTypes.ADD_NEW_COLOR:
            return { ...state, color: { ...state.color, ...action.payload } };

        case colorPaletteTypes.REMOVE_COLOR:
            return { ...state, color: { ...state.color, name: '', id: '', color: '', code: '', source: '' } };

        default:
            return { ...state };
    }
};

export default colorPaletteReducer;