//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product71 = new product();
export const product72 = new product();
export const product73 = new product();
export const product74 = new product();
export const product75 = new product();
export const product76 = new product();
export const product77 = new product();
// export const product78 = new product();
export const product79 = new product();
export const product80 = new product();

//product71----------------------------------------------------------------------------------------------------------------------------
product71.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp';
product71.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product71.codeModelItem = '100.300.131';
product71.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 100X80';
product71.titleItem = 'Τραπέζι Καφενείου';
product71.category = 'ΤΡΑΠΕΖΙΑ';
product71.xondriki.minSalesProducts = 3;
product71.xondriki.priceItem = 110;
product71.id = '71';

//product72----------------------------------------------------------------------------------------------------------------------------
product72.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp';
product72.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product72.codeModelItem = '100.300.132';
product72.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 120X80';
product72.titleItem = 'Τραπέζι Καφενείου';
product72.category = 'ΤΡΑΠΕΖΙΑ';
product72.xondriki.minSalesProducts = 2;
product72.xondriki.priceItem = 120;
product72.id = '72';

//product73----------------------------------------------------------------------------------------------------------------------------
product73.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp';
product73.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product73.codeModelItem = '100.300.133';
product73.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 140X80';
product73.titleItem = 'Τραπέζι Καφενείου';
product73.category = 'ΤΡΑΠΕΖΙΑ';
product73.xondriki.minSalesProducts = 2;
product73.xondriki.priceItem = 150;
product73.id = '73';

//product74----------------------------------------------------------------------------------------------------------------------------
product74.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp';
product74.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product74.codeModelItem = '100.300.134';
product74.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 160X80';
product74.titleItem = 'Τραπέζι Καφενείου';
product74.category = 'ΤΡΑΠΕΖΙΑ';
product74.xondriki.minSalesProducts = 2;
product74.xondriki.priceItem = 170;
product74.id = '74';

//product75----------------------------------------------------------------------------------------------------------------------------
product75.images.center = stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp';
product75.images.others = [stadarURL.trapezia + 'kontra-plake/kontra-plake_1_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_2_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_3_normal.webp',
stadarURL.trapezia + 'kontra-plake/kontra-plake_4_normal.webp']
product75.codeModelItem = '100.300.135';
product75.nameModelItem = 'ΚΟΝΤΡΑ ΠΛΑΚΕ ΣΗΜΥΔΑ 18ΑΡΙ 180X80';
product75.titleItem = 'Τραπέζι Καφενείου';
product75.category = 'ΤΡΑΠΕΖΙΑ';
product75.xondriki.minSalesProducts = 2;
product75.xondriki.priceItem = 180;
product75.id = '75';

//product76----------------------------------------------------------------------------------------------------------------------------
product76.images.center = stadarURL.trapezia + 'trapezi-marmaro/marmaro_2_normal.webp';
product76.images.others = [stadarURL.trapezia + 'trapezi-marmaro/marmaro_5_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_2_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_3_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_4_normal.webp']
product76.codeModelItem = '100.300.136';
product76.nameModelItem = 'ΜΑΡΜΑΡΟ - ΠΟΔΙΑ ΟΞΥΑ 70Χ70';
product76.titleItem = 'Τραπέζι Καφενείου';
product76.category = 'ΤΡΑΠΕΖΙΑ';
product76.xondriki.minSalesProducts = 2;
product76.xondriki.priceItem = 80;
product76.id = '76';

//product77----------------------------------------------------------------------------------------------------------------------------
product77.images.center = stadarURL.trapezia + 'trapezi-marmaro/marmaro_3_normal.webp';
product77.images.others = [stadarURL.trapezia + 'trapezi-marmaro/marmaro_5_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_2_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_3_normal.webp',
stadarURL.trapezia + 'trapezi-marmaro/marmaro_4_normal.webp']
product77.codeModelItem = '100.300.137';
product77.nameModelItem = 'ΜΑΡΜΑΡΟ - ΠΟΔΙΑ ΟΞΥΑ 80Χ80';
product77.titleItem = 'Τραπέζι Καφενείου';
product77.category = 'ΤΡΑΠΕΖΙΑ';
product77.xondriki.minSalesProducts = 3;
product77.xondriki.priceItem = 85;
product77.id = '77';

//product78----------------------------------------------------------------------------------------------------------------------------
// product78.images.center = stadarURL.trapezia + 'trapezi-marmaro/marmaro_4_normal.webp';
// product78.images.others = [stadarURL.trapezia + 'trapezi-marmaro/marmaro_5_normal.webp',
// stadarURL.trapezia + 'trapezi-marmaro/marmaro_2_normal.webp',
// stadarURL.trapezia + 'trapezi-marmaro/marmaro_3_normal.webp',
// stadarURL.trapezia + 'trapezi-marmaro/marmaro_4_normal.webp']
// product78.codeModelItem = '100.300.138';
// product78.nameModelItem = 'ΜΑΡΜΑΡΟ - ΠΟΔΙΑ ΟΞΥΑ 80Χ120';
// product78.titleItem = 'Τραπέζι Καφενείου';
// product78.category = 'ΤΡΑΠΕΖΙΑ';
// product78.xondriki.minSalesProducts = 2;
// product78.xondriki.priceItem = 160;
// product78.id = '78';

//product79----------------------------------------------------------------------------------------------------------------------------
product79.images.center = stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp';
product79.images.others = [stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_2_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_3_normal.webp']
product79.codeModelItem = '100.300.139';
product79.nameModelItem = 'ΒΑΣΗ 60Χ60';
product79.titleItem = 'Ανταλακτικά Τραπεζιού';
product79.category = 'ΑΝΤΑΛΛΑΚΤΙΚΑ';
product79.xondriki.minSalesProducts = 5;
product79.xondriki.priceItem = 40;
product79.id = '79';

//product80----------------------------------------------------------------------------------------------------------------------------
product80.images.center = stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp';
product80.images.others = [stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_2_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_3_normal.webp']
product80.codeModelItem = '100.300.140';
product80.nameModelItem = 'ΒΑΣΗ 70Χ70';
product80.titleItem = 'Ανταλακτικά Τραπεζιού';
product80.category = 'ΑΝΤΑΛΛΑΚΤΙΚΑ';
product80.xondriki.minSalesProducts = 5;
product80.xondriki.priceItem = 40;
product80.id = '80';