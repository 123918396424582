//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Notify data
import { setNewNotification} from '../../../redux/notification/notification.data'

//Actions
import { SetNotificationAdd } from '../../../redux/notification/notification.acions';

//For send email to customer (Data it's for create stacture data for email)
import { sendEmail } from '../../../code/send.email';

//Style
import './contact.form.style.scss';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            name: '',
            phone: '',
            message: '',
            fromEmail: '',
            sendEmail: '',
            modeEmail: 'contact'// 'contact'or 'order'
        }
    }

    render() {

        return (
            <div className='contact-form'>
                <div className='contact-form-mask'>
                </div>
                <div className='contact-form-inputs'>
                    <h4 className='contact-form-inputs-title'>Στείλτε μας Μήνυμα</h4>
                    <form className='contact-form-inputs-form' onSubmit={this.handleSubmit.bind(this)}>

                        <div className='form-text'>
                            <input className='form-text-input'
                                id='form-name'
                                placeholder='Όνομα'
                                type='text'
                                onChange={this.onNameChange.bind(this)}
                                required
                            ></input>
                            <label className='form-text-label'
                                htmlFor='form-name'>Όνομα</label>
                        </div>

                        <div className='form-text'>
                            <input className='form-text-input'
                                id='form-tel'
                                placeholder='Τηλέφωνο'
                                type='tel'
                                onChange={this.onPhoneChange.bind(this)}
                                required
                            ></input>
                            <label className='form-text-label'
                                htmlFor='form-tel'>Τηλέφωνο</label>
                        </div>

                        <div className='form-text'>
                            <input className='form-text-input'
                                id='form-email'
                                placeholder='Email'
                                type='email'
                                onChange={this.onEmailChange.bind(this)}
                            ></input>
                            <label className='form-text-label'
                                htmlFor='form-email'>Email</label>
                        </div>

                        <div className='form-text'>
                            <input className='form-text-input'
                                id='form-subject'
                                placeholder='Θέμα'
                                type='text'
                                onChange={this.onSubjectChange.bind(this)}
                            // required
                            ></input>
                            <label className='form-text-label'
                                htmlFor='form-subject'>Θέμα</label>
                        </div>

                        <div className='form-text'>
                            <textarea className='form-text-input'
                                id='form-message'
                                placeholder='Το Μήνυμα σας'
                                type='text'
                                rows='6'
                                onChange={this.onMessageChange.bind(this)}
                                required
                            ></textarea>
                            <label className='form-text-label'
                                htmlFor='form-message'>Το Μήνυμα σας</label>
                        </div>

                        <div className='form-btn'>
                            <input className='form-btn-input' type='submit' value='Αποστολη'></input>
                        </div>
                    </form>
                </div>
            </div>
        )
    }


    onNameChange = (event) => {
        this.setState({ name: event.target.value })
    }

    onPhoneChange = (event) => {
        this.setState({ phone: event.target.value })
    }

    onEmailChange = (event) => {
        this.setState({ sendEmail: event.target.value })
    }

    onSubjectChange = (event) => {
        this.setState({ subject: event.target.value })
    }

    onMessageChange = (event) => {
        this.setState({ message: event.target.value })
    }

    resetForm = () => {
        document.getElementById('form-name').value = "";
        document.getElementById('form-tel').value = "";
        document.getElementById('form-email').value = "";
        document.getElementById('form-subject').value = "";
        document.getElementById('form-message').value = "";

        this.setState({
            subject: '',
            name: '',
            phone: '',
            message: '',
            fromEmail: '',
            sendEmail: '',
            modeEmail: 'contact'// 'contact'or 'order'
        });
    };


    //When click for send email
    handleSubmit = async (event) => {
        const { newNotification } = this.props;

        //send email
        const emailReseltData = await sendEmail(event, this.state);

        let newNotify;
        //Check the reselet from axios post
        if (emailReseltData.status === 'success') {
            //set the message for user
            newNotify = setNewNotification('Το μήνυμα σας στάλθηκε', 'success', true);

        } else if (emailReseltData.status === 'fail') {
            //set the message for user
            newNotify = setNewNotification('Το μήνυμα σας δεν στάλθηκε', 'error', true);
        }

        //Create notification
        newNotification(newNotify);

        //Clear all input and the memory if message has send successfull
        if (emailReseltData.status === 'success') this.resetForm();

    }
};

const mapDispatchToProps = dispatch => ({
    newNotification: notification => dispatch(SetNotificationAdd(notification))
});

export default connect(null, mapDispatchToProps)(ContactForm);
