//React
import React from 'react';

//Style
import {
    TitleContainer,

    TitlePanelContainer,

    BankPanelContainer,
    TitleBankContainer,
    PayDetailsContainer,
    DeliveryWrap,
    DeliveryTextContainer,
} from './details.style.jsx';

export const DetailsForPayment = () => {

    return (
        <React.Fragment>

            <TitleContainer>Τρόποι Πληρωμής</TitleContainer>

            <div>
                <TitlePanelContainer>Τραπεζική Μεταφορά</TitlePanelContainer>

                <BankPanelContainer>
                    <span>Όνομα : Ζωγράφος Χαρίλαος</span>
                </BankPanelContainer>

                <BankPanelContainer>
                    <TitleBankContainer>Eurobank</TitleBankContainer>
                    <span>IBAN : GR 600 260 057 000 053 020 095 5630</span>
                    <span>Αριθ. Λογ/σμού : 002 600 575 302009 556 30</span>
                </BankPanelContainer>

                <BankPanelContainer>
                    <TitleBankContainer>Εθνική</TitleBankContainer>
                    <span>IBAN : GR 050 110 709 000 007 090 038 0240</span>
                    <span>Αριθ. Λογ/σμού : 709 003 802 40</span>
                </BankPanelContainer>

                <PayDetailsContainer>
                    <p>Κάντε την πληρωμή σας σε καποιόν από τους τραπεζικούς μας λογαριασμούς.</p>
                    <p>Η κατασκευή της παραγγελία σας, θα ξεκινήσει μετά την εκκαθάριση του ποσού στο λογαριασμό μας.</p>
                    <p>Η παραλαβή των προϊόντων γίνεται αποκλειστικά κατόπιν συνεννόησης από τον</p>
                    <DeliveryWrap><DeliveryTextContainer>χωρο μας</DeliveryTextContainer></DeliveryWrap>
                    <p>Παρακαλούμε χρησιμοποιήστε το ID παραγγελίας σας , στην Αιτιολογία πληρωμής. </p>
                </PayDetailsContainer>

            </div>

        </React.Fragment>
    )
}