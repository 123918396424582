//React
import React from 'react';


//Style
import '../map.style.scss';

export const MapFactory = ({adress='Τατοϊου 91 (πρώην 349) Αχαρναί Τ.Κ 13671'}) => {
    

    return (
        <div className='map-panel'>
        <div className='map-panel-map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d660.1512315118893!2d23.76941665560313!3d38.088399095849354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1a23a4e099789%3A0x191b354fac8a260a!2zz4DOsc-BzrHOtM6_z4POuc6xzrrOrc-CIM66zrHPgc6tzrrOu861z4IgzpbOqc6TzqHOkc6mzp_Oow!5e0!3m2!1sen!2sgr!4v1597665825214!5m2!1sen!2sgr"
                    width="100%" height="100%" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title='Map Factory'></iframe>
        </div>

        <div className='map-panel-adress'>
            <h4 className='map-panel-adress-title'>Διεύθυνση :</h4>
            <a className='map-panel-adress-link'
                target='_blank' rel="noopener noreferrer"
                href='https://www.google.com/maps/place/%CF%80%CE%B1%CF%81%CE%B1%CE%B4%CE%BF%CF%83%CE%B9%CE%B1%CE%BA%CE%AD%CF%82+%CE%BA%CE%B1%CF%81%CE%AD%CE%BA%CE%BB%CE%B5%CF%82+%CE%96%CE%A9%CE%93%CE%A1%CE%91%CE%A6%CE%9F%CE%A3/@38.088341,23.769845,18z/data=!4m5!3m4!1s0x0:0x191b354fac8a260a!8m2!3d38.0883413!4d23.7698446?hl=en'>
                {adress}
            </a>
        </div>

    </div>
    )
};