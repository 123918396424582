//React
import React from 'react';

//Style
import {
    WrapDivPanel,
    PanelTitleContainer,
    ChoiseContainer,
    NoCostContainer,
    ChoiseInputContainer,
    FormContainer,
    ExtraChoiseContainer
} from '../select.extra.choise.style.jsx';


export const SelectLoystroValue = ({ loystroValues, calculateExtraPrice, selectExtraChoise, setChangesToState }) => {
    return (
        <WrapDivPanel>
            <PanelTitleContainer>Βαφή με <strong>Λούστρο</strong></PanelTitleContainer>
            <FormContainer style={{ display: "flex" }}>

                <NoCostContainer cost={loystroValues.price}
                    showNoCost={loystroValues.showNoCost}>{loystroValues.showNoCost ?
                        'Χωρίς \n Χρέωση'
                        :
                        `+${loystroValues.price}€`}
                </NoCostContainer>

                <ExtraChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            id="loystro-none"
                            type="radio"
                            value='none'
                            name='loystro'
                            onChange={() => {
                                calculateExtraPrice('minus', loystroValues.price);
                                setChangesToState({ name: 'loystro', values: { ...loystroValues, showNoCost: true, value: 'none' } });
                            }}
                            defaultChecked={loystroValues.value === 'none' ? true : false}
                        />
                        <label htmlFor="loystro-none">Χωρίς Λούστρο</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            id='loystro-glossy'
                            type="radio"
                            value='Λούστρο'
                            name='loystro'
                            onChange={() => {
                                calculateExtraPrice('plus', loystroValues.price);
                                setChangesToState({ name: 'loystro', values: { ...loystroValues, showNoCost: false, value: 'Λούστρο' } });
                            }}
                            defaultChecked={loystroValues.value === 'Λούστρο' ? true : false}
                        />
                        <label htmlFor="loystro-glossy">Με Λούστρο</label>
                    </ChoiseContainer>
                
                </ExtraChoiseContainer>

            </FormContainer>
        </WrapDivPanel>
    );
};