//React
import React from 'react';
//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectorPagesProperties } from '../../../redux/products-panel/products-panel.selectors';

//Actions
import { setNumberOfPages, setItemsPerPage, setPageNow } from '../../../redux/products-panel/products-panel.actions';

//component
import PageButton from './page-button/page.button.component.jsx';

//Extra code 
import { findPagesToShow, setPerPageItems } from './page.code';

//Styled
import styled from 'styled-components';

const PagesContainer = styled.div`
    font-size: 1.8rem;
    font-family: serif;
    color: dimgray;
    /* padding: 0rem 1rem; */
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin: 2rem 0 0;
`;

const PanelControlContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const PageControlContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
`;


//3 na pairnaei ta items se enan array basei ton numOfItemsPerPage kai to showPages 
//4 na deixnoyme to page now
//5 na emfanizontai h oxi ta control pages

class ProductsPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    //1 na exoyme parei to items per page
    //2 na ypologisoyme tis pages kai na tis emfanizei san buttons


    // componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(prevProps) {
        const { allItems, numItemPerPage } = this.props.pageProperties;



        if (prevProps.pageProperties.allItems !== allItems ||
            prevProps.pageProperties.numItemPerPage !== numItemPerPage) {
            this.addNewPagesNum();

            const itemsPerPage = setPerPageItems(numItemPerPage, allItems);
            this.props.setItemsPerPage(itemsPerPage);
        }
    };


    //Πρέπει να κάνει έλεγχο πόσες σελίδες πρέπει να φτιάξει
    addNewPagesNum = () => {
        const { allItems, numItemPerPage } = this.props.pageProperties;
        const newPages = findPagesToShow(allItems.length, numItemPerPage);

        this.props.setNumberOfPages(newPages);
    }

    selectPageNow = (num) => {
        // ('press page to ' + num);
        this.props.setPageNow(Number(num));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    goToPage = (mode) => {
        const { pageNow } = this.props.pageProperties;
        let newPageNumber = 0;

        if (mode === 'previous') {
            newPageNumber = pageNow - 1;
        } else if (mode === 'next') {
            newPageNumber = pageNow + 1;
        }

        this.props.setPageNow(newPageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }

    render() {
        const { pageNow, showPages } = this.props.pageProperties;

        // = ({ showPages, pageNow, itemsPerPage, selectPageNow, goToPage }) => {
        // };

        //Πρέπει να φτιάξει έναν Array για να βάζει τους αριθμούς των/της σελίδων
        const pageArray = [];
        for (let i = 1; i <= showPages; i++) {
            pageArray.push(i);
        };

        return (showPages > 1 &&

            (<PagesContainer>
                <PanelControlContainer>
                    {
                        pageNow > 1 ?
                            <PageButton btnMode='previous' goToPage={() => this.goToPage('previous')} /> : null
                    }
                </PanelControlContainer>

                <PageControlContainer>
                    {
                        pageArray.map((pageNum, idx) => pageNum === pageNow ?
                            <PageButton key={idx + pageNum} pageNum={pageNum} selectPageNow={this.selectPageNow} isSelected />
                            :
                            <PageButton key={idx + pageNum} pageNum={pageNum} selectPageNow={this.selectPageNow} />)
                    }
                </PageControlContainer>

                <PanelControlContainer>
                    {
                        pageNow < showPages && pageNow !== showPages ?
                            <PageButton btnMode='next' goToPage={() => this.goToPage('next')} />
                            : null
                    }
                </PanelControlContainer>
            </PagesContainer>)
        );
    }
};

const mapStateToProps = createStructuredSelector({
    pageProperties: selectorPagesProperties
});

const mapDispatchToProps = dispatch => ({
    setNumberOfPages: item => dispatch(setNumberOfPages(item)),
    setItemsPerPage: items => dispatch(setItemsPerPage(items)),
    setPageNow: num => dispatch(setPageNow(num)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPages);