//React
import React from 'react';

//Router
import { withRouter } from 'react-router-dom';

//Reselect
import { createStructuredSelector } from 'reselect';

//Redux
import { connect } from 'react-redux';

//Selectos
import { selectCategory } from '../../../redux/category/category.selector';

//Component
import Button from './button/button.component.jsx';

//Style
import './footerCategory.style.scss';

const FooterCategory = ({ categories, history }) => {
    return (
        <div className='footer-categories-panel'>
            {categories.map((category, index) => (
                <Button link={category.link}
                    history={history}
                    key={category.name + index}
                    name={category.name}
                    perigrafi={category.perigrafi}
                    item={category} />
            ))
            }
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    categories: selectCategory
});


export default withRouter(connect(mapStateToProps)(FooterCategory));