//React
import React from 'react';

//component.jsx
import { OrarioFactory } from './orario/factory/orario.factory.component.jsx';
import { OrarioShop } from './orario/shop/orario.shop.component.jsx';
//
import { PhonesFactory } from './phones/factory/phones.factory.content.jsx';
import { PhonesShop } from './phones/shop/phones.shop.content.jsx';
//
import { MapFactory } from './maps/factory/map.factory.component.jsx';
import { MapShop } from './maps/shop/map.shop.component.jsx';

//Images
import { ContactImage } from './images/contact-image.component.jsx';



//Style
import './contact.style.scss';

export const ContactItem = ({ contactItem = 'factory' }) => {

    return (
        <div className='contact'>

            <div className='contact-title'>
                <h2>
                    {contactItem === 'factory' ? 'Εργοστάσιο' : 'Έκθεση'}
                </h2>
            </div>

            <div className='contact-images'>
                <ContactImage contactItem={contactItem} />
            </div>

            <div className='contact-details'>

                <div className='contact-details-text'>

                    <div className='contact-details-text-orario'>
                        <h3 className='contact-details-text-orario-title'>Ωράριο Λειτουργίας</h3>
                        {contactItem === 'factory' ? <OrarioFactory /> : <OrarioShop />}
                    </div>

                    <div className='contact-details-text-phones'>
                        <h3 className='contact-details-text-phones-title'>Τηλέφωνα Επικοινωνίας</h3>
                        {contactItem === 'factory' ? <PhonesFactory /> : <PhonesShop />}
                    </div>

                    <div className='contact-details-text-mail'>
                        <h3 className='contact-details-text-mail-title'>Email</h3>
                        <a className='contact-details-text-mail-email' href='mailTo:info@zwgrafos.gr' target='_blank' rel="noopener noreferrer">info@zwgrafos.gr</a>
                    </div>

                </div>

                <div className='contact-details-map'>
                    <h3 className='contact-details-map-title'>Χάρτης</h3>
                    {contactItem === 'factory' ? <MapFactory /> : <MapShop />}
                </div>

            </div>

        </div>
    )
};