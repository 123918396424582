//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//reselect
import { createStructuredSelector } from 'reselect';

//selector
import { selectProsfores } from '../../redux/prosfores/prosfores.selectors';

//Style
import './prosfores.style.scss';
// import { Prosfores, ProsforesMSG } from './prosfores.style.js';

class ProsforesPage extends Component {

    render() {
        const { prosfores } = this.props;
        return !prosfores.length ?
            (
                <div className='prosfores'>
                    <h2 className='prosfores-msg'>Αυτή την στιγμή δεν υπάρχει καμία προσφορά διαθέσιμη.</h2>
                </div>
            ) : (
                <div>kosmas Prosfores Page</div>
            );
    }
};

const mapStateToProps = createStructuredSelector({
    prosfores: selectProsfores
});


export default connect(mapStateToProps)(ProsforesPage);