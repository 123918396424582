//React
import React from 'react';


//Component
import ContactForm from '../../components/contact/form/contact.form.component.jsx';

//Style
import './company.style.scss';
// import {
//     CompanyPanelStyle, CompanyDetails, DetailsTitleStyle, DetailsTitleTextStyle, TitleStyle, HistoryTitle, HistoryPanelStyle,
//     PhotoStyle, OwnerSpanLeft, OwnerSpanRight, OwnerLeft1, OwnerLeft2, OwnerRight
// } from './company.style';

const CompanyPage = () => {

    return (
        <React.Fragment>
            <div className='company-panel'>

                <div className='company-panel-details'>

                    <h1 className='company-panel-details-title' >Εργοστάσιο παραδοσιακών καθισμάτων και τραπεζιών</h1>

                    <h3 className='company-panel-title'>ΖΩΓΡΑΦΟΣ</h3>

                    <p className='company-panel-details-text'>Μέσα από την ιστορική μας διαδρομή, που μετράει περισσότερα από εβδομήντα χρόνια ζωής,
                        έχουμε αναδειχθεί σε έναν από τους πλέον αξιόπιστους και ποιοτικούς κατασκευαστές παραδοσιακών καθισμάτων και τραπεζιών
                        με έμφαση στην ποιότητα και τις καλύτερες τιμές της αγοράς.
                        Αξιοποιώντας τις τεχνολογικές καινοτομίες και ανταποκρινόμενη στις προκλήσεις της εποχής,
                        η εταιρία μας έχει διευρύνει την γκάμα των προϊόντων και των υπηρεσιών που προσφέρουμαι με μια σειρά από μοντέρνες και κλασσικές κατασκευές εξαιρετικής σχεδίασης,
                        μια πλούσια συλλογή ποιοτικών προϊόντων αποκλειστικής κατασκευής σε ιδιαίτερα ανταγωνιστικές τιμές.</p>
                </div>

                <div className='company-panel-history-title'>
                    <h4 className='company-panel-history-title-text'>75 Χρόνια ιστορίας στα παραδοσιακά καθίσματα και συνεχίζουμε</h4>
                </div>

                <div className='company-panel-history'>

                    <div className='company-panel-owners company-panel-owners-1 company-panel-owners-left'>

                        <span className='company-panel-owners-person person-left' >ΜΑΡΙΝΟΣ ΖΩΓΡΑΦΟΣ : 1945 – 1990</span>

                        <div className='company-panel-owners-photo' style={{ order: 1 }}>
                            <img className='company-panel-owners-photo-image' src='https://images.zwgrafos.gr/company/marinos_zografos_min.webp' alt='Marinos Zografos , Μαρίνος Ζωγράφος' title='Marinos Zografos' />
                        </div>

                    </div>


                    <div className='company-panel-owners company-panel-owners-2 company-panel-owners-right'>

                        <span className='company-panel-owners-person person-rigth' >ΓΙΩΡΓΟΣ ΖΩΓΡΑΦΟΣ : 1980 – 2015</span>

                        <div className='company-panel-owners-photo' style={{ order: 2 }}>
                            <img className='company-panel-owners-photo-image' src='https://images.zwgrafos.gr/company/giorgos_zografos_min.webp' alt='Marinos Zografos , Μαρίνος Ζωγράφος' title='Marinos Zografos' />
                        </div>

                    </div>

                    <div className='company-panel-owners company-panel-owners-3 company-panel-owners-left'>

                        <span className='company-panel-owners-person person-left' >ΧΑΡΗΣ ΖΩΓΡΑΦΟΣ : 1998 – ΣΗΜΕΡΑ</span>

                        <div className='company-panel-owners-photo' style={{ order: 1 }}>
                            <img className='company-panel-owners-photo-image' src='https://images.zwgrafos.gr/company/xaris_zografos_min.webp' alt='Marinos Zografos , Μαρίνος Ζωγράφος' title='Marinos Zografos' />
                        </div>

                    </div>

                </div>
            </div>

            <ContactForm />
        </React.Fragment>
    )
};

export default CompanyPage;