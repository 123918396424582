//Style
import styled, { css } from 'styled-components'

const compineButton = css`
width: 100%;
text-align: center;
margin: 0 auto;
border-radius: 2px;
color: white;
font-family: sans-serif;
font-weight: 600;
letter-spacing: 1px;
text-transform: uppercase;
cursor:pointer;
margin-bottom:1rem;
font-size: 2rem;
padding: 1rem 3rem;
transition:background-color .3s ease;
`;

export const CartContainer = styled.div`
    position:absolute;
    top:4.5rem;
    right:4rem;

    border: 2px solid green;
    min-width: 20rem;
    /* min-height: 20rem; */
    background: white;
    
    padding: 1rem;
    z-index:100;
`;

export const MainContainer = styled.div`
    max-height: 40rem;
    overflow: overlay;
    margin-bottom: 1rem;
    border-bottom: 1px solid #80808080;
`;

export const NoItemsContainer = styled.div`
    font-size: 1.3rem;
    color: #bbb;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15rem;
    width: 100%;
`;


export const BasketContainer = styled.div`
    ${compineButton}
    background-color: #e91e63;

    :hover{
        background-color:#ff6f00;
    }
`;

export const ShopNowContainer = styled.div`
${compineButton}
background-color:#009688;
margin-bottom:0;
:hover{
    background-color:#ff6f00;
}
`;