//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectExtraPrice, selectExtraChoise, selectSaleItems } from '../../redux/product/selectExtraChoise/selectExtraChoiseSelectors';
import { selectCurrentColor } from '../../redux/colorPalette/colorPalette.selector';



//Components
import TitlePanel from './title/title.component.jsx';
import MinSalesItems from './minSalesItems/min.sales.items.component.jsx';
import ButtonSales from './buttonSales/button.Sales.component.jsx';
import SelectExtraChoise from './selectExtraChoise/select.extra.choise.component.jsx';
import ItemPrice from './itemPrice/itemPrice.component.jsx';


//Actions
import { setNewSaleItems } from '../../redux/product/selectExtraChoise/selectExtraChoiseActions.js';

import { fetchingCategoryStartAsync } from '../../redux/category/category.actions.js'



//Style
import {
    CenterDetailsContainer,
    FPAContainer,
    DivDetailsPanel,
    CenterDetailsPanel
} from './product.details.style.jsx';

class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            salesProducts: props.saleItems !== null ? props.saleItems : props.product.xondriki.minSalesProducts || 12,
        }
    };


    //Allazei ta items poy einai na ginoyn sales apo to proion 
    //-->To stelnoyme sto button sales
    changeSalesProducts = (items) => {
        const { setNewSaleItems } = this.props;

        //Pairnaei sthn Redux ta temaxia poy einai gia paragkelia
        setNewSaleItems(items);

        this.setState({ salesProducts: items })
    }

    componentWillUnmount() {
        const { setNewSaleItems } = this.props;

        setNewSaleItems(this.state.salesProducts);
    }

    componentDidMount() {
        const { fetchingCategoryStartAsync } = this.props;

        fetchingCategoryStartAsync();

        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        };
    };

    render() {

        const { codeModelItem, nameModelItem, xondriki } = this.props.product;
        const { selectColor } = this.props;
        // <SelectStandarChoise category={this.state.product.category} />
        return (
            <DivDetailsPanel>
                <TitlePanel codeModelItem={codeModelItem} nameModelItem={nameModelItem} />

                <CenterDetailsContainer>

                    <CenterDetailsPanel>
                        <MinSalesItems minSalesProducts={xondriki.minSalesProducts} />
                        <ItemPrice priceItem={xondriki.priceItem} extraPrice={this.props.extraPrice} />
                        <FPAContainer>Στην τιμή δεν συμπεριλαμβάνεται ο Φ.Π.Α.</FPAContainer>
                    </CenterDetailsPanel>

                    <div>
                        <SelectExtraChoise category={this.props.product.category} />
                    </div>

                </CenterDetailsContainer>

                <ButtonSales
                    changeSalesProducts={this.changeSalesProducts}
                    selectState={{
                        extra: {
                            ...this.props.extra,
                            color: {
                                ...this.props.extra.color,
                                selectColor: selectColor.id !== '' ? selectColor : ''
                            }
                        },
                        extraPrice: this.props.extraPrice,
                        product: this.props.extra.product,
                        ...this.state,

                    }}
                    priceItem={xondriki.priceItem + this.props.extraPrice}
                    minNumForSale={xondriki.minSalesProducts}
                    saleItems={this.state.salesProducts}
                />
            </DivDetailsPanel>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    extraPrice: selectExtraPrice,
    extra: selectExtraChoise,
    selectColor: selectCurrentColor,
    selectSaleItems: selectSaleItems
});

const mapDispatchToProps = dispatch => ({
    fetchingCategoryStartAsync: () => dispatch(fetchingCategoryStartAsync()),
    setNewSaleItems: (items) => dispatch(setNewSaleItems(items))
})




export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);