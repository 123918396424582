//Reselect
import { createSelector } from 'reselect';

const selectProductsPanel = state => state.productsPanel;

export const selectorProductsPanelProperties = createSelector(
    [selectProductsPanel],
    productsPanel => productsPanel.properties
);

export const selectorProductsPanelShowItems = createSelector(
    [selectProductsPanel],
    productsPanel => productsPanel.showItems
);

//Properties Bar Selector
export const selectorClassificationChoise = createSelector(
    [selectorProductsPanelProperties],
    properties => properties.classification_choise
);
export const selectorNumItemChoise = createSelector(
    [selectorProductsPanelProperties],
    properties => properties.numItem_choise
);

// Pages
export const selectorPagesProperties = createSelector(
    [selectProductsPanel],
    productsPanel => ({
        allItems: productsPanel.showItems,
        numItemPerPage: productsPanel.numItemPerPage,
        showPages: productsPanel.showPages,
        pageNow: productsPanel.pageNow,

        titlePanel: productsPanel.titlePanel,
        itemsPerPage: productsPanel.itemsPerPage,
        classification: productsPanel.classification,
        filter: productsPanel.filter,
    })
);