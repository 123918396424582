//Style
import styled from 'styled-components';

export const NavCartContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    border: 1px solid #ccc;
    cursor: pointer;
    box-shadow: 1px 1px 3px;
    
    ${props => props.item < 1 ?
        {
            backgroundColor: '#ab0808',
            borderColor: '#f36f6f'
        }
        :
        {
            backgroundColor: '#1c8c2f',
            borderColor: '#186725'
        }
    }
    color: white;
    padding: 0 2rem;

    transition: background-color .3s;
    
    @media only screen and (min-width:320px) and (max-width:420px){
        padding:0 1rem;
    }
    @media only screen and (min-width:100px) and (max-width:320px){
        padding:0 2rem;
    }

    &:hover{
        ${props => props.item < 1 ?
        {
            backgroundColor: '#c20404'
        }
        :
        props.location.pathname !== '/cart' &&
        {
            backgroundColor: '#4dd43d'
        }
    }
    }

`;
//() => <CartLogo {...prop} />
export const CartLogoSVGContainer = styled.div`
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: white;
    z-index: 10;
    @media only screen and (max-width:320px){
        width:100%;
        margin-right:0;
    }
`;

export const ItemTextContainer = styled.span`
    color: currentColor;
    font-size: 1.5rem;
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    user-select: none;
    @media only screen and (max-width:320px){
        display:none;
    }
`;