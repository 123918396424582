import React from 'react';

//Style
import { WrapTitleContainer, TitleContainer, CodeContainer } from './title.style.jsx';

const TitlePanel = ({codeModelItem, nameModelItem}) => {

    return (
        <WrapTitleContainer>
            <TitleContainer>{nameModelItem}</TitleContainer>
            <CodeContainer>
                <span>code:</span> <span style={{ marginLeft: '1.5rem' }}>{codeModelItem}</span>
            </CodeContainer>
        </WrapTitleContainer>
    )
};

export default TitlePanel;