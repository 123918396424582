//Style
import styled from 'styled-components';

export const PageContainer = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    user-select:none;
`;

export const MessageContainer = styled.p`
    font-size: 3rem;
    letter-spacing: 1rem;
    line-height: 2;
    font-weight: bold;
    color: #00BCD4;
    border-top:1px solid currentColor;
    border-bottom:1px solid currentColor;
    margin-bottom:5rem;

    @media(max-width:900px){
        font-size:2rem;
    }

    @media(max-width:650px){
        font-size:1.5rem;
        text-align:center;
    }

    @media(max-width:400px){
        line-height:normal;
    }
`;

export const WrapPeymentContainer = styled.div`
    display:grid;
    ${props => ({ gridTemplateColumns: `repeat(${props.count}, 50%)` })}
    /* grid-template-columns:repeat(2,60rem); */
    justify-content:center;
    align-items:baseline;
    margin:0rem 2rem 3rem;

    @media(max-width:750px){
        grid-template-columns: 90%;
        grid-gap: 1rem;
    }

    @media(max-width:400px){
        grid-template-columns: 100%;
        grid-gap: 1rem;
        margin: 0 1rem 1rem;
    }
`;

export const ReturnButtonContaine = styled.button`
    font-size: 2rem;
    letter-spacing: 2px;
    padding: 1rem 6rem;
    border: none;
    background-color: #E91E63;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom:3rem;

    transition:background-color .2s ease;

    &:hover{
        background-color:#ff3a7a;
    }

    @media(max-width:900px){
        font-size:1.7rem;
    }

    @media(max-width:450px){
        font-size:1.6rem;
        padding:1rem;
        width:95%;
    }
`;