//Reselect
import { createSelector } from 'reselect';

const selectStateReviews = state =>state.reviews;

export const selectReviews = createSelector(
    [selectStateReviews],
    reviews => reviews.reviews_data
);


export const selectNumFromReviews = createSelector(
    [selectReviews],
    reviews_data => reviews_data.length
);

export const selectReviewSelect = createSelector(
    [selectStateReviews],
    reviews => reviews.selectReview
);