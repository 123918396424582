//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product21 = new product();
export const product22 = new product();
// export const product23 = new product();
export const product24 = new product();
export const product25 = new product();
export const product26 = new product();
export const product27 = new product();
export const product28 = new product();
export const product29 = new product();
export const product30 = new product();

//product21----------------------------------------------------------------------------------------------------------------------------
product21.images.center = stadarURL.karekles + 'zakynthos/karekla-kafeneioy-zakynthos-2_normal.webp';
product21.images.others = [stadarURL.karekles + 'zakynthos/karekla-kafeneioy-zakynthos-1_normal.webp',
stadarURL.karekles + 'zakynthos/karekla-kafeneioy-zakynthos-2_normal.webp',
stadarURL.karekles + 'zakynthos/karekla-kafeneioy-zakynthos-3_normal.webp',
stadarURL.karekles + 'zakynthos/karekla-kafeneioy-zakynthos-4_normal.webp']
product21.codeModelItem = '100.100.121';
product21.nameModelItem = 'ΖΑΚΥΝΘΟΣ';
product21.titleItem = 'Καρέκλα Καφενείου';
product21.category = 'ΚΑΡΕΚΛΕΣ';
product21.xondriki.minSalesProducts = 16;
product21.xondriki.priceItem = 35;
product21.id = '21';

//product22----------------------------------------------------------------------------------------------------------------------------
product22.images.center = stadarURL.karekles + 'mirela/mirela_1_normal.webp';
product22.images.others = [stadarURL.karekles + 'mirela/mirela_1_normal.webp',
stadarURL.karekles + 'mirela/mirela_2_normal.webp',
stadarURL.karekles + 'mirela/mirela_3_normal.webp']
product22.codeModelItem = '100.100.122';
product22.nameModelItem = 'ΚΑΡΕΚΛΑ ΜΙΡΕΛΑ';
product22.titleItem = 'Καρέκλα Καφενείου';
product22.category = 'ΚΑΡΕΚΛΕΣ';
product22.xondriki.minSalesProducts = 12;
product22.xondriki.priceItem = 40;
product22.id = '22';

//product23----------------------------------------------------------------------------------------------------------------------------
// product23.images.center = stadarURL.karekles + 'mirela/mirela_1_normal.webp';
// product23.images.others = [stadarURL.karekles + 'mirela/mirela_1_normal.webp',
// stadarURL.karekles + 'mirela/mirela_2_normal.webp',
// stadarURL.karekles + 'mirela/mirela_3_normal.webp']
// product23.codeModelItem = '100.100.123';
// product23.nameModelItem = 'ΚΑΡΕΚΛΑ ΜΙΡΕΛΑ';
// product23.titleItem = 'Καρέκλα Καφενείου';
// product23.category = 'ΚΑΡΕΚΛΕΣ';
// product23.xondriki.minSalesProducts = 12;
// product23.xondriki.priceItem = 40;
// product23.id = '23';

//product24----------------------------------------------------------------------------------------------------------------------------
product24.images.center = stadarURL.karekles + 'mixaela/mixaela_4_normal.webp';
product24.images.others = [stadarURL.karekles + 'mixaela/mixaela_1_normal.webp',
stadarURL.karekles + 'mixaela/mixaela_2_normal.webp',
stadarURL.karekles + 'mixaela/mixaela_3_normal.webp',
stadarURL.karekles + 'mixaela/mixaela_4_normal.webp']
product24.codeModelItem = '100.100.124';
product24.nameModelItem = 'ΚΑΡΕΚΛΑ ΜΙΧΑΕΛΑ';
product24.titleItem = 'Καρέκλα Καφενείου';
product24.category = 'ΚΑΡΕΚΛΕΣ';
product24.xondriki.minSalesProducts = 4;
product24.xondriki.priceItem = 60;
product24.id = '24';

//product25----------------------------------------------------------------------------------------------------------------------------
product25.images.center = stadarURL.karekles + 'afroditi-1/afroditi-1_1_normal.webp';
product25.images.others = [stadarURL.karekles + 'afroditi-1/afroditi-1_1_normal.webp',
stadarURL.karekles + 'afroditi-1/afroditi-1_2_normal.webp',
stadarURL.karekles + 'afroditi-1/afroditi-1_3_normal.webp',
stadarURL.karekles + 'afroditi-1/afroditi-1_4_normal.webp']
product25.codeModelItem = '100.100.125';
product25.nameModelItem = 'ΚΑΡΕΚΛΑ ΑΦΡΟΔΙΤΗ 1';
product25.titleItem = 'Καρέκλα Καφενείου';
product25.category = 'ΚΑΡΕΚΛΕΣ';
product25.xondriki.minSalesProducts = 4;
product25.xondriki.priceItem = 60;
product25.id = '25';

//product26----------------------------------------------------------------------------------------------------------------------------
product26.images.center = stadarURL.karekles + 'afroditi-2/afroditi-2_1_normal.webp';
product26.images.others = [stadarURL.karekles + 'afroditi-2/afroditi-2_1_normal.webp',
stadarURL.karekles + 'afroditi-2/afroditi-2_2_normal.webp',
stadarURL.karekles + 'afroditi-2/afroditi-2_3_normal.webp',
stadarURL.karekles + 'afroditi-2/afroditi-2_4_normal.webp']
product26.codeModelItem = '100.100.126';
product26.nameModelItem = 'ΚΑΡΕΚΛΑ ΑΦΡΟΔΙΤΗ 2';
product26.titleItem = 'Καρέκλα Καφενείου';
product26.category = 'ΚΑΡΕΚΛΕΣ';
product26.xondriki.minSalesProducts = 4;
product26.xondriki.priceItem = 60;
product26.id = '26';

//product27----------------------------------------------------------------------------------------------------------------------------
product27.images.center = stadarURL.karekles + 'kathrin/kathrin_1_normal.webp';
product27.images.others = [stadarURL.karekles + 'kathrin/kathrin_1_normal.webp',
stadarURL.karekles + 'kathrin/kathrin_2_normal.webp',
stadarURL.karekles + 'kathrin/kathrin_3_normal.webp',
stadarURL.karekles + 'kathrin/kathrin_4_normal.webp']
product27.codeModelItem = '100.100.127';
product27.nameModelItem = 'ΚΑΡΕΚΛΑ ΚΑΘΡΙΝ';
product27.titleItem = 'Καρέκλα Καφενείου';
product27.category = 'ΚΑΡΕΚΛΕΣ';
product27.xondriki.minSalesProducts = 4;
product27.xondriki.priceItem = 60;
product27.id = '27';

//product28----------------------------------------------------------------------------------------------------------------------------
product28.images.center = stadarURL.karekles + 'z1/karekla-kafeneioy-z1-2_normal.webp';
product28.images.others = [stadarURL.karekles + 'z1/karekla-kafeneioy-z1-1_normal.webp',
stadarURL.karekles + 'z1/karekla-kafeneioy-z1-2_normal.webp',
stadarURL.karekles + 'z1/karekla-kafeneioy-z1-3_normal.webp',
stadarURL.karekles + 'z1/karekla-kafeneioy-z1-4_normal.webp']
product28.codeModelItem = '100.100.128';
product28.nameModelItem = 'Ζ1';
product28.titleItem = 'Καρέκλα Καφενείου';
product28.category = 'ΚΑΡΕΚΛΕΣ';
product28.xondriki.minSalesProducts = 4;
product28.xondriki.priceItem = 45;
product28.id = '28';

//product29----------------------------------------------------------------------------------------------------------------------------
product29.images.center = stadarURL.karekles + 'z2/karekla-kafeneioy-z2-1_normal.webp';
product29.images.others = [stadarURL.karekles + 'z2/karekla-kafeneioy-z2-1_normal.webp',
stadarURL.karekles + 'z2/karekla-kafeneioy-z2-2_normal.webp',
stadarURL.karekles + 'z2/karekla-kafeneioy-z2-3_normal.webp',
stadarURL.karekles + 'z2/karekla-kafeneioy-z2-4_normal.webp']
product29.codeModelItem = '100.100.129';
product29.nameModelItem = 'Ζ2';
product29.titleItem = 'Καρέκλα Καφενείου';
product29.category = 'ΚΑΡΕΚΛΕΣ';
product29.xondriki.minSalesProducts = 4;
product29.xondriki.priceItem = 45;
product29.id = '29';

//product30----------------------------------------------------------------------------------------------------------------------------
product30.images.center = stadarURL.karekles + 'biennezikh/biennezikh_1_normal.webp';
product30.images.others = [stadarURL.karekles + 'biennezikh/biennezikh_1_normal.webp']
product30.codeModelItem = '100.100.130';
product30.nameModelItem = 'ΒΙΕΝΝΕΖΙΚΗ';
product30.titleItem = 'Καρέκλα Καφενείου';
product30.category = 'ΚΑΡΕΚΛΕΣ';
product30.xondriki.minSalesProducts = 16;
product30.xondriki.priceItem = 55;
product30.id = '30';