//Πρέπει να πίανει το Panel για το Contact και να το μετακινεί με animation προς το mouse μονο στον άξονα X
//και να κάνει rotate.


let moveForce = 20; // max popup movement in pixels
let rotateForce = 20; // max popup rotation in deg
let rotateForceY = 5; // max popup rotation in deg

if (typeof window !== 'undefined') {
    window.addEventListener('load', () => {
        const contactForm = document.getElementById('contactForm');

        const contactPanel = document.getElementById('contactPanel');

        if (contactForm && contactPanel) {

            let docX = contactForm.offsetWidth;
            let docY = contactForm.offsetHeight;

            let eleX = contactPanel.offsetWidth;
            let eleY = contactPanel.offsetHeight;

            let centerX = (docX / 2) - (eleX / 2);
            let centerY = (docY / 2) - (eleY / 2);

            contactPanel.style.left = centerX + 'px';
            contactPanel.style.top = centerY + 'px';

        }
    });
}

export function findContactPanel(MouseEvent) {

    const contactForm = document.getElementById('contactForm');

    const contactPanel = document.getElementById('contactPanel');

    if (contactForm && contactPanel) {
        let docX = contactForm.offsetWidth;
        let docY = contactForm.offsetHeight;

        let moveX = (MouseEvent.clientX - docX / 2) / (docX / 2) * -moveForce;
        let moveY = (MouseEvent.clientY - docY / 2) / (docY / 2) * -moveForce;

        let rotateY = (MouseEvent.pageX / docX * rotateForce * 2) - rotateForce;
        let rotateX = -((MouseEvent.pageY / docY * rotateForceY * 2) - rotateForce);

        let centerX = (docX / 2) - (contactPanel.offsetWidth / 2);
        let centerY = (docY / 2) - (contactPanel.offsetHeight / 2);


        contactPanel.style.left = (centerX + moveX) + 'px';
        contactPanel.style.top = (centerY + moveY) + 'px';//
        contactPanel.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;//
    }
};

export function setContactFormCenter() {

    const contactForm = document.getElementById('contactForm');

    const contactPanel = document.getElementById('contactPanel');

    if (contactForm && contactPanel) {
        let centerX = (contactForm.offsetWidth / 2) - (contactPanel.offsetWidth / 2);
        let centerY = (contactForm.offsetHeight / 2) - (contactPanel.offsetHeight / 2);

        contactPanel.style.left = centerX + 'px';//(centerX + moveX) + 'px';
        contactPanel.style.top = centerY + 'px';//(centerY + moveY) + 'px';
        contactPanel.style.transform = `rotateX(${0}deg) rotateY(${0}deg)`;//translate(-50%,-50%) 
    }


}