//Style
import styled from 'styled-components';

export const ProductsPanelContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(3,max-content);
    grid-template-columns:300px 1fr;
    grid-auto-rows: max-content;
    grid-column-gap:2rem;
    padding:0 2rem 2rem;

    @media(max-width:400px){
        grid-column-gap:0px;
        grid-template-columns:100px 1fr;
        padding:0 .5rem 2rem;
    }
`;

export const CenterContainer = styled.div`
 grid-row:3/4;
 grid-column:2/3;

 @media only screen and (max-width:1100px){
    grid-column:1/3;
 }
`;