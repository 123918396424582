//React
import React from 'react';

//Style
import '../orario.style.scss';

export const OrarioShop = () => {

    return (
        <div className='orario'>
            <h4 className='orario-title'>Δευτέρα-Τετάρτη-Σάββατο</h4>
            <span className='orario-times'>10:00-14:00</span>

            <h4 className='orario-title'>Τρίτη-Πέμπτη-Παρασκευή</h4>
            <span className='orario-times'>10:00-14:00</span>
            <span className='orario-times-and'>&</span>
            <span className='orario-times-afternoom'>17:00-20:00</span>
        </div>
    );
};