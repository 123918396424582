//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from '../classProduct';

export const product11 = new product();
export const product12 = new product();
export const product13 = new product();
export const product14 = new product();
export const product15 = new product();
// export const product16 = new product();
export const product17 = new product();
// export const product18 = new product();
export const product19 = new product();
export const product20 = new product();

//product11----------------------------------------------------------------------------------------------------------------------------
product11.images.center = stadarURL.karekles + 'corina-koyrmpa/karekla-kafeneioy-corina-koyrmpa-2_normal.webp';
product11.images.others = [stadarURL.karekles + 'corina-koyrmpa/karekla-kafeneioy-corina-koyrmpa-1_normal.webp',
stadarURL.karekles + 'corina-koyrmpa/karekla-kafeneioy-corina-koyrmpa-2_normal.webp',
stadarURL.karekles + 'corina-koyrmpa/karekla-kafeneioy-corina-koyrmpa-3_normal.webp',
stadarURL.karekles + 'corina-koyrmpa/karekla-kafeneioy-corina-koyrmpa-4_normal.webp']
product11.codeModelItem = '100.100.111';
product11.nameModelItem = 'ΚΟΡΙΝΑ ΚΟΥΡΜΠΑ';
product11.titleItem = 'Καρέκλα Καφενείου';
product11.category = 'ΚΑΡΕΚΛΕΣ';
product11.xondriki.minSalesProducts = 16;
product11.xondriki.priceItem = 24;
product11.id = '11';

//product12----------------------------------------------------------------------------------------------------------------------------
product12.images.center = stadarURL.karekles + 'skyrianh/karekla-kafeneioy-skyrianh-koyrmpa-2_normal.webp';
product12.images.others = [stadarURL.karekles + 'skyrianh/karekla-kafeneioy-skyrianh-koyrmpa-1_normal.webp',
stadarURL.karekles + 'skyrianh/karekla-kafeneioy-skyrianh-koyrmpa-2_normal.webp',
stadarURL.karekles + 'skyrianh/karekla-kafeneioy-skyrianh-koyrmpa-3_normal.webp',
stadarURL.karekles + 'skyrianh/karekla-kafeneioy-skyrianh-koyrmpa-4_normal.webp']
product12.codeModelItem = '100.100.112';
product12.nameModelItem = 'ΣΚΗΡΙΑΝΗ ΚΟΥΡΜΠΑ';
product12.titleItem = 'Καρέκλα Καφενείου';
product12.category = 'ΚΑΡΕΚΛΕΣ';
product12.xondriki.minSalesProducts = 16;
product12.xondriki.priceItem = 24;
product12.id = '12';

//product13----------------------------------------------------------------------------------------------------------------------------
product13.images.center = stadarURL.karekles + 'krhth/karekla-kafeneioy-krhth-1_normal.webp';
product13.images.others = [stadarURL.karekles + 'krhth/karekla-kafeneioy-krhth-1_normal.webp',
stadarURL.karekles + 'krhth/karekla-kafeneioy-krhth-2_normal.webp',
stadarURL.karekles + 'krhth/karekla-kafeneioy-krhth-3_normal.webp',
stadarURL.karekles + 'krhth/karekla-kafeneioy-krhth-4_normal.webp']
product13.codeModelItem = '100.100.113';
product13.nameModelItem = 'ΚΡΗΤΗ ΚΟΥΡΜΠΑ';
product13.titleItem = 'Καρέκλα Καφενείου';
product13.category = 'ΚΑΡΕΚΛΕΣ';
product13.xondriki.minSalesProducts = 16;
product13.xondriki.priceItem = 24;
product13.id = '13';

//product14----------------------------------------------------------------------------------------------------------------------------
product14.images.center = stadarURL.karekles + 'ermhs/karekla-kafeneioy-ermhs-2_normal.webp';
product14.images.others = [stadarURL.karekles + 'ermhs/karekla-kafeneioy-ermhs-1_normal.webp',
stadarURL.karekles + 'ermhs/karekla-kafeneioy-ermhs-2_normal.webp',
stadarURL.karekles + 'ermhs/karekla-kafeneioy-ermhs-3_normal.webp',
stadarURL.karekles + 'ermhs/karekla-kafeneioy-ermhs-4_normal.webp']
product14.codeModelItem = '100.100.114';
product14.nameModelItem = 'ΕΡΜΗΣ ΚΟΥΡΜΠΑ';
product14.titleItem = 'Καρέκλα Καφενείου';
product14.category = 'ΚΑΡΕΚΛΕΣ';
product14.xondriki.minSalesProducts = 16;
product14.xondriki.priceItem = 27;
product14.id = '14';

//product15----------------------------------------------------------------------------------------------------------------------------
product15.images.center = stadarURL.karekles + 'paros/karekla-kafeneioy-paros-3_normal.webp';
product15.images.others = [stadarURL.karekles + 'paros/karekla-kafeneioy-paros-1_normal.webp',
stadarURL.karekles + 'paros/karekla-kafeneioy-paros-2_normal.webp',
stadarURL.karekles + 'paros/karekla-kafeneioy-paros-3_normal.webp',
stadarURL.karekles + 'paros/karekla-kafeneioy-paros-4_normal.webp']
product15.codeModelItem = '100.100.115';
product15.nameModelItem = 'ΠΑΡΟΣ ΚΟΥΡΜΠΑ ΚΑΘΙΣΜΑ ΚΠΘ';
product15.titleItem = 'Καρέκλα Καφενείου';
product15.category = 'ΚΑΡΕΚΛΕΣ';
product15.xondriki.minSalesProducts = 16;
product15.xondriki.priceItem = 35;
product15.id = '15';

//product16----------------------------------------------------------------------------------------------------------------------------
// product16.images.center = stadarURL.karekles + 'naksos/karekla-kafeneioy-naksos-masif-1_normal.webp';
// product16.images.others = [stadarURL.karekles + 'naksos/karekla-kafeneioy-naksos-masif-1_normal.webp',
// stadarURL.karekles + 'naksos/karekla-kafeneioy-naksos-masif-2_normal.webp',
// stadarURL.karekles + 'naksos/karekla-kafeneioy-naksos-masif-3_normal.webp',
// stadarURL.karekles + 'naksos/karekla-kafeneioy-naksos-masif-4_normal.webp']
// product16.codeModelItem = '100.100.116';
// product16.nameModelItem = 'ΝΑΞΟΣ';
// product16.titleItem = 'Καρέκλα Καφενείου';
// product16.category = 'ΚΑΡΕΚΛΕΣ';
// product16.xondriki.minSalesProducts = 16;
// product16.xondriki.priceItem = 20;
// product16.id = '16';

//product17----------------------------------------------------------------------------------------------------------------------------
product17.images.center = stadarURL.karekles + 'naksos-koyrmpa/karekla-kafeneioy-naksos-koyrmpa-1_normal.webp';
product17.images.others = [stadarURL.karekles + 'naksos-koyrmpa/karekla-kafeneioy-naksos-koyrmpa-1_normal.webp',
stadarURL.karekles + 'naksos-koyrmpa/karekla-kafeneioy-naksos-koyrmpa-2_normal.webp',
stadarURL.karekles + 'naksos-koyrmpa/karekla-kafeneioy-naksos-koyrmpa-3_normal.webp',
stadarURL.karekles + 'naksos-koyrmpa/karekla-kafeneioy-naksos-koyrmpa-4_normal.webp']
product17.codeModelItem = '100.100.117';
product17.nameModelItem = 'ΝΑΞΟΣ ΚΟΥΡΜΠΑ';
product17.titleItem = 'Καρέκλα Καφενείου';
product17.category = 'ΚΑΡΕΚΛΕΣ';
product17.xondriki.minSalesProducts = 16;
product17.xondriki.priceItem = 27;
product17.id = '17';

//product18----------------------------------------------------------------------------------------------------------------------------
// product18.images.center = stadarURL.karekles + 'kagkeloti/karekla-kafeneioy-kagkeloti-1_normal.webp';
// product18.images.others = [stadarURL.karekles + 'kagkeloti/karekla-kafeneioy-kagkeloti-1_normal.webp',
// stadarURL.karekles + 'kagkeloti/karekla-kafeneioy-kagkeloti-2_normal.webp',
// stadarURL.karekles + 'kagkeloti/karekla-kafeneioy-kagkeloti-3_normal.webp',
// stadarURL.karekles + 'kagkeloti/karekla-kafeneioy-kagkeloti-4_normal.webp']
// product18.codeModelItem = '100.100.118';
// product18.nameModelItem = 'ΚΑΓΚΕΛΩΤΗ';
// product18.titleItem = 'Καρέκλα Καφενείου';
// product18.category = 'ΚΑΡΕΚΛΕΣ';
// product18.xondriki.minSalesProducts = 16;
// product18.xondriki.priceItem = 24;
// product18.id = '18';

//product19----------------------------------------------------------------------------------------------------------------------------
product19.images.center = stadarURL.karekles + 'kythira-1/karekla-kafeneioy-kythira-3_normal.webp';
product19.images.others = [stadarURL.karekles + 'kythira-1/karekla-kafeneioy-kythira-1_normal.webp',
stadarURL.karekles + 'kythira-1/karekla-kafeneioy-kythira-2_normal.webp',
stadarURL.karekles + 'kythira-1/karekla-kafeneioy-kythira-3_normal.webp']
product19.codeModelItem = '100.100.119';
product19.nameModelItem = 'ΚΥΘΗΡΑ 1';
product19.titleItem = 'Καρέκλα Καφενείου';
product19.category = 'ΚΑΡΕΚΛΕΣ';
product19.xondriki.minSalesProducts = 16;
product19.xondriki.priceItem = 26;
product19.id = '19';

//product20----------------------------------------------------------------------------------------------------------------------------
product20.images.center = stadarURL.karekles + 'kythira-2/karekla-kafeneioy-kythira-2-1_normal.webp';
product20.images.others = [stadarURL.karekles + 'kythira-2/karekla-kafeneioy-kythira-2-1_normal.webp',
stadarURL.karekles + 'kythira-2/karekla-kafeneioy-kythira-2-2_normal.webp',
stadarURL.karekles + 'kythira-2/karekla-kafeneioy-kythira-2-3_normal.webp',
stadarURL.karekles + 'kythira-2/karekla-kafeneioy-kythira-2-4_normal.webp']
product20.codeModelItem = '100.100.120';
product20.nameModelItem = 'ΚΥΘΗΡΑ 2';
product20.titleItem = 'Καρέκλα Καφενείου';
product20.category = 'ΚΑΡΕΚΛΕΣ';
product20.xondriki.minSalesProducts = 16;
product20.xondriki.priceItem = 26;
product20.id = '20';