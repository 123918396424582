//Style
import styled from 'styled-components';

export const ColorItemContainer = styled.div`
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    ${(props) => props.isSelect ?
        { border: '4px solid #008000' }
        :
        { border: '4px solid #555' }
    }
    border-radius: 10px;
    cursor: pointer;
    position:relative;

    transition:border .2s ease;

    &:hover{
        ${(props) => props.isSelect ?
        { border: '4px solid #00c900' }
        :
        { border: '4px solid #999' }
    }
    }

    @media(max-width:1300px){
        width: 15rem;
        height: 15rem;
    }

    @media(max-width:1000px){
        width: 10rem;
        height: 10rem;
    }

    @media(max-width:600px){
        width: 8rem;
        height: 8rem;
    }

    @media(max-width:450px){
        width: 5rem;
        height: 5rem;
    }

    @media(max-width:450px){
        width: 4rem;
        height: 4rem;
        border-width:2px;
    }
    
`;

export const PanelImageContainer = styled.img`
    width:100%;
`;

export const PanelSelectContainer = styled.div`
    width: 4rem;
    height: 4rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    @media(max-width:450px){
        width: 3rem;
        height: 3rem;
        bottom: .5rem;
        right: .5rem;
    }
    @media(max-width:400px){
        width: 2rem;
        height: 2rem;
        bottom: .5rem;
        right: .5rem;
    }
`;