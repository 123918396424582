//Types
import { notificationTypes } from './notifications.types';
//Data
import { notificationsData } from './notification.data';

const INIT = notificationsData;

const notificationReducer = (state = INIT, action) => {
    switch (action.type) {
        case notificationTypes.NOTIFICATION_VIEW:
            return state;

        case notificationTypes.NOTIFICATION_ADD:
            return { ...state, notifications: [...state.notifications, action.payload] };

        case notificationTypes.NOTIFICATION_REMOVE:
            return {
                ...state, notifications: state.notifications.map(
                    (notification) => (notification.id !== action.payload.id ? notification : null)
                ).filter(
                    (item) => item !== null
                )
            }

        default:
            return state;
    }
}

export default notificationReducer;