//Style
import styled from 'styled-components';

export const PaycenterWrap = styled.div`
    display:grid;
    grid-template-columns: 40% 30%;
    justify-content:center;

    grid-column-gap:1.5rem;
    margin-bottom:5rem;

    @media(max-width:1600px){
        grid-template-columns: 40% 40%;
    }

    @media(max-width:1000px){
        grid-template-columns: 45% 45%;
    }

    @media(max-width:900px){
        grid-template-columns: 90%;
        grid-template-rows: max-content;
    }
`;

export const DetailsPanelContainer = styled.div`
    grid-column:1/2;

    @media(max-width:900px){
        grid-column:1/2;
    }
`;

export const PayPanelContainer = styled.div`
    grid-column:2/3;
    height:100%;

    @media(max-width:900px){
        grid-column:1/2;
    }
`;