//React
import React, { Component } from 'react';

//Components
import ElementPhoto from './image_button/image.button.component.jsx';


//Style
import {
    BackgroundContainer,
    WrapImageContainer,
    ProductImageContainer,
    ImageProductCenter,
    SmallImageContainer,
} from './image.panel.style.jsx';

class ImagePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            centerImage: props.images.center,
            images: props.images.others,
            details: props.details
        }
    };
    // componentDidMount() {
    // }

    setCenterImageFromButton = (image) => {
        image && this.setState({ centerImage: image });
    };

    render() {

        return (
            <ProductImageContainer>

                <BackgroundContainer />

                <WrapImageContainer>
                    <ImageProductCenter src={this.state.centerImage} alt={this.state.details} />
                </WrapImageContainer>

                <SmallImageContainer>
                    {
                        this.state.images.map((ele, idx) => {
                            return (<ElementPhoto key={idx}
                                num={idx}
                                // select={false}
                                id={idx}
                                image_src={ele}
                                details={this.state.details}
                                OnClick={this.setCenterImageFromButton} />)
                        })
                    }
                </SmallImageContainer>

            </ProductImageContainer>
        )
    }
}

export default ImagePanel;