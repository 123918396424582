//Reselect
import { createSelector } from 'reselect';


const selectStateAddressBar = state => state.addressBar;

export const selectorAddressData = createSelector(
    [selectStateAddressBar],
    addressBar => addressBar
);

export const selectorAddressTitle = createSelector(
    [selectStateAddressBar],
    addressBar => addressBar.route.barTiTle
);

export const selectorAddressUrl = createSelector(
    [selectStateAddressBar],
    addressBar => addressBar.route.url
)