//React
import React from 'react';
//Redux
import { connect } from 'react-redux';
//Action
import { setFilterCategory } from '../../../../redux/products-panel/products-panel.actions';

//Router
// import { Link } from 'react-router-dom'
import { ROUTER_ADRESS } from '../../../../pages/site.map.router';

//Style
import './button.style.scss';

const Button = ({ link, history, name, perigrafi, item, setFilter }) => {

    return (
        <div onClick={() => {
            setFilter({ state: true, category: [item] });
            history.push(ROUTER_ADRESS.products.url);
            window.scrollTo(0, 0);
        }} className='footer-button' title={perigrafi}>
            <h2 className='footer-button-text' >{name}</h2>
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    setFilter: filter => dispatch(setFilterCategory(filter))
});

export default connect(null, mapDispatchToProps)(Button);