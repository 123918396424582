//React
import React from 'react';
//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectSmallNavBar } from '../../../../redux/small_nav_bar/small.nav.bar.selectors'
//Actions
import { setSmallNavBarSelect } from '../../../../redux/small_nav_bar/small.nav.bar.actions';

//SVG
import { ReactComponent as SmallNavSVG } from '../../../../assets/svg/sm-nav-bar.svg';
import { ReactComponent as NavExitSVG } from '../../../../assets/svg/sm-exit.svg';
//Style
import { SmallContainer } from './small.nav.style.jsx';

class SmallNavBar extends React.Component {

    handlerOnClick = () => {
        const { setSelect } = this.props;
        // const btn = document.getElementsByClassName('js-nav-items');
        // if (select) {
        //     btn[0].style.transform = 'scale(0)';
        //     setSelect();
        // } else if (!select) {
        //     btn[0].style.transform = 'scale(1)';
        //     setSelect();
        // };

        setSelect();
    }

    render() {
        const { select } = this.props;
        return (
            <SmallContainer onClick={() => this.handlerOnClick()}>
                {
                    select ?
                        <NavExitSVG />
                        :
                        <SmallNavSVG />
                }
            </SmallContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    select: selectSmallNavBar
});

const mapDispatchToProps = dispatch => ({
    setSelect: () => dispatch(setSmallNavBarSelect())
})

export default connect(mapStateToProps, mapDispatchToProps)(SmallNavBar);