//React
import React from 'react';

//SVG
import { ReactComponent as ProsforaLogo } from '../../../../assets/svg/prosfora.svg';

//Style
// import './prosfora.style.scss';
import { ProsforaStyle } from './prosfora.style';

const Prosfora = ({ prosforatext }) => {
    prosforatext = 'Προσφορά για το Προϊόν'
    return (
        <ProsforaStyle className='prosfora' title={prosforatext}>
            <ProsforaLogo className='prosfora-logo' />
        </ProsforaStyle>
    )
}

export default Prosfora;