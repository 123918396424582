import styled from "styled-components";

export const SingInSingUpStyle = styled.div`
    display: grid;
    grid-template-rows: 90vh;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
`;

export const CreateNewUserStyle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #65b79c;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
`;
