//React
import React, { Component } from 'react';
//Redux
import { connect } from 'react-redux';

//reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectorAddressTitle } from '../../redux/AddressBar/addressBar.selectors';
import { selectProductsAll } from '../../redux/product/product.selectors';
import { selectorPagesProperties } from '../../redux/products-panel/products-panel.selectors';

//Actions 
import { setShowItems } from '../../redux/products-panel/products-panel.actions';
import { fetchingCategoryStartAsync } from '../../redux/category/category.actions';

//Components
import BarTitle from './bar-title/bar.title.component.jsx';//title for position panel
import CenterPanel from './center/center.component.jsx';//items panel
import PropertiesBar from './bar-properties/properties.bar.component.jsx'; //Top->Properties for view panel
import ProductsPages from './pages/products.pages.component.jsx';//Bottom->pages panel
import ProductsFilter from './filtering/products.filter.component.jsx';//Left->filters panel

//Right->ad panel 

//Extra code function
import { checkForFilters } from './filtering/filter.code.js';
import { sortItemsFn } from './bar-properties/properties.bar.code.js';

//Style
import { ProductsPanelContainer, CenterContainer } from './products-panel.style.jsx';



class ProductsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allItems: props.allItems,
        };
    };

    //Πρεπει να βλέπει αλλαγές που μπορεί να έρθουν σαν (props)
    static getDerivedStateFromProps(props, state) {

        //Έλεγχος για τα Items αν υπαρχει διαφωρά με τα προιγούμενα που έχει φτιάξει
        if (props.allItems !== state.allItems) {
            state = { ...state, allItems: props.allItems }
            return state;
        } else {
            return null;
        }
    };

    //prepei na kataxorisi oti hr8e sthn redux
    async componentDidMount() {
        const { fetchingCategoryStartAsync } = this.props;
        await this.sendItemsToRedux(this.props.allItems);
        fetchingCategoryStartAsync();
    }

    async componentDidUpdate(prevProps, prevState) {
        // const { filter } = this.props.pageProperties;
        // const { pageProperties } = prevProps;

        if (prevProps.allItems !== this.props.allItems) {
            //filter
            const filterItems = await checkForFilters(this.props.pageProperties.filter, this.props.allItems);
            //classification 
            const classificationItems = await sortItemsFn(filterItems, this.props.pageProperties.classification);

            //add to redux
            await this.sendItemsToRedux(classificationItems);
        }
    };

    // καταχωρεί τα items που πείρε στην redux
    sendItemsToRedux = async (items) => {
        await this.props.setShowItems(items);
    };


    render() {
        const { itemsPerPage, pageNow } = this.props.pageProperties;

        // const { page } = this.state;
        let itemsPage = [];

        if (itemsPerPage.length > 0) {
            itemsPage = itemsPerPage[pageNow - 1];
        }


        return (
            <ProductsPanelContainer id='product-panel'>
                <BarTitle barTitle={this.props.title} />

                <ProductsFilter startItems={this.props.allItems} />

                <CenterContainer >
                    <PropertiesBar />

                    <CenterPanel itemPerRow={3} items={itemsPage ? itemsPage : this.state.allItems} />

                    <ProductsPages />
                </CenterContainer>

            </ProductsPanelContainer>
        )
    }

}

const mapStateToProps = createStructuredSelector({
    title: selectorAddressTitle,
    showItems: selectProductsAll,
    pageProperties: selectorPagesProperties
});

const mapDispatchToProps = dispatch => ({
    setShowItems: item => dispatch(setShowItems(item)),
    fetchingCategoryStartAsync: () => dispatch(fetchingCategoryStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPanel);