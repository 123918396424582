//Reselect
import { createSelector } from 'reselect';

const selectStateCart = state => state.cart;

export const selectCartItems = createSelector(
    [selectStateCart],
    cart => cart.cartItem
);

export const selectCountOnCart = createSelector(
    [selectStateCart],
    cart => cart.cartItem.length
);

export const selectCartFPA = createSelector(
    [selectStateCart],
    cart => cart.fpa
);

//Prepei na ypologizei thn ka8arh Timh gia ola ta proionta mazi
export const SelectAllItemPrices = createSelector(
    [selectStateCart],

    cart => {
        let count = 0;
        if (cart.cartItem.length) {
            const price = cart.cartItem.map((item) => (item.product.xondriki.priceItem + item.extraPrice) * item.quantity);
            price.forEach(element => count = count + element);
        };
        
        return count;
    }
);

//Prepei na ypologizei to Fpa gia ta proionta
export const selectPriceFpaForItems = createSelector(
    [selectStateCart, SelectAllItemPrices],
    (cart, count) => {

        let priceFpa = 0;
        if (+cart.fpa > 0) {
            priceFpa = Number.parseFloat((count * cart.fpa) - count).toFixed(2);
        } else {
            priceFpa = Number.parseFloat((count * 1) - count).toFixed(2);
        };

        return priceFpa;
    }
);