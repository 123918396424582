//Style
import styled from 'styled-components';

export const TimologioContainer = styled.div`

`;

export const PanelDetailsContainer = styled.div`
    height:100%;
    width: 100%;
    padding: 1rem;
    border: 1px solid #4444;
    border-radius: 10px;
`;

export const TitleContainer = styled.h2`
    text-align: center;
    padding-bottom: 2rem;
    font-size: 2rem;
    letter-spacing: 2px;
    color: #666;
`;

export const WrapContainerDiv = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;

    @media(max-width:600px){
        flex-direction:column;
    }
`;


export const WrapInputContainer = styled.div`
    position: relative;

    &:not(:last-child){
        padding:.5rem 1rem 1.5rem;
    }

    ${props =>
        props.style
    }
    
`;

export const InputContainer = styled.input`
    width: 100%;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    background-color: transparent;
    border: none;
    color:#555;
    padding-bottom:.3rem;
    border-bottom:1px solid #4444;

    @media(max-width:600px){
        font-size:1.5rem;
    }
`;

export const LabelContainer = styled.label`
    position:absolute;
    left: 1rem;
    font-size: 1.2rem;
    color: #999;

    transition: bottom .2s ease , opacity .2s ease;

${props => props.select ?
        {
            bottom: 0,
            opacity: 1
        }
        :
        {
            bottom: '50%',
            opacity: 0
        }
    }
`;

export const SelectContainer = styled.select`
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    background-color: transparent;
    color:#555;
    width:100%;
`;


export const SpanContainer = styled.span`
    width: 100%;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    background-color: transparent;
    color:#555;
    user-select: none;
`;

export const WrapTimologioContainer = styled.div`
    display:flex;
    padding-top:.5rem;
`;

export const OptionTimologioContainer = styled.div`
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    background-color: transparent;
    color:#555;
    user-select: none;

    &:not(:last-child){
        margin-right:2rem;
    };

    label{
        margin-left:.5rem;
    }

    @media(max-width:600px){
        font-size:1.6rem;
    }
`;

export const DivForTextAreaContainer = styled.div`
    width:100%;
    padding:.5rem 1rem 1.5rem;

    textarea{
        min-width:100%;
        max-width:100%;
        border: 1px solid #4444;
    }

`;

