//React
import React from 'react';

//Components
// import ThreeDSVG from './3dObject/3DSVG.component';
import ColorPaleteSVG from './colorPallet/colorPalet.component.jsx';
import CameraImageSVG from './cameraImage/cameraImage.component.jsx';

//Style
// import './cartHoverMain.style.scss';
import { CartHoverMainStyle, MainLogo, MainText } from './cart.hover.main.style';

const CartHoverMain = ({ imagesNum }) => {
    //Extra choise
    // <div className='cartHoverMain-logo'>
    //     <ThreeDSVG/>
    // </div>
    // <h4 className='cartHoverMain-text'>Αντικείμενο</h4>

    // const imagesNum = 4;

    return (
        <CartHoverMainStyle className='cartHoverMain'>

            <MainLogo className='cartHoverMain-logo'>
                <CameraImageSVG imagesNum={imagesNum} />
            </MainLogo>

            <MainText className='cartHoverMain-text'>Φωτογραφιες</MainText>

            <MainLogo className='cartHoverMain-logo'>
                <ColorPaleteSVG />
            </MainLogo>

            <MainText className='cartHoverMain-text'>Χρωματολόγιο</MainText>

        </CartHoverMainStyle>
    );
};

export default CartHoverMain;