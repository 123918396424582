//Δειλώνω όλα τα αντοικείμενα για την δημιουργία της Βάσης ανά δεκάδες

//1-10 (OUT product3,product6,product9)
import { product1, product2, product4, product5, product7, product8, product10 } from './files/products_1-10';
//11-20 (OUT product16,product18)
import { product11, product12, product13, product14, product15, product17, product19, product20 } from './files/products_11-20';
//21-30 (OUT product23)
import { product21, product22, product24, product25, product26, product27, product28, product29, product30 } from './files/products_21-30';
//31-40 (OUT product31,product32,product33,product34,product35,product36,product37,product38,product39,product40)
//
//41-50
import { product41, product42, product43, product44, product45, product46, product47, product48, product49, product50 } from './files/products_41-50';
//51-60
import { product51, product52, product53, product54, product55, product56, product57, product58, product59, product60 } from './files/products_51-60';
//61-70
import { product61, product62, product63, product64, product65, product66, product67, product68, product69, product70 } from './files/products_61-70';
//71-80 (OUT product78)
import { product71, product72, product73, product74, product75, product76, product77, product79, product80 } from './files/products_71-80';
//81-90
import { product81, product82, product83, product84, product85, product86, product87, product88, product89, product90 } from './files/products_81-90';
//91-100 (OUT product93,product100)
import { product91, product92, product94, product95, product96, product97, product98, product99 } from './files/products_91-100';
//101-110 (OUT product101,product102,product103,product104,product105,product106,product107,product108,product109,product110)
//
//111-120(OUT product111,product112,product113,product114,product115,product116,product117,product118,product119,product120)
//
//121-130(OUT product121,product122,product123,product124,product125,product128,product129,product130)
import { product126, product127 } from './files/products_121-130 ';
//131-140(OUT product131,product132,product133,product134,product135,product136,product137,product138,product139,product140)
//
//141-150 (OUT product147,product150); 
import { product141, product142, product143, product144, product145, product146, product148, product149 } from './files/products_141-150';
//151-160(OUT product153,product154,product155,product156,product157,product158,product159,product160)
import { product151, product152 } from './files/products_151-160';
//161-170(OUT product161,product162,product166,product167,product168,product169,product170)
import { product163, product164, product165 } from './files/products_161-170';

//(OUT product3,product6,product9)
export const products_1_10 = [product1, product2, product4, product5, product7, product8, product10];
//(OUT product16,product18)
export const product_11_20 = [product11, product12, product13, product14, product15, product17, product19, product20];
//(OUT product23)
export const products_21_30 = [product21, product22, product24, product25, product26, product27, product28, product29, product30];
//(OUT product31,product32,product33,product34,product35,product36,product37,product38,product39,product40)
// export const products_31_40 = [product31,product32,product33,product34,product35,product36,product37,product38,product39,product40];
export const products_41_50 = [product41, product42, product43, product44, product45, product46, product47, product48, product49, product50];
export const products_51_60 = [product51, product52, product53, product54, product55, product56, product57, product58, product59, product60];
export const products_61_70 = [product61, product62, product63, product64, product65, product66, product67, product68, product69, product70];
//(OUT product78)
export const products_71_80 = [product71, product72, product73, product74, product75, product76, product77, product79, product80];
export const products_81_90 = [product81, product82, product83, product84, product85, product86, product87, product88, product89, product90];
//(OUT product93,product100)
export const products_91_100 = [product91, product92, product94, product95, product96, product97, product98, product99];
//(OUT product101,product102,product103,product104,product105,product106,product107,product108,product109,product110)
// export const products_101_110 = [product101, product102, product103, product104, product105, product106, product107, product108, product109, product110];
//(OUT product111,product112,product113,product114,product115,product116,product117,product118,product119,product120)
// export const products_111_120 = [product111, product112, product113, product114, product115, product116, product117, product118, product119, product120];
//(OUT product121,product122,product123,product124,product125,product128,product129,product130)
export const products_121_130 = [product126, product127];
//(OUT product131,product132,product133,product134,product135,product136,product137,product138,product139,product140)
// export const products_131_140 = [product131, product132, product133, product134, product135, product136, product137, product138, product139, product140];
//(out: product147,products150)
export const products_141_150 = [product141, product142, product143, product144, product145, product146, product148, product149];
//(OUT product153,product154,product155,product156,product157,product158,product159,product160)
export const products_151_160 = [product151, product152];
//(OUT product161,product162,product166,product167,product168,product169,product170)
export const products_161_170 = [product163, product164, product165];


//(out: products_31_40, products_101_110, products_111_120, products_131_140)
export const products = [...products_1_10, ...product_11_20, ...products_21_30, ...products_41_50, ...products_51_60,
...products_61_70, ...products_71_80, ...products_81_90, ...products_91_100,
...products_121_130, ...products_141_150, ...products_151_160];