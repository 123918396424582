//Types
import { cartActionsTypes } from './cart.types';

//Extra library
import isEqual from 'lodash.isequal';

//Extra Code
import { addItemToCart, changeQuantity } from './cart.utilities';

// set default variable 
const INITIAL_STATE = {
    cartItem: [],
    fpa: 0,
};

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cartActionsTypes.ADD_ITEM:
            return addItemToCart(state, action.payload);
        // { ...state, cartItem: [...state.cartItem, action.payload] };

        case cartActionsTypes.REMOVE_ITEM:
            const newCartItemAfterRemove = [...state.cartItem.map((item) => !isEqual(item, action.payload) && item).filter(item => item !== false)];
            if (newCartItemAfterRemove.length <= 0) {
                return { ...state, fpa: 0, cartItem: newCartItemAfterRemove }
            };

            return {
                ...state, cartItem: newCartItemAfterRemove
            };

        case cartActionsTypes.CHANGE_QUANTITY:
            return changeQuantity(state, action.payload);

        case cartActionsTypes.SELECT_FPA:
            return { ...state, fpa: action.payload }

        case cartActionsTypes.REMOVE_ALL:
            return {
                cartItem: [],
                fpa: 0,
            }

        default:
            return state;
    }
};

export default cartReducer;