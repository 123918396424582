//React 
import React from 'react';

//SVG
import { ReactComponent as ArrowLeft } from '../../assets/svg/arrow-left.svg'; // '../../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';

//Style
import { ArrowPointer, SVGContainerLeft, SVGContainerRight } from './arrow.style.jsx';

//side       -> επιλογή πλευράς
//onClickFun -> λειτουργία αν κλικαριστή
//enable     -> αν είναι ενεργά

const Arrow = ({ side, onClickFun, enable }) => {
    switch (side) {
        case 'left':
            return (
                <ArrowPointer onClick={onClickFun}>
                    <SVGContainerLeft enable={enable}>
                        <ArrowLeft style={{ width: '100%', height: '100%' }} />
                    </SVGContainerLeft>
                </ArrowPointer>
            );

        case 'right':
            return (
                <ArrowPointer onClick={onClickFun}>
                    <SVGContainerRight enable={enable}>
                        <ArrowRight style={{ width: '100%', height: '100%' }} />
                    </SVGContainerRight>
                </ArrowPointer>
            );

        default:
            break;
    }
};

export default Arrow;