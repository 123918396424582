//React
import React from 'react';

//Style
import './factor_details.style.scss';

export const FactorDetails = () => {


    return (
        <div className='factor-details'>
            <h3 className='factor-text' id='js-animationText'>
                ΟΙ ΤΙΜΕΣ ΠΟΥ ΑΝΑΓΡΑΦΟΝΤΑΙ ΣΤΟ SITE ΕΙΝΑΙ ΧΟΝΔΡΙΚΗΣ ΚΑΙ ΙΣΧΥΟΥΝ ΓΙΑ ΑΓΟΡΑ ΠΟΛΛΩΝ ΤΕΜΑΧΙΩΝ
        </h3>
        </div>
    )
}