//Types
import { addressBarTypes } from './addressBar.types';

const INIT_STATE = {
    route: {
        address: [],
        barTiTle: '',
        url: '',
        sendUrl: ''
    }
};

const addressBarReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case addressBarTypes.ADD_ADDRESS_DATA:
            return { ...state, route: { ...state.route, ...action.payload } };

        default:
            return state;
    }
};

export default addressBarReducer;