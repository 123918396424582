//React
import React from 'react';

//Style
import { SpinnerContainer, LoadindContainer } from './with.spinner.style.jsx';

const WithSpinner = WrapComponent => ({ isLoading, ...otherProps }) => {
    return isLoading ?
        (<SpinnerContainer>
            <LoadindContainer>Loading...</LoadindContainer>
        </SpinnerContainer>)
        :
        (<WrapComponent {...otherProps} />)
};

export default WithSpinner;