//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectorProiontaPanelAnimation } from '../../../redux/homeSlider/homeSliderAnimation.selectors';

//Actions
import { setHomeSliderProiontaStartAnimation, setHomeSliderProiontaEndAnimation, setHomeSliderProiontaFirstTimeAnimation } from '../../../redux/homeSlider/homeSliderAnimation.action';

//Components
import { CircleImageProion } from './proion-images/proion.image.component.jsx';

//Data
import { proiontaArray } from './homeSlideProoionta.js';


//Style
import './home-slider-prooionta.style.scss';



class HomeSliderProoionta extends Component {

    componentDidUpdate(prevProps) {
        const { proiontaAnimation: { startAnimation } } = this.props;


        if (prevProps !== this.props &&
            this.props.play === true &&
            this.props.proiontaAnimation.firstTimeAnimation === true &&
            this.props.proiontaAnimation.animationEnd === false &&
            this.props.proiontaAnimation.animationStart === false) {

            setTimeout(() => {
                this.startAnimation();
            }, startAnimation);

        } else if (prevProps !== this.props &&
            this.props.play === true &&
            this.props.proiontaAnimation.firstTimeAnimation === false &&
            this.props.proiontaAnimation.animationEnd === true &&
            this.props.proiontaAnimation.animationStart === false) {


            //Prepei na bazei ta element sthn sosth 8esh
            const placeImageLeft = document.getElementsByClassName('prooionta-grid-left')[0].childNodes;
            const placeImageRight = document.getElementsByClassName('prooionta-grid-right')[0].childNodes;
            const placeTexts = document.getElementsByClassName('list-products')[0].childNodes;

            //Prepei na bazei ta antikoimena sto shmeio poy prepei
            placeImageLeft.forEach((el) => {
                if (window.screen.availWidth > 500) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(1)';
                } else if (window.screen.availWidth < 500 && window.screen.availWidth > 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.9)';
                } else if (window.screen.availWidth < 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.7)';
                }
            });
            placeImageRight.forEach((el) => {
                if (window.screen.availWidth > 500) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(1)';
                } else if (window.screen.availWidth < 500 && window.screen.availWidth > 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.9)';
                } else if (window.screen.availWidth < 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.7)';
                }
            });
            placeTexts.forEach((el) => {
                if (window.screen.availWidth > 500) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(1)';
                } else if (window.screen.availWidth < 500 && window.screen.availWidth > 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.9)';
                } else if (window.screen.availWidth < 400) {
                    el.style.opacity = '1';
                    el.style.transform = 'scale(.7)';
                }
            })

            this.props.nextPanel();

        }
    }

    startAnimation() {


        const { proiontaStartAnimation, proiontaAnimation: { animationElementTime } } = this.props;

        //Erxete gia na parei ton elegxo toy stage kai na kanei to animation
        let startElement = 0;
        const animTimeForChange = animationElementTime;

        const placeImageLeft = document.getElementsByClassName('prooionta-grid-left')[0].childNodes;
        const placeImageRight = document.getElementsByClassName('prooionta-grid-right')[0].childNodes;

        const placeTexts = document.getElementsByClassName('list-products')[0].childNodes;

        const title = document.getElementsByClassName('products')[0];

        //Set style for title
        title.style.transform = 'scale(1)';
        title.style.opacity = '1';

        //prepei na enhmeronei to state oti arxise to anim
        proiontaStartAnimation();

        const animProionta = setInterval(() => {
            //elegxei to animation gia ta images kai ta texts
            let scaleSelect;

            if (window.screen.availWidth > 500) {
                scaleSelect = 'scale(1)';
            } else if (window.screen.availWidth < 500 && window.screen.availWidth > 400) {
                scaleSelect = 'scale(.9)';
            } else if (window.screen.availWidth < 400) {
                scaleSelect = 'scale(.7)';
            };


            if (startElement < placeTexts.length) {

                //einai gia to anim ton texts
                let selectElementText = placeTexts[startElement]
                // placeTexts[0].style={display:'block',opacity:1}
                selectElementText.style.opacity = '1';
                selectElementText.style.transform = scaleSelect;


                //einai gia to anim ton image
                if (startElement < 4) {
                    placeImageLeft[startElement].style.opacity = "1";
                    placeImageLeft[startElement].style.transform = scaleSelect;
                } else if (startElement < placeTexts.length && startElement > 3) {
                    placeImageRight[startElement - 4].style.opacity = "1";
                    placeImageRight[startElement - 4].style.transform = scaleSelect;
                }
                startElement++;
            } else {

                //telos toy Anim
                clearInterval(animProionta);

                //prepei na oloklhronei thn parousiash toy panel meta apo to xrono poy toy exoyme dosei
                setTimeout(() => {
                    //prepei na teliosei to anim
                    this.endAnimation();
                }, this.props.proiontaAnimation.awaitAfterAnimation)
            }

        }, animTimeForChange);

    }

    endAnimation() {
        //trabaei thn function gia na enhmerosh to state oti stamatise to animation
        const { proiontaEndAnimation, changeFirstTimeAnimation } = this.props;

        //Enhmeronei to state oti teleiose to animation 
        proiontaEndAnimation();

        //Enhmeronei oti allakse to First Time Animation
        changeFirstTimeAnimation();

    }


    render() {

        return (
            <div className='home-slider-prooionta'>

                <div className='prooionta-grid'>
                    <div className='prooionta-grid-left'>
                        <CircleImageProion key='dsfsdf1' scale='0' position='right' {...proiontaArray[0]} />
                        <CircleImageProion key='dsfsdf2' scale='0' position={window.screen.availWidth > 700 ? 'center' : 'right'} {...proiontaArray[1]} />
                        <CircleImageProion key='dsfsdf3' scale='0' position={window.screen.availWidth > 700 ? 'center' : 'right'} {...proiontaArray[2]} />
                        <CircleImageProion key='dsfsdf4' scale='0' position='right' {...proiontaArray[3]} />
                    </div>

                    <div className='prooionta-grid-center'>

                        <div className='center-details'>

                            <div className='center-details-text'>
                                <p>Όλα μας τα προϊόντα είναι κατασκευασμένα με πρώτες ύλες, σχολαστικά διαλεγμένες, έχοντας ως γνώμονα την άριστη ποιότητα. Στόχος μας είναι να παραδείδουμε προϊόντα με αντοχή στην χρήση & μοναδικό τελικό αποτέλεσμα.
                                Γι'αυτό μπορούμε να δώσουμε μέχρι και</p>
                                <p className='center-details-text-red'>8 ΧΡΟΝΙΑ εγγύηση</p>

                                <p className='products'>Μερικά από τα προϊόντα που κατασκευάζουμε</p>

                                <ul className='list-products'>
                                    {
                                        proiontaArray.map((element, index) => {
                                            const { name } = element
                                            return (<li key={name + index} className='list-product'>
                                                <h2 className='list-product-text'>{name}</h2>
                                            </li>)
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className='prooionta-grid-right'>
                        <CircleImageProion key='dsfsdf5' scale='0' position='left' {...proiontaArray[4]} />
                        <CircleImageProion key='dsfsdf6' scale='0' position={window.screen.availWidth > 700 ? 'center' : 'left'} {...proiontaArray[5]} />
                        <CircleImageProion key='dsfsdf7' scale='0' position={window.screen.availWidth > 700 ? 'center' : 'left'} {...proiontaArray[6]} />
                        <CircleImageProion key='dsfsdf8' scale='0' position='left' {...proiontaArray[7]} />
                    </div>

                </div>

            </div>
        )
    }

}


const mapStateToProps = createStructuredSelector({
    proiontaAnimation: selectorProiontaPanelAnimation
});




const mapDispatchToProps = (dispatch) => ({
    proiontaStartAnimation: () => dispatch(setHomeSliderProiontaStartAnimation()),
    proiontaEndAnimation: () => dispatch(setHomeSliderProiontaEndAnimation()),
    changeFirstTimeAnimation: () => dispatch(setHomeSliderProiontaFirstTimeAnimation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSliderProoionta);