//Types
import { notificationTypes } from './notifications.types';


export const setNotificationView = () => ({
   type:notificationTypes.NOTIFICATION_VIEW 
});

export const SetNotificationAdd = (item) => ({
    type: notificationTypes.NOTIFICATION_ADD,
    payload: item
});

export const setNotificationRemove = (item) => ({
    type: notificationTypes.NOTIFICATION_REMOVE,
    payload:item
});