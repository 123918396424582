//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product51 = new product();
export const product52 = new product();
export const product53 = new product();
export const product54 = new product();
export const product55 = new product();
export const product56 = new product();
export const product57 = new product();
export const product58 = new product();
export const product59 = new product();
export const product60 = new product();

//product51----------------------------------------------------------------------------------------------------------------------------
product51.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp';
product51.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product51.codeModelItem = '100.300.111';
product51.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 160X80 ΛΟΥΣΤΡΟ';
product51.titleItem = 'Τραπέζι Καφενείου';
product51.category = 'ΤΡΑΠΕΖΙΑ';
product51.xondriki.minSalesProducts = 3;
product51.xondriki.priceItem = 120;
product51.id = '51';

//product52----------------------------------------------------------------------------------------------------------------------------
product52.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp';
product52.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product52.codeModelItem = '100.300.112';
product52.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 180X80 ΛΟΥΣΤΡΟ';
product52.titleItem = 'Τραπέζι Καφενείου';
product52.category = 'ΤΡΑΠΕΖΙΑ';
product52.xondriki.minSalesProducts = 3;
product52.xondriki.priceItem = 130;
product52.id = '52';

//product53----------------------------------------------------------------------------------------------------------------------------
product53.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product53.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product53.codeModelItem = '100.300.113';
product53.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 50X50 ΛΟΥΣΤΡΟ';
product53.titleItem = 'Τραπέζι Καφενείου';
product53.category = 'ΤΡΑΠΕΖΙΑ';
product53.xondriki.minSalesProducts = 3;
product53.xondriki.priceItem = 55;
product53.id = '53';

//product54----------------------------------------------------------------------------------------------------------------------------
product54.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product54.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product54.codeModelItem = '100.300.114';
product54.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 60X60 ΛΟΥΣΤΡΟ';
product54.titleItem = 'Τραπέζι Καφενείου';
product54.category = 'ΤΡΑΠΕΖΙΑ';
product54.xondriki.minSalesProducts = 3;
product54.xondriki.priceItem = 55;
product54.id = '54';

//product55----------------------------------------------------------------------------------------------------------------------------
product55.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product55.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product55.codeModelItem = '100.300.115';
product55.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 70X70 ΛΟΥΣΤΡΟ';
product55.titleItem = 'Τραπέζι Καφενείου';
product55.category = 'ΤΡΑΠΕΖΙΑ';
product55.xondriki.minSalesProducts = 3;
product55.xondriki.priceItem = 60;
product55.id = '55';

//product56----------------------------------------------------------------------------------------------------------------------------
product56.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product56.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product56.codeModelItem = '100.300.116';
product56.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 80X80 ΛΟΥΣΤΡΟ';
product56.titleItem = 'Τραπέζι Καφενείου';
product56.category = 'ΤΡΑΠΕΖΙΑ';
product56.xondriki.minSalesProducts = 3;
product56.xondriki.priceItem = 65;
product56.id = '56';

//product57----------------------------------------------------------------------------------------------------------------------------
product57.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product57.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product57.codeModelItem = '100.300.117';
product57.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 80X90 ΛΟΥΣΤΡΟ';
product57.titleItem = 'Τραπέζι Καφενείου';
product57.category = 'ΤΡΑΠΕΖΙΑ';
product57.xondriki.minSalesProducts = 3;
product57.xondriki.priceItem = 75;
product57.id = '57';

//product58----------------------------------------------------------------------------------------------------------------------------
product58.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product58.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product58.codeModelItem = '100.300.118';
product58.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 90X90 ΛΟΥΣΤΡΟ';
product58.titleItem = 'Τραπέζι Καφενείου';
product58.category = 'ΤΡΑΠΕΖΙΑ';
product58.xondriki.minSalesProducts = 3;
product58.xondriki.priceItem = 75;
product58.id = '58';

//product59----------------------------------------------------------------------------------------------------------------------------
product59.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp';
product59.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product59.codeModelItem = '100.300.119';
product59.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 100X80 ΛΟΥΣΤΡΟ';
product59.titleItem = 'Τραπέζι Καφενείου';
product59.category = 'ΤΡΑΠΕΖΙΑ';
product59.xondriki.minSalesProducts = 3;
product59.xondriki.priceItem = 80;
product59.id = '59';

//product60----------------------------------------------------------------------------------------------------------------------------
product60.images.center = stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp';
product60.images.others = [stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_1_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_2_normal.webp',
stadarURL.trapezia + 'mdf-kaplamas/mdf_kaplamas_3_normal.webp',]
product60.codeModelItem = '100.300.120';
product60.nameModelItem = 'MDF ME ΚΑΠΛΑΜΑ 110X80 ΛΟΥΣΤΡΟ';
product60.titleItem = 'Τραπέζι Καφενείου';
product60.category = 'ΤΡΑΠΕΖΙΑ';
product60.xondriki.minSalesProducts = 3;
product60.xondriki.priceItem = 90;
product60.id = '60';