//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { setNewExtraValue, setNewExtraPrice } from '../../../redux/product/selectExtraChoise/selectExtraChoiseActions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectExtraChoise } from '../../../redux/product/selectExtraChoise/selectExtraChoiseSelectors';
import { selectCurrentColor } from '../../../redux/colorPalette/colorPalette.selector';

//Components
import { SelectColorValue } from './selectColor/select.color.component.jsx';
import { SelectLegsValue } from './selectLegs/select.legs.component.jsx';
import { SelectLoystroValue } from './selectLoystro/select.loystro.values.jsx';
import { SelectSyrmaValue } from './selectSyrma/select.syrma.values.jsx';
import { SelectSeatValue } from './selectSeat/select.syrma.values.jsx';

//Style
import {
    WrapEtraChoiseContainer,
    TitlePanelContainer,

} from './select.extra.choise.style.jsx';


class SelectExtraChoise extends Component {

    //Pairname mesa se ka8e extra epilogi
    setChangesToState = (value) => {
        this.props.setNewExtraValue(value);
    };

    //Prepei na blepei an 8a kanei subtrak h plus thn timh apo thn extra epilogh poy ekane o xrhsths
    calculateExtraPrice = (mode, price) => {
        let newExtraPrice = this.props.extraChoise.extraPrice;

        if (mode === 'plus') {
            newExtraPrice += price;
        } else if (mode === 'minus') {
            newExtraPrice -= price;
        }

        //Prepei na allazei thn timh sth redux
        this.props.newExtraPrice(newExtraPrice);
    }

    render() {
        const { color, legs, loystro, syrma, seat } = this.props.extraChoise;

        return (
            <WrapEtraChoiseContainer>

                {
                    this.props.category !== 'ΤΡΑΠΕΖΙΑ' &&
                    <TitlePanelContainer><strong>Επιπλέον επιλογές</strong></TitlePanelContainer>
                }

                {color.show &&
                    <SelectColorValue category={this.props.category}
                        colorValues={{ ...color, selectColor: this.props.selectCurrentColor !== '' ? this.props.selectCurrentColor : '' }}
                        // selectExtraChoise={this.newExtraChoise}
                        calculateExtraPrice={this.calculateExtraPrice}
                        setChangesToState={this.setChangesToState}
                    />
                }

                {legs.show &&
                    <SelectLegsValue legsValues={{ ...legs }}
                        // selectExtraChoise={this.newExtraChoise}
                        calculateExtraPrice={this.calculateExtraPrice}
                        setChangesToState={this.setChangesToState}
                    />
                }

                {loystro.show &&
                    <SelectLoystroValue
                        loystroValues={{ ...loystro }}
                        // selectExtraChoise={this.newExtraChoise}
                        calculateExtraPrice={this.calculateExtraPrice}
                        setChangesToState={this.setChangesToState}
                    />
                }



                {syrma.show &&
                    <SelectSyrmaValue
                        syrmaValues={{ ...syrma }}
                        // selectExtraChoise={this.newExtraChoise}
                        calculateExtraPrice={this.calculateExtraPrice}
                        setChangesToState={this.setChangesToState}
                    />
                }

                {seat.show &&
                    <SelectSeatValue
                        seatValues={{ ...seat }}
                        // selectExtraChoise={this.newExtraChoise}
                        calculateExtraPrice={this.calculateExtraPrice}
                        setChangesToState={this.setChangesToState}
                    />
                }
            </WrapEtraChoiseContainer>
        );
    }
};

const mapStateToProps = createStructuredSelector({
    extraChoise: selectExtraChoise,
    selectCurrentColor: selectCurrentColor
});

const mapDispatchToProps = dispatch => ({
    setNewExtraValue: value => dispatch(setNewExtraValue(value)),
    newExtraPrice: price => dispatch(setNewExtraPrice(price)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectExtraChoise);