//Πρέπει να είναι ένα component που να φτιάχνει ένα element.
// 1 -> θα πρέπει να ποιάνει όλο το διαθέσιμο χώρο που του δίνουμε.
// 1a-> και όταν γίνετε αλλαγή μεγέθους

// 2 -> θα πρέπει να μπορούμε να φτιάχνουμε πάνελ ή πάνελς.
// 3 -> θα πρέπει να μπορούμε να βάζουμε άλλα elements μέσα σε ένα πάνελ ή να γίνετε αυτόματα αν έχουμε επιλέξει συγκεκριμένο αριθμό πάνελ.

// 4 -> θα πρέπει να μπορουμε να επιλέγουμε αν θέλουμε να αλλάζουμε πάνελ με βελάκια αριστερά και δεξιά.

// 5 -> θα πρεπει να βάζουμε χρόνο για να κάνει αυτόματη κύληση.
// 6 -> θα πρέπει να έχει αυτοματη κύληση προς τα δεξιά ή αριστερά ανάλογα με την επιλογή
// 7 -> θα πρέπει να υπάρχει αυτοματο σταμάτιμα του χρόνου όταν ο χρήστης βάλει πάνω το mouse και να ξεκινάει όταν φεύγει.

// 8 -> θα πρέπει 

//React
import React, { Component } from 'react';

//Auto key
import { v5 as uuidV5 } from 'uuid';

//Animation
import { TransitionGroup, Transition } from 'react-transition-group';

//Redux
import { connect } from 'react-redux'

//Reselect
// import { createStructuredSelector } from 'reselect'

//selectors
import {
    selectPanelAllItems,
    selectActive,
    selectDisplayArrow,
    selectDisplayPanelItems,
    selectDirection,
    selectShowItems,
    selectDefaultStyle,
    selectStyles,
    selectTimer

} from '../../redux/panelContainer/panel.container.selectors'

//Actions
import {
    setItems,
    setActive,
    setDisplayArrow,
    setPanelDisplayItems,
    setShowItems,
    setDirection,
    setNewStyle
} from '../../redux/panelContainer/panel.container.actions';

//Components
import Arrow from '../arrows/arrow.component.jsx';
import PanelItem from './panel_item/panel.item.component.jsx';

//Functions 

//Style
import './panel.container.style.scss';


class Carousel extends Component {

    //Φτιάχνει τα Items που θα εμφανιστουν στο Panel ή όταν γίνεται σε κάποιο Arrow click εμφανίζει τα επόμενα
    generateItems = async () => {
        const { displayPanelItems, activeItem, direction, setShowItems, items } = this.props;

        //Για να βλέπουμε αν έχει προσπελάσει την Function
        let passFunction = false;
        let panelitems = [];
        let level;

        //Υπολογίζει από πού ξεκινάει και που θα τελειώνει
        let startItem = Math.floor(displayPanelItems / 2);
        let endItems = Math.round(displayPanelItems / 2);

        //Είναι για να βάζει το σωστό νούμερο από το index
        // let numIndex = 0;

        for (let i = activeItem - startItem; i < activeItem + endItems; i++) {
            let index = i;

            //Φτιάχνει το Key για το Item
            if (i < 0) {
                index = items.length + i;
            } else if (i >= items.length) {
                index = i % items.length;
            }

            //Υπολογίζει το μέρος που θα βρίσκεται
            level = -(activeItem - i) === 0 ? 0 : -(activeItem - i);

            // ('level->', level);


            //Καταχωρεί στον Array το Item 
            panelitems.push(
                {
                    in: true,
                    key: index + uuidV5.DNS,
                    //test object
                    item: items[index], //// id: items[index]
                    level: level,
                    direction: direction,
                }
            );

            // numIndex++;
        }

        //Περνάει τα Items που έφτιαξε για να τα εμφανίσει
        await setShowItems(panelitems);

        //Στέλνει 'true' για την προσπέλαση της Function
        passFunction = true;

        return passFunction;
        //End function
    };

    //πρέπει να παιρνάει τα props του component στο state
    componentDidMount() {
        const {
            items,
            setPanelItems,
            active,
            setActive,
            arrow,
            setDisplayArrow,
            panelDisplayItems,
            setPanelDisplayItems,

        } = this.props;

        const init = async () => {
            //Πρέπει να περάσει τα items που παιρνει στο state
            setPanelItems(items);
            //Πρέπει να βάζει το Active Item στο Panel
            setActive(active);
            //Πρέπει να περνάει εάν είναι ορατά τα Arrows
            setDisplayArrow(arrow);
            //Πρέπει να βλέπει πόσα Items πρέπει να είναι ορατά στο Panel
            setPanelDisplayItems(panelDisplayItems);
        }

        init().then(item => {
            //Φτιάχνει τα Items που θα εμφανιστουν στο Panel
            this.generateItems().then(item => {

                //Φτιάχνει τις διαστάσεις και το Style για κάθε Item
                this.findDimensions();
            }
            );
        })

        //End function
    }

    //Einai gia thn katastash eksodoy
    directionStyle = (state, item) => {

        const { selectStyles, direction } = this.props;

        const leftMinus = 50;
        const heightMinus = 5;

        const thisItem = { ...selectStyles[item.level] };

        const outItem = {};

        let outLeft = 0;
        let outHeight = 0;
        let outWidth = 0;


        if (state === 'exiting') {

            if (direction === 'left') {
                outLeft = Number(thisItem.left.slice(0, -2)) - (leftMinus);
            } else if (direction === 'right') {
                outLeft = Number(thisItem.left.slice(0, -2)) + (leftMinus);
            }

            outHeight = thisItem.height * heightMinus;

            outWidth = thisItem.width * heightMinus;

            outItem.left = outLeft + 'px';
            outItem.height = outHeight + 'px';
            outItem.width = outWidth + 'px';
            outItem.opacity = 0;

        }

        if (state === 'exited') {
            outItem.opacity = 0;
        }



        if (state === 'entering') {

            if (direction === 'left') {
                outLeft = Number(thisItem.left.slice(0, -2)) + (leftMinus / 2);
            } else if (direction === 'right') {
                outLeft = Number(thisItem.left.slice(0, -2)) - (leftMinus / 2);
            }

            outItem.left = outLeft + 'px';
            outItem.height = outHeight + 'px';
            outItem.width = outWidth + 'px';
            outItem.opacity = 0;
        }

        if (state === 'entered') {
            outItem.opacity = 1;
        }

        return outItem;
        //end function
    }

    //Μετακινεί τα items πρός τα Αριστερά
    moveLeft = async () => {
        const { activeItem, setActive, setDirection, items } = this.props;

        let newActive = activeItem;
        newActive--;
        newActive = newActive < 0 ? items.length - 1 : newActive;

        await setDirection('left');
        await setActive(newActive);

        this.generateItems();
    };

    //Μετακινεί τα items προς τα Δεξιά
    moveRight = async () => {
        const { activeItem, setActive, setDirection, items } = this.props;

        let newActive = activeItem;
        newActive = (newActive + 1) % items.length

        await setDirection('right');
        await setActive(newActive);

        this.generateItems();
    };

    //Gia to touch direction
    startTouch = 0
    touchDirection = ''

    //pernei thn timh poy egine to touch
    onTouchStart = (ev) => {
        this.startTouch = ev.touches[0].clientX;
    };

    //kanei elegxo pros ta poy ginete to direction tou touch
    onTouchMove = (ev) => {
        let now = this.startTouch - ev.touches[0].clientX;
        this.touchDirection = now > 0 ? 'left' : 'right';
    };

    //ypologizei kai stelnei thn katey8insi toy touch
    onTouchEnd = () => {
        switch (this.touchDirection) {
            case 'right':
                this.moveLeft();
                break;
            case 'left':
                this.moveRight();
                break;
            default:
        }
    }

    //einai gia na krataei tis times apo ta timers
    timerArray = [];

    // ftiaxnei to timer allaghs ton items
    timer = () => {
        const { timer } = this.props;

        this.clearTimers();

        if (timer.autoRotate) {
            let timers;

            if (timer.direction === 'left') {
                timers = setInterval(() => this.moveLeft(), timer.time);

            } else {

                timers = setInterval(() => this.moveRight(), timer.time);
            }

            this.timerArray.push(timers)
        }
    };

    // ka8arizei ola ta timers
    clearTimers = () => {
        this.timerArray.forEach((item) => {
            clearInterval(item);
        })
    };

    //erxete gia na ftiaksei tis times gia ola ta items otan mpoyne sthn selida
    findDimensions = () => {
        const { setNewStyle, showItems } = this.props;

        const panelItems = document.getElementsByClassName('panel-items').item(0);

        //elegxos gia to an exei antikoimena mesa
        if (!panelItems) return;

        const panelWidth = panelItems.offsetWidth;
        const panelHeight = panelItems.offsetHeight

        const childItems = showItems.length;

        const heightPercent = .080;

        let itemHeight = panelHeight - 20;

        // let num = 0;

        const newStyle = {};
        ////////////////////////////////////////////////////////////////////////////////////////

        const startAngle = 0;

        let frags = 180 / (childItems - 1); //Prepei na brei tis moires analoga me ta items apo (0-180)
        ////////////////////////////////////////////////////////////////////////////////////////
        let itemRadian = 0
        for (let i = -(Math.floor(childItems / 2)); i < childItems - (Math.floor(childItems / 2)); i++) {


            let newItemHeight = itemHeight;
            // let newItemHeight = itemHeight - (Math.abs(i) * heightPercent);
            let newItemWidth = newItemHeight * .8;

            ////////
            var moires = 180 - ((frags * itemRadian) + startAngle);

            let radians = moires * (Math.PI / 180);

            let posx = Math.floor(((panelWidth - (itemHeight)) / 2) * (Math.cos(radians)));

            let itemLeft = ((panelWidth - ((itemHeight * 1.5) / 2)) / 2) + parseInt(posx);

            ////////

            newStyle[i] = {
                left: itemLeft + 'px',
                backgroundColor: '#fff',
                width: newItemWidth + 'px',
                height: newItemHeight + 'px',
                transform: `scale(${1 - (Math.abs(i) * heightPercent)})`,
                zIndex: 100 - Math.abs(i),
            };

            itemRadian++;
            // num++;
        }

        setNewStyle(newStyle);

        //End Function
    }

    // Επιστρέφει το component
    render() {
        const { displayArrows, showItems, defaultStyle, selectStyles } = this.props;

        // Ελέγχει αν είναι το timer ενεργό ->
        this.timer();

        // Φτιαχνει παλι τα μεγεθει αν γινει resize στο παραθυρο
        window.onresize = this.findDimensions

        return (
            <div id="carousel" className="noselect"
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                onMouseEnter={this.clearTimers}
                onMouseLeave={this.timer}
            >
                {displayArrows === 'true' && <Arrow side='left' enable={'true'} onClickFun={this.moveLeft} onMouseEnter={this.clearTimers} />}

                <TransitionGroup component={"div"} className="panel-items" >
                    {showItems.map((item) => {


                        return (<Transition
                            in={true}
                            key={uuidV5.DNS + item.key}
                            timeout={{ enter: 50, exit: 500 }}
                            unmountOnExit
                        >
                            {(state) => {

                                return (
                                    <div className={`level ${item.level}`} style={{
                                        ...defaultStyle,
                                        ...selectStyles[item.level],
                                        ...this.directionStyle(state, item)
                                    }}
                                    >
                                        <PanelItem imgPath={item.item.images.center} title={item.item.nameModelItem} price={item.item.xondriki.priceItem} item={item.item} />
                                    </div>
                                )
                            }}
                        </Transition>)
                    })}

                </TransitionGroup>

                {displayArrows === 'true' && <Arrow side='right' enable={'true'} onClickFun={this.moveRight} onMouseEnter={this.clearTimers} />}
            </div>
        )
    }
};




const mapStateToProps = (state) => ({
    panel: selectPanelAllItems(state),
    activeItem: selectActive(state),
    displayArrows: selectDisplayArrow(state),
    //Είναι ο αριθμός απο τα Items που θα εμφανίζονται στο Panel
    displayPanelItems: selectDisplayPanelItems(state),
    //Εμφανίζει τα items που είναι να μπούν στο panel
    showItems: selectShowItems(state),
    //Είναι για να φτιάχνουν τα Items ανάλογα την κατεύθυνση με τα arrows
    direction: selectDirection(state),
    //Είναι για να πέρνει το Default Style για το Item 
    defaultStyle: selectDefaultStyle(state),
    //Είναι για να παίρνει τα Style ανάλογα με την θέση που βρείσκετε
    selectStyles: selectStyles(state),
    //Είναι για να πέρνει τις ρυθμίσεις για το Timer
    timer: selectTimer(state)
});

const mapDispatchToProps = (dispatch) => ({
    setPanelItems: (items) => dispatch(setItems(items)),
    setActive: (active) => dispatch(setActive(active)),
    setDisplayArrow: (arrow) => dispatch(setDisplayArrow(arrow)),
    setPanelDisplayItems: (items) => dispatch(setPanelDisplayItems(items)),
    //Καταχωρεί την κατεύφυνση από τα βέλοι 
    setDirection: (direction) => dispatch(setDirection(direction)),
    //Καταχωρεί τα Items που είναι να εμφανιστούν στο Panel
    setShowItems: (items) => dispatch(setShowItems(items)),
    //Καταχωρεί ενα καινούργιο Style για το Item
    setNewStyle: (style) => dispatch(setNewStyle(style))
});

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);