//React
import React, { Component } from 'react';

//Style
import './dots.style.scss';


export class Dots extends Component {

    render() {
        const { num, onClickFun } = this.props;

        return (
            <div className='dots' onClick={onClickFun.bind(this, num)}>
            </div>
        );

    }
};

