//Style
import styled from 'styled-components';



export const PanelPaymentContainer = styled.div`
    width:100%;
    height:100%;
    padding:1rem;
    border:1px solid #4444;
    border-radius:10px;
    position:relative;

    display:flex;
    flex-direction:column;
    align-items:center;
`;


export const ButtonSendOrderContainer = styled.div`
    width: 80%;

    /* position:absolute;
    bottom: 2rem;
    left:50%;
    transform:translateX(-50%); */

    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 1rem 4rem;
    text-align: center;
  
    background-color: #009688;
    color: white;

    cursor:pointer;

    transition:background-color .2s ease;

    &:hover{
        background-color: #FF9800;
    }

    @media(max-width:400px){
        width: 95%;
        font-size: 1.8rem;
    }
`;