//React
import React from 'react';

//Components
import CartItem from '../../cartItem/cartItem.component.jsx';
//Styled
import styled from 'styled-components';

const CenterPanelContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px,350px));
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2rem;

    @media only screen and (max-width:1100px){
    justify-content: space-evenly;
    }
`;


const CenterPanel = ({ items }) => {
    // @ items Ο Array με τα προϊόντα που θα εμφανίζονται
    // const { items } = props;

    return (
        <CenterPanelContainer>
            {
                items ? items.map((item) => <CartItem key={item.id} cartItemNow={item} />) : null
            }
        </CenterPanelContainer>
    )
};

export default CenterPanel;