//Style
import styled from 'styled-components';


export const SpinnerContainer = styled.div`
    width:100%;
    height:100%;

    display:flex;
    justify-content:center;
    align-items:center;

`;

export const LoadindContainer = styled.p`
    color:#999a;
    font-size:1.8rem;
`;