//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product91 = new product();
export const product92 = new product();
// export const product93 = new product();
export const product94 = new product();
export const product95 = new product();
export const product96 = new product();
export const product97 = new product();
export const product98 = new product();
export const product99 = new product();
// export const product100 = new product();

//product91----------------------------------------------------------------------------------------------------------------------------
product91.images.center = stadarURL.trapezia + 'bohthitiko/bohthitiko_1_normal.webp';
product91.images.others = [stadarURL.trapezia + 'bohthitiko/bohthitiko_1_normal.webp']
product91.codeModelItem = '100.300.153';
product91.nameModelItem = 'ΒΟΗΘΗΤΙΚΟ 35Χ35';
product91.titleItem = 'Τραπέζι Καφενείου';
product91.category = 'ΤΡΑΠΕΖΙΑ';
product91.xondriki.minSalesProducts = 2;
product91.xondriki.priceItem = 30;
product91.id = '91';

//product92----------------------------------------------------------------------------------------------------------------------------
product92.images.center = stadarURL.trapezia + 'monh-kolona/monh-kolona_1_normal.webp';
product92.images.others = [stadarURL.trapezia + 'monh-kolona/monh-kolona_1_normal.webp',
stadarURL.trapezia + 'monh-kolona/monh-kolona_2_normal.webp',
stadarURL.trapezia + 'monh-kolona/monh-kolona_3_normal.webp']
product92.codeModelItem = '100.300.154';
product92.nameModelItem = 'ΤΡΑΠΕΖΙ 80Χ80 ΜΕ ΜΟΝΗ ΚΟΛΩΝΑ 10Χ10';
product92.titleItem = 'Τραπέζι Καφενείου';
product92.category = 'ΤΡΑΠΕΖΙΑ';
product92.xondriki.minSalesProducts = 2;
product92.xondriki.priceItem = 90;
product92.id = '92';

//product93----------------------------------------------------------------------------------------------------------------------------
// product93.images.center = stadarURL.trapezia + 'dyplh-kolona/dyplh-kolona_1_normal.webp';
// product93.images.others = [stadarURL.trapezia + 'dyplh-kolona/dyplh-kolona_1_normal.webp']
// product93.codeModelItem = '100.300.155';
// product93.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΕ ΔΙΠΛΗ ΚΟΛΩΝΑ 120Χ80';
// product93.titleItem = 'Τραπέζι Καφενείου';
// product93.category = 'ΤΡΑΠΕΖΙΑ';
// product93.xondriki.minSalesProducts = 1;
// product93.xondriki.priceItem = 130;
// product93.id = '93';

//product94----------------------------------------------------------------------------------------------------------------------------
product94.images.center = stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp';
product94.images.others = [stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_3_normal.webp']
product94.codeModelItem = '100.300.156';
product94.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΑΣΙΦ ΕΛΑΤΟ 70Χ70';
product94.titleItem = 'Τραπέζι Καφενείου';
product94.category = 'ΤΡΑΠΕΖΙΑ';
product94.xondriki.minSalesProducts = 2;
product94.xondriki.priceItem = 110;
product94.id = '94';

//product95----------------------------------------------------------------------------------------------------------------------------
product95.images.center = stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp';
product95.images.others = [stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_3_normal.webp']
product95.codeModelItem = '100.300.157';
product95.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΑΣΙΦ ΕΛΑΤΟ 80Χ80';
product95.titleItem = 'Τραπέζι Καφενείου';
product95.category = 'ΤΡΑΠΕΖΙΑ';
product95.xondriki.minSalesProducts = 2;
product95.xondriki.priceItem = 120;
product95.id = '95';

//product96----------------------------------------------------------------------------------------------------------------------------
product96.images.center = stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp';
product96.images.others = [stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp',
stadarURL.trapezia + 'masif-elato/masif-elato_3_normal.webp']
product96.codeModelItem = '100.300.158';
product96.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΑΣΙΦ ΕΛΑΤΟ 120Χ80';
product96.titleItem = 'Τραπέζι Καφενείου';
product96.category = 'ΤΡΑΠΕΖΙΑ';
product96.xondriki.minSalesProducts = 2;
product96.xondriki.priceItem = 160;
product96.id = '96';

//product97----------------------------------------------------------------------------------------------------------------------------
product97.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
product97.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product97.codeModelItem = '100.300.151';
product97.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 160Χ80';
product97.titleItem = 'Τραπέζι Καφενείου';
product97.category = 'ΤΡΑΠΕΖΙΑ';
product97.xondriki.minSalesProducts = 2;
product97.xondriki.priceItem = 160;
product97.id = '97';

//product98----------------------------------------------------------------------------------------------------------------------------
product98.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
product98.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product98.codeModelItem = '100.300.152';
product98.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 180Χ80';
product98.titleItem = 'Τραπέζι Καφενείου';
product98.category = 'ΤΡΑΠΕΖΙΑ';
product98.xondriki.minSalesProducts = 2;
product98.xondriki.priceItem = 180;
product98.id = '98';

//product99----------------------------------------------------------------------------------------------------------------------------
product99.images.center = stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp';
product99.images.others = [stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp',
stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_2_normal.webp']
product99.codeModelItem = '100.300.159';
product99.nameModelItem = 'ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ60Χ54';
product99.titleItem = 'Τραπέζι Καφενείου';
product99.category = 'ΤΡΑΠΕΖΙΑ';
product99.xondriki.minSalesProducts = 2;
product99.xondriki.priceItem = 110;
product99.id = '99';

// //product100----------------------------------------------------------------------------------------------------------------------------
// product100.images.center = stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp';
// product100.images.others = [stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp',
// stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_2_normal.webp']
// product100.codeModelItem = '100.300.159';
// product100.nameModelItem = 'ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ60Χ54';
// product100.titleItem = 'Τραπέζι Καφενείου';
// product100.category = 'ΤΡΑΠΕΖΙΑ';
// product100.xondriki.minSalesProducts = 2;
// product100.xondriki.priceItem = 110;
// product100.id = '100';