//React
import React from 'react';

//Style
import '../orario.style.scss';

export const OrarioFactory = () => {

    return (
        <div className='orario'>
            <h4 className='orario-title'>Δευτέρα-Παρασκευή</h4>
            <span className='orario-times'>09:00-14:00</span>
        </div>
    );
};