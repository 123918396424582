//React
import React from 'react';

//Router
import { withRouter } from 'react-router-dom';

//Redux
import { connect } from 'react-redux'
//Actions
import { fetchingCategoryStartAsync } from '../../redux/category/category.actions';

//Reselect
import { createStructuredSelector } from 'reselect'

//Selectors
import { selectCategory, selectIsFetching } from '../../redux/category/category.selector';

//Components
import Category from './category/category.component.jsx';

//WrapComponent
import WithSpinner from '../with-spinner/with.spinner.component.jsx';

import isEqual from 'lodash.isequal'

class CategoriesOverview extends React.Component {

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.categories, this.props.categories);
    }

    componentDidMount() {
        const { fetchingCategoryStartAsync } = this.props;
        //Take the category data from firestore 
        fetchingCategoryStartAsync();
    }

    render() {
        const { categories, history, isFetching } = this.props;

        const NewComponentWithSpinner = WithSpinner(Category);

        return (categories.map(category => <NewComponentWithSpinner isLoading={isFetching} key={category.id} item={category} history={history} />));
    }
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectIsFetching,
    categories: selectCategory,
});

const mapDispatchToProps = dispatch => ({
    fetchingCategoryStartAsync: () => dispatch(fetchingCategoryStartAsync())
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesOverview));