//React
import React from 'react';

//Styles
import {
    WrapDivPanel,
    PanelTitleContainer,
    ChoiseContainer,
    NoCostContainer,
    ChoiseInputContainer,
    FormContainer,
    ExtraChoiseContainer
} from '../select.extra.choise.style.jsx';

export const SelectSyrmaValue = ({ syrmaValues, selectExtraChoise, calculateExtraPrice, setChangesToState }) => {
    return (
        <WrapDivPanel>
            <PanelTitleContainer>Τοποθέτηση <strong>Σύρματος</strong></PanelTitleContainer>
            <FormContainer style={{ display: "flex" }}>

                <NoCostContainer cost={syrmaValues.price}
                    showNoCost={syrmaValues.showNoCost}>{syrmaValues.showNoCost ?
                        'Χωρίς \n Χρέωση'
                        :
                        `+${syrmaValues.price}€`}
                </NoCostContainer>

                <ExtraChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            name='syrma'
                            id='syrma-false'
                            type="radio"
                            value='none'
                            onChange={() => {
                                calculateExtraPrice('minus', syrmaValues.price);
                                setChangesToState({ name: 'syrma', values: { ...syrmaValues, showNoCost: true, value: 'none' } });
                            }}
                            defaultChecked={syrmaValues.value === 'none' ? true : false}
                        />
                        <label htmlFor="syrma-false">Χωρίς Σύρμα</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            name='syrma'
                            id='syrma-true'
                            type="radio"
                            value='Σύρμα'
                            onChange={() => {
                                calculateExtraPrice('plus', syrmaValues.price);
                                setChangesToState({ name: 'syrma', values: { ...syrmaValues, showNoCost: false, value: 'Σύρμα' } });
                            }}
                            defaultChecked={syrmaValues.value === 'Σύρμα' ? true : false}
                        />
                        <label htmlFor="syrma-true">Με Σύρμα</label>
                    </ChoiseContainer>

                </ExtraChoiseContainer>

            </FormContainer>
        </WrapDivPanel>
    )
};
