//Styled component
import styled from 'styled-components';

export const BackgroundContainer = styled.div`
    width: 100%;
    height: 100%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 30%;
    background: linear-gradient(90deg,#707070,transparent);
    /* position: absolute; */
    z-index: 1;

    @media(max-width:1100px){
        border-top-right-radius: 30%;
        border-bottom-right-radius: 30%;
        border-bottom-left-radius: 0%;

        width:50%;
    }

    @media(max-width:900px){
        border-top-right-radius: 25%;
        border-bottom-right-radius: 25%;
        border-bottom-left-radius: 0%;

        width:50%;
    }

    @media(max-width:650px){
        border-top-right-radius: 20%;
        border-bottom-right-radius: 20%;
        border-bottom-left-radius: 0%;

        width:40%;
    }

    @media(max-width:550px){
        position: absolute;
        bottom: 0;
        left: 0;
        height: 30%;
        width: 100%;
        background: linear-gradient(0deg,#707070,transparent);
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        border-bottom-right-radius: 0;
    }
`;

export const WrapImageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    @media(max-width:550px){
        position:relative;
        align-items:flex-start;
    }
`;

export const ProductImageContainer = styled.div`
    position:relative;

    @media(max-width:1100px){
        min-height:60vh;
        margin-bottom:2rem;
    }

    @media(max-width:500px){
        min-height:50vh;
    }

    @media(max-width:400px){
        min-height:45vh;
    }
`;

export const ImageProductCenter = styled.img`
    width: 80%;
    transform: translateX(35%);

    @media(max-width:1200px){
        transform: translateX(30%);
    }
    @media(max-width:1100px){
        width:50%;
        transform: translateX(20%);
    }
    @media(max-width:900px){
        width:60%;
        transform: translateX(30%);
    }
    @media(max-width:650px){
        width:70%;
        transform: translateX(15%);
    }
    @media(max-width:550px){
        width:75%;
        transform: translateX(0%);
    }
    @media(max-width:500px){
        width:70%;
    }
    @media(max-width:450px){
        width:80%;
    }

`;

export const SmallImageContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 2rem;
    transform:translateY(-50%);
    width: 25rem;
    display: flex;
    flex-direction: column;
    z-index:20;

    @media(max-width:1100px){
        left: 5rem;
        width: 30rem;
    }

    @media(max-width:1000px){
        left: 2rem;
        width: 25rem;
    }

    @media(max-width:650px){
        left: 2rem;
        width: 18rem;
    }

    @media(max-width:550px){
        flex-direction: row;
        top: auto;
        bottom: 0rem;
        left: 0rem;
        width: 100%;
        /* height: 13%; */
        justify-content: space-evenly;
        align-items: center;
        transform:translateY(0%);
        padding-bottom:1rem;
    }
`;

