//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Create notification obj
import { setNewNotification } from '../../../../redux/notification/notification.data';

//Add New Notification
import { SetNotificationAdd } from '../../../../redux/notification/notification.acions';


//Actions
import { setAddNewItem, removeItem, changeQuantity } from '../../../../redux/cart/cart.actions';



//Style
import {
    NumContainer,

    WrapListItem,
    WrapImage,

    WrapExtraCost,
    TitleItemContainer,
    ExtraCostPanelContainer,

    ItemPriceContainer,

    QuantityPanel,
    ButtonQuantity,
    QuantityNumberContainer,

    DeleteContainer,
    PanelDel
} from './list.items.style.jsx';

const ListItem = ({ imgSource, item, num = 1, removeItem, changeQuantity, setNotification }) => {
    const { product } = item;


    return (
        <WrapListItem>
            {window.screen.availWidth < 601 ?
                null
                :
                <NumContainer>
                    {num}
                </NumContainer>
            }

            <WrapImage>
                <img src={imgSource} alt='Sales Product' />
            </WrapImage>

            <WrapExtraCost>
                <TitleItemContainer>{product.nameModelItem}</TitleItemContainer>
                <ExtraCostPanelContainer>
                    {
                        product.category === 'ΚΑΡΕΚΛΕΣ' ? selectExtraChoise(true, true, true, true, false, item.extraCost) :
                            product.category === 'ΚΑΡΕΚΛΑΚΙΑ' ? selectExtraChoise(true, true, true, true, false, item.extraCost) :
                                product.category === 'ΣΚΑΜΠΟ' ? selectExtraChoise(true, true, true, true, false, item.extraCost) :
                                    product.category === 'ΤΡΑΠΕΖΙΑ' ? selectExtraChoise(true, false, false, false, true, item.extraCost) :
                                        selectExtraChoise(true, false, false, false, false, item.extraCost)
                    }
                </ExtraCostPanelContainer>
            </WrapExtraCost>

            <ItemPriceContainer>
                {(item.extraPrice + product.xondriki.priceItem)}€
            </ItemPriceContainer>

            <QuantityPanel>
                <ButtonQuantity onClick={() => changeNumForItem('minus', item, item.quantity, changeQuantity, setNotification)} >-</ButtonQuantity>
                <QuantityNumberContainer>{item.quantity}</QuantityNumberContainer>
                <ButtonQuantity onClick={() => changeNumForItem('plus', item, item.quantity, changeQuantity)}>+</ButtonQuantity>
            </QuantityPanel>

            <ItemPriceContainer>
                {item.quantity * (item.extraPrice + product.xondriki.priceItem)}€
            </ItemPriceContainer>

            <DeleteContainer onClick={() => removeItem(item)}>{window.screen.availWidth > 600 ? <p>Διαγραφή</p> : <PanelDel>X</PanelDel>}</DeleteContainer>
        </WrapListItem>
    );

};

const changeNumForItem = (mode, item, quantity, changeQuantityfn, setNotification) => {
    const { minSalesProducts } = item.product.xondriki;

    if (mode === 'plus' && minSalesProducts <= quantity) {
        //prepei na pros8esei 1 sto quantity
        item.quantity += 1;

    } else if (mode === 'minus' && minSalesProducts < quantity) {
        //prepei na afairesei 1 sto quantity
        item.quantity -= 1;

    } else {
        //prepei na bazei to elaxisto num gia sale
        item.quantity = quantity
        const notify = setNewNotification('Είναι τα ελάχιστά τεμάχια για παραγγελία.', 'alert', true, 10, 2000, 400);
        setNotification(notify);
    }

    changeQuantityfn(item);
}

const selectExtraChoise = (color = false, loystro = false, syrma = false, seat = false, legs = false, extra) => {

    return (
        <React.Fragment>
            {color &&
                <span>Χρώμα : {
                    extra.color.selectColor !== '' ?
                        extra.color.selectColor.name + ' ' + extra.color.selectColor.code
                        :
                        extra.color.value
                }</span>}
            {loystro &&
                <span>Λούστρο : {
                    extra.loystro.value === 'none' ?
                        'ΟΧΙ' : 'ΝΑΙ'
                }</span>}
            {syrma &&
                <span>Σύρμα : {
                    extra.syrma.value === 'none' ?
                        'ΟΧΙ' : 'ΝΑΙ'
                }</span>}
            {seat &&
                <span>Κάθισμα : {
                    extra.seat.value
                }</span>}
            {legs &&
                <span>Πόδια τορναριστά : {
                    extra.legs.value === 'none' ?
                        'ΟΧΙ' : 'ΝΑΙ'
                }</span>}
        </React.Fragment>
    )
};

const mapDispatchToProps = dispatch => ({
    newNumOfItem: num => dispatch(setAddNewItem(num)),
    removeItem: item => dispatch(removeItem(item)),
    changeQuantity: item => dispatch(changeQuantity(item)),
    setNotification: notify => dispatch(SetNotificationAdd(notify)),
})



export default connect(null, mapDispatchToProps)(ListItem);
