//React
import React from 'react';

//SVG
import { ReactComponent as QuoteLeft } from '../../../assets/icons/quote-left.svg';
// import { ReactComponent as QuoteRight } from '../../../assets/icons/quote-right.svg';

//Components
import { Person } from '../review-person/review-person.component.jsx';

//Style
import './reviewDetails.style.scss';

export const ReviewDetails = ({ name, imageUrl, reviewTxt }) => {

    return (
        <div className='reviewsDetails'>
            <QuoteLeft className='reviewsDetails-quotes' />

            <span className='reviewsDetails-details'>{reviewTxt}</span>

            <Person personName={name}
                imageUrl={imageUrl} />
        </div>
    )
}