//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

// export const product121 = new product();
// export const product122 = new product();
// export const product123 = new product();
// export const product124 = new product();
// export const product125 = new product();
export const product126 = new product();
export const product127 = new product();
// export const product128 = new product();
// export const product129 = new product();
// export const product130 = new product();

//product121----------------------------------------------------------------------------------------------------------------------------
// product121.images.center = stadarURL.trapezia + 'bohthitiko/bohthitiko_1_normal.webp';
// product121.images.others = [stadarURL.trapezia + 'bohthitiko/bohthitiko_1_normal.webp']
// product121.codeModelItem = '100.300.153';
// product121.nameModelItem = 'ΒΟΗΘΗΤΙΚΟ 35Χ35';
// product121.titleItem = 'Τραπέζι Καφενείου';
// product121.category = 'ΤΡΑΠΕΖΙΑ';
// product121.xondriki.minSalesProducts = 2;
// product121.xondriki.priceItem = 30;
// product121.id = '101';

//product122----------------------------------------------------------------------------------------------------------------------------
// product122.images.center = stadarURL.trapezia + 'monh-kolona/monh-kolona_1_normal.webp';
// product122.images.others = [stadarURL.trapezia + 'monh-kolona/monh-kolona_1_normal.webp',
// stadarURL.trapezia + 'monh-kolona/monh-kolona_2_normal.webp',
// stadarURL.trapezia + 'monh-kolona/monh-kolona_3_normal.webp']
// product122.codeModelItem = '100.300.154';
// product122.nameModelItem = 'ΤΡΑΠΕΖΙ 80Χ80 ΜΕ ΜΟΝΗ ΚΟΛΩΝΑ 10Χ10';
// product122.titleItem = 'Τραπέζι Καφενείου';
// product122.category = 'ΤΡΑΠΕΖΙΑ';
// product122.xondriki.minSalesProducts = 2;
// product122.xondriki.priceItem = 90;
// product122.id = '102';

//product123----------------------------------------------------------------------------------------------------------------------------
// product123.images.center = stadarURL.trapezia + 'dyplh-kolona/dyplh-kolona_1_normal.webp';
// product123.images.others = [stadarURL.trapezia + 'dyplh-kolona/dyplh-kolona_1_normal.webp']
// product123.codeModelItem = '100.300.155';
// product123.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΕ ΔΙΠΛΗ ΚΟΛΩΝΑ 120Χ80';
// product123.titleItem = 'Τραπέζι Καφενείου';
// product123.category = 'ΤΡΑΠΕΖΙΑ';
// product123.xondriki.minSalesProducts = 1;
// product123.xondriki.priceItem = 130;
// product123.id = '103';

//product124----------------------------------------------------------------------------------------------------------------------------
// product124.images.center = stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp';
// product124.images.others = [stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp',
// stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp',
// stadarURL.trapezia + 'masif-elato/masif-elato_3_normal.webp']
// product124.codeModelItem = '100.300.156';
// product124.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΑΣΙΦ ΕΛΑΤΟ 70Χ70';
// product124.titleItem = 'Τραπέζι Καφενείου';
// product124.category = 'ΤΡΑΠΕΖΙΑ';
// product124.xondriki.minSalesProducts = 2;
// product124.xondriki.priceItem = 110;
// product124.id = '104';

//product125----------------------------------------------------------------------------------------------------------------------------
// product125.images.center = stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp';
// product125.images.others = [stadarURL.trapezia + 'masif-elato/masif-elato_1_normal.webp',
// stadarURL.trapezia + 'masif-elato/masif-elato_2_normal.webp',
// stadarURL.trapezia + 'masif-elato/masif-elato_3_normal.webp']
// product125.codeModelItem = '100.300.157';
// product125.nameModelItem = 'ΤΡΑΠΕΖΙ ΜΑΣΙΦ ΕΛΑΤΟ 80Χ80';
// product125.titleItem = 'Τραπέζι Καφενείου';
// product125.category = 'ΤΡΑΠΕΖΙΑ';
// product125.xondriki.minSalesProducts = 2;
// product125.xondriki.priceItem = 120;
// product125.id = '105';

//product126----------------------------------------------------------------------------------------------------------------------------
product126.images.center = stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_1_normal.webp';
product126.images.others = [stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_1_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_2_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_3_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_4_normal.webp']
product126.codeModelItem = '100.120.100';
product126.nameModelItem = 'ΚΑΡΕΚΛΑ ΣΚΗΝΟΘΕΤΗ ΚΟΝΤΡΑ ΠΛΑΚΕ';
product126.titleItem = 'ΣΚΗΝΟΘΕΤΗ ΚΟΝΤΡΑ ΠΛΑΚΕ';
product126.category = 'ΣΚΗΝΟΘΕΤΗ';
product126.xondriki.minSalesProducts = 14;
product126.xondriki.priceItem = 60;
product126.id = '126';

//product127----------------------------------------------------------------------------------------------------------------------------
product127.images.center = stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_1_normal.webp';
product127.images.others = [stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_1_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_2_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_3_normal.webp',
stadarURL.skhnotheti + 'skhnotheti-gkri/skhnotheti_gkri_4_normal.webp']
product127.codeModelItem = '100.120.101';
product127.nameModelItem = 'ΚΑΡΕΚΛΑ ΣΚΗΝΟΘΕΤΗ ΟΞΙΑ';
product127.titleItem = 'ΣΚΗΝΟΘΕΤΗ ΟΞΙΑ';
product127.category = 'ΣΚΗΝΟΘΕΤΗ';
product127.xondriki.minSalesProducts = 16;
product127.xondriki.priceItem = 35;
product127.id = '127';

//product128----------------------------------------------------------------------------------------------------------------------------
// product128.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
// product128.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
// stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
// stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
// product128.codeModelItem = '100.300.152';
// product128.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 180Χ80';
// product128.titleItem = 'Τραπέζι Καφενείου';
// product128.category = 'ΤΡΑΠΕΖΙΑ';
// product128.xondriki.minSalesProducts = 2;
// product128.xondriki.priceItem = 180;
// product128.id = '108';

//product129----------------------------------------------------------------------------------------------------------------------------
// product129.images.center = stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp';
// product129.images.others = [stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp',
// stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_2_normal.webp']
// product129.codeModelItem = '100.300.159';
// product129.nameModelItem = 'ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ60Χ54';
// product129.titleItem = 'Τραπέζι Καφενείου';
// product129.category = 'ΤΡΑΠΕΖΙΑ';
// product129.xondriki.minSalesProducts = 2;
// product129.xondriki.priceItem = 110;
// product129.id = '109';

// //product130----------------------------------------------------------------------------------------------------------------------------
// product130.images.center = stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp';
// product130.images.others = [stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_1_normal.webp',
// stadarURL.trapezia + 'trapezi_nypeiagogeioy/nypeiagogioy_2_normal.webp']
// product130.codeModelItem = '100.300.159';
// product130.nameModelItem = 'ΝΗΠΙΑΓΩΓΕΙΟΥ 120Χ60Χ54';
// product130.titleItem = 'Τραπέζι Καφενείου';
// product130.category = 'ΤΡΑΠΕΖΙΑ';
// product130.xondriki.minSalesProducts = 2;
// product130.xondriki.priceItem = 110;
// product130.id = '110';