// Είναι η data για το panel με τα items στην σελίδα Home page

export const panelContainerData = {
    in: true,               //('true' or 'false' for transition Animation)
    allItems: [],           //(All Items)
    showItems: [],          //(Is the Items who render from the Panel)
    panelDisplayItems:9,    //(how many Items can render the Panel)
    active: 0,              //(is for the center item '0'-> First Item)
    direction: '',          //('left' or 'right')
    displayArrows: true,    //('true' or 'false')
    timer: {                ////////////////////// Is for rotate the items
        autoRotate: true,   // ('true' or 'false')
        time: 4000,         // ('1000 = 1min')
        direction: 'right'  // ('left' or 'right')
    }
}

export const defaultStyleItem = {
    default: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '',
        top: '',
        opacity: 0,
        transition: 'left .5s, top .5s, opacity .5s, height .5s, width .5s,transform .5s'
    }
}