//Reselect
import { createSelector } from 'reselect';

const selectState = state => state.cartPage;

export const selectStep = createSelector(
    [selectState],
    cartPage => cartPage.step
);

export const selectUser = createSelector(
    [selectState],
    cartPage => cartPage.user
);

export const selectPriceValues = createSelector(
    [selectState],
    cartPage => cartPage.cart
);