//Types
import { productTypes } from './products.types';

//local data
import { products } from './data/product.data';

const INITIAL_STATE = products;


const productsReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case productTypes.PRODUCTS_HOME_SLIDER:
            return state;

        default:
            return state;
    }
};


export default productsReducer;