//React
import React from 'react';

//components
import FavoriteSVG from './svg/favoriteSVG.component.jsx';

//Style
import './favorite.style.scss';

const FavoriteComponent = ({isSelect}) => {
    return (
        <FavoriteSVG isSelectedSVG={isSelect} />
    );
}

export default FavoriteComponent;