//React
import React from 'react';


//Style
import './circle.style.scss';


export const CircleContact = ({ name, title, goFunction }) => {
    return (
        <div className='circle-img' title={title} onClick={() => goFunction()}>
            <div className='circle-contact' >
                <svg className='circle-contact-img' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox='0 0 24 24'>
                    {name === 'facebook'
                        ? <path d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z" /> : null
                    }
                    {
                        name === 'email'
                            ? < path d="M11.99 0L0 8.723V24h24V8.723L11.99 0zm.001 2.472l9.793 7.113-6.735 4.588L12 11.703l-3.049 2.471-6.737-4.589 9.777-7.113zM2 11.858l5.329 3.63L2 19.806v-7.948zM2.474 22L12 14.277 21.526 22H2.474zM22 19.806l-5.329-4.317L22 11.858v7.948z" /> : null
                    }
                    {
                        name === 'phone'
                            ? <path d="M3.59 1.322L6.434 0l4.041 7.89L7.75 9.231c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396C7.113 27.194-4.127 5.345 3.59 1.322zm1.93 1.274L4.497 3.1c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5C8.656 18.976 3.557 9.21 6.764 7.491l1.007-.497L5.52 2.596zm7.832 7.649l2.917.87a3.03 3.03 0 00-.24-2.317 3.033 3.033 0 00-1.808-1.469l-.869 2.916zm1.804-6.059a6.302 6.302 0 013.756 3.051 6.3 6.3 0 01.498 4.813l-1.795-.535a4.434 4.434 0 00-.352-3.387 4.422 4.422 0 00-2.643-2.146l.536-1.796zM16.106 1a9.61 9.61 0 015.729 4.656A9.61 9.61 0 0122.596 13l-1.84-.548a7.709 7.709 0 00-.608-5.882 7.704 7.704 0 00-4.591-3.729L16.106 1z" /> : null
                    }
                </svg>
            </div>
        </div>
    )
}