//Style
import styled from 'styled-components';

export const BackgroundContainer = styled.div`
position:absolute;
left:0;
top:0;

width:100%;
height:100%;

background-image :url(${props => props.data_src});
background-origin:border-box;
background-position:center;
background-repeat:no-repeat;
background-size:cover;

filter:saturate(0.4);

z-index:-1;
`;