import { store } from '../../../redux/store/store';
//Actions
import { setOrderSettings, setNewDate } from '../../../redux/cartPage/cart.page.actions';
import { setNewOrder } from '../../../redux/order/order.actions';

import { firestore, collectDataFromOrdersSettings } from '../../../firebase/firebase.utils';

//--Πρέπει να κάνει τους (ελέγχους) για να ολοκληρώσει την παραγγελια.
//--Πρέπει να φτιάχνει την παραγγελία και να την στέλνει στον (user) αλλά και στο (κατάστημα).

export const checkUserInports = async (userInputs, cartItems, price, vat) => {

    //Εαν είναι ενεργό το τιμολόγιο πρέπει να ελέγχει και το (ΦΠΑ,ΔΟΥ,ΔΡΑΣΤΗΡΙΟΤΗΤΑ) όπου είναι (required)
    if (userInputs.timologio.value) {
        if (userInputs.afm.value === '' && userInputs.afm.required) throw new Error('Πρέπει να βάλετε το Α.Φ.Μ. σας.');
        if (userInputs.doy.value === '' && userInputs.doy.required) throw new Error('Πρέπει να Βάλετε την Δ.Ο.Υ. σας.');
        if (userInputs.drasthriothta.value === '' && userInputs.drasthriothta.required) throw new Error('Πρέπει να Βάλετε την Δραστηριότητα σας.');
    };

    if (userInputs.name.value === '' && userInputs.name.required) throw new Error('Πρέπει να βάλετε το Όνομα σας.');
    if (userInputs.lastname.value === '' && userInputs.lastname.required) throw new Error('Πρέπει να βάλετε το Επώνυμο σας.');
    if (userInputs.companyName.value === '' && userInputs.companyName.required) throw new Error('Πρέπει να βάλετε το Όνομα της εταιρείας σας.');
    if (userInputs.country.value === '' && userInputs.country.required) throw new Error('Πρέπει να βάλετε την Χώρα σας.');
    if (userInputs.nomos.value === '' && userInputs.nomos.required) throw new Error('Πρέπει να επιλέξετε τον Νομό σας.');
    if (userInputs.town.value === '' && userInputs.town.required) throw new Error('Πρέπει να βάλετε την Πόλη σας.');
    if (userInputs.address.value === '' && userInputs.address.required) throw new Error('Πρέπει να βάλετε την Διεύθυνση σας.');
    if (userInputs.addressNumber.value === '' && userInputs.addressNumber.required) throw new Error('Πρέπει να βάλετε τον Αριθμό της Διευθυνσή σας.');
    if (userInputs.tk.value === '' && userInputs.tk.required) throw new Error('Πρέπει να βάλετε τον Ταχυδρομικό Κωδικό σας.');
    if (userInputs.phone.value === '' && userInputs.phone.required) throw new Error('Πρέπει να βάλετε το τηλέφωνο σας.');
    if (userInputs.email.value === '' && userInputs.email.required) throw new Error('Πρέπει να βάλετε το Email σας.');

    //Εάν περάσει όλους τους ελέγχους πρέπει να πάρει τον αριθμό της παραγγελίας και να την καταχωρηση στην Redux
    const reselt = await takeNumOrder(userInputs, cartItems, price, vat);
    return reselt;
};

async function takeNumOrder(userInputs, cartItems, price, vat) {
    if (typeof window !== 'undefined') {

        try {

            const orderSettingsRef = firestore.collection('order_settings');

            //Πρέπει τραβίξει το snapshot και να το μετατρέψει σε (Data)
            const orderSettings = await orderSettingsRef.get().then(snapshot => {

                const newObjFromSnapshot = collectDataFromOrdersSettings(snapshot);
                return newObjFromSnapshot;
            });

            //Πρεπει να περασει στη Redux το Obj (OrderSettings)
            store.dispatch(setOrderSettings(orderSettings));

            //Πρέπει να φτιάξει το τελικό Obj για την παραγγελία
            const newOrder = createObjOrder(userInputs, cartItems, orderSettings, price, vat);

            //Καταχωρεί την παραγγελία στην Redux.
            store.dispatch(setNewOrder(newOrder));

            return true;

        } catch (error) {

            // console.error('Error message ->', error.message);
            return false;
        }
    }
};

//Πρέπει να φτιαχνει το τελικο Obj
function createObjOrder(user, cart, orderSettings, prices, vat) {
    // User -> 
    const userObj = Object.keys(user).map((item) => {
        return ({ name: item, value: user[item].value })
    })
        .reduce((accumulator, property) => {
            accumulator[property.name] = { value: property.value }

            return accumulator;
        }, {});

    //Φτιάχνει την Ημερομηνια
    const dateSnapshot = new Date();
    const date = {
        day: dateSnapshot.getUTCDate() + '/' + (dateSnapshot.getUTCMonth() + 1) + '/' + dateSnapshot.getUTCFullYear(),
        time: (dateSnapshot.getUTCHours() + 2) + ':' + dateSnapshot.getUTCMinutes() + ':' + dateSnapshot.getUTCSeconds()
    };

    store.dispatch(setNewDate(date));


    //Παίρναει όλες τις τιμές για την παραγγελία
    const order = {
        customer: { ...userObj },
        order: [...cart],
        settings: { ...orderSettings },
        prices: { ...prices },
        vat: { fpa: vat },
        date: { ...date },
    }

    return order;
}