//React
import React, { Component } from 'react';

//Style
import {
    WrapNumSalesContainer,
    TitleContainer,
    PanelNumItemsContainer,
    WrapMinSalesPanel,
    NumMinSalesContainer,
    ItemsTextContainer,

} from './min.sales.items.style.jsx';


class MinSalesItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minSalesProducts: props.minSalesProducts,
        }
    }
    render() {
        return (
            <WrapNumSalesContainer>
                
                <TitleContainer>Ελάχιστη <br /> παραγγελία</TitleContainer>

                <PanelNumItemsContainer>
                    <WrapMinSalesPanel>
                        <NumMinSalesContainer>
                            <span>{this.state.minSalesProducts}</span>
                        </NumMinSalesContainer>
                    </WrapMinSalesPanel>

                    <ItemsTextContainer>τεμάχια</ItemsTextContainer>
                </PanelNumItemsContainer>


            </WrapNumSalesContainer>
        );
    }
}

export default MinSalesItems; 