//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product151 = new product();
export const product152 = new product();
// export const product153 = new product();
// export const product154 = new product();
// export const product155 = new product();
// export const product156 = new product();
// export const product157 = new product();
// export const product158 = new product();
// export const product159 = new product();
// export const product160 = new product();


//product151----------------------------------------------------------------------------------------------------------------------------
product151.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
product151.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
product151.codeModelItem = '100.200.104';
product151.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
product151.titleItem = 'ΣΚΑΜΠΟ';
product151.category = 'ΣΚΑΜΠΟ';
product151.xondriki.minSalesProducts = 3;
product151.xondriki.priceItem = 32;
product151.id = '151';

//product152----------------------------------------------------------------------------------------------------------------------------
product152.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
product152.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
product152.codeModelItem = '100.200.105';
product152.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
product152.titleItem = 'ΣΚΑΜΠΟ';
product152.category = 'ΣΚΑΜΠΟ';
product152.xondriki.minSalesProducts = 3;
product152.xondriki.priceItem = 32;
product152.id = '152';

//product153----------------------------------------------------------------------------------------------------------------------------
// product153.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product153.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product153.codeModelItem = '100.200.105';
// product153.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product153.titleItem = 'ΣΚΑΜΠΟ';
// product153.category = 'ΣΚΑΜΠΟ';
// product153.xondriki.minSalesProducts = 3;
// product153.xondriki.priceItem = 32;
// product153.id = '153';

//product154----------------------------------------------------------------------------------------------------------------------------
// product154.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product154.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product154.codeModelItem = '100.200.105';
// product154.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product154.titleItem = 'ΣΚΑΜΠΟ';
// product154.category = 'ΣΚΑΜΠΟ';
// product154.xondriki.minSalesProducts = 3;
// product154.xondriki.priceItem = 32;
// product154.id = '154';

//product155----------------------------------------------------------------------------------------------------------------------------
// product155.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product155.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product155.codeModelItem = '100.200.105';
// product155.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product155.titleItem = 'ΣΚΑΜΠΟ';
// product155.category = 'ΣΚΑΜΠΟ';
// product155.xondriki.minSalesProducts = 3;
// product155.xondriki.priceItem = 32;
// product155.id = '155';

//product156----------------------------------------------------------------------------------------------------------------------------
// product156.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product156.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product156.codeModelItem = '100.200.105';
// product156.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product156.titleItem = 'ΣΚΑΜΠΟ';
// product156.category = 'ΣΚΑΜΠΟ';
// product156.xondriki.minSalesProducts = 3;
// product156.xondriki.priceItem = 32;
// product156.id = '156';

//product157----------------------------------------------------------------------------------------------------------------------------
// product157.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product157.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product157.codeModelItem = '100.200.105';
// product157.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product157.titleItem = 'ΣΚΑΜΠΟ';
// product157.category = 'ΣΚΑΜΠΟ';
// product157.xondriki.minSalesProducts = 3;
// product157.xondriki.priceItem = 32;
// product157.id = '157';

//product158----------------------------------------------------------------------------------------------------------------------------
// product158.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product158.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product158.codeModelItem = '100.200.105';
// product158.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product158.titleItem = 'ΣΚΑΜΠΟ';
// product158.category = 'ΣΚΑΜΠΟ';
// product158.xondriki.minSalesProducts = 3;
// product158.xondriki.priceItem = 32;
// product158.id = '158';

//product159----------------------------------------------------------------------------------------------------------------------------
// product159.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product159.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product159.codeModelItem = '100.200.105';
// product159.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product159.titleItem = 'ΣΚΑΜΠΟ';
// product159.category = 'ΣΚΑΜΠΟ';
// product159.xondriki.minSalesProducts = 3;
// product159.xondriki.priceItem = 32;
// product159.id = '159';

//product160----------------------------------------------------------------------------------------------------------------------------
// product160.images.center = stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp';
// product160.images.others = [stadarURL.skampo + 'skampo-ermhs/ermhs_1_normal.webp',
// stadarURL.skampo + 'skampo-ermhs/ermhs_2_normal.webp',
// stadarURL.skampo + "skampo-ermhs/ermhs_3_normal.webp",
// stadarURL.skampo + 'skampo-ermhs/ermhs_4_normal.webp']
// product160.codeModelItem = '100.200.105';
// product160.nameModelItem = 'ΣΚΑΜΠΟ ΕΡΜΗΣ';
// product160.titleItem = 'ΣΚΑΜΠΟ';
// product160.category = 'ΣΚΑΜΠΟ';
// product160.xondriki.minSalesProducts = 3;
// product160.xondriki.priceItem = 32;
// product160.id = '160';