//Actions
import { colorPaletteTypes } from './colorPalette.types';


export const setNewSelectColor = (item) => ({
    type: colorPaletteTypes.ADD_NEW_COLOR,
    payload: item
});

export const removeSelectColor = () => ({
    type: colorPaletteTypes.REMOVE_COLOR
});