class slider_pelates {
    id = '';
    displayname = '';
    workshop = {
        workshop_name: '',
        town: '',
        images: {
            centerImage: [],
            smallImages: []
        },
        details: ''
    };
};

// export const pelates_data = {
//     pelatis_view: {
//         id: 'uwefkqweyufewg826352323',
//         displayname: 'Kosmas',
//         workshop: {
//             workshop_name: 'στοα',
//             town: 'Athens',
//             images: {
//                 centerImage: {
//                     images: ['https://www.καρεκλεσ.gr/images/home/pelates/stoa_1.webp']
//                 },
//                 smallImages: {
//                     images: ['https://www.καρεκλεσ.gr/images/home/pelates/stoa_1.webp',
//                         'https://www.καρεκλεσ.gr/images/home/pelates/stoa_2.webp',
//                         'https://www.καρεκλεσ.gr/images/home/pelates/stoa_3.webp',
//                         'https://www.καρεκλεσ.gr/images/home/pelates/stoa_4.webp',
//                         'https://www.καρεκλεσ.gr/images/home/pelates/stoa_1.webp']
//                 }
//             },
//             details: "Phasellus convallis luctus ex, non aliquet nibh aliquam eu. Aliquam blandit justo ut sapien volutpat, sed facilisis nisl tincidunt. Etiam aliquam diam tincidunt molestie ornare. Nunc egestas ipsum eu justo faucibus ullamcorper. Nam maximus facilisis mattis. Sed quis leo hendrerit, condimentum tellus quis, varius velit. Integer dignissim ornare ornare. Sed varius pellentesque risus at lobortis. Aliquam arcu lorem, imperdiet a vestibulum in, malesuada vel tortor."
//         },
//     }
// }

const pelatis_1 = new slider_pelates();
// const pelatis_2 = new slider_pelates();
// const pelatis_3 = new slider_pelates();
// const pelatis_4 = new slider_pelates();
// const pelatis_5 = new slider_pelates();

// 1 - mezedokafeneio toy iordanh
pelatis_1.id = '1';
pelatis_1.workshop.workshop_name = 'Στου ιορδανη';
pelatis_1.workshop.town = 'Περιστέρι';
pelatis_1.workshop.images.centerImage = ['https://res.cloudinary.com/karekles-gr/image/upload/v1593734725/general_images/pelates/iordanhs/iordani-1_obkadt.webp'];
pelatis_1.workshop.images.smallImages = [
    'https://res.cloudinary.com/karekles-gr/image/upload/v1593734727/general_images/pelates/iordanhs/iordani-5_jbsmym.webp',
    'https://res.cloudinary.com/karekles-gr/image/upload/v1593734725/general_images/pelates/iordanhs/iordani-3_gxtwt4.webp',
    'https://res.cloudinary.com/karekles-gr/image/upload/v1593734725/general_images/pelates/iordanhs/iordani-4_g0vycv.webp',
    'https://res.cloudinary.com/karekles-gr/image/upload/v1593734725/general_images/pelates/iordanhs/iordani-1_obkadt.webp',
    'https://res.cloudinary.com/karekles-gr/image/upload/v1593734724/general_images/pelates/iordanhs/iordani-2_xkjcst.webp'
];
pelatis_1.workshop.details = 'Μια παλιά γειτονιά στο κέντρο του περιστερίου. Σκηνικό βγαλμένο από την δεκαετία του 50.';



//Data - pelates data
//-------------------------------------------------------
// 1 - mezedokafeneio toy iordanh
// 2 - fagodeion
// 3 - porfyra
// 4 - mantam pelazi
// 5 - Boy Grill & Cuisine

export const pelates_data = [pelatis_1];