//React
import React from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectSystemPelatesImages } from '../../../redux/system/system.selectors';

//Code
import { setRandomNum } from '../../utils/randomNum/randomNum';

//Style
import { BackgroundContainer } from './pelatesBackground.style.jsx';

import { ColorExtractor } from 'react-color-extractor';


const PelatesBackground = ({ images }) => {

    //erxete gia na dialeksei enan tyxaio ari8mo
    const selectnum = setRandomNum(images.length);


    //epistrefei to img poy dialekse random
    const selectUrlImage = images[selectnum].data_src;

    const selectImg = selectUrlImage// 'https://res.cloudinary.com/karekles-gr/image/upload/v1591012835/general_images/home-slider/trapezaki-min_uqlkme.webp';

    return (
        <React.Fragment>
            <BackgroundContainer  data_src={selectUrlImage} >
                <ColorExtractor rgb src={selectImg} getColors={color => {
                    const changeBackground = document.getElementById('pelates_background');

                    changeBackground.style.backgroundImage = `linear-gradient(to bottom left,rgba(${color[0][0]},${color[0][1]},${color[0][2]}, 0.5),rgb(0,0,0)),url('${selectUrlImage}')`
                }} />

            </BackgroundContainer>
        </React.Fragment>
    )
};

const mapStateToProps = createStructuredSelector({
    images: selectSystemPelatesImages
})

export default connect(mapStateToProps)(PelatesBackground);