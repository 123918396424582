//React
import React from 'react';

//components
import PelatesImage from './pelates-image/pelates-image.component.jsx';
import PelatesBackground from './pelates_background/pelatesBackground.component.jsx';
import PelatesDetails from './pelates_details/pelates_details.component.jsx';

//Style
import { PelatesContainerStyle, PelatesPanelStyle } from './pelates-container.style.jsx';
// import { BackgroundContainer } from './pelates_background/pelatesBackground.style.jsx';
const PelatesContainer = () => {

    //prepei na exei 
    //1 to center image ok
    //2 to mikra 1-5 ok
    //3 to background na allazei analoga ton pelati ok

    //custom timer 


    //gia ton titlo toy component.jsx
    const personal_style = {
        fontSize: window.screen.availWidth > 800 ? '3rem'
            : window.screen.availWidth > 400 ? '2rem' : '1.7rem',
        padding: window.screen.availWidth > 800 ? '1rem 1rem 2rem' : '1rem',
        margin: window.screen.availWidth > 800 ? '0 auto 8rem' :
            window.screen.availWidth > 400 ? '0 auto 5rem' : '0 auto 2rem',
        borderBottom: '2px solid',
        color: 'white',
        textAlign: 'center',
        display: 'block',
        width: '100%',
        letterSpacing: '3px',
        textTransform: 'uppercase'
    };

    return (

        <PelatesPanelStyle id='pelates_background'>

            <h2 style={personal_style} >Πελατες που μας εμπιστευτηκαν</h2>

            <PelatesContainerStyle>

                <PelatesImage />

                <PelatesDetails />

                <PelatesBackground />

            </PelatesContainerStyle>

        </PelatesPanelStyle>
    )
};

// <PelatesBackground />

export default PelatesContainer;
