//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Actions
import { changeFpa } from '../../../redux/cart/cart.actions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectCartFPA } from '../../../redux/cart/cart.selectors';

//Style
import {
    WrapEtraChoiseContainer,
    WrapDivPanel,
    ChoiseContainer,
    ChoiseInputContainer,
    PanelTitleContainer
} from '../../../components/product-details/selectExtraChoise/select.extra.choise.style.jsx';

class SelectFPA extends Component {

    changeFPA = (price) => {
        this.props.changeFpa(price)
    }

    render() {
        const { selectFpa } = this.props;

        return (
            <WrapEtraChoiseContainer style={{ margin: '0 auto 1rem' }}>
                <WrapDivPanel>
                    <PanelTitleContainer>Επιλογή καθεστώτος <strong>Φ.Π.Α.</strong></PanelTitleContainer>
                    <form style={{ display: 'flex' }}>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                id='noneFpa0'
                                name='selectfpa'
                                type="radio"
                                value='1.00'
                                // defaultChecked
                                onChange={(evt) => {
                                    this.changeFPA(Number(evt.currentTarget.value));
                                }}
                                defaultChecked={+selectFpa === 1 ? true : false}
                            />
                            <label htmlFor="noneFpa0">0%</label>
                        </ChoiseContainer>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                id='noneFpa17'
                                name='selectfpa'
                                type="radio"
                                value='1.17'
                                onChange={(evt) => {
                                    this.changeFPA(Number(evt.currentTarget.value));
                                }}
                                defaultChecked={+selectFpa === 1.17 ? true : false}
                            />
                            <label htmlFor="noneFpa17">17%</label>
                        </ChoiseContainer>

                        <ChoiseContainer>
                            <ChoiseInputContainer
                                name='selectfpa'
                                type="radio"
                                id='noneFpa24'
                                value='1.24'
                                onChange={(evt) => {
                                    this.changeFPA(Number(evt.currentTarget.value));
                                }}
                                defaultChecked={+selectFpa === 1.24 ? true : false}
                            />
                            <label htmlFor="noneFpa24">24%</label>
                        </ChoiseContainer>

                    </form>
                </WrapDivPanel>
            </WrapEtraChoiseContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    selectFpa: selectCartFPA,
});

const mapDispatchToProps = dispatch => ({
    changeFpa: fpa => dispatch(changeFpa(fpa)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectFPA);