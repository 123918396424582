import { smallNavBarTypes } from './small.nav.bar.types';

const INITIAL_STATE = {
    select: false
};

const smallNavBarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case smallNavBarTypes.CHANGE_VALUE:
            return { ...state, select: !state.select };

        case smallNavBarTypes.SET_VALUE:
            return { ...state, select: action.payload };

        default:
            return { ...state };
    }
};

export default smallNavBarReducer;