//React
import React from 'react';

//SVG
import { ReactComponent as GoToStartSVG } from '../../../../assets/icons/go_start.svg';
import { ReactComponent as GoToEndSVG } from '../../../../assets/icons/go_end.svg';
import { ReactComponent as NextSVG } from '../../../../assets/icons/next.svg';
import { ReactComponent as PreviousSVG } from '../../../../assets/icons/previous.svg';

//Style
import styled from 'styled-components';


const PageButtonContainer = styled.div`
    min-width: 3.5rem;
    border: 1px solid rgb(168, 168, 168, .5);
    font-weight: 600;
    border-radius: 3px;
    padding: 0.3rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    box-shadow: 0.3rem 0.3rem 0.5rem rgba(105, 105, 105, .5),inset 0.1rem 0.1rem 0.5rem rgba(253, 253, 253,0.8);
    background-color:white;
    color:dimgray;
    margin: .5rem 1rem;

    transition:background-color .3s ease, color .3s ease;


    ${props => props.isSelected && {
        backgroundColor: '#353535',
        color: 'white'
    }};

    /* &:not(:last-child){
        margin-right: 1.5rem
    } */

    &::selection{
        background-color:transparent;
    }

    &:hover{
        background-color:dimgray;
        color:white;
    }

`;

const StyleSVGContainer = styled.svg`
    width: 2.5rem;
    height: 2.5rem;
    fill:currentColor;

    transition: fill .2s ease,width .2s ease,height .2s ease;

    &:hover{
        /* width:2.2rem;
        height:2.2rem; */

        fill:currentColor;
    }
`;

const PageButton = ({ pageNum, btnMode, isSelected, itemsForPage, selectPageNow, goToPage }) => {

    const showSvg = () => {
        if (btnMode) {
            switch (btnMode) {
                case 'gotostart':
                    return (<StyleSVGContainer><GoToStartSVG /></StyleSVGContainer>);
                case 'previous':
                    return (<StyleSVGContainer><PreviousSVG /></StyleSVGContainer>)
                case 'next':
                    return (<StyleSVGContainer><NextSVG /></StyleSVGContainer>)
                case 'gotoend':
                    return (<StyleSVGContainer><GoToEndSVG /></StyleSVGContainer>)
                default:
                    return null
            }
        }
    };

    return pageNum ?
        <PageButtonContainer isSelected={isSelected} itemsForPage={itemsForPage} onClick={() => selectPageNow(pageNum)}>
            {pageNum}
        </PageButtonContainer>
        :
        <PageButtonContainer onClick={() => goToPage(btnMode)}>
            {showSvg()}
        </PageButtonContainer>
};

export default PageButton;