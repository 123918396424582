//React
import React from 'react';
//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectorAddressData } from '../../redux/AddressBar/addressBar.selectors';//'./redux/addressBar.selectors'
//Actions
import { setAddressData } from '../../redux/AddressBar/addressBar.actions';//'./redux/addressBar.actions';
//Router
import { Link, withRouter } from 'react-router-dom';
//SVG
import { ReactComponent as HomeSVG } from '../../assets/svg/home.svg'; //'./images/home.svg';

//Code
import { ROUTER_ADRESS } from '../../pages/site.map.router';
//Style
import './address.style.scss';
// import { AddressContainerStyle, AddressPanelStyle, AddressLiStyle, AddressLinkStyle, HomeSVGStyle } from './address.style';

class AddressBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            match: {
                address: props.addressData.route.address,
                barTiTle: props.addressData.route.barTiTle,
                url: props.addressData.route.url,
                sendUrl: ''
            }
        }
    }

    componentDidMount() {
        this.props.setAddressData({ ...this.state.match });
    }

    //Πρέπει να ελέγχει αν υπάρχει νέα Address
    static getDerivedStateFromProps(props, state) {

        if (props.match.path !== state.match.url) {

            const addressNames = props.match.path.split('/').filter((e) => e !== '');

            const a = ROUTER_ADRESS[addressNames[0].toString()].title; //addressNames.toString(); 

            const arrayName = ROUTER_ADRESS[addressNames[addressNames.length > 1 ? addressNames.length - 2 : addressNames.length - 1]].sendUrl;

            const bartitle = a;//addressNames.toString();

            const sendUrl = arrayName;

            state = { match: { address: addressNames, barTiTle: bartitle, url: props.match.path, sendUrl: sendUrl !== "" ? sendUrl : null } }

            return state;
        } else {

            return null;
        }
    };


    render() {
        const { address } = this.state.match;

        return (
            <div className='address-container'> 
                <ul className='address-container-panel'>

                    <li className='address-container-panel-li'>
                        <Link className='address-container-panel-li-link' to='/'>
                            <HomeSVG className='address-container-panel-li-home'></HomeSVG>
                        </Link>
                    </li>

                    {address.length > 0 && address.map((pathName, index) => {

                        const addressReselt = pathName.startsWith(':');

                        return !addressReselt ? (<li key={index} className='address-container-panel-li'>
                            <Link className='address-container-panel-li-link'
                                to={ROUTER_ADRESS[pathName].sendUrl ? ROUTER_ADRESS[pathName].sendUrl : `/${pathName}`}>
                                {ROUTER_ADRESS[pathName].title}
                            </Link>
                        </li>) :
                            //Edo prepei na baloyme to onoma apo to proion

                            // (<li key={index} className='address-container-panel-li'>
                            //     <Link className='address-container-panel-li-link' to={ROUTER_ADRESS[address[index - 1]].sendUrl}>{ROUTER_ADRESS[address[index - 1]].title}</Link>
                            // </li>)
                            null
                    })}

                </ul>
            </div>
        )
    }
};

//Πρεπει να βλεπει αν υπάρχει send url σε αυτο το route και να το επιστρέφει

const mapStateToProps = createStructuredSelector({
    addressData: selectorAddressData
});
const mapDispatchToProps = dispatch => ({
    setAddressData: itemData => dispatch(setAddressData(itemData))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressBar));