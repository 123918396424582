//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product41 = new product();
export const product42 = new product();
export const product43 = new product();
export const product44 = new product();
export const product45 = new product();
export const product46 = new product();
export const product47 = new product();
export const product48 = new product();
export const product49 = new product();
export const product50 = new product();

//product41----------------------------------------------------------------------------------------------------------------------------
product41.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp';
product41.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product41.codeModelItem = '100.300.101';
product41.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 50X50 ΛΟΥΣΤΡΟ';
product41.titleItem = 'Τραπέζι Καφενείου';
product41.category = 'ΤΡΑΠΕΖΙΑ';
product41.xondriki.minSalesProducts = 3;
product41.xondriki.priceItem = 45;
product41.id = '41';

//product42----------------------------------------------------------------------------------------------------------------------------
product42.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp';
product42.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product42.codeModelItem = '100.300.102';
product42.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 60X60 ΛΟΥΣΤΡΟ';
product42.titleItem = 'Τραπέζι Καφενείου';
product42.category = 'ΤΡΑΠΕΖΙΑ';
product42.xondriki.minSalesProducts = 3;
product42.xondriki.priceItem = 45;
product42.id = '42';

//product43----------------------------------------------------------------------------------------------------------------------------
product43.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp';
product43.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product43.codeModelItem = '100.300.103';
product43.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 70X70 ΛΟΥΣΤΡΟ';
product43.titleItem = 'Τραπέζι Καφενείου';
product43.category = 'ΤΡΑΠΕΖΙΑ';
product43.xondriki.minSalesProducts = 3;
product43.xondriki.priceItem = 50;
product43.id = '43';

//product44----------------------------------------------------------------------------------------------------------------------------
product44.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp';
product44.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product44.codeModelItem = '100.300.104';
product44.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 80X80 ΛΟΥΣΤΡΟ';
product44.titleItem = 'Τραπέζι Καφενείου';
product44.category = 'ΤΡΑΠΕΖΙΑ';
product44.xondriki.minSalesProducts = 3;
product44.xondriki.priceItem = 55;
product44.id = '44';

//product45----------------------------------------------------------------------------------------------------------------------------
product45.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp';
product45.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product45.codeModelItem = '100.300.105';
product45.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 80X90 ΛΟΥΣΤΡΟ';
product45.titleItem = 'Τραπέζι Καφενείου';
product45.category = 'ΤΡΑΠΕΖΙΑ';
product45.xondriki.minSalesProducts = 3;
product45.xondriki.priceItem = 60;
product45.id = '45';

//product46----------------------------------------------------------------------------------------------------------------------------
product46.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp';
product46.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product46.codeModelItem = '100.300.106';
product46.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 90X90 ΛΟΥΣΤΡΟ';
product46.titleItem = 'Τραπέζι Καφενείου';
product46.category = 'ΤΡΑΠΕΖΙΑ';
product46.xondriki.minSalesProducts = 3;
product46.xondriki.priceItem = 65;
product46.id = '46';

//product47----------------------------------------------------------------------------------------------------------------------------
product47.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp';
product47.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product47.codeModelItem = '100.300.107';
product47.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 100X80 ΛΟΥΣΤΡΟ';
product47.titleItem = 'Τραπέζι Καφενείου';
product47.category = 'ΤΡΑΠΕΖΙΑ';
product47.xondriki.minSalesProducts = 3;
product47.xondriki.priceItem = 65;
product47.id = '47';

//product48----------------------------------------------------------------------------------------------------------------------------
product48.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp';
product48.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product48.codeModelItem = '100.300.108';
product48.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 110X80 ΛΟΥΣΤΡΟ';
product48.titleItem = 'Τραπέζι Καφενείου';
product48.category = 'ΤΡΑΠΕΖΙΑ';
product48.xondriki.minSalesProducts = 3;
product48.xondriki.priceItem = 70;
product48.id = '48';

//product49----------------------------------------------------------------------------------------------------------------------------
product49.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp';
product49.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product49.codeModelItem = '100.300.109';
product49.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 120X80 ΛΟΥΣΤΡΟ';
product49.titleItem = 'Τραπέζι Καφενείου';
product49.category = 'ΤΡΑΠΕΖΙΑ';
product49.xondriki.minSalesProducts = 3;
product49.xondriki.priceItem = 70;
product49.id = '49';

//product50----------------------------------------------------------------------------------------------------------------------------
product50.images.center = stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp';
product50.images.others = [stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_1_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_2_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_3_normal.webp',
stadarURL.trapezia + 'mdf-anependito/trapezi-anependito_4_normal.webp']
product50.codeModelItem = '100.300.110';
product50.nameModelItem = 'MDF ΑΝΕΠΕΝΔΥΤΟ 140X80 ΛΟΥΣΤΡΟ';
product50.titleItem = 'Τραπέζι Καφενείου';
product50.category = 'ΤΡΑΠΕΖΙΑ';
product50.xondriki.minSalesProducts = 3;
product50.xondriki.priceItem = 100;
product50.id = '50';