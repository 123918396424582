import React from 'react';

//style for logo component
import './logo.style.scss'


const Logo = () => {
    return (
        <div className='logo'>
            <h2 className='logo-name'>ΖΩΓΡΑΦΟΣ</h2>
            <span className='logo-text'>Καρέκλες - Τραπέζια</span>
        </div>
    )
}


export default Logo;