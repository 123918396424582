import { homeSliderAnimationTypes } from './homeSliderAnimation.types';

//Data
import { homeAnimationData } from './homeSliderAnimation.data';
//Initial state with data
const INITIAL_STATE = homeAnimationData;

const homeSliderAnimationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //For Home Slider -- Start Animation - Change Panel - Stop Animation

        //Change num for Panel show
        case homeSliderAnimationTypes.CHANGE_PANEL_NUMBER:
            return { ...state, numShowPanel: action.payload };


        //Change panel animation
        case homeSliderAnimationTypes.CHANGE_PANEL_ANIMATION:
            return {
                ...state, animationPanel: action.payload
            };

        //For Panel Start -- Animation
        case homeSliderAnimationTypes.START_START_ANIMATION:
            return {
                ...state, start: {
                    ...state.start,
                    animationStart: true,
                    animationEnd: false
                }
            };
        case homeSliderAnimationTypes.START_END_ANIMATION:
            return {
                ...state, start: {
                    ...state.start,
                    animationStart: false,
                    animationEnd: true
                }
            };
        case homeSliderAnimationTypes.CHANGE_START_FIRST_TIME_ANIMATION:
            return {
                ...state, start: {
                    ...state.start,
                    firstTimeAnimation: false
                }
            };

        //For Panel Proionta -- Animation
        case homeSliderAnimationTypes.PROIONTA_START_ANIMATION:
            return {
                ...state, proionta: {
                    ...state.proionta,
                    animationStart: true,
                    animationEnd: false
                }
            };
        case homeSliderAnimationTypes.PROIONTA_END_ANIMATION:
            return {
                ...state, proionta: {
                    ...state.proionta,
                    animationStart: false,
                    animationEnd: true
                }
            };

        case homeSliderAnimationTypes.CHANGE_PROIONTA_FIRST_TIME_ANIMATION:
            return {
                ...state, proionta: {
                    ...state.proionta,
                    firstTimeAnimation: false
                }
            };


        //For Panel Creation -- Animation
        case homeSliderAnimationTypes.CREATION_START_ANIMATION:
            return {
                ...state, creation: {
                    ...state.creation,
                    animationStart: true,
                    animationEnd: false
                }
            };

        case homeSliderAnimationTypes.CREATION_END_ANIMATION:
            return {
                ...state, creation: {
                    ...state.creation,
                    animationStart: false,
                    animationEnd: true
                }
            };

        case homeSliderAnimationTypes.CHANGE_CREATION_FIRST_TIME_ANIMATION:
            return {
                ...state, creation: {
                    ...state.creation,
                    firstTimeAnimation: false
                }
            };

        //Default
        default:
            return state;
    }
};

export default homeSliderAnimationReducer;