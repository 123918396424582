const generalData = {
    // enable:false,
    show: '',
    showNoCost: '',
    value: '',
    price: 0,
}

export const selectExtraChoiseData = {
    product: {},
    extraPrice: 0,
    saleItems: 0,
    color: { ...generalData, value: 'Καρυδί' },
    loystro: { ...generalData, value: 'none' },
    syrma: { ...generalData, value: 'none' },
    seat: { ...generalData, value: 'Ψάθα' },
    legs: { ...generalData, value: 'none' },
}

//Bazoyme tis times san euro xoris symbolo nomismatos 
//Mono noymero
export const productExtraPrices = {
    chair: {
        color: 3,
        loystro: 4,
        syrma: 2,
        seat: 4,
    },
    table: {
        color: 0,
        legs: 20
    },
    skhnotheti: {
        color: 5
    },
    skabo:{
        color: 3,
        loystro: 4,
        syrma: 2,
        seat: 4,
    },
    kareklakia:{
        color: 3,
        loystro: 4,
        syrma: 2,
        seat: 4,
    },
};