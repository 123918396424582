//πρέπει να κρατήσουμε όλο το router σε αυτο το αρχείο για να γίνονται αλλαγές μόνο από εδώ

export const ROUTER_ADRESS = {
    home: { url: '/', title: 'αρχικη' },
    products: { url: '/products', title: 'Προιοντα' },
    product: { url: '/product/:itemID', title: 'Προιοντα', sendUrl: '/products' },
    prosfores: { url: '/offers', title: 'Προσφορες' },
    jobFromUs: { url: '/jobsfromus', title: 'Εργα μας' },
    aboutUs: { url: '/aboutus', title: 'Για εμας' },
    company: { url: '/company', title: 'Εταιρεια ' },
    news: { url: '/news', title: 'Νεα ' },
    contactUs: { url: '/contactus', title: 'Επικοινωνια' },
    connect: { url: '/singin', title: 'Συνδεση' },
    colors: { url: '/colors', title: 'Χροματολώγιο' },
    shopingCart: { url: '/cart', title: 'Καλάθι' },
};