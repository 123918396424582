//React
import React from 'react';

//Style
import '../circleMenu.style.scss';
// import { SvgLogoStyle, SvgPathStyle } from '../circle.menu.style';

const ColorPaleteSVG = () => {

    return (
        <svg className='svg-logo' xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" version="1.1" viewBox="0 0 100 100">
            <path className="svg-fill-none svg-stroke svg-stroke-big" d="M40.43 3.25c-18.44,3.6 -33.3,18.42 -36.94,36.81 -6.86,34.7 24.44,60.56 48.02,56.91 7.65,-1.19 11.39,-10.13 7.89,-17.02 -4.29,-8.42 1.83,-18.26 11.3,-18.26l14.79 0c6.64,0 12.02,-5.49 12.04,-12.11 -0.1,-29.24 -26.7,-52.25 -57.1,-46.33z" />
            <path className="svg-fill-none svg-stroke svg-stroke-medium" d="M73.78 37.94c-3.29,0 -5.94,-2.65 -5.94,-5.93 0,-3.29 2.65,-5.94 5.94,-5.94 3.28,0 5.94,2.65 5.94,5.94 0,3.28 -2.66,5.93 -5.94,5.93z" />
            <path className="svg-fill-none svg-stroke svg-stroke-medium" d="M50.03 26.07c-3.29,0 -5.94,-2.66 -5.94,-5.94 0,-3.28 2.65,-5.94 5.94,-5.94 3.28,0 5.93,2.66 5.93,5.94 0,3.28 -2.65,5.94 -5.93,5.94z" />
            <path className="svg-fill-none svg-stroke svg-stroke-medium" d="M26.28 37.94c-3.29,0 -5.94,-2.65 -5.94,-5.93 0,-3.29 2.65,-5.94 5.94,-5.94 3.28,0 5.93,2.65 5.93,5.94 0,3.28 -2.65,5.93 -5.93,5.93z" />
            <path className="svg-fill-none svg-stroke svg-stroke-medium" d="M20.34 61.69c-3.28,0 -5.94,-2.65 -5.94,-5.93 0,-3.29 2.66,-5.94 5.94,-5.94 3.28,0 5.94,2.65 5.94,5.94 0,3.28 -2.66,5.93 -5.94,5.93z" />
        </svg>
    );
}
export default ColorPaleteSVG;