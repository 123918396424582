//Είναι το στύλ για το Center Image για το PelatesImage
//
//Μεταβλητές που μπορείς να περάσεις είναι :
//1.(width)     -> Βάζει το Μήκος
//2.(height)    -> Βάζει το Ύψος
//3.(left)      -> Βάζει το αριστερά
//4.(top)       -> Βάζει το πάνω
//3.(data_src)  -> Βάζει το img στο background
//4.(border)    -> Βάζει το μέγεθος του border 
//5.(color)     -> Αλλάζει το χρώμα στο border και στα γράμματα

//Style
import styled from 'styled-components';

export const CenterImageContainer = styled.div`

    width:${(props) => props.width + 'px' || '500px'};
    height:${(props) => props.height + 'px' || '500px'};

    left:${(props) => props.left + 'px' || 'auto'};
    top:${(props) => props.top + 'px' || 'auto'};

    background-image: url(${props => props.data_src});
    background-position:bottom;
    background-repeat:no-repeat;
    background-size:cover;

    border: ${(props) => props.border + 'px' || '1px'} solid;
    
    border-radius: 50%;

    color: ${(props) => props.color || 'white'};

    display: flex;
    justify-content: center;
    align-items: center;

    position:relative;

    z-index:1;
    
    transition:filter .4s linear
`;