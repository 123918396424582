//Types
// Types
import { cartActionsTypes } from './cart.types';

// Set New item to cart
export const setAddNewItem = (cart) => ({
    type: cartActionsTypes.ADD_ITEM,
    payload: cart
});

export const removeItem = (item) => ({
    type: cartActionsTypes.REMOVE_ITEM,
    payload: item
})

export const changeQuantity = (item) => ({
    type: cartActionsTypes.CHANGE_QUANTITY,
    payload: item
});

export const changeFpa = (fpa) => ({
    type: cartActionsTypes.SELECT_FPA,
    payload: fpa
});

export const cartRemoveAll = () => ({
    type: cartActionsTypes.REMOVE_ALL
});