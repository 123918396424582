//Style
import styled from 'styled-components';

export const WrapEtraChoiseContainer = styled.div`
    padding: 1rem;
    border: 3px solid rgba(105,105,105,0.5);
    border-radius: 5px;
    /* height: 7vh; */
    /* width: 25vw; */
    background: rgba(105,105,105,.1);
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* margin-bottom:1.5rem; */

    transition: all .3s ease;
`;

export const TitlePanelContainer = styled.span`
    margin-bottom:1.5rem;
    font-size:2rem;
    letter-spacing:3px;
    color:green;
    font-weight:bold;
    padding-bottom:.5rem;
    border-bottom:2px double green;
`;

export const WrapDivPanel = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    &:not(:last-child){
        margin-bottom:1.5rem;
    }
`;

export const PanelTitleContainer = styled.span`
    padding-bottom: 0.5rem;
    border-bottom: 1px solid dimgray;
    margin-bottom: .5rem;
`;

export const FormContainer = styled.form`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
`;

export const ExtraChoiseContainer = styled.div`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

    @media(max-width:500px){
        flex-direction:column;
        align-items:flex-start;
    }
`;


export const ChoiseContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    input,label{
        cursor:pointer;
    }

    @media(min-width:501px){
        &:not(:last-child){
            margin-right:1rem;
        }
    }

    @media(max-width:500px){
        padding:.3rem;
    }



`;
export const ChoiseInputContainer = styled.input`
    margin-right:0.4rem;
`;

export const NoCostContainer = styled.span`
    font-weight: 600;
    display: flex;
    justify-content:center;
    align-items:center;
    width:6rem;
    height:2.5rem;
    margin-right: 1rem;
    white-space:pre-line;
    text-align:center;

    transition:opacity .3s ease;

    font-size:1.5rem;

    ${props => props.showNoCost ?
        {
            color: 'darkgreen'
        }
        :
        props.cost > 0 ?
            {
                color: 'red'
            }
            :
            {
                color: 'darkgreen'
            }
    }
`;
