//Reselect
import { createSelector } from 'reselect';

const selectSystem = state => state.system;

const selectSystemPelatesView = createSelector(
    [selectSystem],
    system => system.pelates_view
);

export const selectSystemPelatesImages = createSelector(
    [selectSystemPelatesView],
    pelates_view => pelates_view.monitor.small_image_dimension.posision
);


export default selectSystemPelatesView;