//Styled
import styled from 'styled-components';

export const PelatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    z-index:1;

    @media(max-width:1100px){
        order:1;
        text-align:center;
    }
`;

export const WorkshopName = styled.h2`
    font-size: 8rem;
    text-align: center;
    color: white;
    text-transform: uppercase;
    letter-spacing:5px;

    @media(max-width:1100px){
        font-size:4rem;
    }
    @media(max-width:800px){
        font-size:3rem;
    }
    @media(max-width:550px){
        font-size:2.5rem;
    }
`;

export const WorkshopDetails = styled.p`
    font-size: 2.5rem;
    color: white;
    letter-spacing: 2px;
    word-spacing: 2px;
    width: 85%;
    margin:4rem auto 0;
    line-height: 2.5;

    @media(max-width:1100px){
        margin:1rem;
        line-height:1.5;
        width:90%;
        letter-spacing:.1;
        word-spacing:.1;
    }
    @media(max-width:900px){
        line-height:1.3;
        font-size:2.2rem
    }
    @media(max-width:900px){
        font-size:1.9rem
    }
    @media(max-width:450px){
        font-size:1.7rem
    }


`;