//React
import React from 'react';

//Style
import './news.page.style.scss';
// import { NewsPanelStyle, NewsTextStyle } from './new.style';

const NewsPage = () => {

    return (
        <div className='news-panel'>
            <h1 className='news-panel-text'>Δεν υπάρχουν νέα αυτή τη στιγμή</h1>
        </div>
    );
}

export default NewsPage;