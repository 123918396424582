//create data for notifycation
// import { setNewNotification } from '../redux/notification/notification.data';

// //make a notifycation
// import { SetNotificationAdd } from '../redux/notification/notification.acions';

// import { store } from '../redux/store/store';

//Create Post to server.
import axios from 'axios'
//Prepei na ftiaksei mia forma gia to email poy einai na steilei 
//------------------------------------------------------------------------------//
// (modeEmail) prepei na epileksoume poia morfh email 8a ftiaksoyme.            //
//------------------------------------------------------------------------------//
// a (@contact) gia epikoinonia.                                                //
// b (@order) gia thn paraggelia.                                               //
//------------------------------------------------------------------------------//
const sendURL = 'https://zwgrafos.gr/sendemail' //'http://localhost:4000/sendemail' //'https://zwgrafos.gr/sendemail';

export const sendEmail = async (event, dataEmail, url = sendURL) => { //"https://xn--mxaiarcg3bu.gr/sendemail" h "http://localhost:3500/sendemail"
    const axiosConfig = { 'Access-Control-Allow-Origin': 'true' };

    //Πρέπει να ακυρώσουμε τις στάνταρ ενέργιες , για να μπορέσουμε να κάνουμε τις δικιές μας.
    if (event !== null) {
        event.preventDefault();
    };
    //Try to Create a Promise
    try {
        //await to promise to fill
        const response = await axios.post(url, dataEmail, axiosConfig);

        console.log('Email Reselt :', response);

        if (response.data !== undefined) {
            const reseltData = response.data;
            return reseltData;
        } else {
            return { status: 'fail' };
        }

    } catch (err) {
        const status = 'fail';
        return status;
    }
}

export const dataEmail = {
    subject: '',
    name: '',
    phone: '',
    message: '',
    modeEmail: '', // 'contact'or 'order'
    fromEmail: '',
    sendEmail: '',
}