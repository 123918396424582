//Style
import styled from 'styled-components';

export const ProductItemContainer = styled.div`
    width:100%;
    height:100%;
    margin-bottom:5rem;
`;

export const NotFindProductContainer = styled.div`
    width:100%;
    min-height:80vh;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 3rem;
    font-weight:bold;
    letter-spacing: 2px;
    color: dimgray;

    @media(max-width:600px){
        font-size:2.5rem;
    }

    @media(max-width:400px){
        font-size:1.8rem;
    }
    
    @media(max-width:280px){
        font-size:1.5rem;
    }
`;

export const ProductContainer = styled.div`
    min-height:70vh;
    display:grid;
    grid-template-columns:35% 1fr;
    position:relative;
    
    
    @media(max-width:1500px){
        grid-template-columns:50% 1fr;
    }

    @media(max-width:1100px){
        grid-template-columns:100%;
    }
`;