// Φτιάχνουμε ότι τύπους ενεργιών έχουμε για το panelContainer

export const panelContainerTypes = {
    ADD_ITEMS: 'ADD_ITEMS',
    SET_SHOW_ITEMS: 'SET_SHOW_ITEMS',
    ADD_ACTIVE: 'ADD_ACTIVE',
    SET_ARROW: 'SET_ARROW',
    SET_PANEL_DISPLAY_ITEMS: 'SET_PANEL_DISPLAY_ITEMS',
    SET_DIRECTION: 'SET_DIRECTION',
    SET_NEW_STYLE:'SET_NEW_STYLE'
};