// export const setUpdate = (oldSate, newState) => {
//     return { ...oldSate, ...newState }
// };

//erxete gia otan ftiaxnei to radius sto Center Image
export const setSystemPelatesMonitorCenterImage = (oldSate, newState)=>{
    return { ...oldSate, ...newState }
};

//erxete otan ftiaxnei to width kai to height sta Small Image
export const setSystemPelatesMonitorSmallImage = (oldSate, newState) => {
    return { ...oldSate, ...newState }
};