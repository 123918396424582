//React
import React from 'react';
//Redux 
import { connect } from 'react-redux';
//Actions
import { setSmallNavBarNewValue } from '../../../../redux/small_nav_bar/small.nav.bar.actions';

//React router
import { Link } from 'react-router-dom';

//SVG
import { ReactComponent as HomeLogo } from '../../../../images/home.svg' // to Logo gia to Home

//Components
import FavoriteComponent from '../../favorite/favorite.component.jsx';



//Style
import './navItem.style.scss';


const NavItem = ({ link, linkTitle, isHome, isFavorite, setValueNavBar, ...otherProps }) => {
    let imageRender = false;
    let imageFile = '';
    // const favoriteListItemsNum = 0;

    if (isFavorite || isHome) {
        imageRender = true;
        if (isHome) {
            imageFile = isHome ? (<HomeLogo className='nav-item-icon' onClick={() => {
                otherProps.history.push(link);
            }} />) : null;
        } else if (isFavorite) {
            imageFile = isFavorite ? (<Link to={link}><FavoriteComponent isSelect /></Link>) : null;
        }
    } else {
        imageRender = false;
    }
    const { title } = otherProps;
    return (
        <li className={`nav-item ${isFavorite ? 'nav-item-favorite' : ''}`}
            title={title} {...otherProps}
            onClick={() => window.screen.availWidth < 900 && setValueNavBar(false)}
        >
            {
                imageRender
                    ? imageFile
                    : <Link to={link} className='nav-item-text'>{linkTitle}</Link>
            }
        </li>
    );
};

const mapDispatchToProps = dispatch => ({
    setValueNavBar: value => dispatch(setSmallNavBarNewValue(value))
});

export default connect(null, mapDispatchToProps)(NavItem);