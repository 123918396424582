import React from 'react'

//SVG
import { ReactComponent as GoogleLogo } from '../../images/google.svg';
import { ReactComponent as Facebook } from '../../images/SVG/facebook.svg';
import { ReactComponent as PencilLogo } from '../../images/SVG/pencil.svg';//Write - singup

//Style for custom button
import './custom-button.style.scss';


const CustomButton = ({ isWrite, isGoogle, isBlack, isFacebook, children, ...otherProps }) => {

    //class for btn
    const styleClass = `custom-button ${isBlack ? 'custom-button-black' : ''} ${isGoogle ? 'custom-button-google' : ''} ${isFacebook ? 'custom-button-facebook' : ''}`

    return (<button
        className={styleClass} {...otherProps}>
        {isGoogle ?
            <GoogleLogo className='google-logo' />
            : null}
        {isFacebook ?
            <Facebook className='facebook-logo' />
            : null}
        {isWrite ?
            <PencilLogo className='new-write'/>
            : null}
        {children}
    </button>)
}

export default CustomButton;