//1--Prepei na emfanizei ta xromata kai na mporei na epileksei o user ayto poy toy kanei 
//2--Na exei mia foto gia na to deixnei sto peripoy.
//3--Kai meta prepei na to metaferei stin paraggelia toy.

//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';

//Actions 
import { removeSelectColor } from '../../redux/colorPalette/colorPalette.actions';
//Selectors
import { selectAllColors, selectCurrentColor } from '../../redux/colorPalette/colorPalette.selector';

//Components
import ColorItem from './colorItem/color.item.component.jsx';

//Notifycation
import { SetNotificationAdd } from '../../redux/notification/notification.acions'

import { setNewNotification } from '../../redux/notification/notification.data'


//Styles
import {
    ColorPaletteContainer,
    PanelImageContainer,

    WrapImageContainerBackground,
    ImagePanelContainer,
    ImageColorDefinitionContainer,
    ImageFrontDetails,

    PanelColorsContainer,
    HeadingContainer,
    ColorPanelContainer,
    PanelColorDetailsContainer,
    ColorDetailsContainer,

    PanelButtonsContainer,
    ButtonContainerSuccess,
    ButtonContainerCancel,

} from './color.palette.style.jsx';

class ColorPalette extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allColors: props.allColors,
            selectColorItem: props.currentColor,
        }
    };

    changeSelectColor = (color) => {
        this.setState({ ...this.state, selectColorItem: color });
    }

    render() {
        const { name, code } = this.props.currentColor;

        return (
            <div style={{ minheight: '90vh' }}>
                <ColorPaletteContainer>

                    <PanelImageContainer>

                        <WrapImageContainerBackground selectbackground={this.state.selectColorItem !== null && this.state.selectColorItem.background}>
                            <ImagePanelContainer
                                selectColor={this.state.selectColorItem !== null && this.state.selectColorItem.color}
                                backgroundimage={this.state.selectColorItem !== null && this.state.selectColorItem.backgroundImage}
                            >
                                <img src="https://res.cloudinary.com/karekles-gr/image/upload/v1602760257/Color_palette/karekla-kafeneioy-zografos-2-seat_rzakzc.webp" alt="Test Color for chair" />
                                <ImageFrontDetails
                                    imageproperty={this.state.selectColorItem !== null && this.state.selectColorItem.imageProperty}
                                    imageopacity={this.state.selectColorItem !== null && this.state.selectColorItem.maskImageOpacity}
                                    backgroundColor={this.state.selectColorItem.backgroundColor !== null && this.state.selectColorItem.backgroundColor}
                                    src="https://res.cloudinary.com/karekles-gr/image/upload/v1602874355/Color_palette/karekla-kafeneioy-zografos-2-front_mm331t.webp"
                                    alt="Test Color for chair" />
                            </ImagePanelContainer>
                        </WrapImageContainerBackground>

                        <ImageColorDefinitionContainer>
                            <span>Το χρώμα στο τελικό προϊόν μπορεί να είναι λίγο διαφορετικό σε απόχρωση από ότι εμφανίζετε στην οθώνη σας.</span>
                            {
                                //<span> Αυτό γιατί τα νερά του ξύλου είναι διαφορετικά, όπως και η ανάλυση της οθώνης μπορεί να είναι διαφωρετική.</span>
                            }
                        </ImageColorDefinitionContainer>

                    </PanelImageContainer>

                    <PanelColorsContainer>
                        <HeadingContainer>Χρωματολογιο</HeadingContainer>

                        <ColorPanelContainer>

                            {this.state.allColors.length &&

                                this.state.allColors.map((colorItem) => <ColorItem
                                    isSelect={this.state.selectColorItem !== null ?
                                        this.state.selectColorItem.id === colorItem.id && true
                                        : false}
                                    key={colorItem.id}
                                    source={colorItem.source}
                                    name={colorItem.name}
                                    code={colorItem.code}
                                    item={colorItem}
                                    changeSelectColor={this.changeSelectColor}
                                />)
                            }

                        </ColorPanelContainer>

                        {this.props.currentColor.id !== "" ?
                            <PanelColorDetailsContainer select={true}>
                                <p>Επιλεγμένο χρώμα</p>
                                <ColorDetailsContainer>
                                    <span>Όνομασία : {name}</span>
                                    <span>Κωδικός : {code}</span>
                                </ColorDetailsContainer>
                            </PanelColorDetailsContainer>
                            :
                            <PanelColorDetailsContainer select={false}>
                                Επιλέξτε κάποιο χρώμα.
                            </PanelColorDetailsContainer>
                        }

                        <PanelButtonsContainer>

                            <ButtonContainerSuccess onClick={() => {

                                this.props.currentColor.id !== '' ?
                                    this.props.history.goBack()
                                    :
                                    this.props.setNotifycationData(setNewNotification('Πρέπει να επιλέξετε χρώμα', 'error', true, 200, 3000))
                            }} >
                                <p>Μεταφορά Χρώματος στην Παραγγελία</p>
                            </ButtonContainerSuccess>

                            <ButtonContainerCancel onClick={() => {
                                //Diagrafh to xroma apo to state
                                this.props.removeColor();
                                //Epistrefh sthn selida apo poy hr8e
                                this.props.history.goBack();
                            }}>
                                <p>Ακυρώση</p>
                            </ButtonContainerCancel>

                        </PanelButtonsContainer>

                    </PanelColorsContainer>

                </ColorPaletteContainer>
            </div>
        );
    }

}

const mapStateToProps = createStructuredSelector({
    allColors: selectAllColors,
    currentColor: selectCurrentColor,

});

const mapDispatchToProps = dispatch => ({
    removeColor: color => dispatch(removeSelectColor(color)),
    setNotifycationData: item => dispatch(SetNotificationAdd(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColorPalette);