import { homeSliderAnimationTypes } from './homeSliderAnimation.types';

//-------------------------Home Slider----------------------------------//
//Set home slider Number
export const setHomeSliderChangeNumber = (item) => ({
    type: homeSliderAnimationTypes.CHANGE_PANEL_NUMBER,
    payload: item
});

//Set home slider Panel Animation
export const setHomeSliderPanelAnimation = (item) => ({
    type: homeSliderAnimationTypes.CHANGE_PANEL_ANIMATION,
    payload: item
});




//For Start Panel
export const sethomeSliderStartStartAnimation = () => ({
    type: homeSliderAnimationTypes.START_START_ANIMATION
});
//For END Animation
export const setHomeSliderStartEndAnimation = () => ({
    type: homeSliderAnimationTypes.START_END_ANIMATION
});
//For First time animation
export const setHomeSliderStartFirstTimeAnimation = () => ({
    type: homeSliderAnimationTypes.CHANGE_START_FIRST_TIME_ANIMATION
});


//For Proionta Panel
export const setHomeSliderProiontaStartAnimation = () => ({
    type: homeSliderAnimationTypes.PROIONTA_START_ANIMATION
});
export const setHomeSliderProiontaEndAnimation = () => ({
    type: homeSliderAnimationTypes.PROIONTA_END_ANIMATION
});
export const setHomeSliderProiontaFirstTimeAnimation = () => ({
    type: homeSliderAnimationTypes.CHANGE_PROIONTA_FIRST_TIME_ANIMATION
});


//For Creation Panel
export const setHomeSliderCreationStartAnimation = () => ({
    type: homeSliderAnimationTypes.CREATION_START_ANIMATION
});
export const setHomeSliderCreationEndAnimation = () => ({
    type: homeSliderAnimationTypes.CREATION_END_ANIMATION
});
export const setHomeSliderCreationFirstTimeAnimation = () => ({
    type: homeSliderAnimationTypes.CHANGE_CREATION_FIRST_TIME_ANIMATION
}); 