//React
import React from 'react';

//Components
import { FollowPanel } from './followUs/followUsPanel/followUsPanel.component.jsx';
import { Time } from './time/time.component.jsx';
//
import FooterCategory from './footerCategory/footerCategory.component.jsx';

//Redux
import { connect } from 'react-redux';

//Import Categories from Firebase to Redux
import { fetchingCategoryStartAsync } from '../../redux/category/category.actions'


//Style
import './footer.style.scss';
// import { Footer, FooterHrStyle, FooterSiteName, FooterFooter, FooterMain, CreateBy } from './footer.style';


class CustomFooter extends React.Component {

    componentDidMount() {
        const { fetchingCategoryStartAsync } = this.props;

        fetchingCategoryStartAsync()
    }

    render() {

        return (
            <div className='footer'>
                <div className='footer-header'>
                    <FooterCategory />
                </div>

                <hr className='footer-hr' />

                <div className='footer-main'>
                    <h1 className='site-name' focus='false'>www.zwgrafos.gr</h1>
                </div>

                <hr className='footer-hr' />

                <div className='footer-footer'>
                    <FollowPanel />
                    <Time />
                </div>

                <div className='create-by'>
                    <h4 className='create-by-txt'>Copyright καρέκλες.gr 2020 &copy; all right reserved -  creation and powered by</h4><h2>  Malatestas Kosmas</h2>
                </div>

            </div>
        )
    }
};

const mapDispatchToProps = dispatch => ({
    fetchingCategoryStartAsync: () => dispatch(fetchingCategoryStartAsync())
});

export default connect(null, mapDispatchToProps)(CustomFooter);