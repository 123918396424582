//Types
import { typesCategory } from './category.types';

import { firestore, collectDataFromSnapshotCategories } from '../../firebase/firebase.utils';


const fetchingCategoryStart = () => ({
    type: typesCategory.FETCH_CATEGORY_START
});

const fetchingCategorySuccess = (categoryMap) => ({
    type: typesCategory.FETCH_CATEGORY_SUCCESS,
    payload: categoryMap
});

const FetchCategoryError = (errorMessage) => ({
    type: typesCategory.FETCH_CATEGORY_ERROR,
    payload: errorMessage
});

export const fetchingCategoryStartAsync = () => {

    return dispatch => {

        const categoriesRef = firestore.collection('categories');
        //Start fetching and send report
        dispatch(fetchingCategoryStart);


        categoriesRef.get().then(snapshot => {
            const categoryMap = collectDataFromSnapshotCategories(snapshot);
            //Update the redux reducer
            dispatch(fetchingCategorySuccess(categoryMap));
        }).catch(error => dispatch(FetchCategoryError(error.message)));
    }
}