import React from 'react';
//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';

//selector
import { selectProductsAll } from '../../redux/product/product.selectors';

import CategoriesOverview from '../../components/category-view/categories.component.jsx';

class TestComponent extends React.Component {
    render() {

        return (
            <div>
                <CategoriesOverview />
            </div>
        )
    }
};


const mapStateToProps = createStructuredSelector({
    showItems: selectProductsAll
});

export default connect(mapStateToProps)(TestComponent);