//Είναι το στύλ για το Center Image για το PelatesImage
//
//Μεταβλητές που μπορείς να περάσεις είναι :
//1.(width)     -> Βάζει το Μήκος
//2.(height)    -> Βάζει το Ύψος
//3.(left)      -> φτιάχνει το αριστερά 
//4.(top)       -> Φτιάχνει το πάνω
//5.(data_src)  -> Βάζει το img στο background
//6.(border)    -> Βάζει το μέγεθος του border 
//7.(color)     -> Αλλάζει το χρώμα στο border και στα γράμματα

//Style
import styled from 'styled-components';

export const SmallImageContainer = styled.div`
    position: absolute;

    width:${(props) => props.width || '100px'};
    height:${(props) => props.height || '100px'};
    
    left:${(props) => props.left || 'auto'};
    top:${(props) => props.top || 'auto'};
    
    background-image: url(${(props) => props.data_src} );
    background-size:cover;
    background-position:bottom;
    background-repeat:no-repeat;
    
    border-radius: 50%;
    
    border: ${(props) => props.border || '1px'} solid;
    
    color: ${(props) => props.color || 'white'};
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index:10;
    cursor:pointer;

    transform:scale(1);
    transition:transform .2s linear , color .2s ease-in;
    

    &:hover{
        color: #995b00;
        transform:scale(1.2);
        z-index:100;
    }
`;