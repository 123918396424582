//React
import React from 'react';

//Style
import { SmallImageContainer } from './small-image.style.jsx';



const SmallImage = ({ handlerOnClick, item, id }) => {
    return (<SmallImageContainer onClick={() => handlerOnClick(item)} {...item} />)
};

export default SmallImage;