//React
import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';
//Action
import { setNotificationRemove } from '../../../redux/notification/notification.acions';

//icon
import { ReactComponent as AlertSVG } from '../../../assets/icons/alert.svg'
import { ReactComponent as ErrorSVG } from '../../../assets/icons/error.svg'
import { ReactComponent as SuccessSVG } from '../../../assets/icons/success.svg'
import { ReactComponent as InformationSVG } from '../../../assets/icons/information.svg'

//Style component
import styled from 'styled-components';


//Style
import './notification.style.scss';

const NotificationContainer = styled.div`
    position: relative;

    right:${props => props.right};

    padding: 1rem;
    
    min-width: 50rem;
    max-width: 60rem;
    min-height: 6rem;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: .5rem;
    ${props => {
        switch (props.mode) {
            case 'info':
                return 'background-color: #20a1c9;'
            case 'alert':
                return 'background-color: #c9af1a;'
            case 'error':
                return 'background-color: #991a1a;'
            case 'success':
                return 'background-color: #5ea957;'
            default:
                return 'background-color:#20a1c9;'
        }
    }}
    
    text-align: center;
    color: white;
    opacity: ${props => props.opacity};
    z-index: 1000;
    transition: opacity .5s ease-in, top .5s ease , right .5s ease-out;

    margin-bottom:0.5rem;

    @media(max-width:600px){
        min-height:5rem;
        max-width:40rem;
        min-width:30rem;
    }

    @media(max-width:450px){
        max-width:30rem;
        min-width:20rem;
    }
`

//Mode : info
//Mode : alert
//Mode : error
//Mode : success
// let TIMER_START, TIMER_WAIT, TIMER_END;

class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            right: '-100%',
            opacity: 0,
            mode: this.props.mode,
            msg: this.props.msg
        }
    }

    componentDidMount() {
        //Time to show the Notification
        setTimeout(() => {
            this.setState({ right: '0%', opacity: 1 }, () => {

                if (this.props.autoExit) {
                    //wait to hide Notification
                    setTimeout(this.onExit, this.props.waitTime ? this.props.waitTime : 4000);
                }
            })
        }, this.props.timeShow ? this.props.timeShow : 500);
    }

    onExit = () => {
        this.setState({
            right: '-100%',
            opacity: 0,
        }, () => {

            //Remove from data
            setTimeout(() => {
                const { removeNotification } = this.props;
                removeNotification(this.props);

                // clearTimeout(TIMER_END);
            }, this.props.endTime ? this.props.endTime : 500);
        });
    }

    findMode = () => {
        switch (this.state.mode) {
            case 'info':
                return <InformationSVG className='notification-icon' />
            case 'alert':
                return <AlertSVG className='notification-icon' />
            case 'error':
                return <ErrorSVG className='notification-icon' />
            case 'success':
                return <SuccessSVG className='notification-icon' />
            default:
                return <InformationSVG className='notification-icon' />
        }
    }

    render() {
        return (
            <NotificationContainer  {...this.state}>
                {this.findMode()}
                <span className='notification-text'>{this.state.msg}</span>
                <div className='notification-exit-panel'>
                    <button className='notification-exit-panel-btn' onClick={this.onExit}>x</button>
                </div>
            </NotificationContainer>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    removeNotification: item => dispatch(setNotificationRemove(item))
});


export default connect(null, mapDispatchToProps)(Notification);