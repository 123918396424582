//React
import React from 'react';


//Style
import './review-person.style.scss';


export const Person = ({ personName, imageUrl }) => {

    return (
        <div className='person'>

            <div className='person-photo'>
                <img
                    src={imageUrl}

                    className='person-photo-img'
                    title={personName}
                    alt={personName} />

            </div>

            <span className='person-name'>{personName}</span>
        </div>
    )
};