//React
import React from 'react';

//react-redux
import { connect } from 'react-redux';

//Action
import { removeItem } from '../../../../redux/cart/cart.actions';

//Styles
import {
    MainContainer,
    ImageContainer,
    HeadingContainer,
    ExtraChoiseContainer,
    FooterContainer,
    ExitContainer,
    HrContainer

} from './small.cart.item.style.jsx';

const SmaillCartItem = ({ item, removeItem }) => {
    const { product, quantity, extraPrice } = item;

    const priceItem = +product.xondriki.priceItem + (+extraPrice ? +extraPrice : 0);

    return (
        <React.Fragment>
            <div style={{ position: 'relative', marginBottom: '1.5rem' }}>

                <MainContainer>
                    <ImageContainer src={product.images.center} alt={product.nameModelItem} />
                    <HeadingContainer>{product.nameModelItem}</HeadingContainer>
                </MainContainer>

                <ExtraChoiseContainer>
                    {item.extraCost.color.selectColor !== '' ?
                        <span>Χρώμα : {item.extraCost.color.selectColor.name + '-' + item.extraCost.color.selectColor.code}</span>
                        :
                        <span>Χρώμα : {item.extraCost.color.value}</span>
                    }

                    {item.extraCost.loystro && <span>Με Λούστρο : {!item.extraCost.loystro.showNoCost ? 'NAI' : 'OXI'}</span>}
                    {item.extraCost.syrma && <span>Με Σύρμα : {!item.extraCost.syrma.showNoCost ? 'NAI' : 'OXI'}</span>}
                    {item.extraCost.seat && <span>Κάθισμα : {item.extraCost.seat.value}</span>}
                    {item.extraCost.legs && <span>Πόδια τορναριστά: {!item.extraCost.legs.showNoCost ? 'NAI' : 'OXI'}</span>}
                </ExtraChoiseContainer>

                <FooterContainer>
                    <div>
                        <span>{quantity} τεμ.</span>
                        <span>X</span>
                        <span>{priceItem}€</span>
                    </div>
                    <span>Σύνολο :{priceItem * quantity}€ + Φ.Π.Α.</span>
                </FooterContainer>

                <ExitContainer onClick={() => removeItem(item)}>
                    <span>Διαγραφή</span>
                </ExitContainer>

            </div>
            <HrContainer />
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    removeItem: item => dispatch(removeItem(item)),
})

export default connect(null, mapDispatchToProps)(SmaillCartItem);