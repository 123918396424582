//React
import React, { Component } from 'react';
//Redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//Actions
import {
    setHomeSliderChangeNumber,
    setHomeSliderPanelAnimation
} from '../../redux/homeSlider/homeSliderAnimation.action';
//selectors
import {
    selectHomeSliderNumber,
    selectHomeSliderPanelChangeTime,
    selectHomeSliderAnimation,
    selectPanelLeftPosition,
    selectPanelAnimation
} from '../../redux/homeSlider/homeSliderAnimation.selectors';
//Components
import HomeSliderStart from '../homeSlider/start/homeSlider.start.component.jsx';
import HomeSliderProoionta from '../homeSlider/prointa/home-slider-proionta.component.jsx';
import HomeSliderCreation from '../homeSlider/creation/homeSlider.creation.component.jsx';
import Arrow from '../arrows/arrow.component.jsx';
import { Dots } from '../dots/dots.component.jsx';
//Style
import './home.slider.style.scss';

class HomeSlider extends Component {

    //erxete gia na kanei to animation anamesa sta panel
    startAnimation() {
        //prepei na elegxei an einai proti fora poy kanei animation sta panel h exei ksanaperasei 
        //ayto prepei na ginete otan paei na emfanisei to ka8e panel
        //an einai proti fora kanei to animation toy panel - allios prepei na blepei to xrono poy toy exoyme dosei

        const {
            //Variables
            homeSliderNumber,
            homeSliders,
            //Actions
            homeSliderChangePanelAnimation
        } = this.props;

        //pernaei se enan array ta onomata apo ta sliders
        const slidersNames = ['start', 'proionta', 'creation'];

        //paei na ftiaksei to z-index sta panel
        this.makePanelZIndex();

        //prepei na dei poio noumero panel 8a emfanistei
        //prepei na eleksei an exei kanei to animation

        if (homeSliders[slidersNames[homeSliderNumber - 1]].firstTimeAnimation) {
            //erxete an DEN exei kanei ANIMATION

            //prepei na balei timh gia na ksekinhsei to animation sto panel kai na enhmeronei to state
            switch (homeSliderNumber) {
                case 1:
                    this.promiseStartPanelAnimation();
                    break;
                case 2:
                    this.promiseProiontaPanelAnimation();
                    break;
                case 3:
                    this.promiseCreationPanelAnimation();
                    break;

                default:
                    break;
            };

        } else {
            //erxete an EXEI kanei ANIMATION
            switch (homeSliderNumber) {
                case 1:
                    //prepei na ksekinaei to animation gia to Panel Start
                    homeSliderChangePanelAnimation([true, false, false]);
                    break;
                case 2:
                    //prepei na ksekinaei to animation gia to Panel Start
                    homeSliderChangePanelAnimation([false, true, false]);
                    break;
                case 3:
                    //prepei na ksekinaei to animation gia to Panel Start
                    homeSliderChangePanelAnimation([false, false, true]);
                    break;
                default:
            }
        }
    }

    //Gia na mporei na dei pote teleiose to animation gia to Panel Start
    async promiseStartPanelAnimation() {
        //functions , Variables

        const startPromise = new Promise((resolve) => {
            //Variables
            const { homeSliderChangePanelAnimation } = this.props;

            //prepei na ksekinaei to animation gia to Panel Start
            homeSliderChangePanelAnimation([true, false, false]);

            const intervalStartAnimation = setInterval(() => {
                const { homeSliders } = this.props;


                //elegxei an exei teleiosei to animation
                if (!homeSliders.start.firstTimeAnimation) {

                    clearInterval(intervalStartAnimation);

                    resolve(true);

                };
            }, 200);

        });

        await startPromise;
    }
    //Gia na mporei na dei pote teleiose to animation gia to Panel Proionta
    async promiseProiontaPanelAnimation() {
        //functions , Variables
        // const { homeSliderChangeNumber, homeSliderNumber } = this.props;

        const proiontaPromise = new Promise((resolve) => {
            //Variables
            const { homeSliderChangePanelAnimation } = this.props;

            //prepei na ksekinaei to animation gia to Panel Start
            homeSliderChangePanelAnimation([false, true, false]);

            const intervalProiontaAnimation = setInterval(() => {
                const { homeSliders } = this.props;

                //elegxei an exei teleiosei to animation
                if (!homeSliders.proionta.firstTimeAnimation) {

                    clearInterval(intervalProiontaAnimation);

                    resolve(true);
                };
            }, 200);
        });

        await proiontaPromise;
    }
    //Gia na mporei na dei pote teleiose to animation gia to Panel Creation
    async promiseCreationPanelAnimation() {
        //functions , Variables
        // const { homeSliderChangeNumber, homeSliderNumber } = this.props;

        const creationPromise = new Promise((resolve) => {
            //Variables
            const { homeSliderChangePanelAnimation } = this.props;

            //prepei na ksekinaei to animation gia to Panel Start
            homeSliderChangePanelAnimation([false, false, true]);

            const intervalCreationAnimation = setInterval(() => {
                const { homeSliders } = this.props;

                //elegxei an exei teleiosei to animation
                if (!homeSliders.creation.firstTimeAnimation) {

                    clearInterval(intervalCreationAnimation);

                    resolve(true);
                };
            }, 200);
        });

        await creationPromise;
    }


    //Prepei na elegxei ta noymera kai epistrefei 
    nextNumber(num, numEnd) {
        if (num < numEnd) {
            return (num = num + 1);
        } else {
            return (num = 1);
        }
    };

    prevNumber(num, numEnd) {
        if (num > 1 && num <= numEnd) {
            return (num = num - 1);
        } else {
            return (num = numEnd);
        }
    };


    //Einai routina poy prepei na xtypiete ana sygkekrimeno xrono poy exoyme dylosei kai paei sto NEXT Panel
    nextPanel = (timeForHit = this.props.homeSliderPanelChangeTime) => {
        setTimeout(() => {
            const { homeSliderChangeNumber, homeSliderNumber } = this.props;

            homeSliderChangeNumber(this.nextNumber(homeSliderNumber, 3));

            this.startAnimation();
        }, timeForHit);
    };
    //Einai routina poy prepei na xtypiete ana sygkekrimeno xrono poy exoyme dylosei kai paei sto PREVIEW Panel
    prevPanel = (timeForHit = this.props.homeSliderPanelChangeTime) => {
        setTimeout(() => {
            const { homeSliderChangeNumber, homeSliderNumber } = this.props;

            homeSliderChangeNumber(this.prevNumber(homeSliderNumber, 3))
        }, timeForHit);
    }

    componentDidMount() {
        if (this.props.play) {
            this.startAnimation();
        }
    }

    componentWillUnmount() {
        this.clearAllTimeouts();
    }

    //erxete gia na ftiaksei to z-index gia to element
    makePanelZIndex(num) {
        //prepei na ftiaksei to zindex gia to panels
        switch (num) {
            case 1:
                return [30, 1, 20]
            case 2:
                return [20, 30, 1]
            case 3:
                return [1, 20, 30]
            default:
                return [30, 20, 1]
        };
    }

    //prepei na akyronei ola ta SetTimeOut poy exoyme balei
    clearAllTimeouts = () => {
        var highestTimeoutId = setTimeout(() => ";", 100);
        for (var i = 0; i < highestTimeoutId; i++) {
            clearTimeout(i);
        }
    }

    goToPanel = (num) => {
        const { homeSliderChangeNumber, homeSliderChangePanelAnimation } = this.props;

        //Prepei na mhdenizei ta timers
        this.clearAllTimeouts();

        //kai na phgainei sto panel poy 8eloyme
        homeSliderChangeNumber(num);

        switch (num) {
            case 1:
                //prepei na ksekinaei to animation gia to Panel Start
                homeSliderChangePanelAnimation([true, false, false]);
                break;
            case 2:
                //prepei na ksekinaei to animation gia to Panel proionta
                homeSliderChangePanelAnimation([false, true, false]);
                break;
            case 3:
                //prepei na ksekinaei to animation gia to Panel creation
                homeSliderChangePanelAnimation([false, false, true]);
                break;
            default:
                break;
        }
    };

    goToNextPanel = () => {
        //prepei na akyrvnei ola ta time out prota kai meta na phgainei sto epomeno panel
        this.clearAllTimeouts();
        const { homeSliderChangeNumber, homeSliderNumber, homeSliderChangePanelAnimation } = this.props;

        const nextPanelToShow = this.nextNumber(homeSliderNumber, 3);

        homeSliderChangeNumber(nextPanelToShow);

        switch (nextPanelToShow) {
            case 1:
                //prepei na ksekinaei to animation gia to Panel Start
                homeSliderChangePanelAnimation([true, false, false]);
                break;
            case 2:
                //prepei na ksekinaei to animation gia to Panel proionta
                homeSliderChangePanelAnimation([false, true, false]);
                break;
            case 3:
                //prepei na ksekinaei to animation gia to Panel creation
                homeSliderChangePanelAnimation([false, false, true]);
                break;
            default:
                break;
        }
    }

    gotoPreviousPavel = () => {
        //prepei na akyrvnei ola ta time out prota kai meta na phgainei sto epomeno panel
        this.clearAllTimeouts();
        const { homeSliderChangeNumber, homeSliderNumber, homeSliderChangePanelAnimation } = this.props;

        const nextPanelToShow = this.prevNumber(homeSliderNumber, 3);

        homeSliderChangeNumber(nextPanelToShow);

        switch (nextPanelToShow) {
            case 1:
                //prepei na ksekinaei to animation gia to Panel Start
                homeSliderChangePanelAnimation([true, false, false]);
                break;
            case 2:
                //prepei na ksekinaei to animation gia to Panel proionta
                homeSliderChangePanelAnimation([false, true, false]);
                break;
            case 3:
                //prepei na ksekinaei to animation gia to Panel creation
                homeSliderChangePanelAnimation([false, false, true]);
                break;
            default:
                break;
        }
    }

    render() {
        const { homeSliderNumber, PanelLeftPosition, panelAnimation } = this.props;

        const selectPanelDetails = PanelLeftPosition[homeSliderNumber - 1];

        const zIndexArray = this.makePanelZIndex(selectPanelDetails.left, homeSliderNumber);

        const sliders = [...document.getElementsByClassName('slider')];


        return (
            <div className='home-slider-control' >
                <div className='leftArrow'>
                    <Arrow side='left' enable={'true'} onClickFun={this.gotoPreviousPavel} />
                </div>

                <div className='slider' style={{
                    left: selectPanelDetails.left[0],
                    zIndex: zIndexArray[0]
                }}>
                    <HomeSliderStart play={panelAnimation[0]} nextPanel={this.nextPanel} />
                </div>

                <div className='slider' style={{
                    left: selectPanelDetails.left[1],
                    zIndex: zIndexArray[1]
                }}>
                    <HomeSliderProoionta play={panelAnimation[1]} nextPanel={this.nextPanel} />
                </div>

                <div className='slider' style={{
                    left: selectPanelDetails.left[2],
                    zIndex: zIndexArray[2]
                }}>
                    <HomeSliderCreation play={panelAnimation[2]} nextPanel={this.nextPanel} />
                </div>

                <div className='rightArrow'>
                    <Arrow side='right' enable={'true'} onClickFun={this.goToNextPanel} />
                </div>

                <div className='panelDots'>
                    {
                        sliders.map((ele, index) => {

                            return (<Dots key={'numOfSlider' + index} onClickFun={this.goToPanel} num={index + 1} />)
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    homeSliders: selectHomeSliderAnimation,
    homeSliderNumber: selectHomeSliderNumber,
    homeSliderPanelChangeTime: selectHomeSliderPanelChangeTime,
    PanelLeftPosition: selectPanelLeftPosition,
    panelAnimation: selectPanelAnimation
});


const mapDispatchToProps = (dispatch) => ({
    homeSliderChangeNumber: (item) => dispatch(setHomeSliderChangeNumber(item)),
    homeSliderChangePanelAnimation: (item) => dispatch(setHomeSliderPanelAnimation(item))
});


export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);

