//React
import React from 'react';

//Style
import styled from 'styled-components';

const BarTitleContainer = styled.div`
    grid-row: 1/2;
    grid-column: 1/-1;

    font-size: 2.2rem;
    color: dimgray;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;

    @media(max-width:900px){
        font-size:1.8rem;
    }
    @media(max-width:400px){
        display:none;
    }
`;

const BarTitle = ({ barTitle }) => {

    return (
        <BarTitleContainer>
            <h2 style={{ marginBottom: '2rem' }}>{barTitle}</h2>
        </BarTitleContainer>
    )
};

export default BarTitle;