//Style
import styled from 'styled-components';


//Wrap Title & Code
export const WrapTitleContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    padding:0 0 1rem;
    border-bottom:1px solid dimgray;
    margin-bottom:1.5rem;

    @media(max-width:500px){
        flex-direction:column;
    }
`;
//Title name Product
export const TitleContainer = styled.h1`
    font-size: 3rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #444;
    /* padding-bottom: 1rem;
    border-bottom: 1px solid dimgray; */
    text-align:center;
`;
//Code 
export const CodeContainer = styled.div`
    font-size:1.8rem;
    color:dimgray;
    letter-spacing:2px;
    display:flex;
    align-items:center;
    margin-left:4rem;

    @media(max-width:500px){
        margin-left:0;
    }
`;