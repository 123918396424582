//Styled
import styled from 'styled-components'

export const PelatesContainerStyle = styled.div`
    display: grid;
    grid-template-columns: max-content minmax(250px,1fr);
    justify-content: center;
    align-items: center;

    @media(max-width:1100px){
        grid-template-columns: 100%;
        grid-template-rows: min-content;
        justify-items:center;
    }
`;

export const PelatesPanelStyle = styled.div`
    padding: 2rem 5rem 12rem;
    position: relative;
    
    background-origin:border-box;
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;

    filter:revert; //saturate(0.4);

    @media(max-width:600px){
        padding: 2rem 5rem 8rem;
    }
    @media(max-width:450px){
        padding: 2rem 2rem 8rem;
    }
`;