//Edo prepei na kanei thn paraggelia
export const paraggelia = (state) => {
    //1 Elegxos an yparxei to fpa
    // if (!state.selectFPA) throw new Error('Σφάλμα : Πρέπει να επιλέξετε καθεστώς για το Φ.Π.Α. που υπάγεστε...');


    //1 Prepei na eleksoyme an to xroma einai apo to xromatologio kai an yparxei xroma epilegmeno.
    if (state.extra.color.value === 'Χρωματολόγιο' && state.extra.color.selectColor === '')
        throw new Error('Πρέπει να έχετε επιλέξει κάποιο χρώμα, μέσα από το χρωματολόγιο. Διαφορετικά επιλέξτε άλλη επιλογή.');

    //2 Prepei na ftiaksoyme ena object gia na to steiloyme sto kala8i
    const newExtra = state.product.category === 'ΤΡΑΠΕΖΙΑ'
        ? {
            color: { ...state.extra.color },
            legs: { ...state.extra.legs }
        }
        : state.product.category === 'ΚΑΡΕΚΛΕΣ'
            ? {
                color: { ...state.extra.color },
                syrma: { ...state.extra.syrma },
                loystro: { ...state.extra.loystro },
                seat: { ...state.extra.seat }
            }
            :
            state.product.category === 'ΣΚΑΜΠΟ' ? {
                color: { ...state.extra.color },
                syrma: { ...state.extra.syrma },
                loystro: { ...state.extra.loystro },
                seat: { ...state.extra.seat }
            }
                :
                state.product.category === 'ΚΑΡΕΚΛΑΚΙΑ' ?
                    {
                        color: { ...state.extra.color },
                        syrma: { ...state.extra.syrma },
                        loystro: { ...state.extra.loystro },
                        seat: { ...state.extra.seat }
                    }
                    :
                    { color: { ...state.extra.color } };


    const newItemForSale = {
        product: state.product, //To item poy kanoyme agora
        // vat: state.fpa,//O FPA gia to proion
        quantity: state.salesProducts,//Ta Kommatia poy poylame
        extraCost: newExtra, //Extra xreosis an yparxoyn
        extraPrice: state.extraPrice,
    };

    return newItemForSale;
};