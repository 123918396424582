//reselect
import { createSelector } from 'reselect';


export const selectHomeSliderAnimation = state => state.homeSliderAnimation;

export const selectorStartPanelAnimation = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.start
);

export const selectorProiontaPanelAnimation = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.proionta
);

export const selectorCreationPanelAnimation = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.creation
);

//-----------------------Home slider selectors-----------------------------//

//Panel number show
export const selectHomeSliderNumber = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.numShowPanel
);
//Panel change time
export const selectHomeSliderPanelChangeTime = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.panelChangeTime
);

//Panel Left Position
export const selectPanelLeftPosition = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.sliderPositionLeftAnimation
);

//Panel Animation
export const selectPanelAnimation = createSelector(
    [selectHomeSliderAnimation],
    homeSliderAnimation => homeSliderAnimation.animationPanel
);