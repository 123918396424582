//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

// export const product161 = new product();
// export const product162 = new product();
export const product163 = new product();
export const product164 = new product();
export const product165 = new product();
// export const product166 = new product();
// export const product167 = new product();
// export const product168 = new product();
// export const product169 = new product();
// export const product170 = new product();

//product161----------------------------------------------------------------------------------------------------------------------------
// product161.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product161.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product161.codeModelItem = '100.200.104';
// product161.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product161.titleItem = 'ΣΚΑΜΠΟ';
// product161.category = 'ΣΚΑΜΠΟ';
// product161.xondriki.minSalesProducts = 3;
// product161.xondriki.priceItem = 32;
// product161.id = '161';

//product162----------------------------------------------------------------------------------------------------------------------------
// product162.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product162.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product162.codeModelItem = '100.200.104';
// product162.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product162.titleItem = 'ΣΚΑΜΠΟ';
// product162.category = 'ΣΚΑΜΠΟ';
// product162.xondriki.minSalesProducts = 3;
// product162.xondriki.priceItem = 32;
// product162.id = '162';

//product163----------------------------------------------------------------------------------------------------------------------------
product163.images.center = stadarURL.kareklakia + 'kareklaki-psatha/kareklaki_1_normal.webp';
product163.images.others = [stadarURL.kareklakia + 'kareklaki-psatha/kareklaki_1_normal.webp',
stadarURL.kareklakia + 'kareklaki-psatha/kareklaki_2_normal.webp',
stadarURL.kareklakia + "kareklaki-psatha/kareklaki_3_normal.webp",
stadarURL.kareklakia + 'kareklaki-psatha/kareklaki_4_normal.webp']
product163.codeModelItem = '100.140.100';
product163.nameModelItem = 'ΚΑΡΕΚΛΑΚΙ ΨΑΘΑ';
product163.titleItem = 'ΚΑΡΕΚΛΑΚΙΑ';
product163.category = 'ΚΑΡΕΚΛΑΚΙΑ';
product163.xondriki.minSalesProducts = 10;
product163.xondriki.priceItem = 15;
product163.id = '163';

//product164----------------------------------------------------------------------------------------------------------------------------
product164.images.center = stadarURL.kareklakia + 'nypeiagogeioy/nypeiagogeioy_1_normal.webp';
product164.images.others = [stadarURL.kareklakia + 'nypeiagogeioy/nypeiagogeioy_1_normal.webp',
stadarURL.kareklakia + 'nypeiagogeioy/nypeiagogeioy_2_normal.webp',
stadarURL.kareklakia + "nypeiagogeioy/nypeiagogeioy_3_normal.webp",
stadarURL.kareklakia + 'nypeiagogeioy/nypeiagogeioy_4_normal.webp']
product164.codeModelItem = '100.140.101';
product164.nameModelItem = 'ΚΑΡΕΚΛΑΚΙ ΝΗΠΙΑΓΩΓΕΙΟΥ ';
product164.titleItem = 'ΚΑΡΕΚΛΑΚΙΑ';
product164.category = 'ΚΑΡΕΚΛΑΚΙΑ';
product164.xondriki.minSalesProducts = 10;
product164.xondriki.priceItem = 22;
product164.id = '164';

//product165----------------------------------------------------------------------------------------------------------------------------
product165.images.center = stadarURL.set + 'set-nypeiagogioy/setNypeiagogioy_1_normal.webp';
product165.images.others = [stadarURL.set + 'set-nypeiagogioy/setNypeiagogioy_1_normal.webp',
stadarURL.set + 'set-nypeiagogioy/setNypeiagogioy_2_normal.webp',
stadarURL.set + 'set-nypeiagogioy/setNypeiagogioy_3_normal.webp',
stadarURL.set + 'set-nypeiagogioy/setNypeiagogioy_4_normal.webp']
product165.codeModelItem = '100.450.102';
product165.nameModelItem = 'ΣΕΤ ΝΥΠΕΙΑΓΩΓΙΟΥ';
product165.titleItem = 'ΣΕΤ';
product165.category = 'ΣΕΤ';
product165.xondriki.minSalesProducts = 1;
product165.xondriki.priceItem = 230;
product165.id = '165';


//product166----------------------------------------------------------------------------------------------------------------------------
// product166.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product166.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product166.codeModelItem = '100.200.104';
// product166.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product166.titleItem = 'ΣΚΑΜΠΟ';
// product166.category = 'ΣΚΑΜΠΟ';
// product166.xondriki.minSalesProducts = 3;
// product166.xondriki.priceItem = 32;
// product166.id = '166';

//product167----------------------------------------------------------------------------------------------------------------------------
// product167.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product167.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product167.codeModelItem = '100.200.104';
// product167.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product167.titleItem = 'ΣΚΑΜΠΟ';
// product167.category = 'ΣΚΑΜΠΟ';
// product167.xondriki.minSalesProducts = 3;
// product167.xondriki.priceItem = 32;
// product167.id = '167';

//product168----------------------------------------------------------------------------------------------------------------------------
// product168.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product168.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product168.codeModelItem = '100.200.104';
// product168.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product168.titleItem = 'ΣΚΑΜΠΟ';
// product168.category = 'ΣΚΑΜΠΟ';
// product168.xondriki.minSalesProducts = 3;
// product168.xondriki.priceItem = 32;
// product168.id = '168';

//product169----------------------------------------------------------------------------------------------------------------------------
// product169.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product169.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product169.codeModelItem = '100.200.104';
// product169.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product169.titleItem = 'ΣΚΑΜΠΟ';
// product169.category = 'ΣΚΑΜΠΟ';
// product169.xondriki.minSalesProducts = 3;
// product169.xondriki.priceItem = 32;
// product169.id = '169';

//product170----------------------------------------------------------------------------------------------------------------------------
// product170.images.center = stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp';
// product170.images.others = [stadarURL.skampo + 'skabo-karfoto/karfoto_1_normal.webp',
// stadarURL.skampo + 'skabo-karfoto/karfoto_2_normal.webp',
// stadarURL.skampo + "skabo-karfoto/karfoto_3_normal.webp",
// stadarURL.skampo + 'skabo-karfoto/karfoto_4_normal.webp']
// product170.codeModelItem = '100.200.104';
// product170.nameModelItem = 'ΣΚΑΜΠΟ ΚΑΡΦΩΤΟ';
// product170.titleItem = 'ΣΚΑΜΠΟ';
// product170.category = 'ΣΚΑΜΠΟ';
// product170.xondriki.minSalesProducts = 3;
// product170.xondriki.priceItem = 32;
// product170.id = '170';