//Reselect
import { createSelector } from 'reselect';

//prepei na epistrefei ta products akeraia
const selectProducts = state => state.products;

export const selectProductsHomeSlider = createSelector(
    [selectProducts],
    //8a prepei na dialegei ta items poy 8a emfanisei sto panel
    products => {
        let startNumber = 0;
        const endNumber = 10;
        const numOfItems = products.length;
        const setItems = [];
        let numberNow;

        for (startNumber; startNumber < endNumber; startNumber++) {
            numberNow = Math.floor(Math.random() * (numOfItems + 1))
            setItems.push(products[numberNow]);
        }

        return setItems;
    }
    //products.filter((items, idx) => idx < 10).map(item => item)


);

export const selectProductsAll = createSelector(
    [selectProducts],
    products => products
);
