//Εισαγωγή του αντικειμένου product για τα δεδωμένα και οι stadarURL
import { product, stadarURL } from "../classProduct";

export const product81 = new product();
export const product82 = new product();
export const product83 = new product();
export const product84 = new product();
export const product85 = new product();
export const product86 = new product();
export const product87 = new product();
export const product88 = new product();
export const product89 = new product();
export const product90 = new product();

//product81----------------------------------------------------------------------------------------------------------------------------
product81.images.center = stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp';
product81.images.others = [stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_1_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_2_normal.webp',
stadarURL.trapezia + 'bash-trapezioy/bash-trapezioy_3_normal.webp']
product81.codeModelItem = '100.300.141';
product81.nameModelItem = 'ΒΑΣΗ 80Χ80';
product81.titleItem = 'Ανταλακτικά Τραπεζιού';
product81.category = 'ΑΝΤΑΛΛΑΚΤΙΚΑ';
product81.xondriki.minSalesProducts = 5;
product81.xondriki.priceItem = 40;
product81.id = '81';

//product82----------------------------------------------------------------------------------------------------------------------------
product82.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp';
product82.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product82.codeModelItem = '100.300.142';
product82.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 50Χ50';
product82.titleItem = 'Τραπέζι Καφενείου';
product82.category = 'ΤΡΑΠΕΖΙΑ';
product82.xondriki.minSalesProducts = 3;
product82.xondriki.priceItem = 65;
product82.id = '82';

//product83----------------------------------------------------------------------------------------------------------------------------
product83.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp';
product83.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product83.codeModelItem = '100.300.143';
product83.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 60Χ60';
product83.titleItem = 'Τραπέζι Καφενείου';
product83.category = 'ΤΡΑΠΕΖΙΑ';
product83.xondriki.minSalesProducts = 3;
product83.xondriki.priceItem = 65;
product83.id = '83';

//product84----------------------------------------------------------------------------------------------------------------------------
product84.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp';
product84.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product84.codeModelItem = '100.300.144';
product84.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 70Χ70';
product84.titleItem = 'Τραπέζι Καφενείου';
product84.category = 'ΤΡΑΠΕΖΙΑ';
product84.xondriki.minSalesProducts = 3;
product84.xondriki.priceItem = 75;
product84.id = '84';

//product85----------------------------------------------------------------------------------------------------------------------------
product85.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp';
product85.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product85.codeModelItem = '100.300.145';
product85.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 80Χ80';
product85.titleItem = 'Τραπέζι Καφενείου';
product85.category = 'ΤΡΑΠΕΖΙΑ';
product85.xondriki.minSalesProducts = 3;
product85.xondriki.priceItem = 85;
product85.id = '85';

//product86----------------------------------------------------------------------------------------------------------------------------
product86.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp';
product86.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product86.codeModelItem = '100.300.146';
product86.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 80Χ90';
product86.titleItem = 'Τραπέζι Καφενείου';
product86.category = 'ΤΡΑΠΕΖΙΑ';
product86.xondriki.minSalesProducts = 3;
product86.xondriki.priceItem = 85;
product86.id = '86';

//product87----------------------------------------------------------------------------------------------------------------------------
product87.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp';
product87.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product87.codeModelItem = '100.300.147';
product87.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 90Χ90';
product87.titleItem = 'Τραπέζι Καφενείου';
product87.category = 'ΤΡΑΠΕΖΙΑ';
product87.xondriki.minSalesProducts = 3;
product87.xondriki.priceItem = 90;
product87.id = '87';

//product88----------------------------------------------------------------------------------------------------------------------------
product88.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
product88.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product88.codeModelItem = '100.300.148';
product88.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 100Χ80';
product88.titleItem = 'Τραπέζι Καφενείου';
product88.category = 'ΤΡΑΠΕΖΙΑ';
product88.xondriki.minSalesProducts = 3;
product88.xondriki.priceItem = 100;
product88.id = '88';

//product89----------------------------------------------------------------------------------------------------------------------------
product89.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
product89.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product89.codeModelItem = '100.300.149';
product89.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 120Χ80';
product89.titleItem = 'Τραπέζι Καφενείου';
product89.category = 'ΤΡΑΠΕΖΙΑ';
product89.xondriki.minSalesProducts = 3;
product89.xondriki.priceItem = 110;
product89.id = '89';

//product90----------------------------------------------------------------------------------------------------------------------------
product90.images.center = stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp';
product90.images.others = [stadarURL.trapezia + 'tabla-elato/tabla-elato_1_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_2_normal.webp',
stadarURL.trapezia + 'tabla-elato/tabla-elato_3_normal.webp']
product90.codeModelItem = '100.300.150';
product90.nameModelItem = 'ΤΡΑΠΕΖΙ ΤΑΒΛΑ ΕΛΑΤΟ 140Χ80';
product90.titleItem = 'Τραπέζι Καφενείου';
product90.category = 'ΤΡΑΠΕΖΙΑ';
product90.xondriki.minSalesProducts = 3;
product90.xondriki.priceItem = 140;
product90.id = '90';