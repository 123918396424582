export const homeAnimationData = {
    numShowPanel: 1,
    panelChangeTime: 9000,

    sliderPositionLeftAnimation: [
        { left: ['0%', '100%', '-100%'] },
        { left: ['-100%', '0%', '100%'] },
        { left: ['100%', '-100%', '0'] }
    ],

    animationPanel: [false, false, false],

    start: {
        firstTimeAnimation: true,
        startAnimationTime: 300,
        animationTime: 3000,
        animationStart: false,
        animationEnd: false,
        awaitAfterAnimation: 2000
    },

    proionta: {
        startAnimation: 1000,
        firstTimeAnimation: true,
        startAnimationTime: 300,
        animationElementTime: 1200,
        animationStart: false,
        animationEnd: false,
        awaitAfterAnimation: 2000
    },

    creation: {
        firstTimeAnimation: true,
        startAnimationTime: 1000,
        animationTime: 2000,
        animationStart: false,
        animationEnd: false,
        awaitAfterAnimation: 2000
    }
};