//Reselect
import { createSelector } from 'reselect';

const selectCategories = state => state.categories;

export const selectCategory = createSelector(
    [selectCategories],
    categories => categories.categories ? Object.keys(categories.categories).map(key => categories.categories[key]) : []
);

export const selectIsFetching = createSelector(
    [selectCategories],
    categories => categories.isFetching
);