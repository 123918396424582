//React
import React from 'react';

//Sttyles
import {
    WrapDivPanel,
    PanelTitleContainer,
    ChoiseContainer,
    NoCostContainer,
    ChoiseInputContainer,
    FormContainer,
    ExtraChoiseContainer
} from '../select.extra.choise.style.jsx';

export const SelectLegsValue = ({ legsValues, calculateExtraPrice, selectExtraChoise, setChangesToState }) => {

    return (
        <WrapDivPanel>
            <PanelTitleContainer>Αλλαγή στα <strong>Πόδια</strong></PanelTitleContainer>
            <FormContainer style={{ display: 'flex' }}>


                <NoCostContainer cost={legsValues.price}
                    showNoCost={legsValues.showNoCost}>{legsValues.showNoCost ?
                        'Χωρίς \n Χρέωση'
                        :
                        `+${legsValues.price}€`}
                </NoCostContainer>

                <ExtraChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            id='legs-normal'
                            type='radio'
                            value='none'
                            name='legs'
                            onChange={() => {
                                calculateExtraPrice('minus', legsValues.price);
                                setChangesToState({ name: 'legs', values: { ...legsValues, showNoCost: true, value: 'none' } });
                            }}
                            defaultChecked={legsValues.value === 'none' ? true : false}
                        />
                        <label htmlFor="legs-normal">Κανονικά τετράγωνα</label>
                    </ChoiseContainer>

                    <ChoiseContainer>
                        <ChoiseInputContainer
                            id='legs-tornos'
                            type='radio'
                            value='Τορναριστά'
                            name='legs'
                            onChange={() => {
                                calculateExtraPrice('plus', legsValues.price);
                                setChangesToState({ name: 'legs', values: { ...legsValues, showNoCost: false, value: 'Τορναριστά' } });
                            }}
                            defaultChecked={legsValues.value === 'Τορναριστά' ? true : false}
                        />
                        <label htmlFor="legs-tornos">Τορναριστά</label>
                    </ChoiseContainer>
                
                </ExtraChoiseContainer>

            </FormContainer>
        </WrapDivPanel>
    )
};