//Style
import styled from "styled-components";

export const StepContainer = styled.div`
    width:5rem;
    user-select: none;
    ${props => props.select ?
        { cursor: 'pointer' }
        :
        { cursor: 'normal' }
    }

    ${props => !props.active && props.select && {
        ':hover': {
            '#js-title-step': { color: '#a1ddae' },
            '#circle-step': { borderColor: '#a1ddae' }
        }
    }}

    @media(max-width:1000px){
        width:4rem;
    }

    @media(max-width:1000px){
        width:3.5rem;
    }
`;

export const TitleContainer = styled.div`
    display:flex;
    justify-content:center;
    font-size: 2rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    ${props => props.active ?
        { color: 'green' }
        :
        { color: '#888' }
    }

    @media(max-width:1400px){
        font-size:1.7rem;
    }

    @media(max-width:1400px){
        font-size:1.3rem;
    }

    @media(max-width:500px){
        font-size:1rem;
    }
`;

export const CircleStepContainer = styled.div`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 10px solid;
    background-color:white;
    position: relative;
    z-index:100;

    ${props => props.active ?
        { borderColor: "green" }
        :
        { borderColor: '#888' }
    }

    &::after{
        content:'';

        ${props => props.active ?
            {
                width: '70%',
                height: '70%',
                borderRadius: '50%',
                backgroundColor: 'green',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
            }
            :
            null
        }
    }

    @media(max-width:1000px){
        width: 4rem;
        height: 4rem;
        border-width: 5px;
    }

    @media(max-width:1000px){
        width: 3.5rem;
        height: 3.5rem;
    }
`;