//Test
// {
//     id: 1,
//     autoExit: true,
//     showTime: 10,
//     waitTime: 4000,
//     endTime: 500,
//     msg: 'test Info Message',
//     mode: 'info'
// }

export const notificationsData = {
    notifications: []
};

//Einai gia thn dhmioyrgia neas notification
export const setNewNotification = (msg = 'none', mode = 'info', autoExit = true, showTime = 500, waitTime = 5000, endTime = 500) => {

    const newDate = new Date();
    const newKey = Math.random(newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds()) * 10;

    return ({
        id: newKey,
        autoExit: autoExit,
        showTime: showTime,
        waitTime: waitTime,
        endTime: endTime,
        msg: msg,
        mode: mode
    })
};