//React
import React from 'react';

//Style
import './contact-image.style.scss';

//Factory Images
const image1 = "https://images.zwgrafos.gr/contact/factory/image-1.webp";
const image2 = "https://images.zwgrafos.gr/contact/factory/image-2.webp";
const image3 = "https://images.zwgrafos.gr/contact/factory/image-3.webp";
const image4 = "https://images.zwgrafos.gr/contact/factory/image-4.webp";
//Shop Images
const image5 = "https://images.zwgrafos.gr/contact/shop/image-1.webp";
const image6 = "https://images.zwgrafos.gr/contact/shop/image-2.webp";
const image7 = "https://images.zwgrafos.gr/contact/shop/image-3.webp";
const image8 = "https://images.zwgrafos.gr/contact/shop/image-4.webp";


export const ContactImage = ({ contactItem }) => {

    return contactItem === 'factory' ?
        <div className='contact-images-all'>
            <div className='contact-images-all-image image-place-1'>
                <img src={image1} alt='image1'></img>
            </div>
            <div className='contact-images-all-image image-place-2'>
                <img src={image2} alt='image2'></img>
            </div>
            <div className='contact-images-all-image image-place-3'>
                <img src={image3} alt='image3'></img>
            </div>
            <div className='contact-images-all-image image-place-4'>
                <img src={image4} alt='image4'></img>
            </div>
        </div>

        :

        <div className='contact-images-all'>
            <div className='contact-images-all-image image-place-1'>
                <img src={image5} alt='image1'></img>
            </div>
            <div className='contact-images-all-image image-place-2'>
                <img src={image6} alt='image2'></img>
            </div>
            <div className='contact-images-all-image image-place-3'>
                <img src={image7} alt='image3'></img>
            </div>
            <div className='contact-images-all-image image-place-4'>
                <img src={image8} alt='image4'></img>
            </div>
        </div>
};