//Types
import { selectExtraChoiseTypes } from './selectExtraChoiseTypes';

//Data
import { selectExtraChoiseData } from './selectExtraChoiseData';

const INIT_STATE = selectExtraChoiseData

const selectExtraChoiseReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case selectExtraChoiseTypes.SET_NEW_EXTRA_VALUE:
            return { ...state, [action.payload.name]: { ...action.payload.values } }

        case selectExtraChoiseTypes.SET_NEW_EXTRA_PRICE:
            return { ...state, extraPrice: action.payload };

        case selectExtraChoiseTypes.SET_PRODUCT_VALES:
            return { ...state, product: action.payload }

        case selectExtraChoiseTypes.SET_DEFAULT_VALUES:
            return { ...selectExtraChoiseData }

        case selectExtraChoiseTypes.SET_EXTRA_CHOISE_OBJ:
            return { ...state, ...action.payload }

        //Kataxorei ta items poy einai na ginoun polisei gia to proion
        case selectExtraChoiseTypes.CHANGE_NEW_SALE_ITEMS:
            return { ...state, saleItems: action.payload }

        default:
            return { ...state };
    }
};

export default selectExtraChoiseReducer;