//React
import React from 'react';
//Redux
import { connect } from 'react-redux';
//Actions
import { setFilterCategory, setShowItems, setItemsPerPage } from '../../../redux/products-panel/products-panel.actions';
//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectorPagesProperties } from '../../../redux/products-panel/products-panel.selectors';


//Components
import FilterCategory from './fiter-category/filter.category.component.jsx';
import { } from '../bar-properties/properties.bar.component.jsx';
//Style
import './products.filter.style.scss';

//Code
import { checkForFilters } from './filter.code.js';

class ProductsFilter extends React.Component {

    //Function for Filters -> πρέπει να βρίσκει ποια κατηγορία είναι ενεργή και να την περναει στην redux
    filterSelectCategory = (names) => {

        //catch form Filter
        const formFilter = document.forms.formFilterJS;

        //New Array with only true values on checkbox
        const reseltTrueKey = [];

        //tsek for reselt and push true only
        names.forEach((name) => {
            formFilter.elements[name.name].checked && reseltTrueKey.push(name);
        });

        //setState with reselt
        reseltTrueKey.length ?
            //if have category filters selected
            this.props.setFilterCategory({ state: true, category: reseltTrueKey })
            :
            //if dont select anythink from categories
            this.props.setFilterCategory({ state: false, category: [] })
    }

    componentDidUpdate(prevProps) {
        const { pageProperties } = this.props;
        //Πρεπει να βλέπει αν υπαρχει Filter 
        if (pageProperties.filter !== prevProps.pageProperties.filter) {
            const filterItems = checkForFilters(pageProperties.filter, this.props.startItems);
            this.props.setShowItems(filterItems);
        }
    };

    render() {
        return (
            <div className='products-filter'>
                <span className='products-filter-title'>ΦΙΛΤΡΑ</span>
                <FilterCategory findSelectCategory={this.filterSelectCategory} filter={this.props.pageProperties.filter} />
            </div>
        );
    }
};

const mapStateToProps = createStructuredSelector({
    pageProperties: selectorPagesProperties
})

const mapDispatchToProps = dispatch => ({
    setFilterCategory: filters => dispatch(setFilterCategory(filters)),

    setShowItems: items => dispatch(setShowItems(items)),

    setItemsPerPage: () => dispatch(setItemsPerPage([]))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFilter);
