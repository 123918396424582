import React from 'react';

// import FormInput from '../Forminput/forminput.component';

import './contact.form.style.scss';

//Components
import { CircleContact } from '../circle_img/circle.component.jsx';


import { findContactPanel, setContactFormCenter } from './contact.form.js';

const ContactForm = () => (
    <React.Fragment>
        <div className='contactForm' id='contactForm'
            // onMouseEnter={findContactPanel}
            onMouseMove={findContactPanel}
            onMouseLeave={setContactFormCenter}
        >
            <div className='contact-form-panel' id='contactPanel' >
                <div className='contact-form-details'>
                    <h3 className='contact-text'>Επικοινωνίστε μαζί μας</h3>
                    <div className='contact-panel'>
                        <CircleContact name='phone' title='Τηλεφωνίστε μας' goFunction={() => window.location.href = 'tel:+036941411448'} />
                        <CircleContact name='email' title='Στείλτε μας email' goFunction={() => window.open('mailto:<info@zwgrafos.gr>')} />
                        <CircleContact name='facebook' title='Στείλτε μας μήνυμα στο Facebook' goFunction={() => window.open('https://www.facebook.com/%CE%9A%CE%B1%CF%81%CE%AD%CE%BA%CE%BB%CE%B5%CF%82-%CE%A4%CF%81%CE%B1%CF%80%CE%AD%CE%B6%CE%B9%CE%B1-%CE%96%CF%89%CE%B3%CF%81%CE%AC%CF%86%CE%BF%CF%82-1072643919521138/?ref=page_internal')} />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default ContactForm;