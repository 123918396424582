//React
import React from 'react';

//Redux
import { connect } from 'react-redux';
//Redux-Actions
import { setAddNewItem } from '../../redux/cart/cart.actions.js';

// Redux-Reselect
// import { createStructuredSelector } from 'reselect';

// Redux-Selectors

//components
// import Footer from '../../components/footer/footer.component';
import CategoriesOverview from '../../components/category-view/categories.component.jsx';
import PelatesContainer from '../../components/pelates-container/pelates-container.component.jsx';
// import Carousel from '../../components/panel-container/panel.container.component';
import ContactForm from '../../components/contactForm-container/contact.form.component.jsx';
import ReviewContainer from '../../components/review/review.component.jsx';
import { FactorDetails } from '../../components/factor-details/factor_details.components.jsx';
import HomeSlider from '../../components/homeSlider/home.slider.component.jsx';
import ProductsSlider from '../../components/products-slider/products.slider.component.jsx';

//Style
import './home.style.scss';
// import { CateroryStyle, CateroryGridStile, ContactStyle, PelatesStyle, ReviewsStyle } from './home.style.js';


const HomePage = (props) => {

    return (
        <div className='home'>

            <div className='home-slider'>
                <HomeSlider play={true}
                //play (true or false) Animation
                />
            </div>

            <FactorDetails />

            <ProductsSlider />

            <div className='home-category'>
                <div className='home-category-grid'>
                    <CategoriesOverview />
                </div>
            </div>

            <div className='home-contact'>
                <ContactForm />
            </div>

            <div className='home-pelates'>
                <PelatesContainer />
            </div>

            <div className='home-reviews'>
                <ReviewContainer />
            </div>

        </div>
    );
}

const mapStateToProps = ({ cart: { cartItem } }) => ({
    cartItem
});

const mapDispatchToProps = dispatch => ({
    setAddNewItem: cart => dispatch(setAddNewItem(cart))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);